//#region Imports
import React, { useState } from 'react';
import { Button } from 'primereact/button';
import PopUp from './PopUps';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
//#endregion

const InfoCard = ({
  data,
  whatsappUrl,
  User,
  testType,
  handleAccessUpdate,
}) => {
  //#region States
  const [flipped, setFlipped] = useState(false);
  const [purchasePopUp, setPurchasePopUp] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const handleFlip = () => {
    setFlipped(!flipped);
  };
  //#endregion

  //#region Start Now Token Check and Access Validation
  const handleStartNowClick = () => {
    const authToken = localStorage.getItem('authToken');

    if (authToken == null || authToken === '') {
      navigate('/login', { state: { from: location.pathname }, replace: true });
    } else if (authToken != null || authToken !== '') {
      if (User?.userInfo?.hasAccess && User.userInfo[`access${testType}`]) {
        data.handleStartNowClick();
        setPurchasePopUp(false);
      } else {
        setPurchasePopUp(true);
      }
    }
  };
  //#endregion

  //#region Answered Change Color
  const isAnswered = () => {
    return User?.userInfo?.hasAccess && !User.userInfo[`access${testType}`];
  };

  const statusClass = isAnswered() ? 'green' : 'blue';
  //#endregion

  //#region Handle Whatsapp Request Request Whatsapp Message
  const handleSendWhatsappRequest = () => {
    setPurchasePopUp(false);
    setTimeout(() => {
      window.open(whatsappUrl, '_blank');
      navigate('/test');
    }, 300);
  };
  //#endregion

  return (
    <div className="infoCardContainer">
      <div className={`infoCard ${flipped ? 'infoCardFlipped' : ''}`}>
        <div className={`infoCardFront  ${statusClass} `}>
          <section
            style={{
              width: '100%',
              height: '200px',
              maxWidth: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img className="infoCardFrontImg" src={data.imgSrc} alt="img" />
          </section>
          <section className="infoCardFrontDetails">
            <div className={`infoCardFrontTitle  ${statusClass}`}>
              {data.title}
            </div>
            <h3 className="infoCardFrontSubheader">{data.subHeader}</h3>
            <div className="infoCardFrontDescriptionContainer">
              <div className="infoCardFrontDescriptionShadow"></div>
              <p className="infoCardFrontDescription">{data.description}</p>
              <div className="infoCardFrontButtonContainer">
                <Button
                  label={t('buttons.more')}
                  className={`button thin  ${statusClass}`}
                  onClick={handleFlip}
                />
              </div>
            </div>
          </section>
        </div>

        <div className={`infoCardBack  ${statusClass}`}>
          <div className={`infoCardBackTitle  ${statusClass}`}>
            {data.title}
          </div>
          <div className="infoCardBackDescriptionContainer">
            <p className="infoCardBackDescription">{data.description}</p>
          </div>
          <Button
            label={t('buttons.back')}
            className={`button thin  ${statusClass}`}
            onClick={handleFlip}
          />
          <Button
            label={t('buttons.start')}
            className={`button thin  ${statusClass}`}
            onClick={handleStartNowClick}
          />
        </div>
      </div>
      {purchasePopUp && (
        <PopUp
          type={'Purchase'}
          header={t('popUps.purchasePopUp.header')}
          visible={purchasePopUp}
          content={t('popUps.purchasePopUp.content')}
          // handlePrimaryAction={() => handleAccessUpdate()}
          // ActionLabel={'Api Request'}
          ActionLabel={t('popUps.purchasePopUp.text')}
          handlePrimaryAction={() => handleSendWhatsappRequest()}
          //handleSecondaryAction={() => setPurchasePopUp(false)}
          CancelLabel={t('buttons.back')}
          handleSecondaryAction={() => setPurchasePopUp(false)}
        />
      )}
    </div>
  );
};

export default InfoCard;
