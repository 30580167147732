import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Use named import
import GetAllUsersInfo from '../../hooks/useAdmin';
import PrimeReactDataTable from '../SharedComponents/DataTable';
import { Tag } from 'primereact/tag';
import { Backdrop, CircularProgress } from '@mui/material';
import ControlledDoc from '../SharedComponents/Accordion';

const AdminPage = () => {
  const { users, loading, error } = GetAllUsersInfo();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    console.log('Token in AdminPage:', token); // Debugging token presence

    if (!token) {
      navigate('/login', { state: { from: location.pathname }, replace: true });
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      //console.log('Decoded Token in AdminPage:', decodedToken); // Debugging token decoding

      const userRole =
        decodedToken[
          'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
        ];
      // console.log('User Role in AdminPage:', userRole);

      if (userRole !== 'Admin') {
        navigate('/login', {
          state: { from: location.pathname },
          replace: true,
        });
      }
    } catch (error) {
      console.error('Failed to decode token in AdminPage:', error);
      navigate('/login', { state: { from: location.pathname }, replace: true });
    }
  }, [navigate, location.pathname]);

  if (error)
    return navigate('/login', {
      state: { from: location.pathname },
      replace: true,
    });
  return (
    <div className="admin">
      <div className="copyrightHandle">
        <p className="copyright"></p>
      </div>

      <div className="admin-header">
        <h1>Start Right Admin Page</h1>
        <hr />
        <ControlledDoc />
      </div>
      <PrimeReactDataTable data={users} />
      <div className="copyrightHandle">
        <p className="copyright">
          Developed and Designed by Visionary Vortex Media
        </p>
      </div>
      {loading && (
        <Backdrop open={loading} style={{ zIndex: 1200 }}>
          <CircularProgress size={60} />
        </Backdrop>
      )}
    </div>
  );
};

export default AdminPage;
