import jsPDF from 'jspdf';
import { deatilsMajorsList } from '../../Data/DetailsMajorList';

const getMajorDetails = (majorName) =>
  deatilsMajorsList[majorName] || {
    engName: majorName,
    arName: majorName,
    enDescription: '',
    arDescription: '',
  };

const fetchImageAsDataURL = (url) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL('image/png');
      resolve(dataURL);
    };
    img.onerror = reject;
    img.src = url;
  });

const generatePDF = async () => {
  // Retrieve user data from local storage
  const userName = localStorage.getItem('userName');
  const userEmail = localStorage.getItem('email');
  const studentPhoneNumber = localStorage.getItem('phoneNumber');
  const testDate = new Date().toLocaleDateString();

  // Retrieve and parse test results from local storage
  const topSelectedMajorsStorage =
    JSON.parse(localStorage.getItem('topSelectedMajors')) || [];
  const topSelectedMajors = topSelectedMajorsStorage || [];
  const topNonSelectedMajorsStorage =
    JSON.parse(localStorage.getItem('topNonSelectedMajors')) || [];
  const topNonSelectedMajors = topNonSelectedMajorsStorage || [];

  // Log data for debugging
  console.log('Top Selected Majors:', topSelectedMajors);
  console.log('Top Non-Selected Majors:', topNonSelectedMajors);

  const doc = new jsPDF();
  const margin = 15;
  let yOffset = margin;
  const lineHeight = 10;
  const pageHeight = doc.internal.pageSize.height - margin * 2;

  // Load image from public directory and add to PDF
  const imageUrl = '/images/pdfHeader.png';
  try {
    const svgImageDataURL = await fetchImageAsDataURL(imageUrl);
    doc.addImage(svgImageDataURL, 'PNG', margin, yOffset, 190, 35);
    yOffset += 20; // Adjust the offset based on the image height
  } catch (error) {
    console.error('Error loading image:', error);
  }

  // Function to add text with page break
  const addTextWithPageBreak = (text, fontSize = 12, fontStyle = 'normal') => {
    doc.setFontSize(fontSize);
    doc.setFont(undefined, fontStyle);
    const pageWidth = doc.internal.pageSize.width - margin * 2;
    const textLines = doc.splitTextToSize(text, pageWidth);
    textLines.forEach((line) => {
      if (yOffset + lineHeight > pageHeight) {
        doc.addPage();
        yOffset = margin;
      }
      doc.text(line, margin, yOffset);
      yOffset += lineHeight;
    });
  };

  // Add Certificate Title
  yOffset += lineHeight * 1;
  yOffset += lineHeight * 2;
  doc.setFontSize(22);
  doc.setTextColor(0, 51, 102); // Dark Blue
  addTextWithPageBreak('Certificate of Achievement', 22, 'bold');
  yOffset += lineHeight * 1;

  // Add Platform Name
  doc.setFontSize(18);
  addTextWithPageBreak('Presented by Start Right', 18, 'bold');
  yOffset += lineHeight * 1;

  // Add Motivational Message
  doc.setFontSize(14);
  doc.setTextColor(128, 128, 128); // Medium Gray
  addTextWithPageBreak(
    'Congratulations on completing your career test! This certificate recognizes your dedication to exploring potential career paths. Keep striving for your dreams and turning them into reality.'
  );
  yOffset += lineHeight * 1;

  // Add User Information with different styles for labels and values
  const addUserInfo = (label, value) => {
    doc.setFontSize(12);
    doc.setFont(undefined, 'bold');
    doc.setTextColor(0, 0, 0); // Black
    addTextWithPageBreak(`${label}:`, 12, 'bold');
    doc.setFont(undefined, 'normal');
    doc.setTextColor(128, 128, 128); // Medium Gray
    addTextWithPageBreak(value, 12, 'normal');
  };

  addUserInfo('Name', userName);
  addUserInfo('Email', userEmail);
  addUserInfo('Phone Number', studentPhoneNumber);
  addUserInfo('Test Date', testDate);

  doc.addPage();
  yOffset = margin;
  yOffset += lineHeight * 1;

  // Add Top Selected Majors
  doc.setFontSize(14);
  doc.setTextColor(0, 51, 102); // Dark Blue
  addTextWithPageBreak('Top Selected Majors:', 18, 'bold');
  doc.setFontSize(12);
  addTextWithPageBreak(
    'This group consists of majors derived from your responses in the initial section of the career assessment, which includes insights on industries, workplaces, job types, preferred courses, and your academic performance. Additionally, it incorporates your answers from the simulation test to provide a more comprehensive match.'
  );
  yOffset += lineHeight * 0.5;

  if (topSelectedMajors.length === 0) {
    doc.setTextColor(128, 128, 128);
    doc.setFontSize(10);
    addTextWithPageBreak(
      'While your preferred industries didn’t yield top results this time, the test has highlighted other promising majors that align with your skills and interests. Check out the alternative majors section on the next page for more options.'
    );
  } else {
    topSelectedMajors.forEach((major) => {
      const name = major.name; // Access name property
      const details = getMajorDetails(name);
      doc.setFont(undefined, 'bold');
      doc.setTextColor(0, 0, 0); // Black
      addTextWithPageBreak(name, 12, 'bold');
      doc.setFont(undefined, 'normal');
      doc.setTextColor(128, 128, 128); // Medium Gray
      addTextWithPageBreak(`Description: ${details.enDescription}`);
    });

    // Add Definition below Top Selected Majors
    yOffset += lineHeight * 1;
    doc.setFontSize(12);
    doc.setFont(undefined, 'normal');
    doc.setTextColor(0, 0, 0); // Black
  }

  // Add Alternative Majors
  if (topNonSelectedMajors.length > 0) {
    doc.addPage();
    yOffset = margin;
    yOffset += lineHeight * 0.5;

    doc.setFontSize(14);
    doc.setTextColor(0, 51, 102); // Dark Blue
    addTextWithPageBreak('Alternative Majors:', 18, 'bold');
    doc.setFontSize(12);
    addTextWithPageBreak(
      'This group is based solely on your responses to the simulation questions. This means these majors do not align with the answers you provided in the first section of the career assessment (such as industries, workplaces, work type, preferred courses, and grades).'
    );
    yOffset += lineHeight * 1;

    topNonSelectedMajors.forEach((major) => {
      const name = major.name; // Access name property
      const details = getMajorDetails(name);
      doc.setFont(undefined, 'bold');
      doc.setTextColor(0, 0, 0); // Black
      addTextWithPageBreak(name, 12, 'bold');
      doc.setFont(undefined, 'normal');
      doc.setTextColor(128, 128, 128); // Medium Gray
      addTextWithPageBreak(`Description: ${details.enDescription}`);
    });

    // Add Definition below Top Non-Selected Majors
    yOffset += lineHeight * 1;
    doc.setFontSize(12);
    doc.setFont(undefined, 'normal');
    doc.setTextColor(0, 0, 0); // Black
  }

  // Save the PDF
  doc.save('career_test_certificate.pdf');
};

export default generatePDF;
