import React, { useEffect } from 'react';
import DownloadCatalogueButton from '../SharedComponents/DownloadCatalogueButton';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';

const Catalogue = () => {
  const [studentRef, studentView] = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });

  const { t } = useTranslation();
  useEffect(() => {
    const studentContainer = document.querySelector(
      '.catalogueBg .studentContainer .student '
    );
    if (studentView) {
      studentContainer.classList.add('flipImage');
    } else {
      studentContainer.classList.remove('flipImage');
    }
  }, [studentView]);

  return (
    <div className="catalogueBg">
      <div className="content">
        <div
          className="title catalogueTitle"
          dangerouslySetInnerHTML={{ __html: t('catalogue.title') }}
        />
        <p className="description descriptionCatalogue">
          {t('catalogue.description')}
        </p>
        <DownloadCatalogueButton />
      </div>
      <div className="studentContainer">
        <img
          className="student"
          ref={studentRef}
          src="images/graduated.svg"
          alt="student"
        />
      </div>
    </div>
  );
};

export default Catalogue;
