import React from 'react';
import { socialMedia } from '../../Data/Data';
import { useTranslation } from 'react-i18next';

const Services = () => {
  const { t } = useTranslation();
  return (
    <div className="socialHolder" id="socialHolder">
      <h2 className="title titleSocial">{t('services.title')}</h2>
      <div className="container">
        {socialMedia.map((item) => (
          <button key={item.name} className="component" onClick={item.onClick}>
            <i className={item.icon}></i>
            <span className="name">{item.name}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Services;
