import { extractErrorMessages } from '../utils/utils';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const login = async (credentials) => {
  try {
    const response = await fetch(`${BASE_URL}/api/Account/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    });

    if (!response.ok) {
      const errorData = await response.json();
      const errorMessages = extractErrorMessages(errorData);
      throw new Error(errorMessages);
    }

    const data = await response.json();

    if (data.flag === false) {
      throw new Error(data.message || 'An unexpected error occurred.');
    }

    localStorage.setItem('authToken', data.token);

    console.log('Token::', data.token);
    return data;
  } catch (error) {
    console.error('Login error:', error);
    throw new Error(
      error.message || 'An unexpected error occurred. Please try again later.'
    );
  }
};

export const register = async (userData) => {
  try {
    const response = await fetch(`${BASE_URL}/api/Account/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Registration failed');
    }

    const data = await response.json();

    console.log('API Response Data:', data);

    if (data.flag === true) {
      return data;
    } else {
      throw new Error(data.message || 'Registration failed');
    }
  } catch (error) {
    console.error('Registration API error:', error);
    throw new Error(error.message || 'Registration failed');
  }
};

export const updateUserAccess = async (emails) => {
  try {
    const email = localStorage.getItem('email');

    if (!email) {
      throw new Error('Email not found.');
    }

    const response = await fetch(`${BASE_URL}/api/Account/updateAccess`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ emails }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error('Error in updateUserAccess:', error.message);
    throw error;
  }
};

export const getUserInfo = async (email) => {
  try {
    if (!email) {
      throw new Error('Email not found.');
    }

    const response = await fetch(
      `${BASE_URL}/api/Account/GetUserInfoByEmail?email=${encodeURIComponent(
        email
      )}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(
        `Failed to fetch user information. Status: ${response.status}, Message: ${errorText}`
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error in fetching the user:', error.message);
    throw error;
  }
};

// Submit only the rating
export const submitRating = async (email, rating) => {
  try {
    const requestBody = {
      email,
      rating,
    };

    const response = await fetch(`${BASE_URL}/api/Account/submitRating`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error in updating Rating:', error.message);
    throw error;
  }
};

// Submit only the feedback
export const submitFeedback = async (email, feedback) => {
  try {
    const requestBody = {
      email,
      feedback,
    };

    const response = await fetch(`${BASE_URL}/api/Account/submitFeedback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error in updating Feedback:', error.message);
    throw error;
  }
};
