const personalityArTestData = {
  'شخصية عملية': [
    {
      id: 1,
      question: 'هل تفضل المهام الروتينية والمتكررة؟',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 2,
      question:
        'هل تصف نفسك كشخص عملي يستمتع بالتعامل مع مشاكل العالم الحقيقية؟',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 3,
      question: 'هل تستمتع بالمهام التي تتيح لك رؤية نتائج فورية وملموسة؟',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 4,
      question:
        'هل تشعر براحة أكبر في المهام ذات التسلسل الهرمي الواضح والأدوار المحددة؟',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 5,
      question:
        'هل تعتمد أكثر على الخبرة العملية والأدلة الملموسة عند اتخاذ القرارات؟',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 6,
      question: 'أنا شخص يهدف إلى الاستقرار',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 7,
      question: 'ماذا ستفعل إذا تضررت قطعة من الآلات؟',
      options: [
        'محاولة اكتشاف السبب وتصليح الآلة',
        'ارسالها الى مكتب تصليح الآلات',
        'عدم تصليحها',
        'رميها',
      ],
      answer: 'محاولة اكتشاف السبب وتصليح الآلة',
    },
  ],
  'شخصية تحليلية': [
    {
      id: 8,
      question:
        'هل تشارك في الأنشطة التي تنطوي على البحث والتحليل والمساعي الفكرية؟',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 9,
      question: 'هل تستخدم الأساليب التحليلية والمبنية على الأدلة؟',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 10,
      question: 'هل تشكك في الافتراضات وتبحث عن الأدلة قبل قبول الاستنتاجات؟',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 11,
      question: 'هل تحب الأفكار المعقدة؟',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 12,
      question: 'أحب أن أفكر في الماورائيات',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 13,
      question: 'ما هي أنواع الكتب أو المقالات التي تستمتع بقراءتها؟',
      options: ['التحقيقية /الاستقصائية', 'السياسية', 'الاجتماعية', 'الفنية'],
      answer: 'التحقيقية /الاستقصائية',
    },
    {
      id: 14,
      question: 'ما نوع بيئة العمل التي تزدهر فيها؟',
      options: ['هادئة', 'مزدحمة'],
      answer: 'هادئة',
    },
  ],
  'شخصية فنية': [
    {
      id: 15,
      question: 'هل لديك أي هوايات فنية كالرسم أو الكتابة أو عزف الموسيقى؟',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 16,
      question: 'هل تفضل الوظائف التي توفر الحرية الإبداعية والمرونة؟',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 17,
      question: 'عندما تواجه مشكلة ما، هل تتوصل إلى حلول مبتكرة وابداعية؟',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 18,
      question:
        'هل تتحدى المعايير التقليدية وتبحث عن طرق فريدة للتعبير عن نفسك؟',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 19,
      question: 'أنا شخص يحب التغيير المتكرر',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 20,
      question: 'لدي خيال حي',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 21,
      question: 'ما هي أنواع البرامج التلفزيونية التي تفضلها أكثر؟',
      options: ['فنية', 'كوميدية', 'سياسية', 'وثائقية', 'اجتماعية'],
      answer: 'فنية',
    },
  ],
  'شخصية اجتماعية': [
    {
      id: 22,
      question: 'هل ترغب بالعمل ضمن فريق عمل والتعاون والتفاعل معهم؟',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 23,
      question: 'هل شاركت في أي عمل تطوعي أو خدمة مجتمعية؟',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 24,
      question:
        'هل تفضل الوظائف التي تتضمن العمل مع الآخرين بدلاً من العمل بمفردك؟',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 25,
      question: 'هل تقوم بتكوين صداقات جديدة بانتظام؟',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 26,
      question: 'أنا شخص أستمتع بالعمل مع أشخاص من مختلف التخصصات والمهارات',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 27,
      question: 'ابذل جهدًا لتكون مشهورًا',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 28,
      question: 'إذا كان هناك شخص يبكي في حضورك',
      options: ['محاولة التحدث معه ومساعدته', 'لا تتخذ أي إجراء'],
      answer: 'محاولة التحدث معه ومساعدته',
    },
  ],
  'شخصية خلاقة': [
    {
      id: 29,
      question: 'هل لديك رؤية واضحة للمستقبل وخطط استراتيجية لتحقيق أهدافك؟',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 30,
      question: 'أنا شخص يحب رؤية الصورة الأكبر',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 31,
      question: 'أحب خلق واستكشاف أفكار جديدة',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 32,
      question:
        'أشعر بالراحة عند تولي الأدوار القيادية وتوجيه الآخرين نحو هدف مشترك',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 33,
      question: 'هل تخاطر في اتخاذ القرارات؟',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 34,
      question: 'ما هو حلمك؟',
      options: [
        'الترقية من منصب إلى آخر',
        'كسب المال',
        'امتلك مشروعًا تجاريًا',
        'ان أكون مؤثر على المجتمع',
        'مساعدة الضعفاء',
      ],
      answer: 'امتلك مشروعًا تجاريًا',
    },
    {
      id: 35,
      question: 'كيف تتعامل مع إدخال التقنيات أو العمليات الجديدة في العمل؟',
      options: [
        'مقاومة التقنيات الجديدة وتفضيل الطرق القديمة',
        'اعتمادها بتردد ولكن استغرق وقتًا طويلاً للتكيف معها',
        'استخدمها فقط عند الضرورة واتجنب تعلم المزيد',
        'اتبعها وأسعى لفهم فوائدها',
      ],
      answer: 'اتبعها وأسعى لفهم فوائدها',
    },
  ],
  'شخصية تقليدية': [
    {
      id: 36,
      question:
        'هل تحدد أولويات المهام وتخطط لها بشكل فعال، وغالباً ما تكملها قبل الموعد النهائي بوقت طويل؟',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 37,
      question: 'هل ترغب في التحقق من عمل الأشخاص قبل الانتهاء منه؟',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 38,
      question: 'هل تظهر الاستقلالية والتنظيم والاهتمام بالتفاصيل؟',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 39,
      question: 'هل تفضل اتباع القواعد والإرشادات والقوانين؟',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 40,
      question: 'لا أستطيع العمل في بيئة غير منظمة',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 41,
      question: 'هل تحب المهام التي تحتاج إلى الدقة؟',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 42,
      question: 'ما هي المهارات التي تشعر بالثقة فيها أكثر؟',
      options: [
        'التفكير التحليلي وحل المشكلات',
        'الإبداع والابتكار',
        'مهارات التعامل والتواصل مع الآخرين',
        'المهارات التقنية (مثل البرمجة والهندسة)',
        'القيادة والعمل الجماعي',
      ],
      answer: 'التفكير التحليلي وحل المشكلات',
    },
  ],
};

export default personalityArTestData;

export const personalityArThresholdScoring = {
  'شخصية عملية': {
    thresholds: {
      50: 'يظهر ميلاً معتدلاً نحو التطبيق العملي والمنطق، متوازنًا مع سمات أخرى. أنت تفضل عادةً الحلول الفعّالة والواقعية ولكنك تظل منفتحًا على الأفكار المبتكرة والاعتبارات العاطفية.',
      55: 'أنت تميل بشكل أكبر نحو التطبيق العملي والمنطق، مع الحفاظ على بعض التوازن مع سمات أخرى. أنت تركز بشكل أساسي على الحلول الواقعية والفعّالة، وغالبًا ما تقترب من المشكلات بعقلية واضحة ومنهجية.',
      60: 'لديك تفضيل قوي للتطبيق العملي والمنطق والكفاءة. أنت تعطي الأولوية باستمرار للحلول الواقعية وتقنيات حل المشكلات الفعّالة. أنت متفوق في تنظيم عملك وإدارة المهام بناءً على الأهمية والمواعيد النهائية.',
      70: 'أنت تركز بشكل كبير على التطبيق العملي والتفكير المنطقي ومدفوعًا بشكل أساسي بالحاجة إلى الكفاءة والحلول الواقعية، وغالبًا ما تنظر إلى المشكلات من خلال عدسة ما هو الأكثر فعالية وقابلية للتطبيق.',
    },
  },
  'شخصية تحليلية': {
    thresholds: {
      50: 'لديك ميل معتدل نحو تحليل البيانات والاستدلال المنطقي والفحص التفصيلي.',
      55: 'أنت تميل قليلاً نحو التفكير التحليلي، وتفضل الاستدلال المنطقي والفحص التفصيلي في معظم المواقف. أنت مرتاح في التعمق في البيانات وتحديد الأنماط واتخاذ القرارات بناءً على التحليل الشامل.',
      60: 'أنت تُظهر تفضيلًا ملحوظًا للتفكير التحليلي. أنت تعطي الأولوية للاستدلال المنطقي وتحليل البيانات والفحص التفصيلي في نهجك تجاه المشكلات واتخاذ القرار.',
      70: 'أنت تركز بشكل كبير على الاستدلال المنطقي وتحليل البيانات والتفكير النقدي. أنت تعتمد بشكل أساسي على الفحص التفصيلي والأساليب المنهجية لحل المشكلات واتخاذ القرارات. أنت تتفوق في الأدوار التي تتطلب الدقة والاهتمام بالتفاصيل والتحليل المنهجي.',
    },
  },
  'شخصية فنية': {
    thresholds: {
      50: 'يشير إلى نهج متوازن بين الإبداع الفني والسمات الأخرى. لديك ميل معتدل نحو الإبداع والخيال والتعبير الفني ولكنك مرتاح بنفس القدر للاعتماد على المنطق والعملية والتفكير التحليلي عند الضرورة.',
      55: 'أنت تميل قليلاً نحو الإبداع الفني، وتفضل الخيال والتعبير الفني في معظم المواقف. أنت مرتاح للخوض في المشاريع الإبداعية، وتوليد أفكار مبتكرة، وتقدير الجمال والجماليات.',
      60: 'يظهر تفضيلًا ملحوظًا للإبداع والتعبير الفني. أنت تعطي الأولوية للخيال والابتكار والجماليات في نهجك للحياة والعمل. أنت متفوق في توليد أفكار فريدة، وتقدير الجمال، والانخراط في المشاريع الإبداعية.',
      70: 'أنت تركز بشكل كبير على الإبداع والخيال والتعبير الفني. أنت تعتمد بشكل أساسي على الغرائز الإبداعية وتقدير الجمال والجماليات. أنت متفوق في الأدوار التي تتطلب الأصالة والابتكار والذوق الفني.',
    },
  },
  'شخصية اجتماعية': {
    thresholds: {
      50: 'يشير إلى نهج متوازن بين المشاركة الاجتماعية والسمات الأخرى. لديك ميل معتدل نحو التفاعل الاجتماعي وبناء العلاقات والعمل الجماعي ولكنك مرتاح بنفس القدر للمهام الانفرادية أو التفكير التحليلي أو العمل المستقل عند الضرورة.',
      55: 'تميل قليلاً نحو المشاركة الاجتماعية، وتفضل التفاعل والتعاون وبناء العلاقات في معظم المواقف. أنت مرتاح في إعدادات المجموعة، وتستمتع بالتواصل مع الآخرين، وتتقن تعزيز العمل الجماعي.',
      60: 'لديك تفضيل ملحوظ للتفاعل الاجتماعي وتعطي الأولوية لبناء العلاقات والعمل الجماعي والتواصل في نهجك للحياة والعمل. أنت تتفوق في العمل في إعدادات المجموعة، وتعزيز التعاون والتواصل مع الآخرين.',
      70: 'أنت تركز بشكل كبير على التفاعل الاجتماعي وبناء العلاقات والعمل الجماعي وتعتمد بشكل أساسي على المهارات الاجتماعية وتستمتع بالتواصل مع الآخرين. أنت تتفوق في الأدوار التي تتطلب مهارات شخصية قوية والتعاون والتواصل.',
    },
  },
  'شخصية خلاقة': {
    thresholds: {
      50: 'لديك ميل معتدل نحو الإبداع والتفكير المستقبلي وتبني الأفكار الجديدة ولكنك مرتاح بنفس القدر للطرق التقليدية والعملية والعمليات الراسخة عند الضرورة.',
      55: 'تميل أكثر قليلاً نحو الابتكار، وتفضل الإبداع والتفكير المستقبلي وتبني الأفكار الجديدة في معظم المواقف. أنت مرتاح في استكشاف المفاهيم الجديدة وتنفيذ التغييرات.',
      60: 'لديك تفضيل ملحوظ للإبداع والتفكير المستقبلي وتعطي الأولوية لتوليد أفكار جديدة وتبني التغيير وتحدي الطرق التقليدية.',
      70: 'أنت تركز بشدة على الإبداع والتفكير المستقبلي وتبني الأفكار الجديدة وتعتمد بشكل أساسي على الغرائز المبتكرة وتستمتع باستكشاف المفاهيم الجديدة. أنت تتفوق في الأدوار التي تتطلب الأصالة والقدرة على التكيف وتنفيذ حلول جديدة.',
    },
  },
  'شخصية تقليدية': {
    thresholds: {
      50: 'لديك ميل معتدل نحو الأساليب الراسخة والتفكير التقليدي والالتزام بالمعايير ولكنك مرتاح بنفس القدر لاحتضان التغيير والابتكار والأفكار الجديدة عند الضرورة.',
      55: 'تميل قليلاً نحو القيم التقليدية، وتفضل الأساليب الراسخة والتفكير التقليدي والالتزام بالمعايير في معظم المواقف. أنت مرتاح للروتين واتباع الإجراءات المحددة والحفاظ على الاستقرار.',
      60: 'لديك تفضيل ملحوظ للأساليب الراسخة والتفكير التقليدي والالتزام بالمعايير. وتعطي الأولوية للحفاظ على الاستقرار واتباع الروتين واحترام التقاليد في تعاملك مع الحياة والعمل.',
      70: 'أنت تركز بشدة على الأساليب الراسخة والتفكير التقليدي والالتزام بالمعايير وتتفوق في الأدوار التي تتطلب الاتساق والموثوقية والنظام.',
    },
  },
};
