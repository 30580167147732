import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Use named import

const PrivateRoute = ({ element: Component, requiredRole }) => {
  const location = useLocation();
  const token = localStorage.getItem('authToken');
  const navigate = useNavigate();

  if (!token) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  let userRole = null;

  try {
    const decodedToken = jwtDecode(token);
    userRole =
      decodedToken[
        'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
      ];
  } catch (error) {
    console.error('Failed to decode token:', error);
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  if (requiredRole && userRole !== requiredRole) {
    navigate('/login', { state: { from: location.pathname }, replace: true });
  }

  return Component;
};

export default PrivateRoute;
