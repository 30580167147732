//#region Imports
import React, { useRef, useState, useEffect } from 'react';
import SimulationTest from './SimulationTest';
import MenuBar from '../../../Header/MenuBar';
import IndustryWorkplace from './IndustryWorkplace';
import { useTranslation } from 'react-i18next';
import RequiredSubject from './RequiredSubjects';
import { Button, CircularProgress } from '@mui/material';
import PopUp from '../../../SharedComponents/PopUps';
//#endregion

export default function BasicDemo() {
  //#region States
  const { i18n, t } = useTranslation();
  const industryWorkplaceRef = useRef(null);
  const simulationRef = useRef(null);
  const requiredSubjectRef = useRef(null);
  const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';
  const [showLoader, setShowLoader] = useState(false);
  //#endregion

  //#region State to track if all required subjects are answered
  const [requiredSubjectsCompleted, setRequiredSubjectsCompleted] =
    useState(false);
  const [simulationCompleted, setSimulationCompleted] = useState(false);

  useEffect(() => {
    const completed = localStorage.getItem('requiredSubjectsCompleted');
    if (completed === 'true') {
      setRequiredSubjectsCompleted(true);
    }
  }, []);
  //#endregion

  //#region Get selectedIndustries and selectedWorkplaces to know what section to hide
  const quizData = localStorage.getItem('filterMajor');
  let selectedIndustries = [];
  let selectedWorkplaces = [];

  if (quizData) {
    try {
      const parsedQuizData = JSON.parse(quizData);
      selectedIndustries = parsedQuizData.selectedIndustries || [];
      selectedWorkplaces = parsedQuizData.selectedWorkplaces || [];
    } catch (e) {
      console.error('Error parsing filterMajor data:', e);
    }
  }
  //#endregion

  //#region Handle Next Button click to other section and Submit the simulation Test
  const handleNext = () => {
    if (industryWorkplaceRef.current) {
      industryWorkplaceRef.current.saveAnswers();
      if (!industryWorkplaceRef.current.checkAllQuestionsAnswered()) {
        industryWorkplaceRef.current.openIncompleteTestDialogue();
      } else {
        window.location.reload();
        window.scrollTo(0, 0);
      }
    } else {
      console.error('IndustryWorkplace component is not mounted');
    }
  };

  const handleNextRequiredSubjects = () => {
    if (requiredSubjectRef.current) {
      requiredSubjectRef.current.saveAnswers();
      if (!requiredSubjectRef.current.checkAllQuestionsAnswered()) {
        requiredSubjectRef.current.openIncompleteTestDialogue();
      } else {
        setRequiredSubjectsCompleted(true);
        localStorage.setItem('requiredSubjectsCompleted', 'true');
        window.scrollTo(0, 0);
      }
    } else {
      console.error('RequiredSubject component is not mounted');
    }
  };

  const handleNextSimulation = () => {
    if (simulationRef.current) {
      const majorsAndScores = simulationRef.current.getMajorsAndScores();
      console.log('Majors and Scores:', majorsAndScores);
      if (!simulationRef.current.checkAllQuestionsAnswered()) {
        simulationRef.current.openIncompleteTestDialogue();
      } else {
        simulationRef.current.openCompleteTestDialogue();
      }
    } else {
      console.error('SimulationTest component is not mounted');
    }
  };
  //#endregion

  return (
    <div dir={direction}>
      <MenuBar />
      <div className="TestPage">
        {/* <div className="contentTestMobile">
          <p className="content">{t('careerPage.content')}</p>
        </div> */}
        <div className="contentTestDesktop before">
          {/* <div className="contentHolder">
            <p className="content">{t('careerPage.content')}</p>
          </div> */}
        </div>
        <div className="stepperHolder">
          <div className="card flex justify-content-center">
            {selectedIndustries.length !== 2 &&
              selectedWorkplaces.length !== 2 &&
              !requiredSubjectsCompleted && (
                <>
                  <div className="testExplain" dir={direction}></div>
                  <div className="title">{t('careerPage.industry.header')}</div>
                  <p className="subtitle">
                    {t('careerPage.industry.description')}
                  </p>
                  <hr />
                  <IndustryWorkplace
                    ref={industryWorkplaceRef}
                    handleNext={handleNext}
                  />

                  <div className="flex panelButtonHolder " dir={direction}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className="panelButton muiStepperPrimaryBtn"
                      disabled={showLoader}
                    >
                      {showLoader ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        t('buttons.submit')
                      )}
                    </Button>
                  </div>
                </>
              )}

            {selectedIndustries.length === 2 &&
              selectedWorkplaces.length === 2 &&
              !requiredSubjectsCompleted && (
                <>
                  <div className="testExplain" dir={direction}></div>
                  <div className="title">{t('careerPage.subject.header')}</div>
                  <p className="subtitle">
                    {t('careerPage.subject.description')}
                  </p>
                  <hr />
                  <RequiredSubject ref={requiredSubjectRef} />
                  <div className="flex panelButtonHolder">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNextRequiredSubjects}
                      className="panelButton muiStepperPrimaryBtn"
                      disabled={showLoader}
                    >
                      {showLoader ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        t('buttons.submit')
                      )}
                    </Button>
                  </div>
                </>
              )}

            {/* Render Simulation only if required subjects are completed */}
            {selectedIndustries.length === 2 &&
              selectedWorkplaces.length === 2 &&
              requiredSubjectsCompleted && (
                <>
                  <div className="testExplain" dir={direction}></div>
                  <div className="title">
                    {t('careerPage.simulation.header')}
                  </div>
                  <p
                    className="subtitle"
                    dangerouslySetInnerHTML={{
                      __html: t('careerPage.simulation.description'),
                    }}
                  />
                  <hr /> <SimulationTest ref={simulationRef} />
                  <div className="flex panelButtonHolder">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNextSimulation}
                      className="panelButton muiStepperPrimaryBtn"
                      disabled={showLoader}
                    >
                      {showLoader ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        t('buttons.submit')
                      )}
                    </Button>
                  </div>
                </>
              )}

            <hr />
          </div>
        </div>
        <div className="contentTestDesktop after">
          <div className="contentHolder">
            {/* <p className="content">{t('careerPage.content')}</p> */}
          </div>
        </div>
      </div>
    </div>
  );
}
