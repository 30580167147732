//#region Imports
import React, { useEffect, useRef, useState } from 'react';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { useAuth } from '../../hooks/useAuth';
import { useLocation, useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { useTranslation } from 'react-i18next';
import { Calendar } from 'primereact/calendar';
//#endregion

export default function LoginComponent() {
  //#region States

  const { t, i18n } = useTranslation();
  const [isSignUp, setIsSignUp] = useState(false);
  const { handleLogin, loading, error, handleRegister } = useAuth();
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [userdata, setUserData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    password: '',
    confirmPassword: '',
    schoolName: '',
    dateOfBirth: '',
  });
  const [formErrors, setFormErrors] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from || '/';
  const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';
  const toast = useRef(null);
  const showError = (error) => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: error,
      life: 30000,
    });
  };
  //#endregion

  //#region handle login and register change
  const handleLoginChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleRegisterChange = (e) => {
    setUserData({ ...userdata, [e.target.name]: e.target.value });
  };
  //#endregion

  //#region Error Showing
  useEffect(() => {
    error && showError(error);
  }, [error]);
  //#endregion

  //#region handle Login and Register api requests
  const handleSubmitLogin = async (e) => {
    e.preventDefault();
    try {
      const result = await handleLogin(credentials);
      if (result?.success) {
        // I used replace:true here to avoid adding another entry in the history stack
        navigate(from || '/', { replace: true });
      } else {
        console.error('Login failed or result is invalid');
      }
    } catch (error) {
      console.error('Login error:', error);
    }
  };
  const handleSubmitRegister = async (e) => {
    e.preventDefault();
    try {
      const result = await handleRegister(userdata);

      // Validate if passwords match
      if (userdata.password !== userdata.confirmPassword) {
        toast.current.show({
          severity: 'error',
          summary: 'Validation Error',
          detail: 'Passwords do not match.',
          life: 5000, // Duration in milliseconds
        });
        return;
      }

      // Validate if passwords match
      if (userdata.phoneNumber && !/^\d{8,}$/.test(userdata.phoneNumber)) {
        toast.current.show({
          severity: 'error',
          summary: 'Validation Error',
          detail:
            'Phone number should only be digits and greater than 8 digits.',
          life: 5000, // Duration in milliseconds
        });
        return; // Exit early if phone number is invalid
      }

      if (result.success) {
        console.log('Registration successful, reloading the page.');
        window.location.reload();
        navigate(from, { replace: true });
      } else {
        console.error('Registration failed, result:', result);
      }
    } catch (error) {
      console.error('Registration error:', error);
    }
  };
  //#endregion
  return (
    <div className="loginDemoOverlay" dir={direction}>
      <Toast ref={toast} position="top-center" />
      <div className={`loginDemoContainer ${isSignUp ? 'signUp' : 'login'}`}>
        <div className="loginDemoForm">
          <h2>{isSignUp ? t('loginPage.signup') : t('loginPage.login')}</h2>
          <div className="formInstruction">
            {isSignUp ? t('loginPage.signuptext') : t('loginPage.logintext')}
          </div>
          <form onSubmit={isSignUp ? handleSubmitRegister : handleSubmitLogin}>
            {!isSignUp ? (
              <>
                <div className="formGroup">
                  <label htmlFor="email"> {t('loginPage.email')}</label>
                  <InputText
                    id="email"
                    name="email"
                    required
                    type="text"
                    className="pInputText"
                    value={credentials.email}
                    onChange={handleLoginChange}
                  />
                  {formErrors.email && (
                    <div className="errorMessage">{formErrors.email}</div>
                  )}
                </div>
                <div className="formGroup">
                  <label htmlFor="password">{t('loginPage.password')}</label>
                  <InputText
                    id="password"
                    name="password"
                    required
                    type="password"
                    className="pInputText"
                    value={credentials.password}
                    onChange={handleLoginChange}
                  />
                  {formErrors.password && (
                    <div className="errorMessage">{formErrors.password}</div>
                  )}
                </div>
                <Button
                  label={t('loginPage.login')}
                  icon="pi pi-sign-in"
                  className="pButtonPrimary"
                  type="submit"
                  loading={loading}
                />
                <Divider layout="horizontal" className="divider">
                  <b>{t('loginPage.or')}</b>
                </Divider>
                <Button
                  label={t('loginPage.signup')}
                  icon="pi pi-user-plus"
                  className="pButtonSecondary"
                  type="button"
                  onClick={() => setIsSignUp(true)}
                />
              </>
            ) : (
              <>
                <div className="formGroup">
                  <label htmlFor="email">{t('loginPage.email')}</label>
                  <InputText
                    id="email"
                    name="email"
                    type="text"
                    className="pInputText"
                    required
                    value={userdata.email}
                    onChange={handleRegisterChange}
                  />
                  {formErrors.email && (
                    <div className="errorMessage">{formErrors.email}</div>
                  )}
                </div>
                <div className="formGroup">
                  <label htmlFor="signup-username">
                    {t('loginPage.username')}
                  </label>
                  <InputText
                    id="signup-username"
                    name="name"
                    required
                    type="text"
                    className="pInputText"
                    value={userdata.name}
                    onChange={handleRegisterChange}
                  />
                  {formErrors.name && (
                    <div className="errorMessage">{formErrors.name}</div>
                  )}
                </div>
                <div className="formGroup">
                  <label htmlFor="phone">{t('loginPage.phonenumber')}</label>
                  <InputText
                    id="phone"
                    name="phoneNumber"
                    required
                    type="text"
                    className="pInputText"
                    value={userdata.phoneNumber}
                    onChange={handleRegisterChange}
                  />
                  {formErrors.phoneNumber && (
                    <div className="errorMessage">{formErrors.phoneNumber}</div>
                  )}
                </div>
                <div className="formGroup">
                  <label htmlFor="email">{t('loginPage.schoolname')}</label>
                  <InputText
                    id="schoolName"
                    name="schoolName"
                    type="text"
                    className="pInputText"
                    value={userdata.schoolName}
                    onChange={handleRegisterChange}
                  />
                  {formErrors.email && (
                    <div className="errorMessage">{formErrors.email}</div>
                  )}
                </div>
                <div className="formGroup">
                  <label htmlFor="email">{t('loginPage.dateofbirth')}</label>
                  <Calendar
                    id="dateofbirth"
                    name="dateofbirth"
                    value={userdata.dateOfBirth}
                    onChange={handleRegisterChange}
                  />
                  {formErrors.dateOfBirth && (
                    <div className="errorMessage">{formErrors.email}</div>
                  )}
                </div>
                <div className="formGroup">
                  <label htmlFor="signup-password">
                    {t('loginPage.password')}
                  </label>
                  <Password
                    id="signup-password"
                    name="password"
                    className="pPassword"
                    required
                    value={userdata.password}
                    onChange={handleRegisterChange}
                  />
                  {formErrors.password && (
                    <div className="errorMessage">{formErrors.password}</div>
                  )}
                </div>
                <div className="formGroup">
                  <label htmlFor="confirm-password">
                    {t('loginPage.confirmPassword')}
                  </label>
                  <Password
                    id="confirm-password"
                    name="confirmPassword"
                    className="pPassword"
                    required
                    value={userdata.confirmPassword}
                    onChange={handleRegisterChange}
                  />
                  {formErrors.confirmPassword && (
                    <div className="errorMessage">
                      {formErrors.confirmPassword}
                    </div>
                  )}
                </div>
                <Button
                  label={t('loginPage.signup')}
                  icon="pi pi-user-plus"
                  className="pButtonPrimary"
                  type="submit"
                  loading={loading}
                />
                <Button
                  label={t('loginPage.backtologin')}
                  icon="pi pi-arrow-left"
                  className="pButtonSecondary"
                  type="button"
                  onClick={() => setIsSignUp(false)}
                />
              </>
            )}
          </form>
          {error && <div className="errorMessage">{error}</div>}
        </div>
      </div>
    </div>
  );
}
