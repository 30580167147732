import './style/styles-animation-en.scss';
import './style/styles-en.scss';
import './style/styles-responsive-en.scss';
import './style/styles-animation-ar.scss';
import './style/styles-ar.scss';
import './style/styles-responsive-ar.scss';
import { PrimeReactProvider } from 'primereact/api';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import 'primeicons/primeicons.css';
import Footer from './components/Footer/Footer';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import HomePage from './components/Pages/HomePage';
import './i18n/i18n';
import PersonalityTestPage from './components/Pages/Tests/TypesPages/PersonalityTest';
import SkillsTest from './components/Pages/Tests/TypesPages/SkillsTest';
import CareerTest from './components/Pages/Tests/TypesPages/CareerTest';
import LoginPage from './components/Pages/LoginPage';
import PrivateRoute from './components/SharedComponents/PrivateRoute';
import AdminPage from './components/Pages/AdminPage'; // Make sure to import AdminPage
import TestPageDemo from './components/Pages/Tests/TestPageDemo';
import Unauthorized from './components/SharedComponents/Unauthorized';
import ResultPage from './components/Pages/ResultPage';

function App() {
  const location = useLocation();
  const hideFooterPaths = [
    '/login',
    '/',
    '/personalityTest',
    '/skillsTest',
    '/careerTest',
    '/admin-dashboard',
  ];
  const shouldHideFooter = hideFooterPaths.includes(location.pathname);

  return (
    <PrimeReactProvider value={{ unstyled: false }}>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/test" element={<TestPageDemo />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/result" element={<ResultPage />} />

          <Route
            path="/personalityTest"
            element={<PrivateRoute element={<PersonalityTestPage />} />}
          />
          <Route
            path="/skillsTest"
            element={<PrivateRoute element={<SkillsTest />} />}
          />
          <Route
            path="/careerTest"
            element={<PrivateRoute element={<CareerTest />} />}
          />
          <Route
            path="/admin-dashboard"
            element={
              <PrivateRoute element={<AdminPage />} requiredRole="Admin" />
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        {!shouldHideFooter && <Footer />}
      </div>
    </PrimeReactProvider>
  );
}

export default App;
