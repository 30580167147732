export const filterEnQuestions = [
  {
    id: 81,
    question: 'Choose 2 industries you like to work in:',
    options: [
      'Healthcare',
      'Construction',
      'Agriculture',
      'Media',
      'Retail',
      'Financial Services',
      'Telecommunication',
      'Education',
      'Aerospace',
      'Manufacturing',
      'Information Technology',
      'Hospitality and Tourism',
      'Logistics',
      'Real estate',
      'Creative Industries',
      'Green Industries (Renewable Energy and Sustainable Agriculture)',
      'Religious Industry',
      'Business and Management Industries',
      'Legal Industries',
      'Administrative and Politics Industries',
    ],
  },

  {
    id: 82,
    question: 'Choose 2 workplaces you like to work in:',
    options: [
      'Hospital',
      'Airport',
      'Government',
      'NGO',
      'Bank',
      'Company',
      'Schools/ Universities',
      'Retail Shop',
      'Manufacturing Enterprise',
      'Research or Consultancy Company',
      'Media',
    ],
  },

  {
    id: 94,
    question: 'What type of work do you like the most?',
    options: [
      'Analysing data',
      'Helping people mentally or physically',
      'Serving people or interacting with them',
      'Working for the environment',
      'Creating new ideas',
      'Transfer your knowledge to others',
    ],
  },

  {
    id: 95,
    question: 'What are the courses you would like to learn?',
    options: [
      'Math',
      'Chemistry',
      'Physics',
      'Biology',
      'Languages',
      'Philosophy',
      'History',
      'Geography',
      'Arts',
      'Computer',
      'Sociology',
    ],
  },
];

export const engRequiredSubjects = [
  {
    id: 86,
    question:
      'Did you score 12/20 or higher in Math on your final exam in grade 12?',
    options: ['Yes', 'No'],
    subject: ['Math'],
  },
  {
    id: 87,
    question:
      'Did you score 12/20 or higher in Chemistry on your final exam in grade 12?',
    options: ['Yes', 'No'],
    subject: ['Chemistry'],
  },
  {
    id: 88,
    question:
      'Did you score 12/20 or higher in Physics on your final exam in grade 12?',
    options: ['Yes', 'No'],
    subject: ['Physics'],
  },
  {
    id: 89,
    question:
      'Did you score 12/20 or higher in Biology on your final exam in grade 12?',
    options: ['Yes', 'No'],
    subject: ['Biology'],
  },
  {
    id: 90,
    question:
      'Did you score 12/20 or higher in Languages on your final exam in grade 12?',
    options: ['Yes', 'No'],
    subject: ['Languages'],
  },
  {
    id: 91,
    question:
      'Did you score 12/20 or higher in Philosophy on your final exam in grade 12?',
    options: ['Yes', 'No'],
    subject: ['Philosophy'],
  },
  {
    id: 92,
    question:
      'Did you score 12/20 or higher in History on your final exam in grade 12?',
    options: ['Yes', 'No'],
    subject: ['History'],
  },
  {
    id: 93,
    question:
      'Did you score 12/20 or higher in Geography on your final exam in grade 12?',
    options: ['Yes', 'No'],
    subject: ['Geography'],
  },
  {
    id: 94,
    question:
      'Do Like to keep creating new ideas in designing, writing, drawing, or acting ?',
    options: ['Yes', 'No'],
    subject: ['Arts'],
  },
  {
    id: 95,
    question:
      'Do you feel comfortable using the computer for at least nine hours per day?',
    options: ['Yes', 'No'],
    subject: ['Computer'],
  },
  {
    id: 96,
    question:
      'Did you score 12/20 or higher in Sociology on your final exam in grade 12?',
    options: ['Yes', 'No'],
    subject: ['Sociology'],
  },
];

export const engSimulationQuestions = [
  {
    id: 96,
    question: 'Helping people with personal or emotional problems:',
    majors: ['Psychology', 'Social Work'],
    options: ['Yes', 'No'],
  },
  {
    id: 97,
    question: 'Developing a website:',
    majors: [
      'Computer Science',
      'Business Information Systems',
      'Communication and Visual Arts',
      'Computer Graphics and Interactive Media',
      'Digital Advertising',
      'Digital Media',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 98,
    question: 'Negotiating Business Contracts:',
    majors: ['Law', 'Business Administration'],
    options: ['Yes', 'No'],
  },
  {
    id: 99,
    question: 'Assembling electronic parts:',
    majors: [
      'Electrical and Electronics Engineering',
      'Electrical Engineering',
      'Electronic Engineering',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 100,
    question: 'Examining blood samples using a microscope:',
    majors: [
      'Biomedical Science',
      'Clinical Laboratory Science',
      'Laboratory Sciences',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 101,
    question: 'Calculating the payroll of employees:',
    majors: [
      'Accounting',
      'Accounting and Auditing',
      'Accounting and Finance',
      'Banking and Finance',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 102,
    question: 'Installing software across computers on a large network:',
    majors: [
      'Computer and Communication Engineering',
      'Computer Engineering',
      'Computer Network and Data Communications',
      'Telecommunication and Networks Engineering',
      'Communication Engineering',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 103,
    question: 'Performing rehabilitation therapy:',
    majors: [
      'Speech Therapy',
      'Ergotherapy/ Occupational Therapy',
      'Physical Therapy',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 104,
    question: 'Analyzing the DNA to assemble evidence of a crime:',
    majors: ['Forensic Science'],
    options: ['Yes', 'No'],
  },
  {
    id: 105,
    question: 'Setting up and operating machines to make products:',
    majors: [
      'Electrical and Electronics Engineering',
      'Electronic Engineering',
      'Electrical Engineering',
      'Mechanical Engineering',
      'Mechatronics Engineering',
      'Industrial Engineering',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 106,
    question: 'Studying the movement of planets:',
    majors: ['Astronomy'],
    options: ['Yes', 'No'],
  },
  {
    id: 107,
    question: 'Buying and selling stocks and bonds:',
    majors: ['Economics', 'Accounting and Finance', 'Statistics'],
    options: ['Yes', 'No'],
  },
  {
    id: 108,
    question: 'Conducting chemical experiments:',
    majors: ['Chemical Engineering', 'Chemistry'],
    options: ['Yes', 'No'],
  },
  {
    id: 109,
    question: 'Researching to know the causes of climate disaster:',
    majors: [
      'Environmental Health/ Sciences',
      'Environmental / Forestry Engineering',
      'Geology',
      'Geography',
      'Earth and Life Science',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 110,
    question: "Designing the house's interior and decoration:",
    majors: ['Arts & Interior Planning'],
    options: ['Yes', 'No'],
  },
  {
    id: 111,
    question: 'Developing programs and applications:',
    majors: ['Computer Engineering', 'Computer Science'],
    options: ['Yes', 'No'],
  },
  {
    id: 112,
    question: 'Designing Fashion:',
    majors: ['Fashion and Pattern Drafting'],
    options: ['Yes', 'No'],
  },
  {
    id: 113,
    question: 'Selling products and services:',
    majors: ['Advertising and Marketing Communication', 'Digital Advertising'],
    options: ['Yes', 'No'],
  },
  {
    id: 114,
    question: 'Delivering scoop & news to the audience:',
    majors: [
      'Radio/TV and Film',
      'Media and Communication',
      'Journalism and Electronic Media',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 115,
    question: 'Creating and updating maps for various purposes:',
    majors: ['Surveying Engineering'],
    options: ['Yes', 'No'],
  },
  {
    id: 116,
    question: 'Creating content and being an influencer on Social Media:',
    majors: [
      'Digital Media',
      'Media and Communication',
      'Communication and Visual Arts',
      'Computer Graphics and Interactive Media',
      'Advertising and Marketing Communication',
      'Digital Advertising',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 117,
    question: "Caring about people's health and well-being:",
    majors: [
      'Nutrition and Dietetics',
      'Public Health',
      'Medicine',
      'Nursing',
      'Ergotherapy/ Occupational Therapy',
      'Psychomotricity',
      'Speech Therapy',
      'Pharmacy',
      'Health Communication/ Promotion',
      'Fundamental Health Science',
      'Midwifery',
      'Physical Therapy',
      'Dentistry',
      'Optics and Optometry',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 118,
    question: 'Guiding tourists:',
    majors: ['Hospitality', 'Hotel Management and Tourism'],
    options: ['Yes', 'No'],
  },
  {
    id: 119,
    question: 'Organizing events:',
    majors: ['Business Administration'],
    options: ['Yes', 'No'],
  },
  {
    id: 120,
    question:
      'Collecting samples of food products at various stages of production:',
    majors: ['Quality Control of Foodstuffs of Animal Origin'],
    options: ['Yes', 'No'],
  },
  {
    id: 121,
    question: 'Production, and management of oil and natural gas resources:',
    majors: [
      'Petrochemical Engineering',
      'Petroleum Engineering',
      'Geoscience and Natural Resources',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 122,
    question:
      'Integrating physical movement with psychological and emotional development for patients:',
    majors: ['Psychomotricity'],
    options: ['Yes', 'No'],
  },
  {
    id: 123,
    question: 'Growing and managing crops or livestock:',
    majors: ['Agri-Business', 'Agricultural Engineering'],
    options: ['Yes', 'No'],
  },
  {
    id: 124,
    question: 'Learning international trade and its impact on agriculture:',
    majors: ['Agricultural Economics', 'Economics'],
    options: ['Yes', 'No'],
  },
  {
    id: 125,
    question: 'Developing and implementing new technologies in agriculture:',
    majors: ['Agricultural Engineering', 'Plant Production/ Protection'],
    options: ['Yes', 'No'],
  },
  {
    id: 126,
    question: 'Interested in Politics and the regional situation:',
    majors: ['Administrative and Political Sciences'],
    options: ['Yes', 'No'],
  },
  {
    id: 127,
    question: 'Maintaining aircraft:',
    majors: ['Aircraft Maintenance'],
    options: ['Yes', 'No'],
  },
  {
    id: 128,
    question: 'Knowing more about animals or pets:',
    majors: [
      'Animal Science and Technology',
      'Pet Medicine',
      'Poultry and Cattle Medicine',
      'Quality Control of Foodstuffs of Animal Origin',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 129,
    question: 'Knowing more about genetics:',
    majors: ['Biochemistry', 'Bioinformatics', 'Forensic Science'],
    options: ['Yes', 'No'],
  },
  {
    id: 130,
    question: 'Ensuring food safety and quality standards:',
    majors: [
      'Biofood Engineering',
      'Engineering Sciences-Food Engineering',
      'Food and Science Technology/ Quality Assurance',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 131,
    question: 'Studying living organisms and their interactions:',
    majors: [
      'Biology',
      'Medicine',
      'Midwifery',
      'Nursing',
      'Fundamental Health Science',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 132,
    question: 'Working with medical equipment:',
    majors: ['Biomedical Engineering'],
    options: ['Yes', 'No'],
  },
  {
    id: 133,
    question: "Focusing on the people's mental health:",
    majors: ['Psychology', 'Social Work', 'Social Sciences'],
    options: ['Yes', 'No'],
  },
  {
    id: 134,
    question: 'Working with food processing technologies and techniques:',
    majors: [
      'Biofood Engineering',
      'Food and Science Technology/ Quality Assurance',
      'Engineering Sciences-Food Engineering',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 135,
    question: 'Directing or producing films/programs:',
    majors: [
      'Cinema Production',
      'Audiovisual Production',
      'Radio/TV and Film',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 136,
    question: 'Interested in shooting movie scenes:',
    majors: ['Photography'],
    options: ['Yes', 'No'],
  },
  {
    id: 137,
    question:
      'Improving public works such as roads, bridges, and water systems:',
    majors: ['Civil Engineering', 'Civil Environmental Engineering'],
    options: ['Yes', 'No'],
  },
  {
    id: 138,
    question:
      'Ensuring that construction projects comply with regulations and safety standards:',
    majors: ['Civil Engineering', 'Architecture'],
    options: ['Yes', 'No'],
  },
  {
    id: 139,
    question:
      'Working on projects that aim to improve water quality and waste management:',
    majors: [
      'Civil Environmental Engineering',
      'Environmental / Forestry Engineering',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 140,
    question: 'Like to be an expert in languages:',
    majors: [
      'Comparative Linguistics Engineering',
      'Translation and Interpretation',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 141,
    question:
      'Working on the development of new technologies in networking and data transmission:',
    majors: [
      'Computer and Communication Engineering',
      'Computer Network and Data Communications',
      'Telecommunication and Networks Engineering',
      'Communication Engineering',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 142,
    question:
      'Identifying, monitoring, and managing pests, diseases, and weeds that threaten plant health:',
    majors: ['Plant Production/ Protection'],
    options: ['Yes', 'No'],
  },
  {
    id: 143,
    question:
      'Learning techniques for restoring damaged art and cultural property:',
    majors: ['Conservation, Restoration of Cultural Property and Sacred Art'],
    options: ['Yes', 'No'],
  },
  {
    id: 144,
    question:
      'Learning about various cooking techniques and cuisines from around the world:',
    majors: ['Culinary Arts and Restaurant Management'],
    options: ['Yes', 'No'],
  },
  {
    id: 145,
    question: 'Working with data to derive insights and solve problems:',
    majors: ['Data Science', 'Statistics', 'Applied Mathematics'],
    options: ['Yes', 'No'],
  },
  {
    id: 146,
    question: 'Helping people to have a good body shape:',
    majors: ['Physical Education'],
    options: ['Yes', 'No'],
  },
  {
    id: 147,
    question: 'Working with different design tools and software:',
    majors: ['Graphic Design', 'Design and Applied Arts', 'Product Design'],
    options: ['Yes', 'No'],
  },
  {
    id: 148,
    question: 'Managing the movement and storage of goods and materials:',
    majors: ['Distribution and Logistics Management'],
    options: ['Yes', 'No'],
  },
  {
    id: 149,
    question: 'Working with students of different ages and backgrounds:',
    majors: ['Early Childhood Education', 'Education'],
    options: ['Yes', 'No'],
  },
  {
    id: 150,
    question:
      'Mentoring and guiding students in their academic and personal development:',
    majors: ['Education'],
    options: ['Yes', 'No'],
  },
  {
    id: 151,
    question:
      'Studying geological formations, climate patterns, and environmental changes:',
    majors: [
      'Geology',
      'Geography',
      'Earth and Life Sciences',
      'Geoscience and Natural Resources',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 152,
    question: 'Designing and overseeing the drilling of oil and gas wells:',
    majors: ['Petrochemical Engineering', 'Petroleum Engineering'],
    options: ['Yes', 'No'],
  },
  {
    id: 153,
    question: 'Taking professional photos for events, nature, etc:',
    majors: ['Photography'],
    options: ['Yes', 'No'],
  },
  {
    id: 154,
    question:
      'Improving efficiency and productivity in manufacturing and industrial processes:',
    majors: ['Industrial Engineering', 'Applied Mathematics'],
    options: ['Yes', 'No'],
  },
  {
    id: 155,
    question:
      'Having connections and interacting with different cultures and nationalities:',
    majors: [
      'International and Diplomatic Relations',
      'International Business',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 156,
    question: 'Interested in religions and theology:',
    majors: [
      'Islamic Studies',
      'Christian Studies',
      'Theology',
      'Religions Sciences',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 157,
    question: 'Knowing more about music:',
    majors: ['Music'],
    options: ['Yes', 'No'],
  },
  {
    id: 158,
    question: 'Designing and planning outdoor spaces, parks, and landscapes:',
    majors: ['Landscape Engineering'],
    options: ['Yes', 'No'],
  },
  {
    id: 159,
    question:
      'Creating landscape plans that consider factors such as soil erosion, drainage, and maintenance requirements:',
    majors: ['Landscape Engineering'],
    options: ['Yes', 'No'],
  },
  {
    id: 160,
    question:
      'Learning about various areas of law such as criminal law, civil law, constitutional law, or international law:',
    majors: ['Law'],
    options: ['Yes', 'No'],
  },
  {
    id: 161,
    question:
      'Focusing on the health and well-being of companion animals or poultry:',
    majors: ['Pet Medicine', 'Poultry and Cattle Medicine'],
    options: ['Yes', 'No'],
  },
  {
    id: 162,
    question: 'Using technology to visualize and diagnose medical conditions:',
    majors: [
      'Medical Imaging Sciences',
      'Medical Technology with Concentration in Radiologic Science',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 163,
    question:
      'Analyzing medical images to identify abnormalities and assist in patient diagnosis:',
    majors: [
      'Medical Imaging Sciences',
      'Medical Technology with Concentration in Radiologic Science',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 164,
    question: 'Studying light, vision, and the human eye:',
    majors: ['Optics and Optometry'],
    options: ['Yes', 'No'],
  },
  {
    id: 165,
    question: 'Being a member of a music band or musical event:',
    majors: ['Music'],
    options: ['Yes', 'No'],
  },
  {
    id: 166,
    question:
      'Curious about fundamental questions concerning the existence, knowledge, ethics, and the nature of reality:',
    majors: ['Philosophy'],
    options: ['Yes', 'No'],
  },
  {
    id: 167,
    question:
      'Staying updated with advancements in exercise science, fitness trends, and sports medicine:',
    majors: ['Physical Education'],
    options: ['Yes', 'No'],
  },
  {
    id: 168,
    question:
      'Learning about fundamental concepts such as mechanics, electromagnetism, thermodynamics, and quantum mechanics:',
    majors: ['Physics', 'Mechanical Engineering'],
    options: ['Yes', 'No'],
  },
  {
    id: 169,
    question: 'Creating a brand or product design:',
    majors: ['Product Design', 'Graphic Design', 'Design and Applied Arts'],
    options: ['Yes', 'No'],
  },
  {
    id: 170,
    question:
      'Exploring issues such as inequality, social justice, globalization, and human development:',
    majors: [
      'Social Sciences',
      'Sociology-Anthropology',
      'Administrative and Politics Sciences',
      'Philosophy',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 171,
    question:
      'Working outdoors, conducting field surveys and collecting precise measurements:',
    majors: ['Surveying Engineering'],
    options: ['Yes', 'No'],
  },
  {
    id: 172,
    question:
      'Working on-site and choosing the best construction materials for the project:',
    majors: ['Architecture', 'Arts and Interior Planning'],
    options: ['Yes', 'No'],
  },
  {
    id: 173,
    question: 'Knowing more about world history or finding physical remains:',
    majors: ['Archaeology', 'History', 'Sociology-Anthropology'],
    options: ['Yes', 'No'],
  },
  {
    id: 174,
    question: 'Implementing and closing invoices with suppliers and clients:',
    majors: ['Accounting'],
    options: ['Yes', 'No'],
  },
  {
    id: 175,
    question:
      "Checking financial transactions and records to ensure the company's accuracy:",
    majors: ['Accounting and Auditing'],
    options: ['Yes', 'No'],
  },
  {
    id: 176,
    question:
      'Providing expert advice to farmers and agribusinesses on improving productivity, sustainability, and profitability:',
    majors: ['Agri-Business'],
    options: ['Yes', 'No'],
  },
  {
    id: 177,
    question:
      'Analyzing market trends, pricing, and economic factors affecting agriculture:',
    majors: ['Agricultural Economics'],
    options: ['Yes', 'No'],
  },
  {
    id: 178,
    question:
      'Maintaining and repairing the electronic systems of an aircraft, such as navigation, communication, and radar systems:',
    majors: ['Aircraft Maintenance'],
    options: ['Yes', 'No'],
  },
  {
    id: 179,
    question:
      'Managing the care, breeding, and conservation programs for animals:',
    majors: ['Animal Science and Technology'],
    options: ['Yes', 'No'],
  },
  {
    id: 180,
    question:
      'Managing collections, designing exhibits, and interpreting historical and archaeological artifacts for public display:',
    majors: ['Archaeology', 'History'],
    options: ['Yes', 'No'],
  },
  {
    id: 181,
    question:
      'Working in space exploration, collaborating on spacecraft design, or satellite:',
    majors: ['Astronomy'],
    options: ['Yes', 'No'],
  },
  {
    id: 182,
    question:
      'Writing scripts for films, television shows, and other audiovisual content, developing dialogue, scenes, and storylines:',
    majors: ['Audiovisual Production', 'Cinema Production'],
    options: ['Yes', 'No'],
  },
  {
    id: 183,
    question: 'Working in the banking sector to provide services for clients:',
    majors: ['Banking and Finance'],
    options: ['Yes', 'No'],
  },
  {
    id: 184,
    question:
      'Working in the pharmaceutical industry to develop new drugs, therapies, or diagnostic tools:',
    majors: [
      'Pharmacy',
      'Biochemistry',
      'Bioinformatics',
      'Biology',
      'Biomedical Science',
      'Chemical Engineering',
      'Chemistry',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 185,
    question:
      'Designing and developing medical devices, such as artificial organs, prosthetics, imaging systems, and diagnostic equipment:',
    majors: ['Biomedical Engineering'],
    options: ['Yes', 'No'],
  },
  {
    id: 186,
    question:
      'Evaluating and designing information systems to meet the needs of a business:',
    majors: ['Business Information Systems'],
    options: ['Yes', 'No'],
  },
  {
    id: 187,
    question:
      'Providing spiritual care and counseling in various settings, such as hospitals, military institutions, correctional facilities, or educational institutions:',
    majors: [
      'Christian Studies',
      'Islamic Studies',
      'Religions Sciences',
      'Theology',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 188,
    question:
      'Collecting blood, tissue, and other bodily fluids from patients and preparing samples for testing:',
    majors: ['Clinical Laboratory Science', 'Laboratory Sciences'],
    options: ['Yes', 'No'],
  },
  {
    id: 189,
    question: 'Translating text, voice or video into different language:',
    majors: [
      'Comparative Linguistics Engineering',
      'Translation and Interpretation',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 190,
    question: 'Being responsible for a restaurant or hotel:',
    majors: [
      'Culinary Arts and Restaurant Management',
      'Hospitality',
      'Hotel Management and Tourism',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 191,
    question:
      'Handling missing data, removing duplicates, and converting data into a suitable format for data analysis:',
    majors: ['Data Science', 'Physics'],
    options: ['Yes', 'No'],
  },
  {
    id: 192,
    question: 'Being a Dentistry:',
    majors: ['Dentistry'],
    options: ['Yes', 'No'],
  },
  {
    id: 193,
    question:
      'Building and maintaining relationships with suppliers and vendors and negotiating contracts:',
    majors: ['Distribution and Logistics Management'],
    options: ['Yes', 'No'],
  },
  {
    id: 194,
    question: 'Caring for infants and children under the age of three:',
    majors: ['Early Childhood Education'],
    options: ['Yes', 'No'],
  },
  {
    id: 195,
    question: 'Studying the impact of environmental aspects on public health:',
    majors: ['Environmental Health/ Sciences'],
    options: ['Yes', 'No'],
  },
  {
    id: 196,
    question:
      'Providing advice to artists, and famous people in their fashion design:',
    majors: ['Fashion and Pattern Drafting'],
    options: ['Yes', 'No'],
  },
  {
    id: 197,
    question:
      'Providing individualized dietary advice and meal planning to achieve optimal health:',
    majors: ['Nutrition and Dietetics'],
    options: ['Yes', 'No'],
  },
  {
    id: 198,
    question:
      'Providing advice for communities to improve their health from different aspects:',
    majors: ['Health Communication/ Promotion', 'Public Health'],
    options: ['Yes', 'No'],
  },
  {
    id: 199,
    question: 'Developing job descriptions and specifications:',
    majors: ['Human Resources Management'],
    options: ['Yes', 'No'],
  },
  {
    id: 200,
    question:
      'Posting job openings, screening resumes, and conducting interviews:',
    majors: ['Human Resources Management'],
    options: ['Yes', 'No'],
  },
  {
    id: 201,
    question:
      'Building and maintaining relationships with foreign governments, trade organizations, and international business networks:',
    majors: [
      'International and Diplomatic Relations',
      'International Business',
    ],
    options: ['Yes', 'No'],
  },
  {
    id: 202,
    question: 'Covering events, disasters, festivals, conferences, etc:',
    majors: ['Journalism and Electronic Media'],
    options: ['Yes', 'No'],
  },
  {
    id: 203,
    question: 'Developing and programming automated systems and robots:',
    majors: ['Mechatronics Engineering'],
    options: ['Yes', 'No'],
  },
];
