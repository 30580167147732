//#region Imports
import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from '@mui/material';
import { RadioButton } from 'primereact/radiobutton';
import { useLocation, useNavigate } from 'react-router-dom';
import useHasAccess from '../../../../hooks/useHasAccess';
import PopUp from '../../../SharedComponents/PopUps';
import { useAuth } from '../../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { deatilsMajorsList } from '../../../../Data/DetailsMajorList';
import { createWhatsAppUrl } from '../../../../utils/utils';
import { engSimulationQuestions } from '../../../../Data/simulationEngQuestions';
import { arSimulationQuestions } from '../../../../Data/simulationArQuestions';
import { simulationEnAnalysis } from '../../../../Data/simulationEnAnalysis';
import { simulationArAnalysis } from '../../../../Data/simulationArAnalysis';
import HoverRating from '../../../SharedComponents/RatingComponent';
import generatePDF from '../../ResultPage';

//#endregion

export const SimulationTest = forwardRef((prop, ref) => {
  //#region States
  const questionRefs = useRef([]);
  const [completeTestDialogue, setCompleteTestDialogue] = useState(false);
  const [showResultDialogue, setShowResultDialogue] = useState(false);
  const [showIncompleteDialogue, setShowIncompleteDialogue] = useState(false);
  const [showContinueDialog, setShowContinueDialog] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const { handleGetUserInfo, showErrorPopUp } = useAuth();
  const { handleSubmitRating, handleSubmitFeedback } = useAuth();
  const location = useLocation();
  const [purchasePopUp, setPurchasePopUp] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [results, setResults] = useState({});
  const { i18n, t } = useTranslation();
  const [testData, setTestData] = useState(engSimulationQuestions);
  const [analysis, setAnalysis] = useState(simulationEnAnalysis);
  const navigate = useNavigate();
  const { handleUpdateTestAccess } = useHasAccess();
  const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';
  const [accessQuiz, setAccessQuiz] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ratingPopUp, setRatingPopUp] = useState(true);
  const [feedbackPopUp, setFeedbackPopUp] = useState(false);

  const [activeStep, setActiveStep] = useState(0);

  //#endregion

  //#region To change the data between En and Ar on change of language

  const getMajorDetails = (majorName) =>
    deatilsMajorsList[majorName] || {
      engName: majorName,
      arName: majorName,
      enDescription: '',
      arDescription: '',
    };

  useEffect(() => {
    setRatingPopUp(false);
    const currentLanguage =
      i18n.language || localStorage.getItem('i18nextLng') || 'en';
    if (currentLanguage === 'ar') {
      setTestData(arSimulationQuestions);
    } else {
      setTestData(engSimulationQuestions);
    }
  }, [i18n.language]);

  useEffect(() => {
    const currentLanguage =
      i18n.language || localStorage.getItem('i18nextLng') || 'en';
    if (currentLanguage === 'ar') {
      setAnalysis(simulationArAnalysis);
    } else {
      setAnalysis(simulationEnAnalysis);
    }
  }, [i18n.language]);

  useEffect(() => {
    if (!analysis) {
      return;
    }
    const filterMajor = JSON.parse(localStorage.getItem('filterMajor')) || {};
    const storedRequiredSubjects = JSON.parse(
      localStorage.getItem('requiredSubjects')
    ) || { requiredMajors: [] };

    console.log('filterMajor:', filterMajor);
    console.log('storedRequiredSubjects:', storedRequiredSubjects);

    const selectedIndustries = filterMajor.selectedIndustries || [];
    const selectedWorkplaces = filterMajor.selectedWorkplaces || [];
    const selectedWorkType = filterMajor.selectedWorkType || [];
    const selectedPreferedCourses = filterMajor.selectedPreferedCourses || [];
    const requiredMajors = storedRequiredSubjects.requiredMajors || [];

    // Flatten requiredMajors
    const flattenedRequiredMajors = requiredMajors.flat();
    console.log('Flattened Required Majors:', flattenedRequiredMajors);

    function getSuitableMajors() {
      // Step 1: Filter by selected industries
      const filteredIndustries = analysis.industries.filter((industry) =>
        selectedIndustries.includes(industry.industryName)
      );
      console.log('Filtered Industries:', filteredIndustries);

      // Step 2: Filter by selected workplaces
      const filteredMajorsByWorkplace = filteredIndustries.flatMap((industry) =>
        industry.majors.filter((major) =>
          major.workplaces.some((workplace) =>
            selectedWorkplaces.includes(workplace)
          )
        )
      );
      console.log('Filtered Majors by Workplace:', filteredMajorsByWorkplace);

      // Step 3: Filter by work type and required subjects
      const filteredMajorsByWorkTypeAndSubjects =
        filteredMajorsByWorkplace.filter((major) => {
          const isWorkTypeMatch = selectedWorkType.some((type) =>
            major.workType.includes(type)
          );

          const hasRequiredSubjects =
            major.requiredSubjects.length === 0 ||
            major.requiredSubjects.every((subject) =>
              flattenedRequiredMajors.includes(subject)
            );

          // Detailed logging for debugging
          console.log('Major:', major.name);
          console.log('Major Required Subjects:', major.requiredSubjects);
          console.log('Stored Required Majors:', flattenedRequiredMajors);
          console.log('Is Work Type Match:', isWorkTypeMatch);
          console.log('Has Required Subjects:', hasRequiredSubjects);

          return isWorkTypeMatch && hasRequiredSubjects;
        });

      console.log(
        'Filtered Majors by Work Type and Required Subjects:',
        filteredMajorsByWorkTypeAndSubjects
      );

      // Step 4: Match preferred courses
      const suitableMajors = filteredMajorsByWorkTypeAndSubjects.filter(
        (major) =>
          major.preferredCourses.some((course) =>
            selectedPreferedCourses.includes(course)
          )
      );
      console.log('Suitable Majors:', suitableMajors);

      return suitableMajors;
    }

    const suitableMajors = getSuitableMajors();
    localStorage.setItem('suitableMajors', JSON.stringify(suitableMajors));
    console.log('Final Suitable Majors:', suitableMajors);
  }, [analysis]);
  //#endregion

  //#region To handle the options selection and save it in local storage
  const [selectedOptions, setSelectedOptions] = useState(
    JSON.parse(localStorage.getItem('simulationOption')) ||
      Array(testData.length).fill(null)
  );

  const handleOptionChange = (index, option) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[index] = option;
    setSelectedOptions(newSelectedOptions);
    localStorage.setItem(
      'simulationOption',
      JSON.stringify(newSelectedOptions)
    );
  };
  //#endregion

  //#region To get the user info constantly to update access
  useEffect(() => {
    setRatingPopUp(false);

    const email = localStorage.getItem('email');
    if (email) {
      setShowContinueDialog(false);
      setLoading(true);

      handleGetUserInfo(email)
        .then((response) => {
          if (response.success) {
            const { userInfo } = response;
            localStorage.setItem(
              'hasAccess',
              JSON.stringify(userInfo.hasAccess)
            );
            localStorage.setItem(
              'accessPersonality',
              JSON.stringify(userInfo.accessPersonality)
            );
            localStorage.setItem(
              'accessCareer',
              JSON.stringify(userInfo.accessCareer)
            );
            localStorage.setItem(
              'accessSkills',
              JSON.stringify(userInfo.accessSkills)
            );
            localStorage.setItem(
              'topSelectedMajors',
              JSON.stringify(userInfo.topSelectedMajors)
            );
            localStorage.setItem(
              'topNonSelectedMajors',
              JSON.stringify(userInfo.topNonSelectedMajors)
            );
          }
        })
        .catch((error) => {
          console.error('Failed to fetch user info:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [handleGetUserInfo]);

  useEffect(() => {
    setShowContinueDialog(false);
    setRatingPopUp(false);
    scrollToLastAnswered();

    if (!loading) {
      const hasAccess = JSON.parse(localStorage.getItem('hasAccess'));
      const accessCareer = JSON.parse(localStorage.getItem('accessCareer'));

      if (hasAccess && !accessCareer) {
        setShowContinueDialog(false);
        setAccessQuiz(true);
        setPurchasePopUp(true);
      } else {
        setAccessQuiz(false);
        setPurchasePopUp(false);
        if (selectedOptions.some((option) => option === null)) {
          setShowContinueDialog(true);
        }
        if (selectedOptions.every((option) => option === null)) {
          setShowContinueDialog(false);
        }
        if (selectedOptions.every((option) => option !== null)) {
          setShowContinueDialog(false);
        }
      }
    }
  }, [loading]);

  //#endregion

  //#region Authentication (Navigation) and Access to test
  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (authToken == null || authToken === '') {
      navigate('/login', { state: { from: location.pathname }, replace: true });
    }
  }, [navigate, location.pathname]);
  //#endregion

  //#region To show the continue dialog if the user has already started the test
  useEffect(() => {
    const simulationCompleted = JSON.parse(
      localStorage.getItem('simulationCompleted')
    );

    if (simulationCompleted) {
      setAccessQuiz(true);
    }

    window.scrollTo(0, 0);
    // const savedOptions = JSON.parse(localStorage.getItem('simulationOption'));
    // console.log('Saved Options:', savedOptions);
    // if (savedOptions && !simulationCompleted) {
    //   console.log('Showing continue dialog');
    //   setShowContinueDialog(true);
    // } else {
    //   setShowContinueDialog(false);
    // }
  }, []);
  //#endregion

  //#region To handle the dialogue for completing the test
  const checkAllQuestionsAnswered = () => {
    return selectedOptions.every((option) => option !== null);
  };
  //#endregion

  //#region To handle the api request of updating the test access
  const handleCareerTestSubmit = async () => {
    setShowContinueDialog(false);
    setRatingPopUp(false);

    const topSelectedMajors =
      JSON.parse(localStorage.getItem('topSelectedMajors')) || [];
    const topNonSelectedMajors =
      JSON.parse(localStorage.getItem('topNonSelectedMajors')) || [];

    const formattedTopNonSelectedMajors = topNonSelectedMajors.map((major) => {
      return `${major.name}-${major.score}`;
    });

    const formattedTopSelectedMajors = topSelectedMajors.map((major) => {
      return `${major.name}-${major.score}`;
    });

    try {
      await handleUpdateTestAccess(
        'career',
        formattedTopSelectedMajors,
        formattedTopNonSelectedMajors
      );
    } catch (error) {
      console.error('Error updating career test results:', error.message);
    }
  };
  //#endregion

  //#region calculating the scores (Yes/No)
  const calculateScores = (newSelectedOptions) => {
    const majorScores = {};

    testData.forEach((question, index) => {
      const selectedOption = newSelectedOptions[index];

      // Check if the selected option is "yes" or "نعم"
      if (selectedOption === 'Yes' || selectedOption === 'نعم') {
        // Loop through all majors associated with the question
        question.majors.forEach((major) => {
          // If the major is not already in the scores object, initialize it with 0
          if (!majorScores[major]) {
            majorScores[major] = 0;
          }
          // Increment the score by 1 for each "yes" response
          majorScores[major] += 1;
        });
      }
    });

    return majorScores; // Return the accumulated scores for all majors
  };

  //#endregion

  //#region To handle the dialogue for completing and submitting the test
  const handleSubmit = async (rating) => {
    setRatingPopUp(false);

    if (checkAllQuestionsAnswered()) {
      const scores = calculateScores(selectedOptions);
      localStorage.setItem('majorsAndScores', JSON.stringify(scores));

      const suitableMajors =
        JSON.parse(localStorage.getItem('suitableMajors')) || [];
      const majorsAndScores =
        JSON.parse(localStorage.getItem('majorsAndScores')) || {};

      const topSelectedMajors = [];
      const topNonSelectedMajors = [];

      Object.entries(majorsAndScores).forEach(([majorName, score]) => {
        const isSuitableMajor = suitableMajors.some(
          (major) => major.name === majorName
        );

        if (isSuitableMajor && score >= 1) {
          topSelectedMajors.push({ name: majorName, score });
        } else if (!isSuitableMajor && score === 2) {
          topNonSelectedMajors.push({ name: majorName, score });
        }
      });

      topSelectedMajors.sort((a, b) => b.score - a.score);
      topNonSelectedMajors.sort((a, b) => b.score - a.score);

      setResults({
        topSelectedMajors,
        topNonSelectedMajors,
      });

      localStorage.setItem(
        'topSelectedMajors',
        JSON.stringify(topSelectedMajors)
      );
      localStorage.setItem(
        'topNonSelectedMajors',
        JSON.stringify(topNonSelectedMajors)
      );
      setShowContinueDialog(false);
      handleCareerTestSubmit();
      setCompleteTestDialogue(false);
      setShowResultDialogue(true);

      try {
        // Submit the feedback and rating
        await handleSubmitRating(rating);
        console.log('Feedback submitted successfully.');

        // Wait for the results to be updated in local storage
        await new Promise((resolve) => setTimeout(resolve, 3000));
        localStorage.removeItem('majorsAndScores');
        localStorage.removeItem('filterMajor');
        localStorage.removeItem('requiredSubjects');
        localStorage.removeItem('requiredSubjectsCompleted');
        localStorage.removeItem('simulationOption');
        localStorage.removeItem('suitableMajors');
      } catch (error) {
        console.error('Error submitting feedback:', error);
      }
    } else {
      setCompleteTestDialogue(false);
      setShowIncompleteDialogue(true);
    }
  };
  //#endregion

  //#region Whatsapp message
  const topSelectedMajors =
    JSON.parse(localStorage.getItem('topSelectedMajors')) || [];
  const topNonSelectedMajors =
    JSON.parse(localStorage.getItem('topNonSelectedMajors')) || [];

  const formatResultsMessage = (
    selectedMajors,
    nonSelectedMajors,
    getMajorDetails
  ) => {
    const userName = localStorage.getItem('userName');
    const userEmail = localStorage.getItem('email');
    const studentPhoneNumber = localStorage.getItem('phoneNumber');
    const testDate = new Date().toLocaleDateString();

    let message = `📋 *Career Test Results*\n\n`;
    message += `*Name:* ${userName}\n`;
    message += `*Email:* ${userEmail}\n`;
    message += `*Phone Number:* ${studentPhoneNumber}\n`;
    message += `*Test Date:* ${testDate}\n\n`;

    message += '🔝 *Top Selected Majors:*\n';
    selectedMajors.forEach((major) => {
      const details = getMajorDetails(major.name);
      message += `*${details.engName}* - ${details.arName}\n`;
      message += `📜 Description (EN): ${details.enDescription}\n`;
      message += `📜 Description (AR): ${details.arDescription}\n\n`;
    });

    message += '🔍 *Alternative Majors:*\n';
    nonSelectedMajors.forEach((major) => {
      const details = getMajorDetails(major.name);
      message += `*${details.engName}* - ${details.arName}\n`;
      message += `📜 Description (EN): ${details.enDescription}\n`;
      message += `📜 Description (AR): ${details.arDescription}\n\n`;
    });

    return encodeURIComponent(message); // Encode for URL
  };

  const sendMessage = (formattedMessage) => {
    const phoneNumber = '71978260'; // Your target phone number
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${formattedMessage}`;

    window.open(url, '_blank');
  };

  const message = formatResultsMessage(
    topSelectedMajors,
    topNonSelectedMajors,
    getMajorDetails
  );
  //#endregion

  //#region Refs & useImperativeHandle
  useImperativeHandle(ref, () => ({
    getMajorsAndScores() {
      const scores = calculateScores(selectedOptions);
      localStorage.setItem('majorsAndScores', JSON.stringify(scores));
      return scores;
    },
    openCompleteTestDialogue() {
      setRatingPopUp(true);
    },
    openIncompleteTestDialogue() {
      setShowIncompleteDialogue(true);
    },
    checkAllQuestionsAnswered,
  }));
  //#endregion

  //#region Handle Action Buttons
  const handleDialogDecision = (decision) => {
    if (decision === 'continue') {
      const lastAnsweredIndex = [...selectedOptions]
        .reverse()
        .findIndex((option) => option !== null);

      if (lastAnsweredIndex !== -1) {
        const actualIndex = selectedOptions.length - 1 - lastAnsweredIndex;
        setActiveIndex(actualIndex);
        if (questionRefs.current[actualIndex]) {
          questionRefs.current[actualIndex].scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }
      setShowContinueDialog(false);
    } else if (decision === 'restart') {
      localStorage.removeItem('simulationOption');
      setSelectedOptions(Array(testData.length).fill(null));
      setShowContinueDialog(false);
    }
  };

  const handleReturnToTestPage = () => {
    navigate('/test');
  };

  const handleShareResults = () => {
    sendMessage(message);
    navigate('/test');
  };

  const handleBack = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

  const handleNext = () => {
    if (activeIndex < testData.length - 1) {
      setActiveIndex(activeIndex + 1);
    } else {
      handleSubmit();
    }
  };

  //#region Whatsapp Access Message
  const url = createWhatsAppUrl({
    name: localStorage.getItem('userName'),
    email: localStorage.getItem('email'),
    studentPhoneNumber: localStorage.getItem('phoneNumber'),
    testDate: new Date().toLocaleDateString(),
    topSelectedMajors:
      JSON.parse(localStorage.getItem('topSelectedMajors')) || [],
    topNonSelectedMajors:
      JSON.parse(localStorage.getItem('topNonSelectedMajors')) || [],
    messageTemplate: 'requestAccess',
  });
  //#endregion

  const handleSendWhatsappRequest = () => {
    setPurchasePopUp(false);
    setTimeout(() => {
      window.open(url, '_blank');
      navigate('/test', { replace: true });
    }, 300);
  };
  //#endregion

  const scrollToLastAnswered = () => {
    const lastAnsweredIndex = [...selectedOptions]
      .map((option, index) => (option !== null ? index : -1))
      .filter((index) => index !== -1)
      .pop();

    if (
      lastAnsweredIndex !== undefined &&
      questionRefs.current[lastAnsweredIndex]
    ) {
      questionRefs.current[lastAnsweredIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      setActiveStep(lastAnsweredIndex);
    }
  };

  const handlefeedbackPopUp = () => {
    setShowResultDialogue(false);
    setFeedbackPopUp(true);
  };

  const handleDownloadPdf = async (feedback) => {
    setShowResultDialogue(false);
    try {
      await handleSubmitFeedback(feedback);
      console.log('Feedback submitted successfully.');

      await new Promise((resolve) => setTimeout(resolve, 1000));

      generatePDF();

      navigate('/test', { replace: true });
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  return (
    <Box className="muiStepperContainer" sx={{ padding: 2 }}>
      <Stepper activeStep={activeIndex} orientation="vertical">
        {testData.map((question, index) => (
          <Step
            key={index}
            ref={(el) => (questionRefs.current[index] = el)}
            className="muiStep"
            dir={direction}
          >
            <StepLabel className="muiStepperLabel">
              {question.question}
            </StepLabel>
            <StepContent className="muiStepperContentHolder">
              <div className="muiStepperContent">
                {question.options.map((option, optionIndex) => (
                  <Box key={optionIndex} className="muiStepperOptions">
                    <RadioButton
                      id={`option${optionIndex}`}
                      onChange={() => handleOptionChange(index, option)}
                      checked={selectedOptions[index] === option}
                    />
                    <label
                      className="muiStepperOptionLabel"
                      htmlFor={`option${optionIndex}`}
                    >
                      {option}
                    </label>
                  </Box>
                ))}
                <Box className="muiStepperButtons" sx={{ mb: 2 }}>
                  {activeIndex > 0 && (
                    <Button
                      disabled={index === 0}
                      onClick={() => handleBack()}
                      sx={{ mt: 1, mr: 1 }}
                      className="muiStepperSecondaryBtn"
                    >
                      {t('buttons.back')}
                    </Button>
                  )}
                  {index !== testData.length - 1 && (
                    <Button
                      variant="contained"
                      className="muiStepperPrimaryBtn"
                      onClick={() => {
                        // if (index === questions.length - 1) {
                        //   handleSubmit();
                        // } else {
                        handleNext();
                        // }
                      }}
                      sx={{ mt: 1, mr: 1 }}
                      disabled={selectedOptions[index] === null || showLoader}
                    >
                      {showLoader ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        t('buttons.next')
                      )}
                    </Button>
                  )}
                </Box>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {showIncompleteDialogue && (
        <PopUp
          type="Content"
          visible={showIncompleteDialogue}
          content={t('popUps.incompletePopup.content')}
          onHide={() => setShowIncompleteDialogue(false)}
          ActionLabel={t('popUps.incompletePopup.button')}
          handlePrimaryAction={() => setShowIncompleteDialogue(false)}
        />
      )}
      {/* {completeTestDialogue && (
        <PopUp
          type="Content"
          visible={completeTestDialogue}
          content={t('popUps.completeTestPopup.content')}
          ActionLabel={t('popUps.completeTestPopup.button')}
          onHide={() => setCompleteTestDialogue(false)}
          handlePrimaryAction={() => OpenRatingPopUp()}
        />
      )} */}
      {showResultDialogue && (
        <PopUp
          type="Result"
          header={t('popUps.showResultPopup.title')}
          visible={showResultDialogue}
          content={
            <div dir={direction} className="popup-content">
              {/* <ul>{t('popUps.showResultPopup.description')}</ul> */}
              {topSelectedMajors && topSelectedMajors.length > 0 ? (
                <>
                  <div className="sub-header">
                    <i className="fas fa-briefcase"></i>
                    {t('popUps.showResultPopup.content.subHeader')}
                  </div>
                  <ul>
                    {topSelectedMajors.slice(0, 2).map((major) => {
                      const details = getMajorDetails(major.name);
                      return (
                        <li key={major.name}>
                          <div className="name">{details.engName}</div>
                          <hr />
                          <div className="details">
                            <p className="detailsEn">{details.enDescription}</p>
                            <p className="detailsAr">{details.arDescription}</p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </>
              ) : (
                <div className="no-results-message">
                  <i className="fas fa-question-circle"></i>{' '}
                  {t('popUps.showResultPopup.content.noResultsMessage')}
                </div>
              )}
              <div className="sub-header">
                <i className="fas fa-lightbulb"></i>{' '}
                {t(
                  'popUps.showResultPopup.content.alternativeMajors.subHeader'
                )}
              </div>
              {topNonSelectedMajors.length > 0 && (
                <ul>
                  {topNonSelectedMajors.map((major) => {
                    const details = getMajorDetails(major.name);
                    return (
                      <li key={major.name}>
                        <div className="name">{details.engName}</div>
                        <hr />
                        <div className="details">
                          <p className="detailsEn">{details.enDescription}</p>
                          <p className="detailsAr">{details.arDescription}</p>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              )}
              <div className="encouragement-message">
                {t('popUps.showResultPopup.content.encouragementMessage')}
              </div>
            </div>
          }
          ActionLabel={t('popUps.downloadpdf')}
          handlePrimaryAction={() => handlefeedbackPopUp()}
        />
      )}
      {showContinueDialog && (
        <PopUp
          type="Content"
          visible={showContinueDialog}
          content={t('popUps.continueDialog.content')}
          ActionLabel={t('popUps.continueDialog.button')}
          onHide={() => setShowContinueDialog(false)}
          onCancel={() => handleDialogDecision('restart')}
          handlePrimaryAction={() => handleDialogDecision('continue')}
        />
      )}
      {accessQuiz && (
        <PopUp
          type={'Purchase'}
          header={t('popUps.purchasePopUp.header')}
          visible={purchasePopUp}
          content={t('popUps.purchasePopUp.content')}
          //handlePrimaryAction={() => handleAccessUpdate()}
          //ActionLabel={'Api Request'}
          ActionLabel={t('popUps.purchasePopUp.text')}
          handlePrimaryAction={() => handleSendWhatsappRequest()}
          //handleSecondaryAction={() => setPurchasePopUp(false)}
          CancelLabel={t('buttons.back')}
          handleSecondaryAction={() => handleReturnToTestPage()}
        />
      )}

      {ratingPopUp && (
        <PopUp
          type={'Rating'}
          header={t('popUps.ratingPopUp.rating')}
          visible={ratingPopUp}
          //handlePrimaryAction={() => handleAccessUpdate()}
          //ActionLabel={'Api Request'}
          RatingLabel={t('popUps.completeTestPopup.button')}
          handleForthAction={handleSubmit}
          //handleSecondaryAction={() => setPurchasePopUp(false)}
        />
      )}

      {feedbackPopUp && (
        <PopUp
          type={'Feedback'}
          header={t('popUps.ratingPopUp.feedback')}
          visible={feedbackPopUp}
          //handlePrimaryAction={() => handleAccessUpdate()}
          PdfLabel={t('popUps.ratingPopUp.continue')}
          handleFifthAction={handleDownloadPdf}
        />
      )}

      {
        <PopUp
          type="Content"
          visible={showErrorPopUp}
          content={t('popUps.errorMsg')}
        />
      }

      <Backdrop open={loading} style={{ zIndex: 1200 }}>
        <CircularProgress size={60} />
      </Backdrop>
    </Box>
  );
});

export default SimulationTest;
