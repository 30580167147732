import React, { useState } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Tag } from 'primereact/tag';

export default function ControlledDoc() {
  const [activeIndex, setActiveIndex] = useState();

  return (
    <div className="card">
      <Accordion
        multiple
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        <AccordionTab header="Admin Page Overview">
          <>
            <p>
              The admin page is designed to provide a comprehensive interface
              for managing users and their access. This powerful toolset allows
              administrators to handle user details with precision and ease.
              Below is an outline of the main features and how you can use them
              to manage the platform efficiently:
            </p>

            <h5>Managing Access</h5>
            <p>
              You have full control over user access. The "Has Access" column
              determines if a user can access the system, while the other fields
              like "Access Personality," "Access Career," and "Access Skills"
              provide granular control over which specific tests users can take.
            </p>
            <ul>
              <li>
                <strong>
                  If set to{' '}
                  <Tag
                    className="tagText"
                    severity="secondary"
                    value="False"
                    rounded
                  ></Tag>
                </strong>
                , the user won't have access to the relevant tests.
              </li>
              <li>
                <strong>
                  If set to{' '}
                  <Tag
                    className="tagText"
                    severity="success"
                    value="True"
                    rounded
                  ></Tag>
                </strong>
                , the user can access and complete the tests associated with
                that category.
              </li>
            </ul>

            <h5>Majors Information</h5>
            <p>
              <strong>Top Selected Majors</strong>: These are the majors most
              commonly chosen by users based on their preferences in industries,
              work environment, work type, and academic performance. This column
              provides insights into the most popular choices among users.
            </p>
            <p>
              <strong>Top Non-Selected Majors</strong>: This column shows the
              majors that users are least likely to choose, based on results
              from simulation tests alone. It highlights areas where users may
              need further encouragement or information.
            </p>

            <h5>Actionable Toolbar</h5>
            <p>
              The toolbar at the top allows you to perform actions such as
              adding a new user{' '}
              <Tag
                className="tagText"
                severity="success"
                icon="pi pi-plus"
                value="New"
                rounded
              ></Tag>
              , deleting user(s){' '}
              <Tag
                className="tagText"
                severity="danger"
                icon="pi pi-trash"
                value="Delete"
                rounded
              ></Tag>
              , or providing access to user(s){' '}
              <Tag
                className="tagText"
                severity="info"
                icon="pi pi-check"
                value="Access"
                rounded
              ></Tag>
              . Bulk actions like selecting multiple users and making changes to
              them at once can be performed through the table’s multi-select
              feature.
            </p>

            <h5>Search Functionality</h5>
            <p>
              Use the global search bar to filter users by any field in the
              table—whether it's name, email, or major selections. This helps
              you quickly find specific users even when dealing with large
              datasets.
            </p>

            <h5>Export to Excel</h5>
            <p>
              The data can be exported into an Excel file for reporting or
              backup purposes{' '}
              <Tag
                className="p-button-help tagText export"
                icon="pi pi-upload"
                value="Export"
                rounded
              ></Tag>
              . This makes it easy to analyze the data outside the system or
              share it with others.
            </p>

            <h5>Sorting</h5>
            <p>
              You can sort the data by various columns, such as user ID, email,
              or access status. This allows you to manage the data in a way that
              best suits your workflow.
            </p>

            <h5>User Count</h5>
            <p>
              At the bottom of the table, the <strong>Total Users</strong> count
              gives you an up-to-date snapshot of how many users are in the
              system at any given time, offering quick insights without needing
              to export the data.
            </p>
          </>
        </AccordionTab>
      </Accordion>
    </div>
  );
}
