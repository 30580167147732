import { Chart } from 'primereact/chart';

export default function BarGraph({ data, options }) {
  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return (
    <div className="card">
      <Chart type="bar" data={data} options={chartOptions} />
    </div>
  );
}
