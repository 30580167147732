import { useState, useEffect } from 'react';
import { DeleteUsers, UsersInfo } from '../api/admin';

const GetAllUsersInfo = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteError, setDeleteError] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('authToken');

    if (!token) {
      setLoading(false);
      setError('Authentication token not found');
      return;
    }

    const getUsers = async () => {
      try {
        const data = await UsersInfo(token);
        setUsers(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    getUsers();
  }, []);

  const handleDeleteUsers = async (emails) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setDeleteError('Authentication token not found');
      return;
    }

    try {
      setLoading(true);
      await DeleteUsers(token, emails);

      setUsers((prevUsers) =>
        prevUsers.filter((user) => !emails.includes(user.email))
      );
    } catch (err) {
      setDeleteError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return { users, loading, error, deleteError, handleDeleteUsers };
};

export default GetAllUsersInfo;
