export const filterArQuestions = [
  {
    id: 81,
    question: 'اختر اثنين من الصناعات التي تحب العمل فيها:',
    options: [
      'الرعاية الصحية',
      'البناء',
      'الزراعة',
      'الاعلام',
      'التجارة',
      'الخدمات المالية',
      'الاتصالات',
      'التعليم',
      'الفضاء',
      'الصناعة',
      'المعلوماتية',
      'السياحة والضيافة',
      'الصناعات اللوجستية',
      'العقارات',
      'الصناعات الابداعية',
      'الصناعات الخضراء (توليد الطاقة, الزراعة المستدامة...)',
      'الاديان',
      'صناعات الاعمال والإدارة',
      'الصناعات القانونية',
      'الصناعات الادارية والسياسية',
    ],
  },

  {
    id: 82,
    question: 'اختر اثنين من اماكن العمل التي تحب العمل فيها:',
    options: [
      'مستشفى',
      'مطار',
      'حكومة',
      'منظمات لا تبغي الربح',
      'بنك',
      'شركة',
      'مدارس/ جامعات',
      'محلات تجارية',
      'مؤسسات صناعية',
      'شركات بحث أو شركات استشارية',
      'اعلام',
    ],
  },
  {
    id: 94,
    question: 'ما هو نوع العمل الذي يعجبك أكثر؟',
    options: [
      'تحليل البيانات',
      'مساعدة الناس عقليًا أو جسديًا',
      'خدمة الناس أو التفاعل معهم',
      'العمل من أجل البيئة',
      'خلق أفكار جديدة',
      'نقل معرفتك إلى الآخرين',
    ],
  },

  {
    id: 95,
    question: 'ما هي المواد التي ترغب في تعلمها؟',
    options: [
      'رياضيات',
      'كيمياء',
      'فيزياء',
      'علم الحياة',
      'اللغات',
      'فلسفة',
      'تاريخ',
      'جغرافيا',
      'فن',
      'كمبيوتر',
      'اجتماع',
    ],
  },
];

export const arRequiredSubjects = [
  {
    id: 86,
    question:
      'هل حصلت على درجة 12/20 أو أعلى على الرياضيات في امتحانك النهائي في الصف الثالث ثانوي؟',
    options: ['نعم', 'لا'],
    subject: ['رياضيات'],
  },
  {
    id: 87,
    question:
      'هل حصلت على درجة 12/20 أو أعلى على الكيمياء في امتحانك النهائي في الصف الثالث ثانوي؟',
    options: ['نعم', 'لا'],
    subject: ['كيمياء'],
  },
  {
    id: 88,
    question:
      'هل حصلت على درجة 12/20 أو أعلى على الفيزياء في امتحانك النهائي في الصف الثالث ثانوي؟',
    options: ['نعم', 'لا'],
    subject: ['فيزياء'],
  },
  {
    id: 89,
    question:
      'هل حصلت على درجة 12/20 أو أعلى على علم الحياة في امتحانك النهائي في الصف الثالث ثانوي؟',
    options: ['نعم', 'لا'],
    subject: ['علم الحياة'],
  },
  {
    id: 90,
    question:
      'هل حصلت على درجة 12/20 أو أعلى على اللغات في امتحانك النهائي في الصف الثالث ثانوي؟',
    options: ['نعم', 'لا'],
    subject: ['اللغات'],
  },
  {
    id: 91,
    question:
      'هل حصلت على درجة 12/20 أو أعلى على الفلسفة في امتحانك النهائي في الصف الثالث ثانوي؟',
    options: ['نعم', 'لا'],
    subject: ['فلسفة'],
  },
  {
    id: 92,
    question:
      'هل حصلت على درجة 12/20 أو أعلى على التاريخ في امتحانك النهائي في الصف الثالث ثانوي؟',
    options: ['نعم', 'لا'],
    subject: ['تاريخ'],
  },
  {
    id: 93,
    question:
      'هل حصلت على درجة 12/20 أو أعلى على الجغرافيا في امتحانك النهائي في الصف الثالث ثانوي؟',
    options: ['نعم', 'لا'],
    subject: ['جغرافيا'],
  },
  {
    id: 94,
    question:
      'هل تحب الاستمرار في الابتكار وخلق أفكار جديدة بالتصميم، الكتابة، الرسم أو التمثيل؟',
    options: ['نعم', 'لا'],
    subject: ['فن'],
  },
  {
    id: 95,
    question:
      'هل تشعر بالارتياح بالعمل على الكمبيوتر لمدة تسع ساعات أو أكثر في اليوم؟',
    options: ['نعم', 'لا'],
    subject: ['كمبيوتر'],
  },
  {
    id: 96,
    question:
      'هل حصلت على درجة 12/20 أو أعلى على الاجتماع في امتحانك النهائي في الصف الثالث ثانوي؟',
    options: ['نعم', 'لا'],
    subject: ['اجتماع'],
  },
];

export const arSimulationQuestions = [
  {
    id: 96,
    question: 'مساعدة الأشخاص الذين يعانون من مشاكل شخصية أو عاطفية',
    majors: ['Psychology', 'Social Work'],
    options: ['نعم', 'لا'],
  },
  {
    id: 97,
    question: 'تطوير موقع على شبكة الإنترنت',
    majors: [
      'Computer Science',
      'Business Information Systems',
      'Communication and Visual Arts',
      'Computer Graphics and Interactive Media',
      'Digital Advertising',
      'Digital Media',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 98,
    question: 'التفاوض على العقود التجارية',
    majors: ['Law', 'Business Administration'],
    options: ['نعم', 'لا'],
  },
  {
    id: 99,
    question: 'تجميع الأجزاء الإلكترونية',
    majors: [
      'Electrical and Electronics Engineering',
      'Electrical Engineering',
      'Electronic Engineering',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 100,
    question: 'فحص عينات الدم باستخدام المجهر',
    majors: [
      'Biomedical Science',
      'Clinical Laboratory Science',
      'Laboratory Sciences',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 101,
    question: 'احتساب رواتب الموظفين',
    majors: [
      'Accounting',
      'Accounting and Auditing',
      'Accounting and Finance',
      'Banking and Finance',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 102,
    question: 'تثبيت البرامج عبر أجهزة الكمبيوتر على شبكات كبيرة',
    majors: [
      'Computer and Communication Engineering',
      'Computer Engineering',
      'Computer Network and Data Communications',
      'Telecommunication and Networks Engineering',
      'Communication Engineering',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 103,
    question: 'إجراء العلاج التأهيلي للمريض',
    majors: [
      'Speech Therapy',
      'Ergotherapy/ Occupational Therapy',
      'Physical Therapy',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 104,
    question: 'تحليل الحمض النووي لجمع الأدلة لجريمة',
    majors: ['Forensic Science'],
    options: ['نعم', 'لا'],
  },
  {
    id: 105,
    question: 'تركيب وتشغيل الآلات لتصنيع المنتجات',
    majors: [
      'Electrical and Electronics Engineering',
      'Electronic Engineering',
      'Electrical Engineering',
      'Mechanical Engineering',
      'Mechatronics Engineering',
      'Industrial Engineering',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 106,
    question: 'دراسة حركة الكواكب',
    majors: ['Astronomy'],
    options: ['نعم', 'لا'],
  },
  {
    id: 107,
    question: 'شراء وبيع الأسهم والسندات',
    majors: ['Economics', 'Accounting and Finance', 'Statistics'],
    options: ['نعم', 'لا'],
  },
  {
    id: 108,
    question: 'إجراء التجارب الكيميائية',
    majors: ['Chemical Engineering', 'Chemistry'],
    options: ['نعم', 'لا'],
  },
  {
    id: 109,
    question: 'إجراء بحث لمعرفة أسباب الكوارث المناخية',
    majors: [
      'Environmental Health/ Sciences',
      'Environmental / Forestry Engineering',
      'Geology',
      'Geography',
      'Earth and Life Science',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 110,
    question: 'تصميم الجزء الداخلي للمنزل',
    majors: ['Arts & Interior Planning'],
    options: ['نعم', 'لا'],
  },
  {
    id: 111,
    question: 'تطوير البرامج والتطبيقات',
    majors: ['Computer Engineering', 'Computer Science'],
    options: ['نعم', 'لا'],
  },
  {
    id: 112,
    question: 'تصميم الأزياء',
    majors: ['Fashion and Pattern Drafting'],
    options: ['نعم', 'لا'],
  },
  {
    id: 113,
    question: 'بيع المنتجات والخدمات',
    majors: ['Advertising and Marketing Communication', 'Digital Advertising'],
    options: ['نعم', 'لا'],
  },
  {
    id: 114,
    question: 'تقديم السبق الصحفي والأخبار للجمهور',
    majors: [
      'Radio/TV and Film',
      'Media and Communication',
      'Journalism and Electronic Media',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 115,
    question: 'إنشاء وتحديث الخرائط لأغراض مختلفة',
    majors: ['Surveying Engineering'],
    options: ['نعم', 'لا'],
  },
  {
    id: 116,
    question: 'انشاء محتوى مؤثرًا على وسائل التواصل الاجتماعي',
    majors: [
      'Digital Media',
      'Media and Communication',
      'Communication and Visual Arts',
      'Computer Graphics and Interactive Media',
      'Advertising and Marketing Communication',
      'Digital Advertising',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 117,
    question: 'الاهتمام بصحة الناس',
    majors: [
      'Nutrition and Dietetics',
      'Public Health',
      'Medicine',
      'Nursing',
      'Ergotherapy/ Occupational Therapy',
      'Psychomotricity',
      'Speech Therapy',
      'Pharmacy',
      'Health Communication/ Promotion',
      'Fundamental Health Science',
      'Midwifery',
      'Physical Therapy',
      'Dentistry',
      'Optics and Optometry',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 118,
    question: 'إرشاد السياح',
    majors: ['Hospitality', 'Hotel Management and Tourism'],
    options: ['نعم', 'لا'],
  },
  {
    id: 119,
    question: 'تنظيم الحفلات',
    majors: ['Business Administration'],
    options: ['نعم', 'لا'],
  },
  {
    id: 120,
    question: 'جمع عينات من المنتجات الغذائية في مراحل مختلفة من الإنتاج',
    majors: ['Quality Control of Foodstuffs of Animal Origin'],
    options: ['نعم', 'لا'],
  },
  {
    id: 121,
    question: 'إنتاج وإدارة موارد النفط والغاز الطبيعي',
    majors: [
      'Petrochemical Engineering',
      'Petroleum Engineering',
      'Geoscience and Natural Resources',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 122,
    question: 'دمج الحركة الجسدية مع التطور النفسي والعاطفي للمرضى',
    majors: ['Psychomotricity'],
    options: ['نعم', 'لا'],
  },
  {
    id: 123,
    question: 'زراعة وإدارة المحاصيل أو الماشية ',
    majors: ['Agri-Business', 'Agricultural Engineering'],
    options: ['نعم', 'لا'],
  },
  {
    id: 124,
    question: 'تعلم التجارة الدولية وأثرها على الزراعة',
    majors: ['Agricultural Economics', 'Economics'],
    options: ['نعم', 'لا'],
  },
  {
    id: 125,
    question: 'تطوير وتنفيذ تقنيات جديدة في الزراعة',
    majors: ['Agricultural Engineering', 'Plant Production/ Protection'],
    options: ['نعم', 'لا'],
  },
  {
    id: 126,
    question: 'مهتم بالسياسة والوضع الإقليمي',
    majors: ['Administrative and Political Sciences'],
    options: ['نعم', 'لا'],
  },
  {
    id: 127,
    question: 'صيانة الطائرات',
    majors: ['Aircraft Maintenance'],
    options: ['نعم', 'لا'],
  },
  {
    id: 128,
    question: 'معرفة المزيد عن الحيوانات أو الحيوانات الأليفة',
    majors: [
      'Animal Science and Technology',
      'Pet Medicine',
      'Poultry and Cattle Medicine',
      'Quality Control of Foodstuffs of Animal Origin',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 129,
    question: 'معرفة المزيد عن علم الوراثة والجينات',
    majors: ['Biochemistry', 'Bioinformatics', 'Forensic Science'],
    options: ['نعم', 'لا'],
  },
  {
    id: 130,
    question: 'ضمان سلامة الأغذية ومعايير الجودة',
    majors: [
      'Biofood Engineering',
      'Engineering Sciences-Food Engineering',
      'Food and Science Technology/ Quality Assurance',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 131,
    question: 'دراسة الكائنات الحية وتفاعلاتها',
    majors: [
      'Biology',
      'Medicine',
      'Midwifery',
      'Nursing',
      'Fundamental Health Science',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 132,
    question: 'العمل مع المعدات الطبية',
    majors: ['Biomedical Engineering'],
    options: ['نعم', 'لا'],
  },
  {
    id: 133,
    question: 'التركيز على الصحة العقلية للناس',
    majors: ['Psychology', 'Social Work', 'Social Sciences'],
    options: ['نعم', 'لا'],
  },
  {
    id: 134,
    question: 'العمل مع تقنيات تجهيز الأغذية',
    majors: [
      'Biofood Engineering',
      'Food and Science Technology/ Quality Assurance',
      'Engineering Sciences-Food Engineering',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 135,
    question: 'إخراج أو إنتاج الأفلام والبرامج',
    majors: [
      'Cinema Production',
      'Audiovisual Production',
      'Radio/TV and Film',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 136,
    question: 'مهتم بتصوير مشاهد الأفلام',
    majors: ['Photography'],
    options: ['نعم', 'لا'],
  },
  {
    id: 137,
    question: 'تحسين الأعمال العامة مثل الطرق والجسور وشبكات المياه',
    majors: ['Civil Engineering', 'Civil Environmental Engineering'],
    options: ['نعم', 'لا'],
  },
  {
    id: 138,
    question: 'التأكد من امتثال مشاريع البناء للوائح ومعايير السلامة',
    majors: ['Civil Engineering', 'Architecture'],
    options: ['نعم', 'لا'],
  },
  {
    id: 139,
    question:
      'العمل على المشاريع التي تهدف إلى تحسين نوعية المياه وإدارة النفايات',
    majors: [
      'Civil Environmental Engineering',
      'Environmental / Forestry Engineering',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 140,
    question: 'ترغب في أن تكون خبيرا في اللغات',
    majors: [
      'Comparative Linguistics Engineering',
      'Translation and Interpretation',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 141,
    question: 'العمل على تطوير التقنيات الجديدة في مجال الشبكات ونقل البيانات',
    majors: [
      'Computer and Communication Engineering',
      'Computer Network and Data Communications',
      'Telecommunication and Networks Engineering',
      'Communication Engineering',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 142,
    question:
      'تحديد ومراقبة وإدارة الآفات والأمراض والأعشاب الضارة التي تهدد صحة النبات',
    majors: ['Plant Production/ Protection'],
    options: ['نعم', 'لا'],
  },
  {
    id: 143,
    question: 'تعلٌم تقنيات ترميم الممتلكات الفنية والثقافية المتضررة',
    majors: ['Conservation, Restoration of Cultural Property and Sacred Art'],
    options: ['نعم', 'لا'],
  },
  {
    id: 144,
    question:
      'التعرُف على تقنيات الطبخ المختلفة والمأكولات من جميع أنحاء العالم',
    majors: ['Culinary Arts and Restaurant Management'],
    options: ['نعم', 'لا'],
  },
  {
    id: 145,
    question: 'العمل مع البيانات لاستخلاص الأفكار وحل المشكلات',
    majors: ['Data Science', 'Statistics', 'Applied Mathematics'],
    options: ['نعم', 'لا'],
  },
  {
    id: 146,
    question: 'مساعدة الناس على الحصول على شكل جسم جيد',
    majors: ['Physical Education'],
    options: ['نعم', 'لا'],
  },
  {
    id: 147,
    question: 'العمل مع أدوات وبرامج التصميم المختلفة',
    majors: ['Graphic Design', 'Design and Applied Arts', 'Product Design'],
    options: ['نعم', 'لا'],
  },
  {
    id: 148,
    question: 'إدارة حركة وتخزين البضائع والمواد',
    majors: ['Distribution and Logistics Management'],
    options: ['نعم', 'لا'],
  },
  {
    id: 149,
    question: 'العمل مع الطلاب من مختلف الأعمار والخلفيات',
    majors: ['Early Childhood Education', 'Education'],
    options: ['نعم', 'لا'],
  },
  {
    id: 150,
    question: 'إرشاد وتوجيه الطلاب في تطورهم الأكاديمي والشخصي',
    majors: ['Education'],
    options: ['نعم', 'لا'],
  },
  {
    id: 151,
    question: 'دراسة التكوينات الجيولوجية والأنماط المناخية والتغيرات البيئية',
    majors: [
      'Geology',
      'Geography',
      'Earth and Life Sciences',
      'Geoscience and Natural Resources',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 152,
    question: 'تصميم والإشراف على حفر آبار النفط والغاز',
    majors: ['Petrochemical Engineering', 'Petroleum Engineering'],
    options: ['نعم', 'لا'],
  },
  {
    id: 153,
    question: 'التقاط صور احترافية للأحداث والطبيعة وما إلى ذلك',
    majors: ['Photography'],
    options: ['نعم', 'لا'],
  },
  {
    id: 154,
    question: 'تحسين الكفاءة والإنتاجية في التصنيع والعمليات الصناعية',
    majors: ['Industrial Engineering', 'Applied Mathematics'],
    options: ['نعم', 'لا'],
  },
  {
    id: 155,
    question: 'الوصول الى معارف كثيرة والتفاعل مع ثقافات وجنسيات مختلفة',
    majors: [
      'International and Diplomatic Relations',
      'International Business',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 156,
    question: 'مهتم بالديانات واللاهوت',
    majors: [
      'Islamic Studies',
      'Christian Studies',
      'Theology',
      'Religions Sciences',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 157,
    question: 'معرفة المزيد عن الموسيقى',
    majors: ['Music'],
    options: ['نعم', 'لا'],
  },
  {
    id: 158,
    question: 'تصميم وتخطيط المساحات الخارجية والحدائق والمناظر الطبيعية',
    majors: ['Landscape Engineering'],
    options: ['نعم', 'لا'],
  },
  {
    id: 159,
    question:
      'إنشاء مخططات للمناظر الطبيعية آخذا بعين الاعتبار عوامل مثل تآكل التربة والصرف ومتطلبات الصيانة',
    majors: ['Landscape Engineering'],
    options: ['نعم', 'لا'],
  },
  {
    id: 160,
    question:
      'تعلم مجالات مختلفة من القانون مثل القانون الجنائي أو القانون المدني أو القانون الدستوري أو القانون الدولي',
    majors: ['Law'],
    options: ['نعم', 'لا'],
  },
  {
    id: 161,
    question: 'التركيز على صحة ورفاهية الحيوانات الأليفة أو الدواجن',
    majors: ['Pet Medicine', 'Poultry and Cattle Medicine'],
    options: ['نعم', 'لا'],
  },
  {
    id: 162,
    question: 'استخدام التكنولوجيا لتصور وتشخيص الحالات الطبية',
    majors: [
      'Medical Imaging Sciences',
      'Medical Technology with Concentration in Radiologic Science',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 163,
    question: 'تحليل الصور الطبية لتحديد التشوهات والمساعدة في تشخيص المريض',
    majors: [
      'Medical Imaging Sciences',
      'Medical Technology with Concentration in Radiologic Science',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 164,
    question: 'دراسة الضوء والرؤية والعين البشرية',
    majors: ['Optics and Optometry'],
    options: ['نعم', 'لا'],
  },
  {
    id: 165,
    question: 'أن تكون عضوًا في فرقة موسيقية أو حدث موسيقي',
    majors: ['Music'],
    options: ['نعم', 'لا'],
  },
  {
    id: 166,
    question:
      'فضولي بشأن الأسئلة الأساسية المتعلقة بالوجود والمعرفة والأخلاقيات وطبيعة الواقع',
    majors: ['Philosophy'],
    options: ['نعم', 'لا'],
  },
  {
    id: 167,
    question:
      'البقاء على اطلاع دائم بالتطورات في علوم التمرينات واتجاهات اللياقة البدنية والطب الرياضي',
    majors: ['Physical Education'],
    options: ['نعم', 'لا'],
  },
  {
    id: 168,
    question:
      'التعرف على المفاهيم الأساسية مثل الميكانيكا والكهرومغناطيسية والديناميكا الحرارية',
    majors: ['Physics', 'Mechanical Engineering'],
    options: ['نعم', 'لا'],
  },
  {
    id: 169,
    question: 'إنشاء علامة تجارية أو تصميم منتج',
    majors: ['Product Design', 'Graphic Design', 'Design and Applied Arts'],
    options: ['نعم', 'لا'],
  },
  {
    id: 170,
    question:
      'استكشاف قضايا مثل عدم المساواة والعدالة الاجتماعية والعولمة والتنمية البشرية',
    majors: [
      'Social Sciences',
      'Sociology-Anthropology',
      'Administrative and Politics Sciences',
      'Philosophy',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 171,
    question:
      'العمل في الهواء الطلق وإجراء المسوحات الميدانية وجمع القياسات الدقيقة',
    majors: ['Surveying Engineering'],
    options: ['نعم', 'لا'],
  },
  {
    id: 172,
    question: 'العمل في الموقع واختيار أفضل المواد الإنشائية للمشروع',
    majors: ['Architecture', 'Arts and Interior Planning'],
    options: ['نعم', 'لا'],
  },
  {
    id: 173,
    question: 'معرفة المزيد عن تاريخ العالم أو العثور على بقايا مادية',
    majors: ['Archaeology', 'History', 'Sociology-Anthropology'],
    options: ['نعم', 'لا'],
  },
  {
    id: 174,
    question: 'تنفيذ وإغلاق الفواتير مع الموردين والعملاء',
    majors: ['Accounting'],
    options: ['نعم', 'لا'],
  },
  {
    id: 175,
    question: 'التحقق من المعاملات والسجلات المالية للتأكد من دقة الشركة',
    majors: ['Accounting and Auditing'],
    options: ['نعم', 'لا'],
  },
  {
    id: 176,
    question:
      'تقديم المشورة المتخصصة للمزارعين والشركات الزراعية بشأن تحسين الإنتاجية والاستدامة والربحية',
    majors: ['Agri-Business'],
    options: ['نعم', 'لا'],
  },
  {
    id: 177,
    question:
      'تحليل اتجاهات السوق، والأسعار، والعوامل الاقتصادية المؤثرة على صناعة الزراعة لإعلام استراتيجيات الأعمال',
    majors: ['Agricultural Economics'],
    options: ['نعم', 'لا'],
  },
  {
    id: 178,
    question:
      'صيانة وإصلاح الأنظمة الإلكترونية للطائرة، مثل أنظمة الملاحة والاتصالات والرادار',
    majors: ['Aircraft Maintenance'],
    options: ['نعم', 'لا'],
  },
  {
    id: 179,
    question: 'إدارة برامج الرعاية والتربية والمحافظة على الحيوانات',
    majors: ['Animal Science and Technology'],
    options: ['نعم', 'لا'],
  },
  {
    id: 180,
    question:
      'إدارة المجموعات وتصميم المعارض وتفسير القطع الأثرية والتاريخية للحضور',
    majors: ['Archaeology', 'History'],
    options: ['نعم', 'لا'],
  },
  {
    id: 181,
    question:
      'العمل في مجال استكشاف الفضاء، والتعاون في تصميم المركبات الفضائية، أو عمليات الأقمار الصناعية',
    majors: ['Astronomy'],
    options: ['نعم', 'لا'],
  },
  {
    id: 182,
    question:
      'كتابة سيناريوهات الأفلام والبرامج التلفزيونية وغيرها من المحتوى السمعي البصري، وتطوير الحوار والمشاهد والقصص',
    majors: ['Audiovisual Production', 'Cinema Production'],
    options: ['نعم', 'لا'],
  },
  {
    id: 183,
    question: 'العمل في القطاع المصرفي لتقديم الخدمات للعملاء',
    majors: ['Banking and Finance'],
    options: ['نعم', 'لا'],
  },
  {
    id: 184,
    question:
      'العمل في صناعة الأدوية لتطوير أدوية أو علاجات أو أدوات تشخيصية جديدة',
    majors: [
      'Pharmacy',
      'Biochemistry',
      'Bioinformatics',
      'Biology',
      'Biomedical Science',
      'Chemical Engineering',
      'Chemistry',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 185,
    question:
      'تصميم وتطوير الأجهزة الطبية، مثل الأعضاء الاصطناعية، والأطراف الصناعية، وأنظمة التصوير، ومعدات التشخيص',
    majors: ['Biomedical Engineering'],
    options: ['نعم', 'لا'],
  },
  {
    id: 186,
    question: 'تقييم وتصميم أنظمة المعلومات لتلبية احتياجات الأعمال',
    majors: ['Business Information Systems'],
    options: ['نعم', 'لا'],
  },
  {
    id: 187,
    question:
      'توفير الرعاية الروحية والاستشارة في أماكن مختلفة، مثل المستشفيات، والمؤسسات العسكرية، والمرافق الإصلاحية، أو المؤسسات التعليمية',
    majors: [
      'Christian Studies',
      'Islamic Studies',
      'Religions Sciences',
      'Theology',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 188,
    question:
      'جمع الدم والأنسجة والسوائل الجسدية الأخرى من المرضى وإعداد العينات للاختبار',
    majors: ['Clinical Laboratory Science', 'Laboratory Sciences'],
    options: ['نعم', 'لا'],
  },
  {
    id: 189,
    question: 'ترجمة النص أو الصوت أو الفيديو إلى لغة مختلفة',
    majors: [
      'Comparative Linguistics Engineering',
      'Translation and Interpretation',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 190,
    question: 'أن تكون مسئولا عن مطعم أو فندق',
    majors: [
      'Culinary Arts and Restaurant Management',
      'Hospitality',
      'Hotel Management and Tourism',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 191,
    question:
      'معالجة البيانات المفقودة، وإزالة البيانات المكررة، وتحويل البيانات إلى تنسيق مناسب لتحليل البيانات',
    majors: ['Data Science', 'Physics'],
    options: ['نعم', 'لا'],
  },
  {
    id: 192,
    question: 'ان تكون طبيب أسنان',
    majors: ['Dentistry'],
    options: ['نعم', 'لا'],
  },
  {
    id: 193,
    question:
      'بناء العلاقات والحفاظ عليها مع الموردين والبائعين والتفاوض على العقود',
    majors: ['Distribution and Logistics Management'],
    options: ['نعم', 'لا'],
  },
  {
    id: 194,
    question: 'رعاية الرضع والأطفال دون سن الثالثة',
    majors: ['Early Childhood Education'],
    options: ['نعم', 'لا'],
  },
  {
    id: 195,
    question: 'دراسة تأثير الجوانب البيئية على الصحة العامة',
    majors: ['Environmental Health/ Sciences'],
    options: ['نعم', 'لا'],
  },
  {
    id: 196,
    question: 'تقديم المشورة للفنانين والمشاهير في تصميم الأزياء الخاصة بهم',
    majors: ['Fashion and Pattern Drafting'],
    options: ['نعم', 'لا'],
  },
  {
    id: 197,
    question:
      'تقديم المشورة الغذائية الفردية وتخطيط الوجبات لتحقيق الصحة المثالية',
    majors: ['Nutrition and Dietetics'],
    options: ['نعم', 'لا'],
  },
  {
    id: 198,
    question: 'تقديم المشورة للمجتمعات لتحسين صحتهم من جوانب مختلفة',
    majors: ['Health Communication/ Promotion', 'Public Health'],
    options: ['نعم', 'لا'],
  },
  {
    id: 199,
    question: 'إعداد توصيف للوظائف والمؤهلات المناسبة لها',
    majors: ['Human Resources Management'],
    options: ['نعم', 'لا'],
  },
  {
    id: 200,
    question: 'نشر فرص العمل، وفحص السير الذاتية، وإجراء المقابلات',
    majors: ['Human Resources Management'],
    options: ['نعم', 'لا'],
  },
  {
    id: 201,
    question:
      'بناء العلاقات والحفاظ عليها مع الحكومات الأجنبية والمنظمات التجارية وشبكات الأعمال الدولية',
    majors: [
      'International and Diplomatic Relations',
      'International Business',
    ],
    options: ['نعم', 'لا'],
  },
  {
    id: 202,
    question: 'تغطية الأحداث والكوارث والمهرجانات والمؤتمرات وما إلى ذلك',
    majors: ['Journalism and Electronic Media'],
    options: ['نعم', 'لا'],
  },
  {
    id: 203,
    question: 'تطوير وبرمجة الأنظمة الآلية والروبوتات',
    majors: ['Mechatronics Engineering'],
    options: ['نعم', 'لا'],
  },
];
