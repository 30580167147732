export const personalityEnTestData = {
  'Pragmatic Personality': [
    {
      id: 1,
      question: 'Do you prefer routine and repetitive tasks?',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 2,
      question:
        'Would you describe yourself as a practical person who enjoys dealing with real-world problems?',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 3,
      question:
        'Do you enjoy tasks that allow you to see immediate and tangible results?',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 4,
      question:
        'Do you feel more comfortable in settings with a clear hierarchy and defined roles?',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 5,
      question:
        'Do you rely more on practical experience and concrete evidence when making decisions?',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 6,
      question: 'I am a person who aims for stability.',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 7,
      question: 'What will you do if a piece of machinery is damaged?',
      options: [
        'Know the causes and fix it',
        'Deliver it to the Maintenance shop',
        'Keep it damaged',
        'Throw it in the trash',
      ],
      answer: 'Know the causes and fix it',
    },
  ],
  'Analytical Personality': [
    {
      id: 8,
      question:
        'Do you engage in activities that involve research, analysis, and intellectual pursuits?',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 9,
      question: 'Do you use analytical and evidence-based methods?',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 10,
      question:
        'Do you often question assumptions and seek evidence before accepting conclusions?',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 11,
      question: 'Do you like complex ideas?',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 12,
      question: 'I like to think of Metaphysics.',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 13,
      question: 'What types of books or articles do you enjoy reading?',
      options: ['Investigative', 'Politics', 'Social', 'Artistic'],
      answer: 'Investigative',
    },
    {
      id: 14,
      question: 'What kind of work environment do you thrive in?',
      options: ['Quiet', 'Crowd'],
      answer: 'Quiet',
    },
  ],
  'Artistic Personality': [
    {
      id: 15,
      question:
        'Do you have any artistic hobbies like painting, writing, or playing music?',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 16,
      question:
        'Do you prefer jobs that offer creative freedom and flexibility?',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 17,
      question:
        'When faced with a problem, do you develop innovative and creative solutions?',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 18,
      question:
        'Do you often challenge traditional norms and seek unique ways to express yourself?',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 19,
      question: 'I am a person who likes frequent change',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 20,
      question: 'I have a vivid imagination.',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 21,
      question: 'What types of TV shows do you prefer the most?',
      options: ['Artistic', 'Comedy', 'Politics', 'Documentary', 'Social'],
      answer: 'Artistic',
    },
  ],
  'Social Personality': [
    {
      id: 22,
      question:
        'Do you prefer work environments that are collaborative, interactive, and people-focused?',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 23,
      question:
        'Did you participate in any volunteer work or community service?',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 24,
      question:
        'Do you prefer jobs that involve working with others rather than working alone?',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 25,
      question: 'Do you regularly make new friends?',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 26,
      question:
        'I am a person who enjoys working with people from different disciplines and skills',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 27,
      question: 'Make an effort to be popular.',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 28,
      question: 'If someone was suffering and crying in your presence.',
      options: [
        'Try talking to him and get him comfortable.',
        'Don’t take any action.',
      ],
      answer: 'Try talking to him and get him comfortable.',
    },
  ],
  'Innovative Personality': [
    {
      id: 29,
      question:
        'Do you have a clear vision for the future and strategic plans to achieve your goals?',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 30,
      question: 'I am a person who likes to see the bigger picture.',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 31,
      question: 'I like to create and explore new ideas.',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 32,
      question:
        'I feel comfortable taking on leading and guiding others towards a common goal.',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 33,
      question: 'Do you take a risk in making decisions?',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 34,
      question: 'What is your dream?',
      options: [
        'To be promoted from position to position',
        'Making Money',
        'Own a Business',
        'To be an influencer',
        'To help weak people',
      ],
      answer: 'Own a Business',
    },
    {
      id: 35,
      question:
        'How do you handle the introduction of new technologies or processes at work?',
      options: [
        'Resist and prefer the old ways',
        'Hesitantly adopt them but take a long time to adjust.',
        'Use them only when necessary and avoid learning more.',
        'Embrace them and seek to understand their benefits',
      ],
      answer: 'Embrace them and seek to understand their benefits',
    },
  ],
  'Traditional Personality': [
    {
      id: 36,
      question:
        'Do you prioritize and plan tasks effectively, often completing them well before the deadline?',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 37,
      question:
        "Do you like to check people's work before you get it finalized?",
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 38,
      question:
        'Do you exhibit dependability, organization, and attention to detail?',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 39,
      question: 'Do you prefer following regulations, guidelines, and laws?',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 40,
      question: "I can't work in an unorganized environment.",
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 41,
      question: 'Do you like tasks that need accuracy and precision?',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 42,
      question: 'Which skills do you feel most confident in?',
      options: [
        'Analytical thinking and problem-solving.',
        'Creativity and innovation.',
        'Communication and interpersonal skills.',
        'Technical skills (e.g., programming, engineering).',
        'Leadership and teamwork.',
      ],
      answer: 'Analytical thinking and problem-solving.',
    },
  ],
};

export const personalityEnThresholdScoring = {
  'Pragmatic Personality': {
    thresholds: {
      50: 'Exhibits a moderate inclination towards practicality and logic, balanced with other traits. You typically favor efficient and realistic solutions but remain open to innovative ideas and emotional considerations.',
      55: 'You lean more heavily towards practicality and logic, though still maintain some balance with other traits. You predominantly focus on realistic and efficient solutions, often approaching problems with a clear, methodical mindset.',
      60: 'You have a strong preference for practicality, logic, and efficiency. You consistently prioritize realistic solutions and effective problem-solving techniques. You excel at organizing your work and managing tasks based on importance and deadlines.',
      70: 'You are highly focused on practicality and logical thinking and predominantly driven by the need for efficiency and realistic solutions, often viewing problems through a lens of what is most effective and feasible.',
    },
  },
  'Analytical Personality': {
    thresholds: {
      50: 'You have a moderate inclination towards data analysis, logical reasoning, and detailed examination.',
      55: 'You lean slightly more towards analytical thinking, favoring logical reasoning and detailed examination in most situations. You are comfortable delving into data, identifying patterns, and making decisions based on thorough analysis.',
      60: 'You show a noticeable preference for analytical thinking. You prioritize logical reasoning, data analysis, and detailed examination in your approach to problems and decision-making.',
      70: 'You are highly focused on logical reasoning, data analysis, and critical thinking. You predominantly rely on detailed examination and methodical approaches to solve problems and make decisions. You excel in roles that demand precision, attention to detail, and systematic analysis.',
    },
  },
  'Artistic Personality': {
    thresholds: {
      50: 'You have a moderate inclination towards creativity, imagination, and artistic expression, but are equally comfortable relying on logic, practicality, and analytical thinking when necessary.',
      55: 'You lean slightly more towards artistic creativity, favoring imagination and artistic expression in most situations. You are comfortable delving into creative projects, generating innovative ideas, and appreciating beauty and aesthetics.',
      60: 'Shows a noticeable preference for creativity and artistic expression. You prioritize imagination, innovation, and aesthetics in your approach to life and work. You excel at generating unique ideas, appreciating beauty, and engaging in creative projects.',
      70: 'You are highly focused on creativity, imagination, and artistic expression. You predominantly rely on creative instincts and appreciation for beauty and aesthetics. You excel in roles that demand originality, innovation, and artistic flair.',
    },
  },
  'Social Personality': {
    thresholds: {
      50: 'You have a moderate inclination towards social interaction, relationship-building, and teamwork but are equally comfortable with solitary tasks, analytical thinking, or independent work when necessary.',
      55: 'You lean slightly more towards social engagement, favoring interaction, collaboration, and relationship-building in most situations. You are comfortable in group settings, enjoy engaging with others, and are adept at fostering teamwork.',
      60: 'You have a noticeable preference for social interaction and engagement, and prioritize relationship-building, teamwork, and communication in your approach to life and work. You excel at working in group settings, fostering collaboration, and engaging with others.',
      70: 'You are highly focused on social interaction, relationship-building, and teamwork and predominantly rely on social skills and enjoy engaging with others. You excel in roles that demand strong interpersonal skills, collaboration, and communication.',
    },
  },
  'Innovative Personality': {
    thresholds: {
      50: 'You have a moderate inclination towards creativity, forward-thinking, and embracing new ideas but are equally comfortable with traditional methods, practicality, and established processes when necessary.',
      55: 'You lean slightly more towards innovation, favoring creativity, forward-thinking, and embracing new ideas in most situations. You are comfortable exploring novel concepts and implementing changes.',
      60: 'You have a noticeable preference for creativity and forward-thinking and prioritize generating new ideas, embracing change, and challenging traditional methods.',
      70: 'You are highly focused on creativity, forward-thinking, and embracing new ideas and predominantly rely on innovative instincts and enjoy exploring novel concepts. You excel in roles that demand originality, adaptability, and the implementation of new solutions.',
    },
  },
  'Traditional Personality': {
    thresholds: {
      50: 'You have a moderate inclination towards established methods, conventional thinking, and adherence to norms but are equally comfortable with embracing change, innovation, and new ideas when necessary.',
      55: 'You lean slightly more towards traditional values, favoring established methods, conventional thinking, and adherence to norms in most situations. You are comfortable with routines, following set procedures, and maintaining stability.',
      60: 'You have a noticeable preference for established methods, conventional thinking, and adherence to norms. You prioritize maintaining stability, following routines, and respecting traditions in your approach to life and work.',
      70: 'You are highly focused on established methods, conventional thinking, and adherence to norms and excel in roles that demand consistency, reliability, and order.',
    },
  },
};
