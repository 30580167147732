//#region Imports
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
} from '@mui/material';
import PopUp from '../../../SharedComponents/PopUps';
import { useAuth } from '../../../../hooks/useAuth';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { simulationEnAnalysis } from '../../../../Data/simulationEnAnalysis';
import { simulationArAnalysis } from '../../../../Data/simulationArAnalysis';
import { engRequiredSubjects } from '../../../../Data/simulationEngQuestions';
import { arRequiredSubjects } from '../../../../Data/simulationArQuestions';
//#endregion

const RequiredSubject = forwardRef((props, ref) => {
  //#region State
  const { i18n, t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [showIncompleteDialogue, setShowIncompleteDialogue] = useState(false);
  const { handleGetUserInfo } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [purchasePopUp, setPurchasePopUp] = useState(false);
  const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';
  const [selectedRequiresSubjects, setSelectedRequiresSubjects] = useState({});
  const [requiredSubjects, setRequiredSubjects] = useState([]);
  //#endregion

  //#region To get the user info constantly to update access
  useEffect(() => {
    const email = localStorage.getItem('email');
    if (email) {
      handleGetUserInfo(email).finally(() => {});
    }
  }, [handleGetUserInfo]);
  //#endregion

  //#region Authentication (Navigation) and Access to test
  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (authToken == null || authToken === '') {
      navigate('/login', { state: { from: location.pathname }, replace: true });
    } else {
      const hasAccess = JSON.parse(localStorage.getItem('hasAccess'));
      const accessPersonality = JSON.parse(
        localStorage.getItem('accessPersonality')
      );
      if (hasAccess === true && accessPersonality === true) {
        setPurchasePopUp(false);
      } else {
        setPurchasePopUp(true);
      }
    }
  }, [navigate, location.pathname]);
  //#endregion

  //#region To change the Required Subject data between En and Ar on change of language
  const [testData, setTestData] = useState(engRequiredSubjects);
  useEffect(() => {
    console.log('Current Language:', i18n.language);
    const currentLanguage =
      i18n.language || localStorage.getItem('i18nextLng') || 'en';
    if (currentLanguage === 'ar') {
      setTestData(arRequiredSubjects);
    } else {
      setTestData(engRequiredSubjects);
    }
  }, [i18n.language]);
  //#endregion

  //#region To change the Simulation Analysis data between En and Ar on change of language
  const [analysisData, setAnalysisData] = useState(simulationEnAnalysis);
  useEffect(() => {
    console.log('Current Language:', i18n.language);
    const currentLanguage =
      i18n.language || localStorage.getItem('i18nextLng') || 'en';
    if (currentLanguage === 'ar') {
      setAnalysisData(simulationArAnalysis);
    } else {
      setAnalysisData(simulationEnAnalysis);
    }
  }, [i18n.language]);
  //#endregion

  //#region To Update with the subject that the user selected as Yes
  const handleOptionChange = (questionId, option) => {
    setSelectedRequiresSubjects((prev) => {
      const updatedSubjects = { ...prev, [questionId]: option };

      const isYes = (option) => option === 'Yes' || option === 'نعم';

      const currentLanguage =
        i18n.language || localStorage.getItem('i18nextLng') || 'en';

      const selectedSubjects = testData
        .filter((question) => isYes(updatedSubjects[question.id]))
        .map((question) => {
          if (currentLanguage === 'ar') {
            return arRequiredSubjects.find(
              (arSubject) => arSubject.id === question.id
            )?.subject;
          } else {
            return question.subject;
          }
        })
        .filter((subject) => subject);

      setRequiredSubjects(selectedSubjects);

      console.log('Updated selected subjects:', selectedSubjects);

      return updatedSubjects;
    });
  };
  //#endregion

  //#region Hard Coded Example
  // useEffect(() => {
  //   //Hard-coded student preferences
  //   const selectedIndustries = ['الاتصالات', 'المعلوماتية'];
  //   const selectedWorkplaces = ['شركة', 'اعلام'];
  //   const selectedWorkType = ['خلق أفكار جديدة'];
  //   const selectedPreferedCourses = ['فن (رسم, مسرح...)', 'كمبيوتر'];
  //   const requiredMajors = [
  //     'كيمياء',
  //     'فيزياء',
  //     'علم الحياة',
  //     'اللغات',
  //     'فلسفة',
  //     'تاريخ',
  //     'فن (رسم, مسرح...)',
  //     'جغرافيا',
  //     'كمبيوتر',
  //     'اجتماع',
  //   ];

  //   // const filterMajor = JSON.parse(localStorage.getItem('filterMajor'));
  //   // const storedRequiredSubjects = JSON.parse(
  //   //   localStorage.getItem('requiredSubjects')
  //   // );

  //   // const requiredSubjects = storedRequiredSubjects || { requiredMajors: [] };
  //   // const selectedIndustries = filterMajor?.selectedIndustries || [];
  //   // const selectedWorkplaces = filterMajor?.selectedWorkplaces || [];
  //   // const selectedWorkType = filterMajor?.selectedWorkType || [];
  //   // const selectedPreferedCourses = filterMajor?.selectedPreferedCourses || [];
  //   // const requiredMajors = requiredSubjects.requiredMajors || [];

  //   // Function to filter majors based on student preferences
  //   function getSuitableMajors() {
  //     // Step 1: Filter by selected industries
  //     const filteredIndustries = simulationArAnalysis.industries.filter(
  //       (industry) => selectedIndustries.includes(industry.industryName)
  //     );
  //     console.log('Filtered Industries:', filteredIndustries);

  //     // Step 2: Filter by selected workplaces
  //     const filteredMajorsByWorkplace = filteredIndustries.flatMap((industry) =>
  //       industry.majors.filter((major) =>
  //         major.workplaces.some((workplace) =>
  //           selectedWorkplaces.includes(workplace)
  //         )
  //       )
  //     );
  //     console.log('Filtered Majors by Workplace:', filteredMajorsByWorkplace);

  //     // Step 3: Filter by work type and required subjects
  //     const filteredMajorsByWorkTypeAndSubjects =
  //       filteredMajorsByWorkplace.filter((major) => {
  //         const isWorkTypeMatch = selectedWorkType.some((type) =>
  //           major.workType.includes(type)
  //         );

  //         const hasRequiredSubjects =
  //           major.requiredSubjects.length === 0 ||
  //           major.requiredSubjects.every((subject) =>
  //             requiredMajors.includes(subject)
  //           );

  //         return isWorkTypeMatch && hasRequiredSubjects;
  //       });
  //     console.log(
  //       'Filtered Majors by Work Type and Required Subjects:',
  //       filteredMajorsByWorkTypeAndSubjects
  //     );

  //     // Step 4: Match preferred courses
  //     const suitableMajors = filteredMajorsByWorkTypeAndSubjects.filter(
  //       (major) =>
  //         major.preferredCourses.some((course) =>
  //           selectedPreferedCourses.includes(course)
  //         )
  //     );
  //     console.log('Suitable Majors:', suitableMajors);

  //     return suitableMajors;
  //   }

  //   // Example usage
  //   const suitableMajors = getSuitableMajors();
  //   localStorage.setItem('suitableMajors', JSON.stringify(suitableMajors));
  //   console.log('Final Suitable Majors:', suitableMajors);
  // }, []);
  //#endregion

  //#region Initialize Data
  useEffect(() => {
    window.scrollTo(0, 0);
    const savedData = JSON.parse(localStorage.getItem('requiredSubjects'));
    if (savedData) {
      setSelectedRequiresSubjects(savedData.selectedRequiresSubjects || {});
      setRequiredSubjects(savedData.requiredMajors || []);
    }
  }, []);
  //#endregion

  //#region Refs & useImperativeHandle
  const checkAllQuestionsAnswered = () => {
    return (
      Object.keys(selectedRequiresSubjects).length === testData.length &&
      Object.values(selectedRequiresSubjects).every((option) => option !== '')
    );
  };

  useImperativeHandle(ref, () => ({
    saveAnswers() {
      const currentLanguage =
        i18n.language || localStorage.getItem('i18nextLng') || 'en';
      const subjects =
        currentLanguage === 'ar' ? arRequiredSubjects : engRequiredSubjects;

      const requiredMajors = subjects
        .filter(
          (question) =>
            selectedRequiresSubjects[question.id] === 'Yes' ||
            selectedRequiresSubjects[question.id] === 'نعم'
        )
        .map((question) => question.subject);

      const savedData = {
        selectedRequiresSubjects,
        requiredMajors,
      };

      localStorage.setItem('requiredSubjects', JSON.stringify(savedData));
      console.log('Answers saved:', savedData);
    },
    openIncompleteTestDialogue() {
      setShowIncompleteDialogue(true);
    },
    checkAllQuestionsAnswered,
    getRequiredSubjects() {
      return requiredSubjects;
    },
  }));
  //#endregion

  //#region Handle Change Buttons
  const handleNext = () => {
    if (selectedRequiresSubjects[testData[activeIndex].id] !== undefined) {
      setActiveIndex((prevIndex) => prevIndex + 1);
    } else {
      setShowIncompleteDialogue(true);
    }
  };

  const handleBack = () => {
    setActiveIndex((prevIndex) => prevIndex - 1);
  };

  const handleReturnToTestPage = () => {
    navigate('/test');
  };
  //#endregion

  return (
    <>
      <Box className="muiStepperContainer" sx={{ padding: 2 }}>
        <Stepper activeStep={activeIndex} orientation="vertical">
          {testData.map((question, index) => (
            <Step key={question.id} className="muiStep" dir={direction}>
              <StepLabel className="muiStepperLabel">
                <Typography variant="h6">{question.question}</Typography>
              </StepLabel>
              <StepContent className="muiStepperContentHolder">
                <Box className="muiStepperContent careerTest">
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label={question.subject}
                      name={`question-${question.id}`}
                      value={selectedRequiresSubjects[question.id] || ''}
                      onChange={(e) =>
                        handleOptionChange(question.id, e.target.value)
                      }
                    >
                      {question.options.map((option, optionIndex) => (
                        <FormControlLabel
                          key={optionIndex}
                          value={option}
                          control={<Radio />}
                          label={option}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <Box className="muiStepperButtons" sx={{ mb: 2, mt: 2 }}>
                    {activeIndex > 0 && activeIndex < testData.length - 1 && (
                      <Button
                        variant="outlined"
                        onClick={handleBack}
                        className="muiStepperSecondaryBtn"
                      >
                        {t('buttons.back')}
                      </Button>
                    )}
                    {activeIndex < testData.length - 1 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className="muiStepperPrimaryBtn"
                      >
                        {t('buttons.next')}
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        onClick={handleBack}
                        className="muiStepperSecondaryBtn"
                      >
                        {t('buttons.back')}
                      </Button>
                    )}
                  </Box>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
      {showIncompleteDialogue && (
        <PopUp
          type="Content"
          visible={showIncompleteDialogue}
          content={t('popUps.incompletePopup.content')}
          onHide={() => setShowIncompleteDialogue(false)}
          ActionLabel={t('popUps.incompletePopup.button')}
          handlePrimaryAction={() => setShowIncompleteDialogue(false)}
        />
      )}
    </>
  );
});

export default RequiredSubject;
