import { Box, Rating, SvgIcon } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

function CustomStarIcon(props) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 24 24"
      style={{ fontSize: props.fontSize || 24 }}
    >
      <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
    </SvgIcon>
  );
}

export default function HoverRating({ onRate, onHover }) {
  const [value, setValue] = React.useState(0);
  const [hover, setHover] = React.useState(-1);
  const [label, setLabel] = React.useState('');
  const starSize = 50; // Set the desired size for the stars

  const { t } = useTranslation();
  const labels = {
    0: '',
    0.5: t('popUps.ratingPopUp.veryBad'),
    1: t('popUps.ratingPopUp.bad'),
    1.5: t('popUps.ratingPopUp.notGood'),
    2: t('popUps.ratingPopUp.belowAverage'),
    2.5: t('popUps.ratingPopUp.average'),
    3: t('popUps.ratingPopUp.fair'),
    3.5: t('popUps.ratingPopUp.good'),
    4: t('popUps.ratingPopUp.veryGood'),
    4.5: t('popUps.ratingPopUp.excellent'),
    5: t('popUps.ratingPopUp.outstanding'),
  };

  function getLabelText(value) {
    return labels[value] || '';
  }

  React.useEffect(() => {
    if (onRate) {
      onRate(label); // Only send the label
    }
  }, [label, onRate]);

  React.useEffect(() => {
    setLabel(getLabelText(hover !== -1 ? hover : value));
    if (onHover) {
      onHover(value !== -1);
    }
  }, [value, hover, onHover]);

  return (
    <div className="ratingHolder">
      <Box sx={{ width: 200, display: 'flex', alignItems: 'center' }}>
        <Rating
          name="hover-feedback"
          value={value}
          precision={0.5}
          onChange={(event, newValue) => {
            setValue(newValue);
            setLabel(getLabelText(newValue));
          }}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
          emptyIcon={
            <CustomStarIcon
              style={{ opacity: 0.55 }}
              fontSize={`${starSize}px`}
            />
          }
          icon={<CustomStarIcon fontSize={`${starSize}px`} />}
        />
      </Box>
      {value !== null && <Box sx={{ ml: 2 }}>{label}</Box>}
    </div>
  );
}
