import { Button } from 'primereact/button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { createWhatsAppUrl } from '../../utils/utils';

const ContactUs = () => {
  const { t } = useTranslation();

  const url = createWhatsAppUrl({
    name: localStorage.getItem('userName'),
    email: localStorage.getItem('email'),
    studentPhoneNumber: localStorage.getItem('phoneNumber'),
    testDate: new Date().toLocaleDateString(),
    topSelectedMajors:
      JSON.parse(localStorage.getItem('topSelectedMajors')) || [],
    topNonSelectedMajors:
      JSON.parse(localStorage.getItem('topNonSelectedMajors')) || [],
    messageTemplate: 'feedback',
  });

  const handleClickContactUs = () => {
    setTimeout(() => {
      window.open(url, '_blank');
    }, 300);
  };

  return (
    <div className="furtherInfo" id="furtherInfo">
      <h2 className="title">{t(`contactUs.text`)}</h2>
      <Button className="button" onClick={handleClickContactUs}>
        {t('menu.contactUs')}
      </Button>
    </div>
  );
};

export default ContactUs;
