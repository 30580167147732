//#region Imports
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import PopUp from '../../../SharedComponents/PopUps';
import { useAuth } from '../../../../hooks/useAuth';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createWhatsAppUrl } from '../../../../utils/utils';
import { filterEnQuestions } from '../../../../Data/simulationEngQuestions';
import { filterArQuestions } from '../../../../Data/simulationArQuestions';
//#endregion

const IndustryWorkplace = forwardRef((props, ref) => {
  //#region States
  const { i18n, t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [showIncompleteDialogue, setShowIncompleteDialogue] = useState(false);
  const { handleGetUserInfo } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [purchasePopUp, setPurchasePopUp] = useState(false);
  const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';
  const [warningPopUp, setWarningPopUp] = useState(false);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [selectedWorkplaces, setSelectedWorkplaces] = useState([]);
  const [selectedWorkType, setSelectedWorkType] = useState([]);
  const [selectedPreferedCourses, setSelectedPreferedCourses] = useState([]);
  //#endregion

  //#region To change the data between En and Ar on change of language
  const [testData, setTestData] = useState(filterEnQuestions);
  useEffect(() => {
    const currentLanguage =
      i18n.language || localStorage.getItem('i18nextLng') || 'en';
    if (currentLanguage === 'ar') {
      setTestData(filterArQuestions);
    } else {
      setTestData(filterEnQuestions);
    }
  }, [i18n.language]);
  //#endregion

  //#region Handle options change and how many selected options can the user select
  const handleOptionChange = (index, option) => {
    if (index === 0) {
      if (selectedIndustries.includes(option)) {
        setSelectedIndustries(
          selectedIndustries.filter((item) => item !== option)
        );
      } else if (selectedIndustries.length < 2) {
        setSelectedIndustries([...selectedIndustries, option]);
      }
    } else if (index === 1) {
      if (selectedWorkplaces.includes(option)) {
        setSelectedWorkplaces(
          selectedWorkplaces.filter((item) => item !== option)
        );
      } else if (selectedWorkplaces.length < 2) {
        setSelectedWorkplaces([...selectedWorkplaces, option]);
      }
    } else if (index === 2) {
      setSelectedWorkType([option]);
    } else if (index === 3) {
      if (selectedPreferedCourses.includes(option)) {
        setSelectedPreferedCourses(
          selectedPreferedCourses.filter((item) => item !== option)
        );
      } else if (selectedPreferedCourses.length < 2) {
        setSelectedPreferedCourses([...selectedPreferedCourses, option]);
      }
    }
  };
  //#endregion

  //#region To get the user info constantly to update access
  useEffect(() => {
    const email = localStorage.getItem('email');
    if (email) {
      handleGetUserInfo(email).finally(() => {});
    }
  }, [handleGetUserInfo]);
  //#endregion

  //#region Authentication (Navigation) and Access to test
  useEffect(() => {
    const authToken = localStorage.getItem('authToken');

    if (authToken == null || authToken === '') {
      navigate('/login', { state: { from: location.pathname }, replace: true });
    } else {
      const hasAccess = JSON.parse(localStorage.getItem('hasAccess'));
      const accessCareer = JSON.parse(localStorage.getItem('accessCareer'));

      console.log('hasAccess::', hasAccess);
      console.log('accessCareer::', accessCareer);

      if (hasAccess === true && accessCareer === true) {
        setPurchasePopUp(false);
      } else {
        setPurchasePopUp(true);
      }
    }
  }, [navigate, location.pathname]);
  //#endregion

  //#region To show the warning pop up if the user didn't take the quiz and intialize our data
  useEffect(() => {
    window.scrollTo(0, 0);
    const savedData = JSON.parse(localStorage.getItem('quizData'));
    if (savedData) {
      setSelectedIndustries(savedData.selectedIndustries || []);
      setSelectedWorkplaces(savedData.selectedWorkplaces || []);
      setSelectedWorkType(savedData.selectedWorkType || []);
      setSelectedPreferedCourses(savedData.selectedPreferedCourses || []);
    } else {
      setWarningPopUp(true);
    }
  }, []);
  //#endregion

  //#region Handle Action Buttons
  const handleNext = () => {
    if (
      (activeIndex === 0 && selectedIndustries.length === 2) ||
      (activeIndex === 1 && selectedWorkplaces.length === 2) ||
      (activeIndex === 2 && selectedWorkType.length === 1) ||
      (activeIndex === 3 && selectedPreferedCourses.length === 2)
    ) {
      setActiveIndex((prevIndex) => prevIndex + 1);
    } else {
      setShowIncompleteDialogue(true);
    }
  };

  const handleBack = () => {
    setActiveIndex((prevIndex) => prevIndex - 1);
  };

  const handleReturnToTestPage = () => {
    navigate('/test');
  };
  //#endregion

  //#region Check questions are answered and pass it and the results to parent component
  const checkAllQuestionsAnswered = () => {
    return (
      selectedIndustries.length === 2 &&
      selectedWorkplaces.length === 2 &&
      selectedWorkType.length === 1 &&
      selectedPreferedCourses.length === 2
    );
  };

  useImperativeHandle(ref, () => ({
    saveAnswers() {
      const quizData = {
        selectedIndustries,
        selectedWorkplaces,
        selectedWorkType,
        selectedPreferedCourses,
      };
      localStorage.setItem('filterMajor', JSON.stringify(quizData));
      console.log('Answers saved:', quizData);
    },
    openIncompleteTestDialogue() {
      setShowIncompleteDialogue(true);
    },
    checkAllQuestionsAnswered() {
      const allAnswered =
        selectedIndustries.length === 2 &&
        selectedWorkplaces.length === 2 &&
        selectedWorkType.length === 1 &&
        selectedPreferedCourses.length === 2;
      return {
        allAnswered,
        lengths: {
          industries: selectedIndustries.length,
          workplaces: selectedWorkplaces.length,
          workType: selectedWorkType.length,
          courses: selectedPreferedCourses.length,
        },
      };
    },
  }));

  //#endregion

  //#region Whatsapp Access Message
  const url = createWhatsAppUrl({
    name: localStorage.getItem('userName'),
    email: localStorage.getItem('email'),
    studentPhoneNumber: localStorage.getItem('phoneNumber'),
    testDate: new Date().toLocaleDateString(),
    topSelectedMajors:
      JSON.parse(localStorage.getItem('topSelectedMajors')) || [],
    topNonSelectedMajors:
      JSON.parse(localStorage.getItem('topNonSelectedMajors')) || [],
    messageTemplate: 'requestAccess',
  });
  //#endregion

  const handleSendWhatsappRequest = () => {
    setPurchasePopUp(false);
    setTimeout(() => {
      window.open(url, '_blank');
      navigate('/test', { replace: true });
    }, 300);
  };

  return (
    <>
      <Box className="muiStepperContainer" sx={{ padding: 2 }}>
        <Stepper activeStep={activeIndex} orientation="vertical">
          {testData.map((question, index) => (
            <Step key={index} className="muiStep" dir={direction}>
              <StepLabel className="muiStepperLabel">
                <Typography variant="h6">{question.question}</Typography>
              </StepLabel>
              <StepContent className="muiStepperContentHolder">
                <Box className="muiStepperContent careerTest">
                  {index === 2 ? (
                    <RadioGroup
                      value={selectedWorkType[0] || ''}
                      onChange={(e) =>
                        handleOptionChange(index, e.target.value)
                      }
                    >
                      {question.options.map((option, optionIndex) => (
                        <FormControlLabel
                          key={optionIndex}
                          value={option}
                          control={<Radio />}
                          label={option}
                        />
                      ))}
                    </RadioGroup>
                  ) : (
                    question.options.map((option, optionIndex) => (
                      <Box key={optionIndex} className="muiStepperOptions">
                        <Checkbox
                          id={`option${optionIndex}`}
                          onChange={() => handleOptionChange(index, option)}
                          checked={
                            index === 0
                              ? selectedIndustries.includes(option)
                              : index === 1
                              ? selectedWorkplaces.includes(option)
                              : index === 3
                              ? selectedPreferedCourses.includes(option)
                              : false
                          }
                        />
                        <label
                          className="muiStepperOptionLabel"
                          htmlFor={`option${optionIndex}`}
                        >
                          {option}
                        </label>
                      </Box>
                    ))
                  )}
                  {activeIndex < testData.length - 1 ? (
                    <Box className="muiStepperButtons" sx={{ mb: 2, mt: 2 }}>
                      {activeIndex !== 0 && (
                        <Button
                          variant="outlined"
                          onClick={handleBack}
                          className="muiStepperSecondaryBtn"
                          disabled={index === 0}
                        >
                          {t('buttons.back')}
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className="muiStepperPrimaryBtn"
                        disabled={
                          (activeIndex === 0 &&
                            selectedIndustries.length !== 2) ||
                          (activeIndex === 1 &&
                            selectedWorkplaces.length !== 2) ||
                          (activeIndex === 2 &&
                            selectedWorkType.length !== 1) ||
                          (activeIndex === 3 &&
                            selectedPreferedCourses.length !== 2)
                        }
                      >
                        {t('buttons.next')}
                      </Button>
                    </Box>
                  ) : (
                    <Box className="muiStepperButtons" sx={{ mb: 2, mt: 2 }}>
                      <Button
                        variant="outlined"
                        onClick={handleBack}
                        disabled={index === 0}
                        className="muiStepperSecondaryBtn"
                      >
                        {t('buttons.back')}
                      </Button>
                    </Box>
                  )}
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
      {showIncompleteDialogue && (
        <PopUp
          type="Content"
          visible={showIncompleteDialogue}
          content={t('popUps.incompletePopup.content')}
          onHide={() => setShowIncompleteDialogue(false)}
          ActionLabel={t('popUps.incompletePopup.button')}
          handlePrimaryAction={() => setShowIncompleteDialogue(false)}
        />
      )}
      {purchasePopUp && (
        <PopUp
          type={'Purchase'}
          header={t('popUps.purchasePopUp.header')}
          visible={purchasePopUp}
          content={t('popUps.purchasePopUp.content')}
          //handlePrimaryAction={() => handleAccessUpdate()}
          //ActionLabel={'Api Request'}
          ActionLabel={t('popUps.purchasePopUp.text')}
          handlePrimaryAction={() => handleSendWhatsappRequest()}
          //handleSecondaryAction={() => setPurchasePopUp(false)}
          CancelLabel={t('buttons.back')}
          handleSecondaryAction={() => setPurchasePopUp(false)}
        />
      )}
      {warningPopUp && (
        <PopUp
          id="warningPopup"
          header={t('popUps.warningPopUp.title')}
          type="Warning"
          visible={warningPopUp}
          content={t('popUps.warningPopUp.content')}
          handleThirdAction={() => setWarningPopUp(false)}
          WarningLabel={t('popUps.warningPopUp.agree')}
        />
      )}
    </>
  );
});

export default IndustryWorkplace;
