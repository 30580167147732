import React from 'react';
import { useTranslation } from 'react-i18next';

const handleDownload = () => {
  const pdfPath = '/CataloguePDF/Final bachelor majors catalog.pdf';
  const link = document.createElement('a');
  link.href = pdfPath;
  link.download = 'Bachelor Majors Catalogue.pdf';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const DownloadCatalogueButton = () => {
  const { t } = useTranslation();
  return (
    <div className="download-button-container">
      <button onClick={handleDownload} className="button">
        <i className="pi pi-download"></i> {t('catalogue.downloadTitle')}
      </button>
    </div>
  );
};

export default DownloadCatalogueButton;
