import { useTranslation } from 'react-i18next';

const TestDetails = ({ details, showSkillsNote }) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';
  const textAlign = i18n.language === 'ar' ? 'right' : 'left';

  console.log('showSkillsNote:::', showSkillsNote);
  return (
    <div dir={direction}>
      <div className="quiz-definitions">
        {Object.entries(details).map(([key, { title, description }]) => (
          <div key={key} className="quiz-definition">
            <div className="quizTitlePopup" style={{ textAlign }}>
              {title}
            </div>
            <div className="quizDescriptionPopup" style={{ textAlign }}>
              {description}
            </div>
          </div>
        ))}
        {showSkillsNote && (
          <div className="skills-note">
            {t('popUps.showResultPopup.skillNote')}
          </div>
        )}
      </div>
    </div>
  );
};

export default TestDetails;
