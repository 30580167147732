export const simulationArAnalysis = {
  industries: [
    {
      id: 1,
      industryName: 'الرعاية الصحية',
      listmajors: [
        'Applied Mathematics',
        'Biochemistry',
        'Bioinformatics',
        'Biology',
        'Biomedical Engineering',
        'Biomedical Science',
        'Chemical Engineering',
        'Chemistry',
        'Clinical Laboratory Science',
        'Dentistry',
        'Earth and Life Science',
        'Ergotherapy/ Occupational Therapy',
        'Food and Science Technology/ Quality Assurance',
        'Fundamental Health Science',
        'Health Communication/ Promotion',
        'Laboratory Sciences',
        'Medical Imaging Sciences',
        'Medical Technology with Concentration in Radiologic Science',
        'Medicine',
        'Nursing',
        'Nutrition and Dietetics',
        'Optics and Optometry',
        'Pet Medicine',
        'Pharmacy',
        'Physical Therapy',
        'Physics',
        'Psychology',
        'Psychomotricity',
        'Public Health',
        'Social Work',
        'Speech Therapy',
      ],
      majors: [
        {
          name: 'Applied Mathematics',
          workplaces: [
            'مستشفى',
            'حكومة',
            'بنك',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات'],
          description:
            'Applied Mathematics focuses on using mathematical methods and techniques to solve real-world problems across various fields, including engineering, science, economics, and technology.',
        },
        {
          name: 'Biochemistry',
          workplaces: [
            'مستشفى',
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['كيمياء', 'علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['كيمياء', 'علم الحياة'],
          description:
            'Biochemistry studies topics such as enzymology, metabolism, molecular genetics, and biochemical pathways, applying this knowledge to research in medicine, genetics, and biotechnology.',
        },
        {
          name: 'Bioinformatics',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['كمبيوتر', 'علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['كمبيوتر', 'علم الحياة'],
          description:
            'Bioinformatics focuses on using computational tools and techniques to analyze and interpret biological data. It combines biology, computer science, and information technology principles to manage and analyze data.',
        },
        {
          name: 'Biology',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['علم الحياة'],
          description:
            'Biology studies topics about the structure, function, growth, and development of various life forms, preparing them for careers in research, healthcare, environmental science, and biotechnology.',
        },
        {
          name: 'Biomedical Engineering',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كمبيوتر', 'علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كمبيوتر', 'علم الحياة'],
          description:
            'Biomedical Engineering involves the development and improvement of medical devices, diagnostic equipment, and biotechnological solutions.',
        },
        {
          name: 'Biomedical Science',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['علم الحياة'],
          description:
            'Biomedical Science Studies topics about the diagnosis, treatment, and prevention of diseases, preparing them for careers in medical research, clinical laboratories, and healthcare settings.',
        },
        {
          name: 'Chemistry',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['كيمياء'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['كيمياء'],
          description:
            'Chemistry studies topics about chemical reactions, molecular interactions, and laboratory techniques, preparing students for careers in research, pharmaceuticals, environmental science, and chemical manufacturing.',
        },
        {
          name: 'Clinical Laboratory Science',
          workplaces: ['مستشفى', 'حكومة', 'شركة', 'مؤسسات صناعية'],
          requiredSubjects: ['كيمياء', 'علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['كيمياء', 'علم الحياة'],
          description:
            'Clinical Laboratory Science is a major that focuses on analyzing bodily fluids and tissues to diagnose and monitor diseases.',
        },
        {
          name: 'Dentistry',
          workplaces: ['مستشفى', 'حكومة', 'شركات بحث أو شركات استشارية'],
          requiredSubjects: ['رياضيات', 'علم الحياة'],
          workType: ['مساعدة الناس عقليًا أو جسديًا'],
          preferredCourses: ['رياضيات', 'علم الحياة'],
          description:
            'Dentistry is a field focused on the study, diagnosis, prevention, and treatment of diseases and conditions affecting the oral cavity, teeth, gums, and related structures.',
        },
        {
          name: 'Earth and Life Science',
          workplaces: [
            'مستشفى',
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['علم الحياة'],
          description:
            'Earth and Life Science studies topics about Earths structure, climate systems, and the diverse forms of life, including their interactions and adaptations.',
        },
        {
          name: 'Ergotherapy/ Occupational Therapy',
          workplaces: [
            'مستشفى',
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'مدارس/ جامعات',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['اجتماع', 'علم الحياة'],
          workType: ['مساعدة الناس عقليًا أو جسديًا'],
          preferredCourses: ['اجتماع', 'علم الحياة'],
          description:
            'Ergotherapy/ Occupational Therapy focuses on helping individuals develop, recover, or maintain the skills needed for daily living and work activities.',
        },
        {
          name: 'Food and Science Technology/ Quality Assurance',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'محلات تجارية',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['علم الحياة'],
          description:
            'Food and Science Technology/ Quality Assurance is a field that focuses on the science and technology of food production, processing, and safety. Students learn about food preservation, quality control, and regulatory compliance.',
        },
        {
          name: 'Fundamental Health Science',
          workplaces: [
            'مستشفى',
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'محلات تجارية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['علم الحياة'],
          workType: ['مساعدة الناس عقليًا أو جسديًا'],
          preferredCourses: ['علم الحياة'],
          description:
            'Fundamental Health Science studies topics about the mechanisms of health and illness, research methodologies, and foundational concepts that inform clinical practices and health interventions.',
        },
        {
          name: 'Health Communication/ Promotion',
          workplaces: [
            'مستشفى',
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'مدارس/ جامعات',
            'محلات تجارية',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['اجتماع', 'علم الحياة'],
          workType: ['مساعدة الناس عقليًا أو جسديًا'],
          preferredCourses: ['اجتماع', 'علم الحياة'],
          description:
            'Health Communication/ Promotion studies topics about health education, public health campaigns, media communication, and behavior change theories.',
        },
        {
          name: 'Laboratory Sciences',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['كيمياء', 'علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['كيمياء', 'علم الحياة'],
          description:
            'Laboratory Sciences focuses on the study of scientific techniques and procedures used in laboratory settings to analyze and interpret biological, chemical, and physical samples.',
        },
        {
          name: 'Medical Imaging Sciences',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كمبيوتر', 'علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كمبيوتر', 'علم الحياة'],
          description:
            'Medical Imaging Sciences focuses on the study of techniques and technologies used to capture and interpret images of the human body for diagnostic and therapeutic purposes.',
        },
        {
          name: 'Medical Technology with Concentration in Radiologic Science',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كمبيوتر', 'علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كمبيوتر', 'علم الحياة'],
          description:
            'Medical Technology with Concentration in Radiologic Science focuses on the application of medical technology and radiologic techniques to diagnose and treat patients.',
        },
        {
          name: 'Medicine',
          workplaces: [
            'مستشفى',
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كيمياء', 'علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كيمياء', 'علم الحياة'],
          description:
            'Medicine focuses on the study of the diagnosis, treatment, and prevention of diseases and medical conditions.',
        },
        {
          name: 'Midwifery',
          workplaces: [
            'مستشفى',
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['علم الحياة'],
          workType: ['مساعدة الناس عقليًا أو جسديًا'],
          preferredCourses: ['علم الحياة'],
          description:
            'Midwifery focuses on the care of pregnant women, childbirth, and postnatal support for mothers and newborns.',
        },
        {
          name: 'Nursing',
          workplaces: ['مستشفى', 'حكومة'],
          requiredSubjects: ['اجتماع', 'علم الحياة'],
          workType: ['مساعدة الناس عقليًا أو جسديًا'],
          preferredCourses: ['اجتماع', 'علم الحياة'],
          description:
            'Nursing focuses on the study of providing medical care and support to patients across various healthcare settings.',
        },
        {
          name: 'Nutrition and Dietetics',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'منظمات لا تبغي الربح',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['علم الحياة'],
          workType: ['مساعدة الناس عقليًا أو جسديًا'],
          preferredCourses: ['علم الحياة'],
          description:
            'Nutrition and Dietetics study topics about nutritional science, diet planning, food safety, and the role of diet in preventing and managing diseases.',
        },
        {
          name: 'Optics and Optometry',
          workplaces: [
            'مستشفى',
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['فيزياء', 'علم الحياة'],
          workType: ['مساعدة الناس عقليًا أو جسديًا'],
          preferredCourses: ['فيزياء', 'علم الحياة'],
          description:
            'Optics and Optometry study topics about visual systems, optics, eye diseases, vision correction, and optical instruments.',
        },
        {
          name: 'Pet Medicine',
          workplaces: [
            'مستشفى',
            'منظمات لا تبغي الربح',
            'شركة',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['علم الحياة'],
          workType: ['العمل من أجل البيئة'],
          preferredCourses: ['علم الحياة'],
          description:
            'Pet Medicine focuses on studying veterinary care specifically for companion animals such as dogs, cats, and other pets.',
        },
        {
          name: 'Pharmacy',
          workplaces: [
            'مستشفى',
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'محلات تجارية',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['كيمياء', 'علم الحياة'],
          workType: ['مساعدة الناس عقليًا أو جسديًا'],
          preferredCourses: ['كيمياء', 'علم الحياة'],
          description:
            'Pharmacy focuses on the study of medications, their effects, and their role in patient care.',
        },
        {
          name: 'Physical Therapy',
          workplaces: [
            'مستشفى',
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['فيزياء', 'علم الحياة'],
          workType: ['مساعدة الناس عقليًا أو جسديًا'],
          preferredCourses: ['فيزياء', 'علم الحياة'],
          description:
            'Physical Therapy involves the assessment, diagnosis, and treatment of physical impairments and disabilities.',
        },
        {
          name: 'Physics',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'منظمات لا تبغي الربح',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['فيزياء'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['فيزياء'],
          description:
            'Physics focuses on studying the fundamental principles governing matter, energy, and the interactions between them.',
        },
        {
          name: 'Psychology',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'منظمات لا تبغي الربح',
            'مدارس/ جامعات',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['اجتماع', 'علم الحياة'],
          workType: ['مساعدة الناس عقليًا أو جسديًا'],
          preferredCourses: ['اجتماع', 'علم الحياة'],
          description:
            'Psychology studies topics about various psychological theories, research methods, and practical applications related to cognition, emotion, development, and social interactions.',
        },
        {
          name: 'Psychomotricity',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'منظمات لا تبغي الربح',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['اجتماع', 'علم الحياة'],
          workType: ['مساعدة الناس عقليًا أو جسديًا'],
          preferredCourses: ['اجتماع', 'علم الحياة'],
          description:
            'Psychomotricity studies topics about the coordination of mental and physical activities, including fine and gross motor skills, sensory integration, and movement control.',
        },
        {
          name: 'Public Health',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'منظمات لا تبغي الربح',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['اجتماع', 'علم الحياة'],
          workType: ['مساعدة الناس عقليًا أو جسديًا'],
          preferredCourses: ['اجتماع', 'علم الحياة'],
          description:
            'Public Health studies topics about disease prevention, health promotion, epidemiology, environmental health, and health policy.',
        },
        {
          name: 'Social Work',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'منظمات لا تبغي الربح',
            'مدارس/ جامعات',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['اجتماع', 'علم الحياة'],
          workType: ['مساعدة الناس عقليًا أو جسديًا'],
          preferredCourses: ['اجتماع', 'علم الحياة'],
          description:
            'Social Work focuses on the study of supporting individuals and communities to improve their well-being and address social issues.',
        },
        {
          name: 'Speech Therapy',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'منظمات لا تبغي الربح',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['اجتماع', 'علم الحياة'],
          workType: ['مساعدة الناس عقليًا أو جسديًا'],
          preferredCourses: ['اجتماع', 'علم الحياة'],
          description:
            'Speech Therapy focuses on the assessment, diagnosis, and treatment of speech, language, and communication disorders.',
        },
      ],
    },
    {
      id: 2,
      industryName: 'البناء',
      listmajors: [
        'Applied Mathematics',
        'Architecture',
        'Arts and Interior Planning',
        'Civil Engineering',
        'Civil Environmental Engineering',
        'Geology',
        'Landscape Engineering',
        'Surveying Engineering',
      ],
      majors: [
        {
          name: 'Applied Mathematics',
          workplaces: [
            'مستشفى',
            'حكومة',
            'بنك',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات'],
          description:
            'Applied Mathematics focuses on using mathematical methods and techniques to solve real-world problems across various fields, including engineering, science, economics, and technology.',
        },
        {
          name: 'Architecture',
          workplaces: [
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'فن'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['رياضيات', 'فن'],
          description:
            'Architecture studies topics about architectural design principles, building materials, structural engineering, and environmental considerations.',
        },
        {
          name: 'Arts and Interior Planning',
          workplaces: [
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['رياضيات', 'فن'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['رياضيات', 'فن'],
          description:
            'Arts and Interior Planning focuses on space planning, color theory, furniture design, and architectural elements, as well as client needs and trends in interior design.',
        },
        {
          name: 'Civil Engineering',
          workplaces: [
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          description:
            'Civil Engineering prepares students for careers in construction, urban planning, transportation, and environmental management, with roles such as civil engineer, project manager, and structural designer.',
        },
        {
          name: 'Civil Environmental Engineering',
          workplaces: [
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كمبيوتر', 'علم الحياة'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كمبيوتر', 'علم الحياة'],
          description:
            'Civil Environmental Engineering combines civil engineering principles with environmental science and focuses on water resources management, waste treatment, pollution control, and sustainable construction practices.',
        },
        {
          name: 'Geology',
          workplaces: ['حكومة', 'شركة', 'شركات بحث أو شركات استشارية'],
          requiredSubjects: ['فيزياء', 'جغرافيا', 'علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['فيزياء', 'جغرافيا', 'علم الحياة'],
          description:
            'Geology involves the study of the Earth, which can be beneficial for understanding archaeological sites geological contexts and using techniques like stratigraphy.',
        },
        {
          name: 'Landscape Engineering',
          workplaces: ['شركة', 'مؤسسات صناعية', 'شركات بحث أو شركات استشارية'],
          requiredSubjects: ['رياضيات', 'كمبيوتر', 'فن'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['رياضيات', 'كمبيوتر', 'فن'],
          description:
            'Landscape Engineering focuses on the study of designing, planning, and managing outdoor spaces and environments with an emphasis on both aesthetic and functional aspects.',
        },
        {
          name: 'Surveying Engineering',
          workplaces: ['حكومة', 'شركة', 'شركات بحث أو شركات استشارية'],
          requiredSubjects: ['رياضيات', 'كمبيوتر', 'علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'كمبيوتر', 'علم الحياة'],
          description:
            'Surveying Engineering focuses on the measurement, analysis, and management of land and property boundaries.',
        },
      ],
    },
    {
      id: 3,
      industryName: 'الزراعة',
      listmajors: [
        'Agri-Business',
        'Agricultural Economics',
        'Agricultural Engineering',
        'Animal Science and Technology',
        'Applied Mathematics',
        'Biochemistry',
        'Biofood Engineering',
        'Bioinformatics',
        'Biology',
        'Chemical Engineering',
        'Chemistry',
        'Civil Environmental Engineering',
        'Earth and Life Science',
        'Engineering Sciences-Food Engineering',
        'Environmental / Forestry Engineering',
        'Environmental Health/ Sciences',
        'Fundamental Health Science',
        'Geology',
        'Geoscience and Natural Resources',
        'Health Communication/ Promotion',
        'Plant Production/ Protection',
        'Poultry and Cattle Medicine',
        'Quality Control of Foodstuffs of Animal Origin',
        'Surveying Engineering',
      ],
      majors: [
        {
          name: 'Agri-Business',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'محلات تجارية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'كيمياء', 'علم الحياة'],
          workType: ['العمل من أجل البيئة'],
          preferredCourses: ['رياضيات', 'كيمياء', 'علم الحياة'],
          description:
            'Agri-Business focuses on the business aspects of agriculture, including farm management, agricultural finance, marketing, and supply chain logistics.',
        },
        {
          name: 'Agricultural Economics',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'كيمياء', 'علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'كيمياء', 'علم الحياة'],
          description:
            'Agricultural Economics focuses on analyzing agricultural production, market trends, and policy impacts, with an emphasis on optimizing resource use, improving farm profitability, and addressing issues related to food supply and rural development.',
        },
        {
          name: 'Agricultural Engineering',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'كمبيوتر', 'كيمياء', 'علم الحياة'],
          workType: ['العمل من أجل البيئة'],
          preferredCourses: ['رياضيات', 'كمبيوتر', 'كيمياء', 'علم الحياة'],
          description:
            'Agricultural Engineering focuses on designing and optimizing machinery, irrigation systems, and infrastructure to enhance productivity, efficiency, and sustainability in agriculture.',
        },
        {
          name: 'Animal Science and Technology',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'كمبيوتر', 'كيمياء', 'علم الحياة'],
          workType: ['العمل من أجل البيئة'],
          preferredCourses: ['رياضيات', 'كمبيوتر', 'كيمياء', 'علم الحياة'],
          description:
            'Animal Science and Technology focuses on the study of animal biology, management, and technology to improve the health, productivity, and welfare of domestic animals.',
        },
        {
          name: 'Applied Mathematics',
          workplaces: [
            'مستشفى',
            'حكومة',
            'بنك',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات'],
          description:
            'Applied Mathematics focuses on using mathematical methods and techniques to solve real-world problems across various fields, including engineering, science, economics, and technology.',
        },
        {
          name: 'Biochemistry',
          workplaces: [
            'مستشفى',
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['كيمياء', 'علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['كيمياء', 'علم الحياة'],
          description:
            'Biochemistry studies topics such as enzymology, metabolism, molecular genetics, and biochemical pathways, applying this knowledge to research in medicine, genetics, and biotechnology.',
        },
        {
          name: 'Biofood Engineering',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['كمبيوتر', 'كيمياء', 'علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['كمبيوتر', 'كيمياء', 'علم الحياة'],
          description:
            'Biofood Engineering studies topics about food technology, bioprocessing, and the design of food production systems, preparing them for careers in the food industry, quality assurance, and food innovation.',
        },
        {
          name: 'Bioinformatics',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['كمبيوتر', 'علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['كمبيوتر', 'علم الحياة'],
          description:
            'Bioinformatics focuses on using computational tools and techniques to analyze and interpret biological data. It combines biology, computer science, and information technology principles to manage and analyze data.',
        },
        {
          name: 'Biology',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['علم الحياة'],
          description:
            'Biology studies topics about the structure, function, growth, and development of various life forms, preparing them for careers in research, healthcare, environmental science, and biotechnology.',
        },
        {
          name: 'Chemical Engineering',
          workplaces: [
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['كمبيوتر', 'كيمياء'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['كمبيوتر', 'كيمياء'],
          description:
            'Chemical Engineering focuses on the design, optimization, and operation of processes that transform raw materials into valuable products.',
        },
        {
          name: 'Chemistry',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['كيمياء'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['كيمياء'],
          description:
            'Chemistry studies topics about chemical reactions, molecular interactions, and laboratory techniques, preparing students for careers in research, pharmaceuticals, environmental science, and chemical manufacturing.',
        },
        {
          name: 'Civil Environmental Engineering',
          workplaces: [
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كمبيوتر', 'علم الحياة'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كمبيوتر', 'علم الحياة'],
          description:
            'Civil Environmental Engineering combines civil engineering principles with environmental science and focuses on water resources management, waste treatment, pollution control, and sustainable construction practices.',
        },
        {
          name: 'Earth and Life Science',
          workplaces: [
            'مستشفى',
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['علم الحياة'],
          description:
            'Earth and Life Science studies topics about Earths structure, climate systems, and the diverse forms of life, including their interactions and adaptations.',
        },
        {
          name: 'Engineering Sciences-Food Engineering',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['كيمياء', 'كمبيوتر', 'علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['كيمياء', 'كمبيوتر', 'علم الحياة'],
          description:
            'Engineering Sciences-Food Engineering studies topics about food processing technologies, biochemical processes, and the engineering aspects of food production, preparing them for careers in the food industry, quality assurance, and food technology.',
        },
        {
          name: 'Environmental / Forestry Engineering',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'كمبيوتر', 'علم الحياة'],
          workType: ['العمل من أجل البيئة'],
          preferredCourses: ['رياضيات', 'كمبيوتر', 'علم الحياة'],
          description:
            'Environmental / Forestry Engineering studies topics about ecosystem management, environmental impact assessment, and the engineering aspects of land and water management.',
        },
        {
          name: 'Environmental Health/ Sciences',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'علم الحياة'],
          workType: ['العمل من أجل البيئة'],
          preferredCourses: ['رياضيات', 'علم الحياة'],
          description:
            'Environmental Health/ Sciences studies topics about environmental toxins, epidemiology, risk assessment, and environmental regulations.',
        },
        {
          name: 'Fundamental Health Science',
          workplaces: [
            'مستشفى',
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'محلات تجارية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['علم الحياة'],
          workType: ['مساعدة الناس عقليًا أو جسديًا'],
          preferredCourses: ['علم الحياة'],
          description:
            'Fundamental Health Science studies topics about the mechanisms of health and illness, research methodologies, and foundational concepts that inform clinical practices and health interventions.',
        },
        {
          name: 'Geology',
          workplaces: ['حكومة', 'شركة', 'شركات بحث أو شركات استشارية'],
          requiredSubjects: ['فيزياء', 'جغرافيا', 'علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['فيزياء', 'جغرافيا', 'علم الحياة'],
          description:
            'Geology involves the study of the Earth, which can be beneficial for understanding archaeological sites geological contexts and using techniques like stratigraphy.',
        },
        {
          name: 'Geoscience and Natural Resources',
          workplaces: ['حكومة', 'شركة', 'شركات بحث أو شركات استشارية'],
          requiredSubjects: ['فيزياء', 'جغرافيا', 'علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['فيزياء', 'جغرافيا', 'علم الحياة'],
          description:
            'Geoscience and Natural Resources is a major that focuses on studying the Earth’s physical structure, processes, and the management of natural resources.',
        },
        {
          name: 'Health Communication/ Promotion',
          workplaces: [
            'مستشفى',
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'مدارس/ جامعات',
            'محلات تجارية',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['اجتماع', 'علم الحياة'],
          workType: ['مساعدة الناس عقليًا أو جسديًا'],
          preferredCourses: ['اجتماع', 'علم الحياة'],
          description:
            'Health Communication/ Promotion studies topics about health education, public health campaigns, media communication, and behavior change theories.',
        },
        {
          name: 'Plant Production/ Protection',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['فيزياء', 'علم الحياة'],
          workType: ['العمل من أجل البيئة'],
          preferredCourses: ['فيزياء', 'علم الحياة'],
          description:
            'Plant Production/ Protection focuses on the study of cultivating crops and managing plant health to maximize yield and minimize losses.',
        },
        {
          name: 'Poultry and Cattle Medicine',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'علم الحياة'],
          workType: ['العمل من أجل البيئة'],
          preferredCourses: ['رياضيات', 'علم الحياة'],
          description:
            'Poultry and Cattle Medicine focuses on the veterinary care of poultry and cattle, including their health management, disease prevention, and treatment.',
        },
        {
          name: 'Quality Control of Foodstuffs of Animal Origin',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['علم الحياة'],
          workType: ['العمل من أجل البيئة'],
          preferredCourses: ['علم الحياة'],
          description:
            'Quality Control of Foodstuffs of Animal Origin studies topics about food safety regulations, inspection techniques, quality assurance processes, and microbiological testing specific to meat, dairy, and other animal products.',
        },
        {
          name: 'Surveying Engineering',
          workplaces: ['حكومة', 'شركة', 'شركات بحث أو شركات استشارية'],
          requiredSubjects: ['رياضيات', 'كمبيوتر', 'علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'كمبيوتر', 'علم الحياة'],
          description:
            'Surveying Engineering focuses on the measurement, analysis, and management of land and property boundaries.',
        },
      ],
    },
    {
      id: 4,
      industryName: 'الاعلام',
      listmajors: [
        'Advertising and Marketing Communication',
        'Audiovisual Production',
        'Cinema Production',
        'Communication and Visual Arts',
        'Computer Graphics and Interactive Media',
        'Design and Applied Arts',
        'Digital Advertising',
        'Digital Media',
        'Graphic Design',
        'Journalism and Electronic Media',
        'Media and Communication',
        'Music',
        'Photography',
        'Radio/TV and Film',
      ],
      majors: [
        {
          name: 'Advertising and Marketing Communication',
          workplaces: [
            'بنك',
            'شركة',
            'محلات تجارية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['فن'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن'],
          description:
            'Advertising and Marketing Communication studies topics on how to develop compelling messages, create marketing campaigns, and utilize various media channels to reach target audiences effectively.',
        },
        {
          name: 'Audiovisual Production',
          workplaces: ['شركة', 'شركات بحث أو شركات استشارية', 'اعلام'],
          requiredSubjects: ['فن', 'كمبيوتر'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن', 'كمبيوتر'],
          description:
            'Audiovisual Production studies topics about filming techniques, sound design, editing, and post-production processes, as well as storytelling and project management.',
        },
        {
          name: 'Cinema Production',
          workplaces: ['شركة', 'شركات بحث أو شركات استشارية', 'اعلام'],
          requiredSubjects: ['فن', 'كمبيوتر'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن', 'كمبيوتر'],
          description:
            'Cinema Production studies topics about technical skills and creative techniques needed to produce films, from pre-production planning to post-production editing.',
        },
        {
          name: 'Communication and Visual Arts',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'محلات تجارية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['فن', 'كمبيوتر'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن', 'كمبيوتر'],
          description:
            'Communication and Visual Arts focuses on how to Create and analyze visual content for various platforms, develop communication strategies, and understand the impact of visual media on society.',
        },
        {
          name: 'Computer Graphics and Interactive Media',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'محلات تجارية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['فن', 'كمبيوتر'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن', 'كمبيوتر'],
          description:
            'Computer Graphics and Interactive Media focuses on the creation and manipulation of visual content using computer technology, along with the development of interactive digital experiences.',
        },
        {
          name: 'Design and Applied Arts',
          workplaces: [
            'منظمات لا تبغي الربح',
            'شركة',
            'محلات تجارية',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['فن', 'كمبيوتر'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن', 'كمبيوتر'],
          description:
            'Design and Applied Arts studies topics about design theory, color theory, materials, and digital tools, preparing them for careers in various creative industries where design plays a central role.',
        },
        {
          name: 'Digital Advertising',
          workplaces: [
            'بنك',
            'شركة',
            'محلات تجارية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['فن', 'كمبيوتر'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن', 'كمبيوتر'],
          description:
            'Digital Advertising studies topics about digital marketing strategies, content creation, analytics, and consumer behavior, preparing them for careers in online marketing, digital media, and advertising agencies.',
        },
        {
          name: 'Digital Media',
          workplaces: [
            'شركة',
            'محلات تجارية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['فن', 'كمبيوتر'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن', 'كمبيوتر'],
          description:
            'Digital Media studies topics about various forms of digital content, including text, images, audio, and video, and how to leverage digital tools and platforms to engage audiences.',
        },
        {
          name: 'Graphic Design',
          workplaces: [
            'شركة',
            'محلات تجارية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['فن', 'كمبيوتر'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن', 'كمبيوتر'],
          description:
            'Graphic Design is a major that focuses on creating visual content to communicate messages and ideas. Students learn to use design software and develop creative problem-solving abilities to produce aesthetically pleasing and effective visual communications.',
        },
        {
          name: 'Journalism and Electronic Media',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['اجتماع', 'اللغات'],
          workType: ['خدمة الناس أو التفاعل معهم'],
          preferredCourses: ['اجتماع', 'اللغات'],
          description:
            'Journalism and Electronic Media studies topics about journalism ethics, multimedia storytelling, digital media platforms, broadcasting, and content creation.',
        },
        {
          name: 'Media and Communication',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: [''],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: [''],
          description:
            'Media and Communication focuses on the study of how information and content are created, distributed, and consumed across various media platforms.',
        },
        {
          name: 'Music',
          workplaces: ['منظمات لا تبغي الربح', 'شركة', 'اعلام'],
          requiredSubjects: ['فن'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن'],
          description:
            'Music studies topics about various musical genres, techniques, and instruments, as well as music theory, ear training, and music history.',
        },
        {
          name: 'Photography',
          workplaces: [
            'منظمات لا تبغي الربح',
            'شركة',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['فن'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن'],
          description:
            'Photography focuses on the study of visual storytelling and the technical aspects of capturing and editing images.',
        },
        {
          name: 'Radio/TV and Film',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['فن', 'كمبيوتر'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن', 'كمبيوتر'],
          description:
            'Radio/TV and Film focuses on the production, direction, and management of audio-visual content across various media platforms.',
        },
      ],
    },
    {
      id: 5,
      industryName: 'التجارة',
      listmajors: [
        'Advertising and Marketing Communication',
        'Agri-Business',
        'Fashion and Pattern Drafting',
        'Product Design',
      ],
      majors: [
        {
          name: 'Advertising and Marketing Communication',
          workplaces: [
            'بنك',
            'شركة',
            'محلات تجارية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['فن'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن'],
          description:
            'Advertising and Marketing Communication studies topics on how to develop compelling messages, create marketing campaigns, and utilize various media channels to reach target audiences effectively.',
        },
        {
          name: 'Agri-Business',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'محلات تجارية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'كيمياء', 'علم الحياة'],
          workType: ['العمل من أجل البيئة'],
          preferredCourses: ['رياضيات', 'كيمياء', 'علم الحياة'],
          description:
            'Agri-Business focuses on the business aspects of agriculture, including farm management, agricultural finance, marketing, and supply chain logistics.',
        },
        {
          name: 'Fashion and Pattern Drafting',
          workplaces: [
            'منظمات لا تبغي الربح',
            'شركة',
            'محلات تجارية',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['فن'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن'],
          description:
            'Fashion and Pattern Drafting focuses on designing and creating clothing and accessories. Students study how to create and modify patterns, select fabrics, and bring fashion concepts to life.',
        },
        {
          name: 'Product Design',
          workplaces: [
            'شركة',
            'محلات تجارية',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['فن'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن'],
          description:
            'Product Design studies topics about design principles, materials, manufacturing processes, user experience, and prototyping.',
        },
      ],
    },
    {
      id: 6,
      industryName: 'الخدمات المالية',
      listmajors: [
        'Accounting',
        'Accounting and Auditing',
        'Accounting and Finance',
        'Agricultural Economics',
        'Applied Mathematics',
        'Banking and Finance',
        'Economics',
        'Statistics',
      ],
      majors: [
        {
          name: 'Accounting',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'منظمات لا تبغي الربح',
            'بنك',
            'شركة',
            'مدارس/ جامعات',
            'محلات تجارية',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['رياضيات'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات'],
          description:
            'Accounting is the systematic process of recording, summarizing, analyzing, and reporting financial transactions of a business or organization.',
        },
        {
          name: 'Accounting and Auditing',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'منظمات لا تبغي الربح',
            'بنك',
            'شركة',
            'مدارس/ جامعات',
            'محلات تجارية',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['رياضيات'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات'],
          description:
            'Accounting and Auditing is the combination of accounting that involves recording and reporting financial transactions and auditing that involves the independent examination and verification of financial statements and records.',
        },
        {
          name: 'Accounting and Finance',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'منظمات لا تبغي الربح',
            'بنك',
            'شركة',
            'مدارس/ جامعات',
            'محلات تجارية',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['رياضيات'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات'],
          description:
            'Accounting and Finance is the combination of accounting that deals with the recording and reporting of financial transactions and finance that involves the management of assets, liabilities, and the planning of future financial strategies.',
        },
        {
          name: 'Agricultural Economics',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'كيمياء', 'علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'كيمياء', 'علم الحياة'],
          description:
            'Agricultural Economics focuses on analyzing agricultural production, market trends, and policy impacts, with an emphasis on optimizing resource use, improving farm profitability, and addressing issues related to food supply and rural development.',
        },
        {
          name: 'Applied Mathematics',
          workplaces: [
            'مستشفى',
            'حكومة',
            'بنك',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات'],
          description:
            'Applied Mathematics focuses on using mathematical methods and techniques to solve real-world problems across various fields, including engineering, science, economics, and technology.',
        },
        {
          name: 'Banking and Finance',
          workplaces: ['بنك', 'شركة', 'شركات بحث أو شركات استشارية'],
          requiredSubjects: ['رياضيات'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات'],
          description:
            'Banking and Finance studies topics about financial markets, corporate finance, and regulatory frameworks, preparing them for careers in banking, investment, financial planning, and related fields.',
        },
        {
          name: 'Economics',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'منظمات لا تبغي الربح',
            'بنك',
            'شركة',
            'محلات تجارية',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['رياضيات'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات'],
          description:
            'Economics studies topics about microeconomics, macroeconomics, econometrics, and economic forecasting.',
        },
        {
          name: 'Statistics',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'بنك',
            'شركة',
            'محلات تجارية',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['رياضيات'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات'],
          description:
            'Statistics focuses on the study of data collection, analysis, interpretation, and presentation.',
        },
      ],
    },
    {
      id: 7,
      industryName: 'الاتصالات',
      listmajors: [
        'Applied Mathematics',
        'Communication and Visual Arts',
        'Communication Engineering',
        'Computer and Communication Engineering',
        'Computer Engineering',
        'Computer Graphics and Interactive Media',
        'Computer Network and Data Communications',
        'Computer Science',
        'Telecommunication and Networks Engineering',
      ],
      majors: [
        {
          name: 'Applied Mathematics',
          workplaces: [
            'مستشفى',
            'حكومة',
            'بنك',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات'],
          description:
            'Applied Mathematics focuses on using mathematical methods and techniques to solve real-world problems across various fields, including engineering, science, economics, and technology.',
        },
        {
          name: 'Communication and Visual Arts',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'محلات تجارية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['فن', 'كمبيوتر'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن', 'كمبيوتر'],
          description:
            'Communication and Visual Arts focuses on how to create and analyze visual content for various platforms, develop communication strategies, and understand the impact of visual media on society.',
        },
        {
          name: 'Communication Engineering',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'منظمات لا تبغي الربح',
            'بنك',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          description:
            'Communication Engineering studies the principles of transmitting data and voice over various media, developing technologies for mobile communication, internet infrastructure, and broadcasting.',
        },
        {
          name: 'Computer and Communication Engineering',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'منظمات لا تبغي الربح',
            'بنك',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          description:
            'Computer and Communication Engineering covers areas like network design, telecommunications, signal processing, and data transmission alongside core computer engineering topics.',
        },
        {
          name: 'Computer Engineering',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'منظمات لا تبغي الربح',
            'بنك',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['رياضيات', 'كمبيوتر'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'كمبيوتر'],
          description:
            'Computer Engineering focuses on designing, developing, and maintaining computer systems and hardware.',
        },
        {
          name: 'Computer Graphics and Interactive Media',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'محلات تجارية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['فن', 'كمبيوتر'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن', 'كمبيوتر'],
          description:
            'Computer Graphics and Interactive Media focuses on the creation and manipulation of visual content using computer technology, along with the development of interactive digital experiences.',
        },
        {
          name: 'Computer Network and Data Communications',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'منظمات لا تبغي الربح',
            'بنك',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          description:
            'Computer Network and Data Communications is a field that focuses on designing, implementing, and managing interconnected computer systems that exchange data.',
        },
        {
          name: 'Computer Science',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'منظمات لا تبغي الربح',
            'بنك',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['رياضيات', 'كمبيوتر'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'كمبيوتر'],
          description:
            'Computer Science Studies topics about programming, data structures, artificial intelligence, databases, and computer networks.',
        },
        {
          name: 'Telecommunication and Networks Engineering',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'منظمات لا تبغي الربح',
            'بنك',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          description:
            'Telecommunication and Networks Engineering studies topics about telecommunications technologies, network architecture, data transmission, and wireless communications.',
        },
      ],
    },
    {
      id: 8,
      industryName: 'التعليم',
      listmajors: [
        'Applied Mathematics',
        'Biology',
        'Chemistry',
        'Comparative Linguistics Engineering',
        'Early Childhood Education',
        'Education',
        'Geography',
        'History',
        'Music',
        'Philosophy',
        'Physical Education',
        'Physics',
        'Religions Sciences',
        'Social Sciences',
        'Sociology-Anthropology',
        'Translation and Interpretation',
      ],
      majors: [
        {
          name: 'Applied Mathematics',
          workplaces: [
            'مستشفى',
            'حكومة',
            'بنك',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات'],
          description:
            'Applied Mathematics focuses on using mathematical methods and techniques to solve real-world problems across various fields, including engineering, science, economics, and technology.',
        },
        {
          name: 'Biology',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['علم الحياة'],
          description:
            'Biology studies topics about the structure, function, growth, and development of various life forms, preparing them for careers in research, healthcare, environmental science, and biotechnology.',
        },
        {
          name: 'Chemistry',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['كيمياء'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['كيمياء'],
          description:
            'Chemistry studies topics about chemical reactions, molecular interactions, and laboratory techniques, preparing students for careers in research, pharmaceuticals, environmental science, and chemical manufacturing.',
        },
        {
          name: 'Comparative Linguistics Engineering',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'مدارس/ جامعات',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['اللغات'],
          workType: ['نقل معرفتك إلى الآخرين'],
          preferredCourses: ['اللغات'],
          description:
            'Comparative Linguistics Engineering focuses on the study and comparison of languages to understand their structures, histories, and relationships.',
        },
        {
          name: 'Early Childhood Education',
          workplaces: [
            'منظمات لا تبغي الربح',
            'مدارس/ جامعات',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['اجتماع'],
          workType: ['خدمة الناس أو التفاعل معهم'],
          preferredCourses: ['اجتماع'],
          description:
            'Early Childhood Education trains to design and implement curricula, assess developmental milestones, and foster positive early learning experiences.',
        },
        {
          name: 'Education',
          workplaces: ['حكومة', 'منظمات لا تبغي الربح', 'مدارس/ جامعات'],
          requiredSubjects: [''],
          workType: ['نقل معرفتك إلى الآخرين'],
          preferredCourses: [''],
          description:
            'Education focuses on the study of teaching methods, learning processes, and educational systems.',
        },
        {
          name: 'Geography',
          workplaces: [
            'حكومة',
            'شركة',
            'مدارس/ جامعات',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['جغرافيا'],
          workType: ['نقل معرفتك إلى الآخرين'],
          preferredCourses: ['جغرافيا'],
          description:
            'Geography focuses on studying the Earths landscapes, environments, and the relationships between people and their environments.',
        },
        {
          name: 'History',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'مدارس/ جامعات',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['تاريخ'],
          workType: ['نقل معرفتك إلى الآخرين'],
          preferredCourses: ['تاريخ'],
          description:
            'History provides a broader context of the human past, focusing on different periods, regions, and cultures. Some programs offer a concentration in archaeological studies.',
        },
        {
          name: 'Music',
          workplaces: ['منظمات لا تبغي الربح', 'شركة', 'اعلام'],
          requiredSubjects: ['فن'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن'],
          description:
            'Music studies topics about various musical genres, techniques, and instruments, as well as music theory, ear training, and music history.',
        },
        {
          name: 'Philosophy',
          workplaces: ['شركة', 'مدارس/ جامعات', 'شركات بحث أو شركات استشارية'],
          requiredSubjects: ['فلسفة'],
          workType: ['نقل معرفتك إلى الآخرين'],
          preferredCourses: ['فلسفة'],
          description:
            'Philosophy focuses on the study of fundamental questions about existence, knowledge, ethics, and reason.',
        },
        {
          name: 'Physical Education',
          workplaces: ['شركة', 'مدارس/ جامعات'],
          requiredSubjects: ['فيزياء', 'علم الحياة'],
          workType: ['مساعدة الناس عقليًا أو جسديًا'],
          preferredCourses: ['فيزياء', 'علم الحياة'],
          description:
            'Physical Education focuses on teaching and promoting physical activity and health through structured programs in schools or recreational settings.',
        },
        {
          name: 'Physics',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'منظمات لا تبغي الربح',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['فيزياء'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['فيزياء'],
          description:
            'Physics focuses on studying the fundamental principles governing matter, energy, and the interactions between them.',
        },
        {
          name: 'Religions Sciences',
          workplaces: ['شركة', 'مدارس/ جامعات', 'شركات بحث أو شركات استشارية'],
          requiredSubjects: ['تاريخ'],
          workType: ['نقل معرفتك إلى الآخرين'],
          preferredCourses: ['تاريخ'],
          description:
            'Religions Sciences focuses on the study of religious beliefs, practices, and institutions across various cultures and historical contexts.',
        },
        {
          name: 'Social Sciences',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'مدارس/ جامعات',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['اجتماع'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['اجتماع'],
          description:
            'Social Sciences focuses on the study of human society, behavior, and social relationships.',
        },
        {
          name: 'Sociology-Anthropology',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'مدارس/ جامعات',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['اجتماع'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['اجتماع'],
          description:
            'Sociology-Anthropology is the study of humans, and their ancestors, which includes cultural, social, biological, and linguistic aspects.',
        },
        {
          name: 'Translation and Interpretation',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'مدارس/ جامعات',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['اللغات'],
          workType: ['نقل معرفتك إلى الآخرين'],
          preferredCourses: ['اللغات'],
          description:
            'Translation and Interpretation studies topics about language proficiency, translation techniques, interpretation skills, and cultural nuances.',
        },
      ],
    },
    {
      id: 9,
      industryName: 'الفضاء',
      listmajors: [
        'Aircraft Maintenance',
        'Applied Mathematics',
        'Astronomy',
        'Mechanical Engineering',
        'Mechatronics Engineering',
        'Physics',
        ,
        'Telecommunication and Networks Engineering',
      ],
      majors: [
        {
          name: 'Aircraft Maintenance',
          workplaces: ['حكومة', 'مطار', 'مؤسسات صناعية'],
          requiredSubjects: ['رياضيات', 'فيزياء'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'فيزياء'],
          description:
            'Aircraft Maintenance focuses on the technical skills and knowledge required to inspect, repair, and maintain aircraft to ensure their safety and functionality.',
        },

        {
          name: 'Applied Mathematics',
          workplaces: [
            'مستشفى',
            'حكومة',
            'بنك',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات'],
          description:
            'Applied Mathematics focuses on using mathematical methods and techniques to solve real-world problems across various fields, including engineering, science, economics, and technology.',
        },
        {
          name: 'Astronomy',
          workplaces: ['حكومة', 'شركة', 'شركات بحث أو شركات استشارية'],
          requiredSubjects: ['رياضيات', 'فيزياء', 'علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'فيزياء', 'علم الحياة'],
          description:
            'Astronomy focuses on the study of celestial objects and phenomena, including stars, planets, galaxies, and the universe as a whole.',
        },
        {
          name: 'Mechanical Engineering',
          workplaces: [
            'مستشفى',
            'حكومة',
            'مطار',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          description:
            'Mechanical Engineering focuses on the study of the design, analysis, and manufacturing of mechanical systems and devices.',
        },
        {
          name: 'Mechatronics Engineering',
          workplaces: [
            'مستشفى',
            'حكومة',
            'مطار',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          description:
            'Mechatronics Engineering focuses on integrating mechanical, electronic, and computer systems to design and create advanced automated and robotic systems.',
        },
        {
          name: 'Physics',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'منظمات لا تبغي الربح',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['فيزياء'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['فيزياء'],
          description:
            'Physics focuses on studying the fundamental principles governing matter, energy, and the interactions between them.',
        },
        {
          name: 'Telecommunication and Networks Engineering',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'منظمات لا تبغي الربح',
            'بنك',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          description:
            'Telecommunication and Networks Engineering studies topics about telecommunications technologies, network architecture, data transmission, and wireless communications.',
        },
      ],
    },
    {
      id: 10,
      industryName: 'الصناعة',
      listmajors: [
        'Agricultural Engineering',
        'Aircraft Maintenance',
        'Applied Mathematics',
        'Architecture',
        'Arts and Interior Planning',
        'Biochemistry',
        'Biofood Engineering',
        'Bioinformatics',
        'Biology',
        'Biomedical Engineering',
        'Chemical Engineering',
        'Chemistry',
        'Civil Engineering',
        'Civil Environmental Engineering',
        'Clinical Laboratory Science',
        'Electrical and Electronics Engineering',
        'Electronic Engineering',
        'Electrical Engineering',
        'Industrial Engineering',
        'Landscape Engineering',
        'Mechanical Engineering',
        'Mechatronics Engineering',
        'Medical Imaging Sciences',
        'Medical Technology with Concentration in Radiologic Science',
        'Petrochemical Engineering',
        'Pharmacy',
        'Physics',
        'Plant Production/ Protection',
        'Statistics',
        'Telecommunication and Networks Engineering',
      ],
      majors: [
        {
          name: 'Agricultural Engineering',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'كمبيوتر', 'كيمياء', 'علم الحياة'],
          workType: ['العمل من أجل البيئة'],
          preferredCourses: ['رياضيات', 'كمبيوتر', 'كيمياء', 'علم الحياة'],
          description:
            'Agricultural Engineering focuses on designing and optimizing machinery, irrigation systems, and infrastructure to enhance productivity, efficiency, and sustainability in agriculture.',
        },
        {
          name: 'Aircraft Maintenance',
          workplaces: ['مطار', 'حكومة', 'مؤسسات صناعية'],
          requiredSubjects: ['رياضيات', 'فيزياء'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'فيزياء'],
          description:
            'Aircraft Maintenance focuses on the technical skills and knowledge required to inspect, repair, and maintain aircraft to ensure their safety and functionality.',
        },
        {
          name: 'Applied Mathematics',
          workplaces: [
            'مستشفى',
            'حكومة',
            'بنك',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات'],
          description:
            'Applied Mathematics focuses on using mathematical methods and techniques to solve real-world problems across various fields, including engineering, science, economics, and technology.',
        },
        {
          name: 'Architecture',
          workplaces: [
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'فن'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['رياضيات', 'فن'],
          description:
            'Architecture studies topics about architectural design principles, building materials, structural engineering, and environmental considerations.',
        },
        {
          name: 'Arts and Interior Planning',
          workplaces: [
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['رياضيات', 'فن'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['رياضيات', 'فن'],
          description:
            'Arts and Interior Planning focuses on space planning, color theory, furniture design, and architectural elements, as well as client needs and trends in interior design.',
        },
        {
          name: 'Biochemistry',
          workplaces: [
            'مستشفى',
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['كيمياء', 'علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['كيمياء', 'علم الحياة'],
          description:
            'Biochemistry studies topics such as enzymology, metabolism, molecular genetics, and biochemical pathways, applying this knowledge to research in medicine, genetics, and biotechnology.',
        },
        {
          name: 'Biofood Engineering',
          workplaces: [
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['كيمياء', 'علم الحياة', 'كمبيوتر'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['كيمياء', 'علم الحياة', 'كمبيوتر'],
          description:
            'Biofood Engineering studies topics about food technology, bioprocessing, and the design of food production systems, preparing them for careers in the food industry, quality assurance, and food innovation.',
        },
        {
          name: 'Bioinformatics',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['كمبيوتر', 'علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['كمبيوتر', 'علم الحياة'],
          description:
            'Bioinformatics focuses on using computational tools and techniques to analyze and interpret biological data. It combines biology, computer science, and information technology principles to manage and analyze data.',
        },
        {
          name: 'Biology',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['علم الحياة'],
          description:
            'Biology studies topics about the structure, function, growth, and development of various life forms, preparing them for careers in research, healthcare, environmental science, and biotechnology.',
        },
        {
          name: 'Biomedical Engineering',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كمبيوتر', 'علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كمبيوتر', 'علم الحياة'],
          description:
            'Biomedical Engineering involves the development and improvement of medical devices, diagnostic equipment, and biotechnological solutions.',
        },
        {
          name: 'Chemical Engineering',
          workplaces: [
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['كمبيوتر', 'كيمياء'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['كمبيوتر', 'كيمياء'],
          description:
            'Chemical Engineering focuses on the design, optimization, and operation of processes that transform raw materials into valuable products.',
        },
        {
          name: 'Chemistry',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['كيمياء'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['كيمياء'],
          description:
            'Chemistry studies topics about chemical reactions, molecular interactions, and laboratory techniques, preparing students for careers in research, pharmaceuticals, environmental science, and chemical manufacturing.',
        },
        {
          name: 'Civil Engineering',
          workplaces: [
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          description:
            'Civil Engineering prepares students for careers in construction, urban planning, transportation, and environmental management, with roles such as civil engineer, project manager, and structural designer.',
        },
        {
          name: 'Civil Environmental Engineering',
          workplaces: [
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كمبيوتر', 'علم الحياة'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كمبيوتر', 'علم الحياة'],
          description:
            'Civil Environmental Engineering combines civil engineering principles with environmental science and focuses on water resources management, waste treatment, pollution control, and sustainable construction practices.',
        },
        {
          name: 'Clinical Laboratory Science',
          workplaces: ['مستشفى', 'حكومة', 'شركة', 'مؤسسات صناعية'],
          requiredSubjects: ['كيمياء', 'علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['كيمياء', 'علم الحياة'],
          description:
            'Clinical Laboratory Science is a major that focuses on analyzing bodily fluids and tissues to diagnose and monitor diseases.',
        },
        {
          name: 'Electrical and Electronics Engineering',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['فيزياء', 'كمبيوتر'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['فيزياء', 'كمبيوتر'],
          description:
            'Electrical and Electronics Engineering is a combination of electrical and electronic Engineering which mixes power systems and infrastructure with the design and application of electronic devices and circuits.',
        },
        {
          name: 'Electronic Engineering',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['فيزياء', 'كمبيوتر'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['فيزياء', 'كمبيوتر'],
          description:
            'Electronics Engineering focuses on the design and development of electronic circuits and devices. It deals with smaller-scale systems that use electronic components like transistors, diodes, and integrated circuits.',
        },
        {
          name: 'Electrical Engineering',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['فيزياء', 'كمبيوتر'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['فيزياء', 'كمبيوتر'],
          description:
            'Electrical Engineering studies topics about electrical theory, circuit analysis, signal processing, and the application of electrical engineering principles to solve problems in various industries.',
        },
        {
          name: 'Industrial Engineering',
          workplaces: [
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          description:
            'Industrial Engineering focuses on the study of optimizing complex systems, processes, and organizations to improve efficiency and productivity.',
        },
        {
          name: 'Landscape Engineering',
          workplaces: ['شركة', 'مؤسسات صناعية', 'شركات بحث أو شركات استشارية'],
          requiredSubjects: ['رياضيات', 'كمبيوتر', 'فن'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['رياضيات', 'كمبيوتر', 'فن'],
          description:
            'Landscape Engineering focuses on the study of designing, planning, and managing outdoor spaces and environments with an emphasis on both aesthetic and functional aspects.',
        },
        {
          name: 'Mechanical Engineering',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'كمبيوتر', 'فيزياء'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'كمبيوتر', 'فيزياء'],
          description:
            'Mechanical Engineering focuses on the study of the design, analysis, and manufacturing of mechanical systems and devices.',
        },
        {
          name: 'Mechatronics Engineering',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'كمبيوتر', 'فيزياء'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'كمبيوتر', 'فيزياء'],
          description:
            'Mechatronics Engineering focuses on integrating mechanical, electronic, and computer systems to design and create advanced automated and robotic systems',
        },
        {
          name: 'Medical Imaging Sciences',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كمبيوتر', 'علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كمبيوتر', 'علم الحياة'],
          description:
            'Medical Imaging Sciences focuses on the study of techniques and technologies used to capture and interpret images of the human body for diagnostic and therapeutic purposes.',
        },
        {
          name: 'Medical Technology with Concentration in Radiologic Science',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كمبيوتر', 'علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كمبيوتر', 'علم الحياة'],
          description:
            'Medical Technology with Concentration in Radiologic Science focuses on the application of medical technology and radiologic techniques to diagnose and treat patients.',
        },
        {
          name: 'Petrochemical Engineering',
          workplaces: [
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'كمبيوتر', 'كيمياء'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'كمبيوتر', 'كيمياء'],
          description:
            'Petrochemical Engineering is a specialized branch of chemical engineering that focuses on the production and processing of chemicals derived from petroleum and natural gas.',
        },
        {
          name: 'Petroleum Engineering',
          workplaces: [
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'كمبيوتر', 'كيمياء'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'كمبيوتر', 'كيمياء'],
          description:
            'Petroleum Engineering focuses on the study of the extraction and production of oil and natural gas.',
        },
        {
          name: 'Pharmacy',
          workplaces: [
            'مستشفى',
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'محلات تجارية',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['كيمياء', 'علم الحياة'],
          workType: ['مساعدة الناس عقليًا أو جسديًا'],
          preferredCourses: ['كيمياء', 'علم الحياة'],
          description:
            'Pharmacy focuses on the study of medications, their effects, and their role in patient care.',
        },
        {
          name: 'Physics',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'منظمات لا تبغي الربح',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['فيزياء'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['فيزياء'],
          description:
            'Physics focuses on studying the fundamental principles governing matter, energy, and the interactions between them.',
        },
        {
          name: 'Plant Production/ Protection',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['فيزياء', 'علم الحياة'],
          workType: ['العمل من أجل البيئة'],
          preferredCourses: ['فيزياء', 'علم الحياة'],
          description:
            'Plant Production/ Protection focuses on the study of cultivating crops and managing plant health to maximize yield and minimize losses.',
        },
        {
          name: 'Statistics',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'بنك',
            'شركة',
            'محلات تجارية',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['رياضيات'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات'],
          description:
            'Statistics focuses on the study of data collection, analysis, interpretation, and presentation.',
        },
        {
          name: 'Telecommunication and Networks Engineering',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'منظمات لا تبغي الربح',
            'بنك',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          description:
            'Telecommunication and Networks Engineering studies topics about telecommunications technologies, network architecture, data transmission, and wireless communications.',
        },
      ],
    },
    {
      id: 11,
      industryName: 'المعلوماتية',
      listmajors: [
        'Applied Mathematics',
        'Bioinformatics',
        'Business Information Systems',
        'Communication Engineering',
        'Computer and Communication Engineering',
        'Computer Engineering',
        'Computer Graphics and Interactive Media',
        'Computer Network and Data Communications',
        'Computer Science',
        'Data Science',
        'Mechatronics Engineering',
        'Telecommunication and Networks Engineering',
      ],
      majors: [
        {
          name: 'Applied Mathematics',
          workplaces: [
            'مستشفى',
            'حكومة',
            'بنك',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات'],
          description:
            'Applied Mathematics focuses on using mathematical methods and techniques to solve real-world problems across various fields, including engineering, science, economics, and technology.',
        },
        {
          name: 'Bioinformatics',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['بيولوجيا', 'كمبيوتر'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['بيولوجيا', 'كمبيوتر'],
          description:
            'Bioinformatics focuses on using computational tools and techniques to analyze and interpret biological data. It combines biology, computer science, and information technology principles to manage and analyze data.',
        },
        {
          name: 'Business Information Systems',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'منظمات لا تبغي الربح',
            'بنك',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'محلات تجارية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['كمبيوتر'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['كمبيوتر'],
          description:
            'Business Information Systems focuses on the use of IT to solve business problems, improve processes, and support decision-making.',
        },
        {
          name: 'Communication Engineering',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'منظمات لا تبغي الربح',
            'بنك',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          description:
            'Communication Engineering studies the principles of transmitting data and voice over various media, developing technologies for mobile communication, internet infrastructure, and broadcasting.',
        },
        {
          name: 'Computer and Communication Engineering',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'منظمات لا تبغي الربح',
            'بنك',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          description:
            'Computer and Communication Engineering covers areas like network design, telecommunications, signal processing, and data transmission alongside core computer engineering topics.',
        },
        {
          name: 'Computer Engineering',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'منظمات لا تبغي الربح',
            'بنك',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['رياضيات', 'كمبيوتر'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'كمبيوتر'],
          description:
            'Computer Engineering focuses on designing, developing, and maintaining computer systems and hardware.',
        },
        {
          name: 'Computer Graphics and Interactive Media',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'محلات تجارية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['فن', 'كمبيوتر'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن', 'كمبيوتر'],
          description:
            'Computer Graphics and Interactive Media focuses on the creation and manipulation of visual content using computer technology, along with the development of interactive digital experiences.',
        },
        {
          name: 'Computer Network and Data Communications',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'منظمات لا تبغي الربح',
            'بنك',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          description:
            'Computer Network and Data Communications is a field that focuses on designing, implementing, and managing interconnected computer systems that exchange data.',
        },
        {
          name: 'Computer Science',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'منظمات لا تبغي الربح',
            'بنك',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['رياضيات', 'كمبيوتر'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'كمبيوتر'],
          description:
            'Computer Science Studies topics about programming, data structures, artificial intelligence, databases, and computer networks.',
        },
        {
          name: 'Data Science',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'بنك',
            'شركة',
            'محلات تجارية',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['رياضيات', 'كمبيوتر', 'فيزياء'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'كمبيوتر', 'فيزياء'],
          description:
            'Data Science is a field that focuses on extracting insights and knowledge from data using statistical analysis, machine learning, and computational techniques.',
        },
        {
          name: 'Mechatronics Engineering',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          description:
            'Mechatronics Engineering focuses on integrating mechanical, electronic, and computer systems to design and create advanced automated and robotic systems.',
        },
        {
          name: 'Telecommunication and Networks Engineering',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'منظمات لا تبغي الربح',
            'بنك',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          description:
            'Telecommunication and Networks Engineering studies topics about telecommunications technologies, network architecture, data transmission, and wireless communications.',
        },
      ],
    },
    {
      id: 12,
      industryName: 'السياحة والضيافة',
      listmajors: [
        'Archaeology',
        'Culinary Arts and Restaurant Management',
        'Hospitality',
        'Hotel Management and Tourism',
      ],
      majors: [
        {
          name: 'Archaeology',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'محلات تجارية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['تاريخ'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['تاريخ'],
          description:
            'Archaeology focuses on the study of human history through excavation and analysis of artifacts, structures, and other physical remains.',
        },
        {
          name: 'Conservation, Restoration of Cultural Property and Sacred Art',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'محلات تجارية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['تاريخ', 'فن'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['تاريخ', 'فن'],
          description:
            'Conservation, Restoration of Cultural Property and Sacred Art is a field dedicated to the preservation, protection, and restoration of historical artifacts, artworks, and sacred objects.',
        },
        {
          name: 'Culinary Arts and Restaurant Management',
          workplaces: ['شركة', 'محلات تجارية'],
          requiredSubjects: ['تاريخ', 'فن'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['تاريخ', 'فن'],
          description:
            'Culinary Arts and Restaurant Management is a field that combines the study of cooking techniques and food preparation with the management of restaurant operations.',
        },
        {
          name: 'Hospitality',
          workplaces: ['شركة', 'محلات تجارية'],
          requiredSubjects: ['اجتماع'],
          workType: ['خدمة الناس أو التفاعل معهم'],
          preferredCourses: ['اجتماع'],
          description:
            'Hospitality focuses on studying the hospitality industry, including hotel and restaurant management, tourism, event planning, and customer service.',
        },
        {
          name: 'Hotel Management and Tourism',
          workplaces: ['شركة', 'محلات تجارية'],
          requiredSubjects: ['اجتماع'],
          workType: ['خدمة الناس أو التفاعل معهم'],
          preferredCourses: ['اجتماع'],
          description:
            'Hotel Management and Tourism studies topics about hospitality management, tourism planning, guest services, marketing, and financial management within the industry.',
        },
      ],
    },
    {
      id: 13,
      industryName: 'الصناعات اللوجستية',
      listmajors: ['Distribution and Logistics Management'],
      majors: [
        {
          name: 'Distribution and Logistics Management',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'منظمات لا تبغي الربح',
            'بنك',
            'شركة',
            'مدارس/ جامعات',
            'محلات تجارية',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: [''],
          workType: ['تحليل البيانات'],
          preferredCourses: [''],
          description:
            'Distribution and Logistics Management studies topics about optimizing supply chain operations, managing logistics networks, and ensuring timely delivery.',
        },
      ],
    },
    {
      id: 14,
      industryName: 'العقارات',
      listmajors: [
        'Architecture',
        'Arts and Interior Planning',
        'Civil Engineering',
        'Civil Environmental Engineering',
        'Landscape Engineering',
      ],
      majors: [
        {
          name: 'Architecture',
          workplaces: [
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'فن'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['رياضيات', 'فن'],
          description:
            'Architecture studies topics about architectural design principles, building materials, structural engineering, and environmental considerations.',
        },
        {
          name: 'Arts and Interior Planning',
          workplaces: [
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['رياضيات', 'فن'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['رياضيات', 'فن'],
          description:
            'Arts and Interior Planning focuses on space planning, color theory, furniture design, and architectural elements, as well as client needs and trends in interior design.',
        },
        {
          name: 'Civil Engineering',
          workplaces: [
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          description:
            'Civil Engineering prepares students for careers in construction, urban planning, transportation, and environmental management, with roles such as civil engineer, project manager, and structural designer.',
        },
        {
          name: 'Civil Environmental Engineering',
          workplaces: [
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كمبيوتر', 'علم الحياة'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كمبيوتر', 'علم الحياة'],
          description:
            'Civil Environmental Engineering combines civil engineering principles with environmental science and focuses on water resources management, waste treatment, pollution control, and sustainable construction practices.',
        },
        {
          name: 'Landscape Engineering',
          workplaces: ['شركة', 'مؤسسات صناعية', 'شركات بحث أو شركات استشارية'],
          requiredSubjects: ['رياضيات', 'كمبيوتر', 'فن'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['رياضيات', 'كمبيوتر', 'فن'],
          description:
            'Landscape Engineering focuses on the study of designing, planning, and managing outdoor spaces and environments with an emphasis on both aesthetic and functional aspects.',
        },
      ],
    },
    {
      id: 15,
      industryName: 'الصناعات الابداعية',
      listmajors: [
        'Advertising and Marketing Communication',
        'Architecture',
        'Arts and Interior Planning',
        'Audiovisual Production',
        'Cinema Production',
        'Civil Engineering',
        'Civil Environmental Engineering',
        'Communication and Visual Arts',
        'Computer Graphics and Interactive Media',
        'Design and Applied Arts',
        'Digital Advertising',
        'Digital Media',
        'Fashion and Pattern Drafting',
        'Graphic Design',
        'Journalism and Electronic Media',
        'Landscape Engineering',
        'Music',
        'Photography',
        'Product Design',
        'Radio/TV and Film"],',
      ],
      majors: [
        {
          name: 'Advertising and Marketing Communication',
          workplaces: [
            'بنك',
            'شركة',
            'محلات تجارية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['فن'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن'],
          description:
            'Advertising and Marketing Communication studies topics on how to develop compelling messages, create marketing campaigns, and utilize various media channels to reach target audiences effectively.',
        },
        {
          name: 'Architecture',
          workplaces: [
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'فن'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['رياضيات', 'فن'],
          description:
            'Architecture studies topics about architectural design principles, building materials, structural engineering, and environmental considerations.',
        },
        {
          name: 'Arts and Interior Planning',
          workplaces: [
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['رياضيات', 'فن'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['رياضيات', 'فن'],
          description:
            'Arts and Interior Planning focuses on space planning, color theory, furniture design, and architectural elements, as well as client needs and trends in interior design.',
        },
        {
          name: 'Audiovisual Production',
          workplaces: ['شركة', 'شركات بحث أو شركات استشارية', 'اعلام'],
          requiredSubjects: ['فن', 'كمبيوتر'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن', 'كمبيوتر'],
          description:
            'Audiovisual Production studies topics about filming techniques, sound design, editing, and post-production processes, as well as storytelling and project management.',
        },

        {
          name: 'Cinema Production',
          workplaces: ['شركة', 'شركات بحث أو شركات استشارية', 'اعلام'],
          requiredSubjects: ['فن', 'كمبيوتر'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن', 'كمبيوتر'],
          description:
            'Cinema Production studies topics about technical skills and creative techniques needed to produce films, from pre-production planning to post-production editing.',
        },
        {
          name: 'Civil Engineering',
          workplaces: [
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كمبيوتر'],
          description:
            'Civil Engineering prepares students for careers in construction, urban planning, transportation, and environmental management, with roles such as civil engineer, project manager, and structural designer.',
        },
        {
          name: 'Civil Environmental Engineering',
          workplaces: [
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كمبيوتر', 'علم الحياة'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كمبيوتر', 'علم الحياة'],
          description:
            'Civil Environmental Engineering combines civil engineering principles with environmental science and focuses on water resources management, waste treatment, pollution control, and sustainable construction practices.',
        },

        {
          name: 'Communication and Visual Arts',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'محلات تجارية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['فن', 'كمبيوتر'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن', 'كمبيوتر'],
          description:
            'Communication and Visual Arts focuses on how to Create and analyze visual content for various platforms, develop communication strategies, and understand the impact of visual media on society.',
        },
        {
          name: 'Computer Graphics and Interactive Media',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'محلات تجارية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['فن', 'كمبيوتر'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن', 'كمبيوتر'],
          description:
            'Computer Graphics and Interactive Media focuses on the creation and manipulation of visual content using computer technology, along with the development of interactive digital experiences.',
        },
        {
          name: 'Design and Applied Arts',
          workplaces: [
            'منظمات لا تبغي الربح',
            'شركة',
            'محلات تجارية',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['فن', 'كمبيوتر'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن', 'كمبيوتر'],
          description:
            'Design and Applied Arts studies topics about design theory, color theory, materials, and digital tools, preparing them for careers in various creative industries where design plays a central role.',
        },
        {
          name: 'Digital Advertising',
          workplaces: [
            'بنك',
            'شركة',
            'محلات تجارية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['فن', 'كمبيوتر'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن', 'كمبيوتر'],
          description:
            'Digital Advertising studies topics about digital marketing strategies, content creation, analytics, and consumer behavior, preparing them for careers in online marketing, digital media, and advertising agencies.',
        },
        {
          name: 'Digital Media',
          workplaces: [
            'شركة',
            'محلات تجارية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['فن', 'كمبيوتر'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن', 'كمبيوتر'],
          description:
            'Digital Media studies topics about various forms of digital content, including text, images, audio, and video, and how to leverage digital tools and platforms to engage audiences.',
        },
        {
          name: 'Fashion and Pattern Drafting',
          workplaces: [
            'منظمات لا تبغي الربح',
            'شركة',
            'محلات تجارية',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['فن'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن'],
          description:
            'Fashion and Pattern Drafting focuses on designing and creating clothing and accessories. Students study how to create and modify patterns, select fabrics, and bring fashion concepts to life.',
        },
        {
          name: 'Graphic Design',
          workplaces: [
            'شركة',
            'محلات تجارية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['فن', 'كمبيوتر'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن', 'كمبيوتر'],
          description:
            'Graphic Design is a major that focuses on creating visual content to communicate messages and ideas. Students learn to use design software and develop creative problem-solving abilities to produce aesthetically pleasing and effective visual communications.',
        },
        {
          name: 'Journalism and Electronic Media',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['اجتماع', 'اللغات'],
          workType: ['خدمة الناس أو التفاعل معهم'],
          preferredCourses: ['اجتماع', 'اللغات'],
          description:
            'Journalism and Electronic Media studies topics about journalism ethics, multimedia storytelling, digital media platforms, broadcasting, and content creation.',
        },
        {
          name: 'Landscape Engineering',
          workplaces: ['شركة', 'مؤسسات صناعية', 'شركات بحث أو شركات استشارية'],
          requiredSubjects: ['رياضيات', 'كمبيوتر', 'فن'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['رياضيات', 'كمبيوتر', 'فن'],
          description:
            'Landscape Engineering focuses on the study of designing, planning, and managing outdoor spaces and environments with an emphasis on both aesthetic and functional aspects.',
        },
        {
          name: 'Music',
          workplaces: ['منظمات لا تبغي الربح', 'شركة', 'اعلام'],
          requiredSubjects: ['فن'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن'],
          description:
            'Music studies topics about various musical genres, techniques, and instruments, as well as music theory, ear training, and music history.',
        },
        {
          name: 'Photography',
          workplaces: [
            'منظمات لا تبغي الربح',
            'شركة',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['فن'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن'],
          description:
            'Photography focuses on the study of visual storytelling and the technical aspects of capturing and editing images.',
        },
        {
          name: 'Product Design',
          workplaces: [
            'شركة',
            'محلات تجارية',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['فن'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن'],
          description:
            'Product Design studies topics about design principles, materials, manufacturing processes, user experience, and prototyping.',
        },

        {
          name: 'Radio/TV and Film',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['فن', 'كمبيوتر'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['فن', 'كمبيوتر'],
          description:
            'Radio/TV and Film focuses on the production, direction, and management of audio-visual content across various media platforms.',
        },
      ],
    },
    {
      id: 16,
      industryName: 'الصناعات الخضراء (مثل توليد الطاقة والزراعة المستدامة)',
      listmajors: [
        'Agri-Business',
        'Agricultural Economics',
        'Agricultural Engineering',
        'Animal Science and Technology',
        'Applied Mathematics',
        'Biology',
        'Chemical Engineering',
        'Chemistry',
        'Civil Environmental Engineering',
        'Engineering Sciences-Food Engineering',
        'Environmental / Forestry Engineering',
        'Environmental Health/ Sciences',
        'Fundamental Health Science',
        'Mechanical Engineering',
        'Mechatronics Engineering',
        'Petrochemical Engineering',
        'Petroleum Engineering',
        'Physics',
        'Plant Production/ Protection',
        'Poultry and Cattle Medicine',
      ],
      majors: [
        {
          name: 'Agri-Business',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'محلات تجارية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'كيمياء', 'علم الحياة'],
          workType: ['العمل من أجل البيئة'],
          preferredCourses: ['رياضيات', 'كيمياء', 'علم الحياة'],
          description:
            'Agri-Business focuses on the business aspects of agriculture, including farm management, agricultural finance, marketing, and supply chain logistics.',
        },
        {
          name: 'Agricultural Economics',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'كيمياء', 'علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'كيمياء', 'علم الحياة'],
          description:
            'Agricultural Economics focuses on analyzing agricultural production, market trends, and policy impacts, with an emphasis on optimizing resource use, improving farm profitability, and addressing issues related to food supply and rural development.',
        },
        {
          name: 'Agricultural Engineering',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'كمبيوتر', 'كيمياء', 'علم الحياة'],
          workType: ['العمل من أجل البيئة'],
          preferredCourses: ['رياضيات', 'كمبيوتر', 'كيمياء', 'علم الحياة'],
          description:
            'Agricultural Engineering focuses on designing and optimizing machinery, irrigation systems, and infrastructure to enhance productivity, efficiency, and sustainability in agriculture.',
        },
        {
          name: 'Animal Science and Technology',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'كمبيوتر', 'كيمياء', 'علم الحياة'],
          workType: ['العمل من أجل البيئة'],
          preferredCourses: ['رياضيات', 'كمبيوتر', 'كيمياء', 'علم الحياة'],
          description:
            'Animal Science and Technology focuses on the study of animal biology, management, and technology to improve the health, productivity, and welfare of domestic animals.',
        },
        {
          name: 'Applied Mathematics',
          workplaces: [
            'مستشفى',
            'حكومة',
            'بنك',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات'],
          description:
            'Applied Mathematics focuses on using mathematical methods and techniques to solve real-world problems across various fields, including engineering, science, economics, and technology.',
        },
        {
          name: 'Biology',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['علم الحياة'],
          description:
            'Biology studies topics about the structure, function, growth, and development of various life forms, preparing them for careers in research, healthcare, environmental science, and biotechnology.',
        },
        {
          name: 'Chemical Engineering',
          workplaces: [
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['كمبيوتر', 'كيمياء'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['كمبيوتر', 'كيمياء'],
          description:
            'Chemical Engineering focuses on the design, optimization, and operation of processes that transform raw materials into valuable products.',
        },
        {
          name: 'Chemistry',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['كيمياء'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['كيمياء'],
          description:
            'Chemistry studies topics about chemical reactions, molecular interactions, and laboratory techniques, preparing students for careers in research, pharmaceuticals, environmental science, and chemical manufacturing.',
        },
        {
          name: 'Civil Environmental Engineering',
          workplaces: [
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'فيزياء', 'كمبيوتر', 'علم الحياة'],
          workType: ['خلق أفكار جديدة'],
          preferredCourses: ['رياضيات', 'فيزياء', 'كمبيوتر', 'علم الحياة'],
          description:
            'Civil Environmental Engineering combines civil engineering principles with environmental science and focuses on water resources management, waste treatment, pollution control, and sustainable construction practices.',
        },
        {
          name: 'Engineering Sciences-Food Engineering',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['كيمياء', 'كمبيوتر', 'علم الحياة'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['كيمياء', 'كمبيوتر', 'علم الحياة'],
          description:
            'Engineering Sciences-Food Engineering studies topics about food processing technologies, biochemical processes, and the engineering aspects of food production, preparing them for careers in the food industry, quality assurance, and food technology.',
        },
        {
          name: 'Environmental / Forestry Engineering',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'كمبيوتر', 'علم الحياة'],
          workType: ['العمل من أجل البيئة'],
          preferredCourses: ['رياضيات', 'كمبيوتر', 'علم الحياة'],
          description:
            'Environmental / Forestry Engineering studies topics about ecosystem management, environmental impact assessment, and the engineering aspects of land and water management.',
        },
        {
          name: 'Environmental Health/ Sciences',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'علم الحياة'],
          workType: ['العمل من أجل البيئة'],
          preferredCourses: ['رياضيات', 'علم الحياة'],
          description:
            'Environmental Health/ Sciences studies topics about environmental toxins, epidemiology, risk assessment, and environmental regulations.',
        },
        {
          name: 'Fundamental Health Science',
          workplaces: [
            'مستشفى',
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'محلات تجارية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['علم الحياة'],
          workType: ['مساعدة الناس عقليًا أو جسديًا'],
          preferredCourses: ['علم الحياة'],
          description:
            'Fundamental Health Science studies topics about the mechanisms of health and illness, research methodologies, and foundational concepts that inform clinical practices and health interventions.',
        },
        {
          name: 'Mechanical Engineering',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'كمبيوتر', 'فيزياء'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'كمبيوتر', 'فيزياء'],
          description:
            'Mechanical Engineering focuses on the study of the design, analysis, and manufacturing of mechanical systems and devices.',
        },
        {
          name: 'Mechatronics Engineering',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'كمبيوتر', 'فيزياء'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'كمبيوتر', 'فيزياء'],
          description:
            'Mechatronics Engineering focuses on integrating mechanical, electronic, and computer systems to design and create advanced automated and robotic systems',
        },
        {
          name: 'Petrochemical Engineering',
          workplaces: [
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'كمبيوتر', 'كيمياء'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'كمبيوتر', 'كيمياء'],
          description:
            'Petrochemical Engineering is a specialized branch of chemical engineering that focuses on the production and processing of chemicals derived from petroleum and natural gas.',
        },
        {
          name: 'Petroleum Engineering',
          workplaces: [
            'حكومة',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'كمبيوتر', 'كيمياء'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['رياضيات', 'كمبيوتر', 'كيمياء'],
          description:
            'Petroleum Engineering focuses on the study of the extraction and production of oil and natural gas.',
        },
        {
          name: 'Physics',
          workplaces: [
            'مستشفى',
            'حكومة',
            'شركة',
            'منظمات لا تبغي الربح',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['فيزياء'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['فيزياء'],
          description:
            'Physics focuses on studying the fundamental principles governing matter, energy, and the interactions between them.',
        },
        {
          name: 'Plant Production/ Protection',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['فيزياء', 'علم الحياة'],
          workType: ['العمل من أجل البيئة'],
          preferredCourses: ['فيزياء', 'علم الحياة'],
          description:
            'Plant Production/ Protection focuses on the study of cultivating crops and managing plant health to maximize yield and minimize losses.',
        },
        {
          name: 'Poultry and Cattle Medicine',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: ['رياضيات', 'علم الحياة'],
          workType: ['العمل من أجل البيئة'],
          preferredCourses: ['رياضيات', 'علم الحياة'],
          description:
            'Poultry and Cattle Medicine focuses on the veterinary care of poultry and cattle, including their health management, disease prevention, and treatment.',
        },
      ],
    },
    {
      id: 17,
      industryName: 'الاديان',
      listmajors: [
        'Christian Studies',
        'Islamic Studies',
        'Religions Sciences',
        'Theology',
      ],
      majors: [
        {
          name: 'Christian Studies',
          workplaces: ['شركة', 'مدارس/ جامعات', 'شركات بحث أو شركات استشارية'],
          requiredSubjects: ['تاريخ'],
          workType: ['نقل معرفتك إلى الآخرين'],
          preferredCourses: ['تاريخ'],
          description:
            'Christian Studies focuses on interfaith dialogue, comparative theology, and the social and political impacts of this religion.',
        },
        {
          name: 'Islamic Studies',
          workplaces: ['شركة', 'مدارس/ جامعات', 'شركات بحث أو شركات استشارية'],
          requiredSubjects: ['تاريخ'],
          workType: ['نقل معرفتك إلى الآخرين'],
          preferredCourses: ['تاريخ'],
          description:
            'Islamic Studies focuses on the study of Islamic religion, culture, history, and civilization.',
        },
        {
          name: 'Religions Sciences',
          workplaces: ['شركة', 'مدارس/ جامعات', 'شركات بحث أو شركات استشارية'],
          requiredSubjects: ['تاريخ'],
          workType: ['نقل معرفتك إلى الآخرين'],
          preferredCourses: ['تاريخ'],
          description:
            'Religions Sciences focuses on the study of religious beliefs, practices, and institutions across various cultures and historical contexts.',
        },
        {
          name: 'Theology',
          workplaces: ['شركة', 'مدارس/ جامعات', 'شركات بحث أو شركات استشارية'],
          requiredSubjects: ['تاريخ'],
          workType: ['نقل معرفتك إلى الآخرين'],
          preferredCourses: ['تاريخ'],
          description:
            'Theology explores theological concepts, sacred texts, religious history, and the philosophy of religion.',
        },
      ],
    },
    {
      id: 18,
      industryName: 'صناعات الاعمال والإدارة',
      listmajors: ['Business Administration', 'Human Resources Management'],
      majors: [
        {
          name: 'Business Administration',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'شركة',
            'بنك',
            'منظمات لا تبغي الربح',
            'محلات تجارية',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: [''],
          workType: ['خدمة الناس أو التفاعل معهم'],
          preferredCourses: [''],
          description:
            'Business Administration focuses on the overall management and operation of businesses, covering a broad range of topics such as finance, marketing, human resources, and operations.',
        },
        {
          name: 'Human Resources Management',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'شركة',
            'بنك',
            'منظمات لا تبغي الربح',
            'محلات تجارية',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: [''],
          workType: ['خدمة الناس أو التفاعل معهم'],
          preferredCourses: [''],
          description:
            'Human Resources Management focuses on the study of managing an organizations workforce, including recruitment, training, employee relations, and performance management.',
        },
      ],
    },
    {
      id: 19,
      industryName: 'الصناعات القانونية',
      listmajors: ['Forensic Science', 'Law'],
      majors: [
        {
          name: 'Forensic Science',
          workplaces: ['حكومة', 'شركة', 'شركات بحث أو شركات استشارية'],
          requiredSubjects: ['علم الحياة', 'فيزياء', 'كيمياء'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['علم الحياة', 'فيزياء', 'كيمياء'],
          description:
            'Forensic Science is a field that applies scientific principles and techniques to solve crimes and legal issues. Students learn about forensic biology, chemistry, toxicology, and crime scene investigation.',
        },
        {
          name: 'Law',
          workplaces: [
            'مستشفى',
            'مطار',
            'حكومة',
            'شركة',
            'بنك',
            'منظمات لا تبغي الربح',
            'محلات تجارية',
            'مدارس/ جامعات',
            'مؤسسات صناعية',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['فلسفة', 'اللغات'],
          workType: ['تحليل البيانات'],
          preferredCourses: ['فلسفة', 'اللغات'],
          description:
            'Law studies topics including constitutional, criminal, civil, and international law, as well as legal research, ethics, and court procedures',
        },
      ],
    },
    {
      id: 20,
      industryName: 'الصناعات الادارية والسياسية',
      listmajors: [
        'Administrative and Politics Sciences',
        'International and Diplomatic Relations',
        'International Business',
      ],
      majors: [
        {
          name: 'Administrative and Politics Sciences',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'شركات بحث أو شركات استشارية',
            'اعلام',
          ],
          requiredSubjects: ['اجتماع', 'تاريخ'],
          workType: ['خدمة الناس أو التفاعل معهم'],
          preferredCourses: ['اجتماع', 'تاريخ'],
          description:
            'Administrative and Politics Sciences studies topics about the creation and implementation of public policies, the roles of various governmental and non-governmental organizations, and the principles of political analysis and leadership.',
        },
        {
          name: 'International and Diplomatic Relations',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: [''],
          workType: ['خدمة الناس أو التفاعل معهم'],
          preferredCourses: [''],
          description:
            'International and Diplomatic Relations focuses on the study of global politics, diplomacy, international law, and relations between countries.',
        },
        {
          name: 'International Business',
          workplaces: [
            'حكومة',
            'منظمات لا تبغي الربح',
            'شركة',
            'شركات بحث أو شركات استشارية',
          ],
          requiredSubjects: [''],
          workType: ['خدمة الناس أو التفاعل معهم'],
          preferredCourses: [''],
          description:
            'International Business focuses on the study of global commerce, including the strategies, operations, and challenges of conducting business across international borders.',
        },
      ],
    },
  ],
};
