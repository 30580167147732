import React from 'react';
import Row from 'react-bootstrap/esm/Row';
import { menuData } from '../../Data/Data';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigation = (item) => {
    if (item.command) {
      item.command(navigate);
    }
  };

  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className="footer">
        <div className="handle">
          <Row className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 footer-logo">
              <img className="logo" src="/images/brightLogo.svg" alt="logo" />
              <div className="col-md-10 footer-text">
                {t('footer.Footer Text')}
              </div>
            </div>
            {menuData
              .filter(
                (item) =>
                  item.title !== 'Footer Text' && item.title !== 'Copyright'
              )
              .map((menu, index) => (
                <div className="col-xs-12 col-sm-2 align" key={index}>
                  <p className="title">
                    {t(`footer.${menu.title}`, menu.title)}
                  </p>
                  {menu.items.map((item, subIndex) => (
                    <p
                      className="text"
                      key={subIndex}
                      onClick={() => handleNavigation(item)}
                      style={{ cursor: 'pointer' }}
                    >
                      {t(`footer.${item.label}`, item.label)}
                    </p>
                  ))}
                </div>
              ))}
          </Row>
        </div>
      </div>

      <div className="copyrightHandle">
        <p className="copyright">
          {t('footer.Copyright', { year: currentYear })}
        </p>
      </div>
    </>
  );
};

export default Footer;
