const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const updateTestAccess = async (
  emails = [],
  type,
  topSelectedMajors = [],
  topNonSelectedMajors = []
) => {
  try {
    const requestBody = {
      emails,
      type,
      topSelectedMajors,
      topNonSelectedMajors,
    };

    const response = await fetch(`${BASE_URL}/api/Account/updateTest`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error('Error in updateTestAccess:', error.message);
    throw error;
  }
};
