import { Button } from 'primereact/button';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';

const Test = () => {
  const navigate = useNavigate();
  const [testRef, testRefView] = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });
  const { t } = useTranslation();

  const handleStartNowClick = () => {
    navigate('/Test');
  };
  useEffect(() => {
    if (testRefView) {
      const testContainerHolder = document.querySelector(
        '.testContainerHolder'
      );
      testContainerHolder.classList.add('fadeIn');
    }
  }, [testRefView]);

  return (
    <div className="test" id="test">
      <div className="testContainerHolder">
        <img src="images/Graduate.svg" alt="Graduate" />
        <div className="main-header">{t(`test.mainHeader`)}</div>
        <div className="sub-header" ref={testRef}>
          {t(`test.subHeader`)}
        </div>
        <Button
          label={t(`test.startNow`)}
          className="button"
          onClick={handleStartNowClick}
        />
      </div>
    </div>
  );
};

export default Test;
