import React from 'react';

const AnimatedTitleLoader = ({ name }) => {
  const letters = name.split('');

  const spanStyle = (index, letter) => ({
    position: 'relative',
    top: '0.63em',
    display: 'inline-block',
    textTransform: 'uppercase',
    opacity: 0,
    transform: 'rotateX(-90deg)',
    animation: 'drop 1.2s ease-in-out infinite',
    animationDelay: `${1.1 + 0.1 * index}s`,
    color: letter === '✓' ? '#4D9190' : '#48638F',
  });

  const keyframes = `
    @keyframes drop {
      10% {
        opacity: 0.5;
      }
      20% {
        opacity: 1;
        top: 3.78em;
        transform: rotateX(-360deg);
      }
      80% {
        opacity: 1;
        top: 3.78em;
        transform: rotateX(-360deg);
      }
      90% {
        opacity: 0.5;
      }
      100% {
        opacity: 0;
        top: 6.94em;
      }
    }
    @media (max-width: 500px) {
      h1 {
        font-size: 40px !important;
        height: 50vh !important;

      }
    }
  `;

  return (
    <div>
      <style>{keyframes}</style>
      <h1
        style={{
          margin: 0,
          padding: 0,
          fontFamily: 'Arial Narrow, sans-serif',
          fontWeight: 700,
          fontSize: '60px',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '30vh',
          direction: 'ltr',
        }}
      >
        {letters.map((letter, index) => (
          <span key={index} style={spanStyle(index, letter)}>
            {letter}
          </span>
        ))}
      </h1>
    </div>
  );
};

export default AnimatedTitleLoader;
