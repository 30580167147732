import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import 'primeicons/primeicons.css';

const MissionVision = () => {
  const [missionRef, missionInView] = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });
  const [visionRef, visionInView] = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (missionInView) {
      const missionContentHolder = document.querySelector('.holder .mission ');
      missionContentHolder.classList.add('slideIn');
    }

    if (visionInView) {
      const visionContentHolder = document.querySelector('.holder .vision ');
      visionContentHolder.classList.add('slideInRight');
    }
  }, [missionInView, visionInView]);

  return (
    <div className="missionVisionContainer" id="missionVisionContainer">
      {/* <img
        className="headerImg"
        src="/images/MissionVisionBg.svg"
        alt="arrow"
      /> */}
      <div className="missionVision">
        <div className="holder">
          <div className="mission">
            <div className="contentHolder">
              {/* <div className="iconTextHolder">
                <i className="pi pi-bullseye icon"></i>
              </div> */}
              <div className="mission-container">
                <hr className="line" />
                <span className="mission-text">
                  {t('missionVision.mission.title')}
                </span>
                <hr className="line" />
              </div>
              <div className="text" ref={missionRef}>
                {t('missionVision.mission.text')}
              </div>
            </div>
          </div>
          <div className="vision">
            <div className="contentHolder">
              <div className="mission-container">
                <hr className="line" />
                <span className="mission-text">
                  {t('missionVision.vision.title')}
                </span>
                <hr className="line" />
              </div>
              <div className="text" ref={visionRef}>
                {t('missionVision.vision.text')}
              </div>
            </div>
          </div>
        </div>
        {/* <img
        className="missionVisionBg"
        ref={imgRef}
        src="/images/missionVisionBg.svg"
        alt="missionVision"
      /> */}
      </div>
    </div>
  );
};

export default MissionVision;
