import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import BarGraph from './Charts';
import LoginComponent from '../Pages/LoginPage';
import TestDetails from './TestDetails';
import CustomHeader from '../../utils/utils';
import { useTranslation } from 'react-i18next';
import HoverRating from './RatingComponent';
import { Box, TextField, Typography } from '@mui/material';
import generatePDF from '../Pages/ResultPage';

const PopUp = ({
  header,
  visible,
  onHide,
  type,
  data,
  content,
  footerButtons,
  handlePrimaryAction,
  handleSecondaryAction,
  ActionLabel,
  CancelLabel,
  WarningLabel,
  handleThirdAction,
  handleForthAction,
  details,
  CenterText = true,
  testType,
  RatingLabel,
  handleFifthAction,
  PdfLabel,
}) => {
  const { i18n } = useTranslation();
  const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';
  const textAlign = CenterText
    ? 'center'
    : i18n.language === 'ar'
    ? 'right'
    : 'left';
  const { t } = useTranslation();
  const [feedback, setFeedback] = useState('');
  const [ratingLabel, setRatingLabel] = useState('');

  const handleRatingChange = (label) => {
    setRatingLabel(label);
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleSubmitRating = () => {
    handleForthAction(ratingLabel);
  };

  const handleSubmitFeedback = () => {
    generatePDF();
    handleFifthAction(feedback);
  };

  useEffect(() => {
    if (visible) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [visible]);

  const getDialogId = (type) => {
    switch (type) {
      case 'BarGraph':
        return 'barGraphPopup';
      case 'loginComponent':
        return 'loginPopup';
      case 'Content':
        return 'contentPopup';
      case 'Result':
        return 'resultPopup';
      case 'Purchase':
        return 'purchasePopup';
      case 'Warning':
        return 'warningPopup';
      case 'ZerResultPopUp':
        return 'zeroResultPopUp';
      case 'AddUserPopUp':
        return 'addUserPopUp';
      case 'Rating':
        return 'ratingPopUp';
      case 'Feedback':
        return 'feedbackPopUp';
      default:
        return 'defaultPopup';
    }
  };

  // Check if both rating and feedback are provided
  const isButtonEnabled = ratingLabel !== null && ratingLabel !== '';

  const isfeedbackbutton = feedback.trim() !== '';

  return (
    <Dialog
      id={getDialogId(type)}
      header={
        <CustomHeader htmlContent={header} style={{ textAlign, direction }} />
      }
      visible={visible}
      onHide={onHide}
      style={{ display: 'flex', textAlign: 'center' }}
      footer={
        footerButtons || (
          <div className="flex popupFooter">
            {handlePrimaryAction && (
              <Button
                label={ActionLabel}
                autoFocus
                className="button popupButton"
                onClick={handlePrimaryAction}
              />
            )}
            {handleSecondaryAction && (
              <Button
                label={CancelLabel}
                className="p-button-secondary button popupButton"
                onClick={handleSecondaryAction}
              />
            )}
            {handleThirdAction && (
              <Button
                label={WarningLabel}
                className="p-button-secondary button popupButton"
                onClick={handleThirdAction}
              />
            )}
            {handleForthAction && type === 'Rating' && (
              <Button
                label={RatingLabel}
                className="p-button-success button popupButton"
                onClick={handleSubmitRating}
                disabled={!isButtonEnabled}
              />
            )}
            {handleFifthAction && (
              <Button
                label={PdfLabel}
                className="p-button-success button popupButton"
                onClick={handleSubmitFeedback}
                disabled={!isfeedbackbutton}
              />
            )}
          </div>
        )
      }
    >
      {type === 'BarGraph' ? (
        <>
          {testType === 'skills' && (
            <div className="displayResultSentence">
              {t('popUps.showResultPopup.skillsDisplaySentence')}
            </div>
          )}
          {testType === 'personality' && (
            <div className="displayResultSentence">
              {t('popUps.showResultPopup.personalityDisplaySentence')}
            </div>
          )}
          <BarGraph data={data} />
          <TestDetails
            details={details}
            showSkillsNote={testType === 'skills'}
          />
        </>
      ) : type === 'loginComponent' ? (
        <LoginComponent />
      ) : type === 'Content' ? (
        <p>{content}</p>
      ) : type === 'Result' ? (
        <p>{content}</p>
      ) : type === 'Purchase' ? (
        <p>{content}</p>
      ) : type === 'Warning' ? (
        <p>{content}</p>
      ) : type === 'AddUserPopUp' ? (
        <p>{content}</p>
      ) : type === 'Rating' ? (
        <Box
          direction={direction}
          sx={{
            width: 400,
            padding: 3,
            backgroundColor: '#fff',
            borderRadius: 2,
            boxShadow: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" gutterBottom direction={direction}>
            {t('popUps.ratingPopUp.title')}
          </Typography>
          <Typography variant="body1" paragraph direction={direction}>
            {t('popUps.ratingPopUp.text')}
          </Typography>

          <HoverRating onRate={handleRatingChange} />
        </Box>
      ) : type === 'Feedback' ? (
        <Box
          direction={direction}
          sx={{
            width: 400,
            padding: 3,
            backgroundColor: '#fff',
            borderRadius: 2,
            boxShadow: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" gutterBottom direction={direction}>
            {t('popUps.ratingPopUp.titleFeedback')}
          </Typography>
          <Typography variant="body1" paragraph direction={direction}>
            {t('popUps.ratingPopUp.textFeedback')}
          </Typography>

          <TextField
            direction={direction}
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            placeholder={t('popUps.ratingPopUp.placeholder')}
            value={feedback}
            onChange={handleFeedbackChange}
            sx={{ marginTop: 2 }}
            style={{ height: '130px' }}
          />
        </Box>
      ) : type === 'ZerResultPopUp' ? (
        <p>{content}</p>
      ) : null}
    </Dialog>
  );
};

export default PopUp;
