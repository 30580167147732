//#region Imports
import React, { useEffect, useRef, useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import {
  correctAnswers,
  countCorrectAnswers,
  createWhatsAppUrl,
  percentageResult,
} from '../../utils/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import PopUp from './PopUps';
import { RadioButton } from 'primereact/radiobutton';
import useHasAccess from '../../hooks/useHasAccess';
import { useTranslation } from 'react-i18next';
import { skillsEnThresholdScoring } from '../../Data/skillsEnQuestions';
import { skillsArThresholdScoring } from '../../Data/skillsArQuestions';
//#endregion

export default function Quiz({ combinedQuestions, questions }) {
  //#region States
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [popUp, setPopUp] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [showContinueDialog, setShowContinueDialog] = useState(false);
  const [incompleteTestDialogue, setIncompleteTestDialogue] = useState(false);
  const [completeTestDialogue, setCompleteTestDialogue] = useState(false);
  const [allAnswered, setAllAnswered] = useState(false);
  const [accessQuiz, setAccessQuiz] = useState(false);
  const [zeroResultPopUp, setZeroResultPopUp] = useState(false);
  const [testDetails, setTestDetails] = useState([]);
  const navigate = useNavigate();
  const questionRefs = useRef([]);
  const [showLoader, setShowLoader] = useState(false);
  const { handleUpdateTestAccess } = useHasAccess();
  const { handleGetUserInfo, hasAccess, showErrorPopUp } = useAuth();
  const location = useLocation();
  const [purchasePopUp, setPurchasePopUp] = useState(false);
  const [warningPopUp, setWarningPopUp] = useState(false);
  const { i18n, t } = useTranslation();
  const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';
  const [loading, setLoading] = useState(true);
  const [descriptionData, setDescriptionData] = useState(
    skillsEnThresholdScoring
  );
  //#endregion

  //#region Load or Initialize Quiz
  // Load and save quiz state
  const saveQuizState = ({ answers, chartData }) => {
    localStorage.setItem('skillsState', JSON.stringify({ answers, chartData }));
  };

  const loadQuizState = () => {
    const savedData = localStorage.getItem('skillsState');
    return savedData ? JSON.parse(savedData) : null;
  };

  const clearQuizState = () => {
    localStorage.removeItem('skillsState');
  };

  const initializeQuiz = (questions) => {
    const initialSelectedOptions = Array(questions.length).fill(null);
    setSelectedOptions(initialSelectedOptions);
    saveQuizState({
      answers: initialSelectedOptions,
      chartData: null,
    });
  };

  useEffect(() => {
    const currentLanguage =
      i18n.language || localStorage.getItem('i18nextLng') || 'en';
    if (currentLanguage === 'ar') {
      setDescriptionData(skillsArThresholdScoring);
    } else {
      setDescriptionData(skillsEnThresholdScoring);
    }
  }, [i18n.language]);

  useEffect(() => {
    if (selectedOptions.length > 0) {
      const allAnswered = selectedOptions.every((option) => option !== null);
      setAllAnswered(allAnswered);
      saveQuizState({
        answers: selectedOptions,
        chartData: chartData,
      });
    }
  }, [selectedOptions, chartData]);
  //#endregion

  //#region To get the user info constantly to update access
  useEffect(() => {
    if (!descriptionData) {
      return;
    }
    console.log('description::', descriptionData);

    const email = localStorage.getItem('email');
    if (email) {
      setLoading(true);

      handleGetUserInfo(email)
        .then((response) => {
          if (response.success) {
            const { userInfo } = response;
            localStorage.setItem(
              'hasAccess',
              JSON.stringify(userInfo.hasAccess)
            );
            localStorage.setItem(
              'accessPersonality',
              JSON.stringify(userInfo.accessPersonality)
            );
            localStorage.setItem(
              'accessCareer',
              JSON.stringify(userInfo.accessCareer)
            );
            localStorage.setItem(
              'accessSkills',
              JSON.stringify(userInfo.accessSkills)
            );
            localStorage.setItem(
              'topSelectedMajors',
              JSON.stringify(userInfo.topSelectedMajors)
            );
            localStorage.setItem(
              'topNonSelectedMajors',
              JSON.stringify(userInfo.topNonSelectedMajors)
            );
          }
        })
        .catch((error) => {
          console.error('Failed to fetch user info:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [handleGetUserInfo, descriptionData]);

  useEffect(() => {
    if (!loading) {
      const hasAccess = JSON.parse(localStorage.getItem('hasAccess'));
      const accessSkills = JSON.parse(localStorage.getItem('accessSkills'));

      if (hasAccess && !accessSkills) {
        setShowContinueDialog(false);
        setAccessQuiz(true);
        setPurchasePopUp(true);
      } else {
        setAccessQuiz(false);
        setPurchasePopUp(false);
      }
    }
    scrollToLastAnswered();
  }, [loading]);

  //#endregion

  //#region To show the continue dialog if the user has already started the test
  useEffect(() => {
    window.scrollTo(0, 0);

    const savedData = loadQuizState();
    if (savedData) {
      const hasAnsweredQuestions = savedData.answers.some(
        (answer) => answer !== null
      );
      const allAnsweredQuestions = savedData.answers.every(
        (answer) => answer !== null
      );

      if (allAnsweredQuestions) {
        setSelectedOptions(savedData.answers);
        setChartData(savedData.chartData);
        setPopUp(false);
        setShowContinueDialog(false);
      } else if (hasAnsweredQuestions) {
        setAccessQuiz(false);
        setSelectedOptions(savedData.answers);
        setShowContinueDialog(true);
      } else {
        initializeQuiz(combinedQuestions);
        setShowContinueDialog(false);
        setWarningPopUp(true);
      }
    } else {
      setWarningPopUp(true);
      initializeQuiz(combinedQuestions);
      setShowContinueDialog(false);
    }
  }, [hasAccess]);
  //#endregion

  //#region To handle the options selection and save it in local storage
  const handleOptionChange = (index, option) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[index] = option;
    setSelectedOptions(newSelectedOptions);
  };

  const selectedAnswers = () => {
    return selectedOptions.map((selected, index) => ({
      id: combinedQuestions[index].id,
      question: combinedQuestions[index].question,
      correctAnswer: combinedQuestions[index].answer,
      selectedOption: selected,
    }));
  };
  //#endregion

  //#region To handle the api request of updating the test access
  const handleTestAccess = async (type) => {
    try {
      setShowLoader(true);
      await handleUpdateTestAccess(type, [], []);
    } catch (error) {
      console.error('Error updating test access:', error.message);
    } finally {
      setShowLoader(false);
    }
  };
  //#endregion

  const getDescriptionForPersonality = (label, percentage, thresholdsData) => {
    const personalityThresholds = thresholdsData[label]?.thresholds;

    if (!personalityThresholds) return 'Description not available';

    // Get all available thresholds and sort them
    const sortedThresholds = Object.keys(personalityThresholds)
      .map(Number)
      .sort((a, b) => a - b);

    // Find the highest threshold that is less than or equal to the given percentage
    const matchingThreshold = sortedThresholds.reduce((prev, curr) => {
      return percentage >= curr ? curr : prev;
    }, sortedThresholds[0]);

    return (
      personalityThresholds[matchingThreshold] || 'Description not available'
    );
  };

  //#region To handle the dialogue for completing and submitting the test
  const handleSubmit = async () => {
    setShowLoader(true);
    try {
      await handleTestAccess('skills');
      const allAnswers = selectedAnswers();
      const correctAnswersList = correctAnswers(allAnswers);

      const correctAnswersCount = countCorrectAnswers(
        questions,
        correctAnswersList
      );

      const chartLabels = Object.keys(correctAnswersCount);
      const chartDataValues = chartLabels.map((label) =>
        percentageResult(label, correctAnswersCount[label])
      );

      // Check if all chart data values are zero
      const allZero = chartDataValues.every((value) => value === 0);

      if (allZero) {
        setCompleteTestDialogue(false);
        setZeroResultPopUp(true);
      } else {
        const filteredChartData = chartLabels
          .filter((label, index) => chartDataValues[index] > 0)
          .map((label, index) => ({
            label,
            data: chartDataValues[chartLabels.indexOf(label)],
          }));

        const sortedChartData = filteredChartData.sort(
          (a, b) => b.data - a.data
        );

        const newChartData = {
          labels: sortedChartData.map((item) => item.label),
          datasets: [
            {
              label: 'Subject Scores',
              data: sortedChartData.map((item) => item.data),
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
            },
          ],
        };

        console.log('Chart Data:', newChartData);
        setChartData(newChartData);
        saveQuizState({
          answers: selectedOptions,
          chartData: newChartData,
        });

        const sortedDescriptions = sortedChartData.reduce(
          (acc, { label, data }) => {
            const description = getDescriptionForPersonality(
              label,
              data,
              descriptionData
            );

            acc[label] = {
              title: `${label} - ${data}%`,
              description,
            };
            return acc;
          },
          {}
        );

        setTestDetails(sortedDescriptions);
        setCompleteTestDialogue(false);
        setPopUp(true);

        await new Promise((resolve) => setTimeout(resolve, 3000));
        localStorage.removeItem('skillsState');
      }
    } catch (error) {
      console.error('Error during submission:', error.message);
    } finally {
      setShowLoader(false);
    }
  };

  //#endregion

  //#region Scroll to Last Answered Questions
  const scrollToLastAnswered = () => {
    const lastAnsweredIndex = [...selectedOptions]
      .map((option, index) => (option !== null ? index : -1))
      .filter((index) => index !== -1)
      .pop();

    if (
      lastAnsweredIndex !== undefined &&
      questionRefs.current[lastAnsweredIndex]
    ) {
      questionRefs.current[lastAnsweredIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      setActiveStep(lastAnsweredIndex);
    }
  };
  //#endregion

  //#region Handle Action Buttons
  const handleDialogDecision = (decision) => {
    if (decision === 'continue') {
      setShowContinueDialog(false);
      setIncompleteTestDialogue(false);
      const savedData = loadQuizState();
      setSelectedOptions(savedData.answers);
      scrollToLastAnswered();
    } else if (decision === 'restart') {
      setShowContinueDialog(false);
      setPopUp(false);
      clearQuizState();
      initializeQuiz(combinedQuestions);
    } else if (decision === 'hide') {
      setShowContinueDialog(false);
      setPopUp(false);
      const savedData = loadQuizState();
      setSelectedOptions(savedData.answers);
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleReturnToTestPage = () => {
    navigate('/test', { replace: true });
    window.location.reload();
  };
  //#endregion

  //#region Whatsapp Access Message
  const url = createWhatsAppUrl({
    name: localStorage.getItem('userName'),
    email: localStorage.getItem('email'),
    studentPhoneNumber: localStorage.getItem('phoneNumber'),
    testDate: new Date().toLocaleDateString(),
    topSelectedMajors:
      JSON.parse(localStorage.getItem('topSelectedMajors')) || [],
    topNonSelectedMajors:
      JSON.parse(localStorage.getItem('topNonSelectedMajors')) || [],
    messageTemplate: 'requestAccess',
  });
  //#endregion

  //#region Handle Action Buttons
  const handleSendWhatsappRequest = () => {
    setPurchasePopUp(false);
    setTimeout(() => {
      window.open(url, '_blank');
      navigate('/test', { replace: true });
    }, 300);
  };

  return (
    <Box className="muiStepperContainer">
      <Stepper activeStep={activeStep} orientation="vertical">
        {combinedQuestions.map((question, index) => (
          <Step
            className="muiStep"
            key={index}
            ref={(el) => (questionRefs.current[index] = el)}
            dir={direction}
          >
            <StepLabel className="muiStepperLabel">
              {question.question}
            </StepLabel>
            <StepContent className="muiStepperContentHolder">
              <div className="muiStepperContent">
                {question.options.map((option, optionIndex) => (
                  <div key={optionIndex} className="muiStepperOptions">
                    <RadioButton
                      inputId={`option${optionIndex}`}
                      name={`question${index}`}
                      value={option}
                      onChange={() => handleOptionChange(index, option)}
                      checked={selectedOptions[index] === option}
                    />
                    <label
                      className="muiStepperOptionLabel"
                      htmlFor={`option${optionIndex}`}
                    >
                      {option}
                    </label>
                  </div>
                ))}
              </div>

              <Box className="muiStepperButtons" sx={{ mb: 2 }}>
                {activeStep > 0 && (
                  <Button
                    disabled={index === 0}
                    onClick={() => handleBack()}
                    sx={{ mt: 1, mr: 1 }}
                    className="muiStepperSecondaryBtn"
                  >
                    {t('buttons.back')}
                  </Button>
                )}
                <Button
                  variant="contained"
                  className="muiStepperPrimaryBtn"
                  onClick={() => {
                    if (index === combinedQuestions.length - 1) {
                      handleSubmit();
                    } else {
                      handleNext();
                    }
                  }}
                  sx={{ mt: 1, mr: 1 }}
                  disabled={selectedOptions[index] === null || showLoader}
                >
                  {showLoader ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : index === combinedQuestions.length - 1 ? (
                    t('buttons.submit')
                  ) : (
                    t('buttons.next')
                  )}
                </Button>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === combinedQuestions.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you're finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}

      {incompleteTestDialogue && (
        <PopUp
          type="Content"
          visible={incompleteTestDialogue}
          content={t('popUps.incompletePopup.content')}
          ActionLabel={t('popUps.incompletePopup.button')}
          onHide={() => setIncompleteTestDialogue(false)}
        />
      )}
      {completeTestDialogue && (
        <PopUp
          type="Content"
          visible={completeTestDialogue}
          content={t('popUps.incompletePopup.content')}
          ActionLabel={t('popUps.incompletePopup.button')}
          onHide={() => setCompleteTestDialogue(false)}
          handlePrimaryAction={() => handleSubmit()}
        />
      )}
      <PopUp
        header={t('popUps.showResultPopup.skillstitle')}
        visible={popUp}
        type={'BarGraph'}
        data={chartData}
        details={testDetails}
        onHide={() => setPopUp(false)}
        handleSecondaryAction={handleReturnToTestPage}
        CancelLabel={t('buttons.back')}
        CenterText={false}
        testType="skills"
      />

      {showContinueDialog && (
        <PopUp
          type="Content"
          visible={showContinueDialog}
          content={t('popUps.continueDialog.content')}
          ActionLabel={t('popUps.continueDialog.button')}
          onHide={() => setShowContinueDialog(false)}
          onCancel={() => handleDialogDecision('restart')}
          handlePrimaryAction={() => handleDialogDecision('continue')}
        />
      )}

      {purchasePopUp && (
        <PopUp
          type={'Purchase'}
          header={t('popUps.purchasePopUp.header')}
          visible={purchasePopUp}
          content={t('popUps.purchasePopUp.content')}
          //handlePrimaryAction={() => handleAccessUpdate()}
          //ActionLabel={'Api Request'}
          ActionLabel={t('popUps.purchasePopUp.text')}
          handlePrimaryAction={() => handleSendWhatsappRequest()}
          //handleSecondaryAction={() => setPurchasePopUp(false)}
          CancelLabel={t('buttons.back')}
          handleSecondaryAction={() => handleReturnToTestPage()}
        />
      )}
      {warningPopUp && (
        <PopUp
          id="warningPopup"
          header={t('popUps.warningPopUp.title')}
          type="Warning"
          visible={warningPopUp}
          content={t('popUps.warningPopUp.content')}
          handleThirdAction={() => setWarningPopUp(false)}
          WarningLabel={t('popUps.warningPopUp.agree')}
        />
      )}
      {accessQuiz && (
        <PopUp
          type={'Purchase'}
          header={t('popUps.purchasePopUp.header')}
          visible={accessQuiz}
          content={t('popUps.purchasePopUp.content')}
          //handlePrimaryAction={() => handleAccessUpdate()}
          //ActionLabel={'Api Request'}
          ActionLabel={t('popUps.purchasePopUp.text')}
          handlePrimaryAction={() => handleSendWhatsappRequest()}
          //handleSecondaryAction={() => setPurchasePopUp(false)}
          CancelLabel={t('buttons.back')}
          handleSecondaryAction={() => handleReturnToTestPage()}
        />
      )}

      {zeroResultPopUp && (
        <PopUp
          id="zeroResultPopUp"
          header={t('popUps.showResultPopup.noResulttitle')}
          type={'ZerResultPopUp'}
          visible={zeroResultPopUp}
          content={t('popUps.showResultPopup.noResultSkillsContent')}
          CancelLabel={t('buttons.back')}
          handleSecondaryAction={() => handleReturnToTestPage()}
        />
      )}
      {
        <PopUp
          type="Content"
          visible={showErrorPopUp}
          content={t('popUps.errorMsg')}
        />
      }
      <Backdrop open={loading} style={{ zIndex: 1200 }}>
        <CircularProgress size={60} />
      </Backdrop>
    </Box>
  );
}
