import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MenuBar from './MenuBar';

const Header = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const menuBar = document.querySelector('.menuBar');
    menuBar.classList.add('slideDown');

    const containerHolder = document.querySelector('.contentHeader');
    containerHolder.classList.add('fadeIn');
  }, []);

  return (
    <div className="mainHeader">
      <div className="menuBar">
        <MenuBar />
      </div>
      <div className="containerHeaderHolder" id="headerContainer">
        <div className="contentHeader">
          <div className="main-header highlight">{t('header.mainOne')}</div>
          <div className="main-header highlight">{t('header.mainTwo')}</div>
          <div className="sub-header">{t('header.sub')}</div>
        </div>
      </div>
    </div>
  );
};

export default Header;
