const skillsEnTestData = {
  Creativity: [
    {
      id: 43,
      question: 'When given a project, which approach do you prefer?',
      options: [
        'Stick to the guidelines and instructions.',
        'Follow what has been done before.',
        'Add my twist to make it unique.',
        'Do the minimum required to complete it.',
      ],
      answer: 'Add my twist to make it unique.',
    },
    {
      id: 44,
      question:
        'What type of activities do you enjoy the most in your free time?',
      options: [
        'Reading books and watching movies.',
        'Solving puzzles and playing strategy games.',
        'Drawing, painting, or crafting.',
        'Playing sports and physical activities.',
      ],
      answer: 'Drawing, painting, or crafting.',
    },
    {
      id: 45,
      question: 'Which type of project do you feel most excited about?',
      options: [
        'A project with clear guidelines and outcomes.',
        'A group project with collaborative efforts.',
        'An open-ended project where you can explore new ideas.',
        'A project that requires detailed analysis and reporting.',
      ],
      answer: 'An open-ended project where you can explore new ideas.',
    },
    {
      id: 46,
      question: 'How do you express your creativity in your daily life?',
      options: [
        'By organizing and planning tasks efficiently.',
        'By trying new recipes or experimenting in the kitchen.',
        'By writing stories or creating art.',
        'By maintaining a detailed journal or diary.',
      ],
      answer: 'By writing stories or creating art.',
    },
    {
      id: 47,
      question:
        'How do you handle situations where traditional methods are not working?',
      options: [
        'Persist with the traditional methods until they work.',
        'Seek advice from an expert.',
        'Experiment with different, unconventional methods.',
        'Look for a tried-and-true solution from past experiences.',
      ],
      answer: 'Experiment with different, unconventional methods.',
    },
    {
      id: 48,
      question: 'If you had a day with no obligations, how would you spend it?',
      options: [
        'Organizing and cleaning your space.',
        'Participating in a community event or volunteering.',
        'Exploring a new hobby or creative activity.',
        'Catching up on reading or studying.',
      ],
      answer: 'Exploring a new hobby or creative activity.',
    },
    {
      id: 49,
      question: 'Where do you find inspiration for your ideas?',
      options: [
        'From structured research and data.',
        'From conversations with others.',
        'From nature, art, or personal experiences.',
        'From books, articles, or documentaries.',
      ],
      answer: 'From nature, art, or personal experiences.',
    },
  ],
  Communication: [
    {
      id: 50,
      question: 'When conveying a message:',
      options: [
        'Use complex vocabulary to impress the listener.',
        'Speak clearly and concisely, using simple language when appropriate.',
        'Interrupt others to make their point.',
        'Use specific sentences to your field without explaining it.',
      ],
      answer:
        'Speak clearly and concisely, using simple language when appropriate.',
    },
    {
      id: 51,
      question: 'When someone talks to you:',
      options: [
        'You will avoid eye contact.',
        'You will try to get distracted by tapping your fingers, for example.',
        'You will maintain an appropriate body posture.',
        'You will check your phone frequently.',
      ],
      answer: 'You will maintain an appropriate body posture.',
    },
    {
      id: 52,
      question: 'What does active listening involve in your point of view?',
      options: [
        'Waiting for your turn to speak without paying attention to the speaker.',
        'Interrupting the speaker with your opinions.',
        "Focusing on the speaker's words, and asking clarifying questions.",
        'Thinking about what you want to say next instead of listening.',
      ],
      answer:
        "Focusing on the speaker's words, and asking clarifying questions.",
    },
    {
      id: 53,
      question: 'When someone shares with you a problem:',
      options: [
        'You will dismiss their feelings or concerns.',
        'You will interrupt them to offer immediate solutions.',
        'You will acknowledge their feelings and show understanding.',
        'You will ignore their emotional cues and focus only on facts.',
      ],
      answer: 'You will acknowledge their feelings and show understanding.',
    },
    {
      id: 54,
      question: 'What is your communication style?',
      options: [
        'You always maintain the same communication style regardless of the situation.',
        'You adapt your tone, language, and approach based on the audience and context.',
        'You insist on your own preferred method of communication.',
        'You avoid difficult conversations altogether.',
      ],
      answer:
        'You adapt your tone, language, and approach based on the audience and context.',
    },
    {
      id: 55,
      question: 'When I disagree with someone:',
      options: [
        'Avoid the discussion.',
        'Try to convince others of my point of view.',
        'Discuss the conflict and be able to change my idea if others have a better idea than me.',
        'Get nervous and uncomfortable.',
      ],
      answer:
        'Discuss the conflict and be able to change my idea if others have a better idea than me.',
    },
  ],
  'Stress Management': [
    {
      id: 56,
      question:
        'When faced with a stressful situation, what will be your reaction?',
      options: [
        'Panic and become overwhelmed.',
        'Take deep breaths and try to calm down before taking action.',
        'Immediately confront the source of stress without assessing the situation.',
        'Ignore the stress and hope it goes away on its own.',
      ],
      answer: 'Take deep breaths and try to calm down before taking action.',
    },
    {
      id: 57,
      question: 'How would you act if someone is stressed?',
      options: [
        'Bottle up your emotions and avoid expressing them.',
        'Recognize their emotions and acknowledge when they are feeling stressed.',
        'Blame them for their stress without reflecting on their actions.',
        'Rely solely on external factors to regulate their stress levels.',
      ],
      answer:
        'Recognize their emotions and acknowledge when they are feeling stressed.',
    },
    {
      id: 58,
      question: 'How do you seek support when you are stressed?',
      options: [
        'Keep stress to yourself without sharing it with others.',
        'Talk to friends, family, or professionals for perspective and emotional support.',
        'Ignore advice and handle all stress alone.',
        'Turn to unhealthy habits to cope with stress.',
      ],
      answer:
        'Talk to friends, family, or professionals for perspective and emotional support.',
    },
    {
      id: 59,
      question:
        'If you worked hard on a project and the laptop is damaged, and you had to redo your work, what would you do?',
      options: [
        'Take a break and redo the work to meet the project deadline.',
        'Talk to the professor and let them know about the incident.',
        'Redo the work without considering the project deadline.',
        "Don't take any action.",
      ],
      answer: 'Take a break and redo the work to meet the project deadline.',
    },
    {
      id: 60,
      question:
        'If you met someone crying and they shared their problem with you:',
      options: [
        'You will be stressed and think about their problem constantly.',
        'You will try to help them but it will not affect your life and thoughts.',
        "You don't care about others' emotions.",
      ],
      answer:
        'You will try to help them but it will not affect your life and thoughts.',
    },
  ],
  'Time Management': [
    {
      id: 61,
      question:
        'Do you prioritize and plan tasks effectively, often completing them well before the deadline?',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 62,
      question:
        'When you schedule an outing with your friends, do you arrive on time?',
      options: ['Yes', 'No'],
      answer: 'Yes',
    },
    {
      id: 63,
      question: 'What do you do if a task is taking longer than expected?',
      options: [
        'Leave it unfinished and come back later.',
        'Reassess and adjust my schedule as needed.',
        'Continue working until it’s done, regardless of other commitments.',
      ],
      answer: 'Reassess and adjust my schedule as needed.',
    },
    {
      id: 64,
      question: 'How do you decide how much time to allocate to each task?',
      options: [
        'Based on how much I enjoy the task.',
        'Equally, regardless of the task’s importance.',
        'Based on the task’s priority and deadline.',
        'Based on the task’s difficulty level.',
      ],
      answer: 'Based on the task’s priority and deadline.',
    },
    {
      id: 65,
      question:
        'If a friend asks for help while you’re working on an important task, what do you do?',
      options: [
        'Drop my task and help them immediately.',
        'Tell them I can’t help and continue working.',
        'Schedule a time to help them after I finish my task.',
        'Try to help them while continuing my work.',
      ],
      answer: 'Schedule a time to help them after I finish my task.',
    },
  ],
  Adaptability: [
    {
      id: 66,
      question: 'When faced with an unexpected change at work, you:',
      options: [
        'Resist the change and continue doing things the old way.',
        'Feel anxious but try to adapt to the new situation.',
        'Embrace the change and look for ways to benefit from it.',
        'Complain about the change and hope it gets reversed.',
      ],
      answer: 'Embrace the change and look for ways to benefit from it.',
    },
    {
      id: 67,
      question:
        'If your usual method of completing a task suddenly becomes unavailable, you:',
      options: [
        'Wait until the usual method is available again.',
        'Get frustrated and struggle to move forward.',
        'Seek alternative methods to complete the task.',
        'Delegate the task to someone else to avoid dealing with the issue.',
      ],
      answer: 'Seek alternative methods to complete the task.',
    },
    {
      id: 68,
      question:
        'How do you approach learning new skills required for your job or course?',
      options: [
        'Avoid learning new skills and stick to what you already know.',
        'Reluctantly learn new skills but struggle with the process.',
        'Eagerly learn new skills and apply them to improve your performance.',
        'Learn new skills only when necessary.',
      ],
      answer:
        'Eagerly learn new skills and apply them to improve your performance.',
    },
    {
      id: 69,
      question: 'When dealing with uncertainty in a project, you:',
      options: [
        'Feel paralyzed and unable to make decisions.',
        'Worry excessively about the unknowns.',
        'Stay calm and gather information to make informed decisions.',
        'Ignore the uncertainty and hope it resolves itself.',
      ],
      answer: 'Stay calm and gather information to make informed decisions.',
    },
    {
      id: 70,
      question: 'How do you respond to constructive feedback or criticism?',
      options: [
        'Take it personally and become defensive.',
        'Acknowledge it but make no changes.',
        'Appreciate it and use it to improve your performance.',
        'Dismiss it as irrelevant or incorrect.',
      ],
      answer: 'Appreciate it and use it to improve your performance.',
    },
  ],
  'Emotional Intelligence': [
    {
      id: 71,
      question:
        'When you experience strong emotions, what is your typical response?',
      options: [
        "I often don't notice them until they are overwhelming.",
        'I recognize them and understand why I am feeling that way.',
        'I ignore them and hope they go away.',
        'I express them without considering the consequences.',
      ],
      answer: 'I recognize them and understand why I am feeling that way.',
    },
    {
      id: 72,
      question: 'How do you handle frustration when things don’t go your way?',
      options: [
        'I lose my temper and become upset.',
        'I remain calm and try to understand the situation.',
        'I keep my feelings to myself but feel stressed.',
        'I avoid the situation and move on without addressing it.',
      ],
      answer: 'I remain calm and try to understand the situation.',
    },
    {
      id: 73,
      question: 'What drives you to achieve your goals?',
      options: [
        'External rewards like money or recognition.',
        'A personal sense of accomplishment and growth.',
        'The need to prove others wrong.',
        'Avoiding failure and criticism.',
      ],
      answer: 'A personal sense of accomplishment and growth.',
    },
    {
      id: 74,
      question:
        'When a conflict arises, what is your approach to resolving it?',
      options: [
        'I avoid the conflict and hope it resolves itself.',
        'I address the issue directly and seek a mutually beneficial solution.',
        'I let the others have their way to avoid confrontation.',
        'I confront and insist on my viewpoint.',
      ],
      answer:
        'I address the issue directly and seek a mutually beneficial solution.',
    },
    {
      id: 75,
      question: 'How well do you pick up on the emotions of others?',
      options: [
        'I usually don’t notice unless they tell me directly.',
        'I can often sense how others are feeling through their body language and tone of voice.',
        'I sometimes notice but am unsure what to do about it.',
        'I notice but tend to misinterpret their feelings.',
      ],
      answer:
        'I can often sense how others are feeling through their body language and tone of voice.',
    },
  ],
  'Problem Solving': [
    {
      id: 76,
      question: 'How do you approach breaking down a complex problem?',
      options: [
        'Focus on one aspect and ignore the rest.',
        'Break it into smaller, manageable parts to analyze each one.',
        'Tackle it all at once to get it over with quickly.',
        'Wait for someone else to take the lead.',
      ],
      answer: 'Break it into smaller, manageable parts to analyze each one.',
    },
    {
      id: 77,
      question: 'When generating potential solutions, you:',
      options: [
        'Stick to what has worked in the past.',
        'Brainstorm multiple options, considering both conventional and creative ideas.',
        'Choose the first solution that comes to mind.',
        'Avoid overthinking and hope the problem goes away.',
      ],
      answer:
        'Brainstorm multiple options, considering both conventional and creative ideas.',
    },
    {
      id: 78,
      question: 'How do you evaluate the potential solutions to a problem?',
      options: [
        'Choose the easiest solution to implement.',
        'Weigh the pros and cons of each option carefully.',
        'Go with your gut feeling without much analysis.',
        'Ask someone else to make the decision.',
      ],
      answer: 'Weigh the pros and cons of each option carefully.',
    },
    {
      id: 79,
      question: 'When making a decision, you typically:',
      options: [
        'Decide quickly to avoid wasting time.',
        'Take time to gather all necessary information and consider the implications.',
        'Let others decide for you to avoid responsibility.',
        'Choose the option that requires the least effort.',
      ],
      answer:
        'Take time to gather all necessary information and consider the implications.',
    },
    {
      id: 80,
      question: 'How do you handle problem-solving in a team setting?',
      options: [
        'Take control and dictate the solution to the team.',
        'Encourage input from all team members and collaborate on the best solution.',
        'Let others handle it and avoid contributing.',
        'Go along with whatever the majority decides without question.',
      ],
      answer:
        'Encourage input from all team members and collaborate on the best solution.',
    },
  ],
};

export default skillsEnTestData;

export const skillsEnThresholdScoring = {
  Creativity: {
    thresholds: {
      20: 'Creativity is the ability to generate new and innovative ideas, solutions, or approaches. It involves thinking outside the box, breaking away from traditional patterns, and seeing problems or opportunities from a fresh perspective. ',
      30: 'Creativity is the ability to generate new and innovative ideas, solutions, or approaches. It involves thinking outside the box, breaking away from traditional patterns, and seeing problems or opportunities from a fresh perspective. ',
      40: 'Creativity is the ability to generate new and innovative ideas, solutions, or approaches. It involves thinking outside the box, breaking away from traditional patterns, and seeing problems or opportunities from a fresh perspective. ',
      50: 'Creativity is the ability to generate new and innovative ideas, solutions, or approaches. It involves thinking outside the box, breaking away from traditional patterns, and seeing problems or opportunities from a fresh perspective. ',
      60: 'Creativity is the ability to generate new and innovative ideas, solutions, or approaches. It involves thinking outside the box, breaking away from traditional patterns, and seeing problems or opportunities from a fresh perspective. ',
      70: 'Creativity is the ability to generate new and innovative ideas, solutions, or approaches. It involves thinking outside the box, breaking away from traditional patterns, and seeing problems or opportunities from a fresh perspective. ',
      75: 'Creativity is the ability to generate new and innovative ideas, solutions, or approaches. It involves thinking outside the box, breaking away from traditional patterns, and seeing problems or opportunities from a fresh perspective. ',
      80: 'Creativity is the ability to generate new and innovative ideas, solutions, or approaches. It involves thinking outside the box, breaking away from traditional patterns, and seeing problems or opportunities from a fresh perspective. ',
    },
  },
  Communication: {
    thresholds: {
      20: 'Communication skills refer to the ability to convey information clearly and effectively through verbal, non-verbal, and written methods. They involve active listening, empathy, clarity, and the ability to adapt messages to different audiences. Strong communication skills are essential for building relationships, resolving conflicts, and achieving goals in both personal and professional settings.',
      30: 'Communication skills refer to the ability to convey information clearly and effectively through verbal, non-verbal, and written methods. They involve active listening, empathy, clarity, and the ability to adapt messages to different audiences. Strong communication skills are essential for building relationships, resolving conflicts, and achieving goals in both personal and professional settings.',
      40: 'Communication skills refer to the ability to convey information clearly and effectively through verbal, non-verbal, and written methods. They involve active listening, empathy, clarity, and the ability to adapt messages to different audiences. Strong communication skills are essential for building relationships, resolving conflicts, and achieving goals in both personal and professional settings.',
      50: 'Communication skills refer to the ability to convey information clearly and effectively through verbal, non-verbal, and written methods. They involve active listening, empathy, clarity, and the ability to adapt messages to different audiences. Strong communication skills are essential for building relationships, resolving conflicts, and achieving goals in both personal and professional settings.',
      60: 'Communication skills refer to the ability to convey information clearly and effectively through verbal, non-verbal, and written methods. They involve active listening, empathy, clarity, and the ability to adapt messages to different audiences. Strong communication skills are essential for building relationships, resolving conflicts, and achieving goals in both personal and professional settings.',
      70: 'Communication skills refer to the ability to convey information clearly and effectively through verbal, non-verbal, and written methods. They involve active listening, empathy, clarity, and the ability to adapt messages to different audiences. Strong communication skills are essential for building relationships, resolving conflicts, and achieving goals in both personal and professional settings.',
      75: 'Communication skills refer to the ability to convey information clearly and effectively through verbal, non-verbal, and written methods. They involve active listening, empathy, clarity, and the ability to adapt messages to different audiences. Strong communication skills are essential for building relationships, resolving conflicts, and achieving goals in both personal and professional settings.',
      80: 'Communication skills refer to the ability to convey information clearly and effectively through verbal, non-verbal, and written methods. They involve active listening, empathy, clarity, and the ability to adapt messages to different audiences. Strong communication skills are essential for building relationships, resolving conflicts, and achieving goals in both personal and professional settings.',
    },
  },
  'Stress Management': {
    thresholds: {
      20: 'Stress management refers to the techniques and strategies used to handle stress healthily and productively. It involves recognizing the sources of stress, developing coping mechanisms, and maintaining a positive attitude. ',
      30: 'Stress management refers to the techniques and strategies used to handle stress healthily and productively. It involves recognizing the sources of stress, developing coping mechanisms, and maintaining a positive attitude. ',
      40: 'Stress management refers to the techniques and strategies used to handle stress healthily and productively. It involves recognizing the sources of stress, developing coping mechanisms, and maintaining a positive attitude. ',
      50: 'Stress management refers to the techniques and strategies used to handle stress healthily and productively. It involves recognizing the sources of stress, developing coping mechanisms, and maintaining a positive attitude. ',
      60: 'Stress management refers to the techniques and strategies used to handle stress healthily and productively. It involves recognizing the sources of stress, developing coping mechanisms, and maintaining a positive attitude. ',
      70: 'Stress management refers to the techniques and strategies used to handle stress healthily and productively. It involves recognizing the sources of stress, developing coping mechanisms, and maintaining a positive attitude. ',
      75: 'Stress management refers to the techniques and strategies used to handle stress healthily and productively. It involves recognizing the sources of stress, developing coping mechanisms, and maintaining a positive attitude. ',
      80: 'Stress management refers to the techniques and strategies used to handle stress healthily and productively. It involves recognizing the sources of stress, developing coping mechanisms, and maintaining a positive attitude. ',
    },
  },
  'Time Management': {
    thresholds: {
      20: 'Time management is the skill of organizing and planning how to divide your time effectively between various activities. It involves prioritizing tasks, setting goals, scheduling work, and efficiently allocating time to maximize productivity and achieve objectives. ',
      30: 'Time management is the skill of organizing and planning how to divide your time effectively between various activities. It involves prioritizing tasks, setting goals, scheduling work, and efficiently allocating time to maximize productivity and achieve objectives. ',
      40: 'Time management is the skill of organizing and planning how to divide your time effectively between various activities. It involves prioritizing tasks, setting goals, scheduling work, and efficiently allocating time to maximize productivity and achieve objectives. ',
      50: 'Time management is the skill of organizing and planning how to divide your time effectively between various activities. It involves prioritizing tasks, setting goals, scheduling work, and efficiently allocating time to maximize productivity and achieve objectives. ',
      60: 'Time management is the skill of organizing and planning how to divide your time effectively between various activities. It involves prioritizing tasks, setting goals, scheduling work, and efficiently allocating time to maximize productivity and achieve objectives. ',
      70: 'Time management is the skill of organizing and planning how to divide your time effectively between various activities. It involves prioritizing tasks, setting goals, scheduling work, and efficiently allocating time to maximize productivity and achieve objectives. ',
      75: 'Time management is the skill of organizing and planning how to divide your time effectively between various activities. It involves prioritizing tasks, setting goals, scheduling work, and efficiently allocating time to maximize productivity and achieve objectives. ',
      80: 'Time management is the skill of organizing and planning how to divide your time effectively between various activities. It involves prioritizing tasks, setting goals, scheduling work, and efficiently allocating time to maximize productivity and achieve objectives. ',
    },
  },
  Adaptability: {
    thresholds: {
      20: 'Adaptability is the ability to adjust to new conditions, changes, or challenges. It involves being open to new ideas, flexible in the face of uncertainty, and resilient when confronted with setbacks. ',
      30: 'Adaptability is the ability to adjust to new conditions, changes, or challenges. It involves being open to new ideas, flexible in the face of uncertainty, and resilient when confronted with setbacks. ',
      40: 'Adaptability is the ability to adjust to new conditions, changes, or challenges. It involves being open to new ideas, flexible in the face of uncertainty, and resilient when confronted with setbacks. ',
      50: 'Adaptability is the ability to adjust to new conditions, changes, or challenges. It involves being open to new ideas, flexible in the face of uncertainty, and resilient when confronted with setbacks. ',
      60: 'Adaptability is the ability to adjust to new conditions, changes, or challenges. It involves being open to new ideas, flexible in the face of uncertainty, and resilient when confronted with setbacks. ',
      70: 'Adaptability is the ability to adjust to new conditions, changes, or challenges. It involves being open to new ideas, flexible in the face of uncertainty, and resilient when confronted with setbacks. ',
      75: 'Adaptability is the ability to adjust to new conditions, changes, or challenges. It involves being open to new ideas, flexible in the face of uncertainty, and resilient when confronted with setbacks. ',
      80: 'Adaptability is the ability to adjust to new conditions, changes, or challenges. It involves being open to new ideas, flexible in the face of uncertainty, and resilient when confronted with setbacks. ',
    },
  },
  'Emotional Intelligence': {
    thresholds: {
      20: 'Emotional intelligence is the ability to recognize, understand, and manage your own emotions and the emotions of others. It includes skills such as empathy, self-awareness, self-regulation, motivation, and social skills.',
      30: 'Emotional intelligence is the ability to recognize, understand, and manage your own emotions and the emotions of others. It includes skills such as empathy, self-awareness, self-regulation, motivation, and social skills.',
      40: 'Emotional intelligence is the ability to recognize, understand, and manage your own emotions and the emotions of others. It includes skills such as empathy, self-awareness, self-regulation, motivation, and social skills.',
      50: 'Emotional intelligence is the ability to recognize, understand, and manage your own emotions and the emotions of others. It includes skills such as empathy, self-awareness, self-regulation, motivation, and social skills.',
      60: 'Emotional intelligence is the ability to recognize, understand, and manage your own emotions and the emotions of others. It includes skills such as empathy, self-awareness, self-regulation, motivation, and social skills.',
      70: 'Emotional intelligence is the ability to recognize, understand, and manage your own emotions and the emotions of others. It includes skills such as empathy, self-awareness, self-regulation, motivation, and social skills.',
      75: 'Emotional intelligence is the ability to recognize, understand, and manage your own emotions and the emotions of others. It includes skills such as empathy, self-awareness, self-regulation, motivation, and social skills.',
      80: 'Emotional intelligence is the ability to recognize, understand, and manage your own emotions and the emotions of others. It includes skills such as empathy, self-awareness, self-regulation, motivation, and social skills.',
    },
  },
  'Problem Solving': {
    thresholds: {
      20: 'Problem solving is the process of identifying, analyzing, and resolving issues or challenges. It involves understanding the root cause of a problem, brainstorming potential solutions, evaluating options, and implementing the best course of action.  ',
      30: 'Problem solving is the process of identifying, analyzing, and resolving issues or challenges. It involves understanding the root cause of a problem, brainstorming potential solutions, evaluating options, and implementing the best course of action.  ',
      40: 'Problem solving is the process of identifying, analyzing, and resolving issues or challenges. It involves understanding the root cause of a problem, brainstorming potential solutions, evaluating options, and implementing the best course of action.  ',
      50: 'Problem solving is the process of identifying, analyzing, and resolving issues or challenges. It involves understanding the root cause of a problem, brainstorming potential solutions, evaluating options, and implementing the best course of action.  ',
      60: 'Problem solving is the process of identifying, analyzing, and resolving issues or challenges. It involves understanding the root cause of a problem, brainstorming potential solutions, evaluating options, and implementing the best course of action.  ',
      70: 'Problem solving is the process of identifying, analyzing, and resolving issues or challenges. It involves understanding the root cause of a problem, brainstorming potential solutions, evaluating options, and implementing the best course of action.  ',
      75: 'Problem solving is the process of identifying, analyzing, and resolving issues or challenges. It involves understanding the root cause of a problem, brainstorming potential solutions, evaluating options, and implementing the best course of action.  ',
      80: 'Problem solving is the process of identifying, analyzing, and resolving issues or challenges. It involves understanding the root cause of a problem, brainstorming potential solutions, evaluating options, and implementing the best course of action.  ',
    },
  },
};
