import React from 'react';
import AnimatedTitleLoader from '../SharedComponents/AnimatedTitleLoader';

const LoaderPage = () => {
  return (
    <div className="loaderContainer">  
      <AnimatedTitleLoader name={'Start✓Right'} />
    </div>
  );
};

export default LoaderPage;
