//#region Imports
import React, { useEffect, useState } from 'react';
import MenuBar from '../../../Header/MenuBar';
import useHasAccess from '../../../../hooks/useHasAccess';
import { useTranslation } from 'react-i18next';
import skillsEnTestData, {
  skillsEnThresholdScoring,
} from '../../../../Data/skillsEnQuestions';
import skillsArTestData, {
  skillsArThresholdScoring,
} from '../../../../Data/skillsArQuestions';
import Quiz from '../../../SharedComponents/Quiz';
import { useLocation, useNavigate } from 'react-router-dom';
//#endregion

const SkillsTestPage = () => {
  //#region States
  const { handleUpdateTestAccess } = useHasAccess();
  const { i18n, t } = useTranslation();
  const [testData, setTestData] = useState(skillsEnTestData);
  const [testDescription, setTestDescription] = useState(
    skillsEnThresholdScoring
  );
  const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';
  const navigate = useNavigate();
  const location = useLocation();
  //#endregion

  //#region To change the data between En and Ar on change of language
  useEffect(() => {
    const currentLanguage =
      i18n.language || localStorage.getItem('i18nextLng') || 'en';
    if (currentLanguage === 'ar') {
      setTestData(skillsArTestData);
      setTestDescription(skillsArThresholdScoring);
      console.log(skillsArTestData);
    } else {
      setTestData(skillsEnTestData);
      setTestDescription(skillsEnThresholdScoring);
    }
  }, [i18n.language]);

  const combineData = (data) => {
    return Object.values(data).flat();
  };
  const combinedQuestions = combineData(testData);
  console.log('Combined Data:: ', combinedQuestions);
  //#endregion

  //#region Authentication (Navigation) and Access to test
  useEffect(() => {
    const authToken = localStorage.getItem('authToken');

    if (authToken == null || authToken === '' || authToken === undefined) {
      navigate('/login', { state: { from: location.pathname }, replace: true });
    }
  }, []);

  const handleTestAccess = async (type) => {
    try {
      await handleUpdateTestAccess(type, [], []);
    } catch (error) {
      console.error('Error updating test access:', error.message);
    }
  };
  //#endregion

  return (
    <div dir={direction}>
      <MenuBar />
      <div className="TestPage">
        {/* <div className="contentTestMobile">
          <p className="content">{t('skillsPage.content')}</p>
        </div> */}
        <div className="contentTestDesktop before">
          {/* <div className="contentHolder">
            <p className="content">{t('skillsPage.content')}</p>
          </div> */}
        </div>
        <div className="stepperHolder">
          <div className="testExplain" dir={direction}>
            <p className="title">{t('skillsPage.title')}</p>
            <p className="subtitle">{t('skillsPage.subtitle')}</p>
            <hr />
          </div>
          <Quiz
            combinedQuestions={combinedQuestions}
            handleTestAccess={() => handleTestAccess('skills')}
            questions={testData}
            description={testDescription}
          />
        </div>
        <div className="contentTestDesktop after">
          <div className="contentHolder">
            {/* <p className="content">{t('skillsPage.content')}</p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillsTestPage;
