import { useCallback, useState } from 'react';
import {
  getUserInfo,
  login,
  register,
  submitFeedback,
  submitRating,
  updateUserAccess,
} from '../api/auth';

export const useAuth = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showErrorPopUp, setShowErrorPopUp] = useState(false);

  const handleLogin = async (credentials) => {
    setLoading(true);
    setError(null);
    setShowErrorPopUp(false);
    try {
      const response = await login(credentials);

      if (response.token) {
        localStorage.setItem('authToken', response.token);
        localStorage.setItem('email', response.email);
        localStorage.setItem('phoneNumber', response.phoneNumber);
        localStorage.setItem('userName', response.username);
        localStorage.setItem('schoolName', response.schoolName);

        setUser({ ...response, token: response.token });
        return { success: true };
      } else {
        throw new Error('Login failed');
      }
    } catch (error) {
      setError(error.message || 'Login failed');
      setShowErrorPopUp(true);
      return { success: false };
    } finally {
      setLoading(false);
    }
  };

  const handleRegister = async (userData) => {
    setLoading(true);
    setError(null);
    setShowErrorPopUp(false);
    try {
      const data = await register(userData);

      if (data.flag === true) {
        setUser(data.user);
        localStorage.setItem('email', data.email);
        return { success: true };
      } else {
        return { success: false, ...data };
      }
    } catch (error) {
      setError(error.message || 'Registration failed');
      setShowErrorPopUp(true);
      return {
        success: false,
        message: error.message || 'Registration failed',
      };
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateAccess = async (emails) => {
    setLoading(true);
    setError(null);
    setShowErrorPopUp(false);
    try {
      const response = await updateUserAccess(emails);
      if (response.hasAccess) {
        return response;
      } else {
        throw new Error('Access update failed');
      }
    } catch (error) {
      setError(error.message);
      setShowErrorPopUp(true);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleGetUserInfo = useCallback(async (email) => {
    setLoading(true);
    setError(null);
    setShowErrorPopUp(false);
    try {
      const response = await getUserInfo(email);
      setUser(response);
      return response;
    } catch (error) {
      setError(error.message);
      setShowErrorPopUp(true);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSubmitRating = async (rating) => {
    setLoading(true);
    try {
      const email = localStorage.getItem('email');
      if (!email) {
        throw new Error('Email not found.');
      }

      // Function to submit only the rating
      await submitRating(email, rating);
    } catch (error) {
      console.error('Error in submitting rating:', error.message);
      setError(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitFeedback = async (feedback) => {
    setLoading(true);
    try {
      const email = localStorage.getItem('email');
      if (!email) {
        throw new Error('Email not found.');
      }

      // Function to submit only the feedback
      await submitFeedback(email, feedback);
    } catch (error) {
      console.error('Error in submitting feedback:', error.message);
      setError(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    user,
    loading,
    error,
    showErrorPopUp,
    handleLogin,
    handleRegister,
    handleUpdateAccess,
    handleGetUserInfo,
    handleSubmitFeedback,
    handleSubmitRating,
  };
};
