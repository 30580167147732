import React, { useEffect, useState } from 'react';
import MissionVision from '../Mission&Vision/MissionVision';
import CoreValues from '../CoreValue/coreValue';
import Test from '../Test/Test';
import Header from '../Header/Header';
import Catalogue from '../Catalogue/Catalogue';
import Services from '../Services/Services';
import ContactUs from '../ContactUs/ContactUs';
import LoaderPage from './LoaderPage';
import Footer from '../Footer/Footer';

const HomePage = () => {
  const [loading, setLoading] = useState(true);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    const loadImages = () => {
      const images = Array.from(document.querySelectorAll('img'));
      let loadedCount = 0;
      const totalImages = images.length;

      if (totalImages === 0) {
        setImagesLoaded(true);
        return Promise.resolve();
      }

      return new Promise((resolve) => {
        images.forEach((image) => {
          if (image.complete) {
            loadedCount++;
            if (loadedCount === totalImages) {
              setImagesLoaded(true);
              resolve();
            }
          } else {
            image.addEventListener('load', () => {
              loadedCount++;
              if (loadedCount === totalImages) {
                setImagesLoaded(true);
                resolve();
              }
            });
          }
        });
      });
    };

    const simulateLoading = () =>
      new Promise((resolve) => setTimeout(resolve, 8000));

    const performLoading = async () => {
      await Promise.all([simulateLoading(), loadImages()]);
      setLoading(false);
      localStorage.setItem('hasSeenLoader', 'true');
    };

    const hasSeenLoader = localStorage.getItem('hasSeenLoader');

    if (!hasSeenLoader) {
      const timer = setTimeout(() => {
        setLoading(false);
        localStorage.setItem('hasSeenLoader', 'true');
      }, 15000);

      performLoading().finally(() => clearTimeout(timer));
    } else {
      performLoading().finally(() => setLoading(false));
    }
  }, []);

  return (
    <div>
      {loading ? (
        <LoaderPage />
      ) : (
        <>
          <Header />
          <MissionVision />
          <CoreValues />
          <Test />
          <Catalogue />
          <ContactUs />
          <Services />
          <Footer />
        </>
      )}
    </div>
  );
};

export default HomePage;
