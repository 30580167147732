const skillsArTestData = {
  'الابداع والابتكار': [
    {
      id: 43,
      question: 'عند تقديم مشروع ما، ما هو النهج الذي تفضله؟',
      options: [
        'التزم بالإرشادات والتعليمات',
        'اتبع ما تم القيام به من قبل',
        'أضف لمسة خاصة بي لجعلها فريدة من نوعها',
        'أقوم بالحد الأدنى المطلوب لإكماله',
      ],
      answer: 'أضف لمسة خاصة بي لجعلها فريدة من نوعها',
    },
    {
      id: 44,
      question: 'ما نوع الأنشطة التي تستمتع بها أكثر في وقت فراغك؟',
      options: [
        'قراءة الكتب ومشاهدة الأفلام',
        'حل الألغاز وممارسة الألعاب الإستراتيجية',
        'الرسم أو اعمال فنية',
        'ممارسة الرياضة والأنشطة البدنية',
      ],
      answer: 'الرسم أو اعمال فنية',
    },
    {
      id: 45,
      question: 'ما هو نوع المشروع الذي تشعر بالإثارة تجاهه أكثر؟',
      options: [
        'مشروع ذو مبادئ توجيهية ونتائج واضحة',
        'مشروع جماعي بجهود تعاونية',
        'مشروع مفتوح حيث يمكنك استكشاف أفكار جديدة',
        'مشروع يتطلب تحليلا مفصلا وإعداد التقارير',
      ],
      answer: 'مشروع مفتوح حيث يمكنك استكشاف أفكار جديدة',
    },
    {
      id: 46,
      question: 'كيف تعبَر عن إبداعك في حياتك اليومية؟',
      options: [
        'من خلال تنظيم وتخطيط المهام',
        'من خلال تجربة وصفات جديدة في المطبخ',
        'من خلال كتابة القصص أو خلق لوحات فنية',
        'من خلال الحفاظ على مجلة أو مذكرات مفصلة',
      ],
      answer: 'من خلال كتابة القصص أو خلق لوحات فنية',
    },
    {
      id: 47,
      question: 'كيف تتعامل مع المواقف التي لا تنجح فيها الطرق التقليدية؟',
      options: [
        'الاستمرار على الطرق التقليدية حتى تنجح',
        'اطلب المشورة من خبير',
        'أقوم بتجربة طرق مختلفة وغير تقليدية',
        'أبحث عن حل مجرب وحقيقي من تجارب الماضي',
      ],
      answer: 'أقوم بتجربة طرق مختلفة وغير تقليدية',
    },
    {
      id: 48,
      question: 'لو كان لديك يوم بلا التزامات كيف ستقضَيه؟',
      options: [
        'تنظيم وتنظيف المساحة الخاصة بك',
        'المشاركة في حدث مجتمعي أو العمل التطوعي',
        'استكشاف هواية جديدة أو نشاط إبداعي',
        'متابعة القراءة أو الدراسة',
      ],
      answer: 'استكشاف هواية جديدة أو نشاط إبداعي',
    },
    {
      id: 49,
      question: 'أين تجد الإلهام لأفكارك؟',
      options: [
        'من البحوث والبيانات المنظمة',
        'من المحادثات مع الآخرين',
        'من الطبيعة أو الفن أو التجارب الشخصية',
        'من كتب أو مقالات أو أفلام وثائقية',
      ],
      answer: 'من الطبيعة أو الفن أو التجارب الشخصية',
    },
  ],
  'فن التواصل': [
    {
      id: 50,
      question: 'عند نقل فكرة',
      options: [
        'استخدم مفردات معقدة لإثارة إعجاب المستمع',
        'اتحدث بوضوح وإيجاز، باستخدام لغة بسيطة عندما يكون الوقت مناسبًا',
        'مقاطعة الآخرين لتوضيح وجهة نظرهم',
        'استخدم جمل محددة في مجال تخصصي من دون شرح',
      ],
      answer: 'اتحدث بوضوح وإيجاز، باستخدام لغة بسيطة عندما يكون الوقت مناسبًا',
    },
    {
      id: 51,
      question: 'عندما يتحدث معك شخص ما',
      options: [
        'تتجنب الاتصال بالعين',
        'تحاول تشتيت انتباهك عن طريق النقر بأصابعك مثلا',
        'سوف تحافظ على وضعية الجسم المناسبة',
        'تقوم بفحص هاتفك بشكل متكرر',
      ],
      answer: 'تحافظ على وضعية الجسم المناسبة',
    },
    {
      id: 52,
      question: 'ما رأيك بالاستماع المتقن؟',
      options: [
        'انتظار دورك في التحدث دون الالتفات للمتحدث',
        'مقاطعة المتحدث بآرائك',
        'التركيز على كلام المتحدث، وطرح الأسئلة التوضيحية',
        'التفكير فيما تريد قوله بدلاً من الاستماع',
      ],
      answer: 'التركيز على كلام المتحدث، وطرح الأسئلة التوضيحية',
    },
    {
      id: 53,
      question: 'عندما يشاركك أحد الأشخاص بمشكلة ما',
      options: [
        'سوف تتغاضى عن مشاعره أو مخاوفه',
        'سوف تقاطعه لتقدم حلولاً فورية',
        'سوف تتفهم مشاعره',
        'سوف تتجاهل مشاعره وتركز فقط على الحقائق',
      ],
      answer: 'سوف تتفهم مشاعره',
    },
    {
      id: 54,
      question: 'ما هو أسلوب التواصل الخاص بك؟',
      options: [
        'تحافظ دائمًا على نفس أسلوب الاتصال بغض النظر عن الموقف',
        'يمكنك تكييف لهجتك ولغتك ونهجك بناءً على المستمعين والسياق',
        'تصرٌ على طريقة الاتصال المفضلة لديك',
        'تتجنب المحادثات الصعبة',
      ],
      answer: 'يمكنك تكييف لهجتك ولغتك ونهجك بناءً على المستمعين والسياق',
    },
    {
      id: 55,
      question: 'عندما أختلف مع شخص ما',
      options: [
        'أتجنب المناقشة',
        'أحاول إقناع الآخرين بوجهة نظري',
        'مناقشتهم والقدرة على تغيير فكرتي إذا كان لدى الآخرين فكرة أفضل مني',
        'عدم الشعور بالارتياح',
      ],
      answer:
        'مناقشتهم والقدرة على تغيير فكرتي إذا كان لدى الآخرين فكرة أفضل مني',
    },
  ],
  'إدارة القلق': [
    {
      id: 56,
      question: 'عندما تواجه موقفا مرهقا، ما هي ردة فعلك؟',
      options: [
        'الذعر',
        'آخذ نفسًا عميقًا واحاول أن أهدأ قبل اتخاذ أي إجراء',
        'مواجهة مصدر التوتر فورًا دون تقييم الموقف',
        'أتجاهل التوتر وآمل أن يختفي من تلقاء نفسه',
      ],
      answer: 'آخذ نفسًا عميقًا واحاول أن أهدأ قبل اتخاذ أي إجراء',
    },
    {
      id: 57,
      question: 'كيف تتصرف إذا كان شخص ما متوترا؟',
      options: [
        'كتم مشاعرك وتجنب التعبير عنها',
        'الإحساس بمشاعره',
        'إلقاء اللوم عليه بسبب ضغوطه دون التفكير في أفعاله',
        'الاعتماد فقط على العوامل الخارجية لتنظيم مستويات التوتر لديه',
      ],
      answer: 'الإحساس بمشاعره',
    },
    {
      id: 58,
      question: 'كيف تطلب الدعم عندما تشعر بالتوتر؟',
      options: [
        'عدم مشاركة مشاعرك مع الآخرين',
        'التحدث مع الأصدقاء أو العائلة أو المهنيين للدعم العاطفي',
        'تجاهل النصائح والتعامل مع كل الضغوطات بمفردك',
        'اللجوء إلى العادات غير الصحية للتغلب على التوتر',
      ],
      answer: 'التحدث مع الأصدقاء أو العائلة أو المهنيين للدعم العاطفي',
    },
    {
      id: 59,
      question:
        'إذا عملت بجد في مشروع ما وتلف الكمبيوتر. وعليك أن تعيد عملك ماذا ستفعل؟',
      options: [
        'تأخذ قسطًا من الراحة وتعيد العمل وتلتزم بالموعد النهائي للمشروع',
        'التحدث مع الأستاذ وإخباره بالحادثة',
        'إعادة العمل دون مراعاة الموعد النهائي للمشروع',
        'لا تتخذ أي إجراء',
      ],
      answer: 'تأخذ قسطًا من الراحة وتعيد العمل وتلتزم بالموعد النهائي للمشروع',
    },
    {
      id: 60,
      question: 'إذا قابلت شخصاً يبكي وشاركك مشكلته',
      options: [
        'سوف تشعر بالتوتر وتفكر طوال الوقت في مشكلته',
        'ستحاول مساعدته لكن ذلك لن يؤثر على حياتك وأفكارك',
        'أنت لا تهتم بمشاعر الآخرين',
      ],
      answer: 'ستحاول مساعدته لكن ذلك لن يؤثر على حياتك وأفكارك',
    },
  ],
  'إدارة الوقت': [
    {
      id: 61,
      question:
        'هل تحدد أولويات المهام وتخطط لها بشكل فعال، وغالباً ما تكملها قبل الموعد النهائي؟',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 62,
      question: 'عندما تحدد موعدًا لنزهة مع أصدقائك، هل تصل في الوقت المحدد؟',
      options: ['نعم', 'لا'],
      answer: 'نعم',
    },
    {
      id: 63,
      question: 'ماذا تفعل إذا استغرقت المهمة وقتًا أطول من المتوقع؟',
      options: [
        'اتركها غير مكتملة وأعود لاحقًا',
        'إعادة تقييم وتعديل الجدول الزمني الخاص بي حسب الحاجة',
        'استمر في العمل حتى الانتهاء منه، بغض النظر عن الالتزامات الأخرى',
      ],
      answer: 'إعادة تقييم وتعديل الجدول الزمني الخاص بي حسب الحاجة',
    },
    {
      id: 64,
      question: 'كيف تحدد مقدار الوقت المخصص لكل مهمة؟',
      options: [
        'بناءً على مدى استمتاعي بالمهمة',
        'بالمثل، بغض النظر عن أهمية المهمة',
        'بناءً على أولوية المهمة والموعد النهائي',
        'بناءً على مستوى صعوبة المهمة',
      ],
      answer: 'بناءً على أولوية المهمة والموعد النهائي',
    },
    {
      id: 65,
      question: 'إذا طلب منك صديق المساعدة أثناء عملك على مهمة، ماذا تفعل؟',
      options: [
        'إسقاط مهمتي ومساعدتهم على الفور',
        'أخبرهم أنني لا أستطيع المساعدة وأواصل العمل',
        'أحدد وقتًا لمساعدتهم بعد أن أنهي مهمتي',
        'أحاول مساعدتهم أثناء مواصلة عملي',
      ],
      answer: 'أحدد وقتًا لمساعدتهم بعد أن أنهي مهمتي',
    },
  ],
  'القدرة على التأقلم': [
    {
      id: 66,
      question: 'عندما تواجه تغييرًا غير متوقع في العمل، فإنك',
      options: [
        'تقاوم التغيير وتستمر في فعل الأشياء بالطريقة القديمة',
        'تشعر بالقلق ولكن تحاول التكيف مع الوضع الجديد',
        'تحتضن التغيير وتبحث عن طرق للاستفادة منه',
        'تشتكي من التغيير وتتمنى أن يتم التراجع عنه',
      ],
      answer: 'تحتضن التغيير وتبحث عن طرق للاستفادة منه',
    },
    {
      id: 67,
      question: 'إذا أصبحت طريقتك المعتادة لإكمال المهمة فجأة غير متاحة، فإنك',
      options: [
        'تنتظر حتى تتوفر الطريقة المعتادة مرة أخرى',
        'تشعر بالإحباط وتكافح من أجل المضي قدمًا ',
        'تبحث عن طرق بديلة لإكمال المهمة',
        'تفويض المهمة لشخص آخر لتجنب التعامل مع المشكلة',
      ],
      answer: 'تبحث عن طرق بديلة لإكمال المهمة',
    },
    {
      id: 68,
      question:
        'كيف تتعامل مع تعلم المهارات الجديدة المطلوبة لعملك أو دورتك الدراسية؟',
      options: [
        'اتجنب تعلم مهارات جديدة والتزم بما أعرفه بالفعل',
        'أتعلم مهارات جديدة على مضض ولكني أواجه صعوبة في هذه العملية',
        'احرص على تعلم مهارات جديدة وتطبيقها لتحسين أدائي',
        'اتعلم مهارات جديدة فقط عند الضرورة القصوى',
      ],
      answer: 'احرص على تعلم مهارات جديدة وتطبيقها لتحسين أدائي',
    },
    {
      id: 69,
      question: 'عند التعامل مع عدم اليقين في المشروع، فإنك',
      options: [
        'تشعر بالعجز وعدم القدرة على اتخاذ القرارات',
        'أقلق بشكل مفرط بشأن المجهول',
        'أحافظ على هدوئي وأجمع المعلومات لاتخاذ قرارات مستنيرة',
        'أتجاهل حالة عدم اليقين',
      ],
      answer: 'أحافظ على هدوئي وأجمع المعلومات لاتخاذ قرارات مستنيرة',
    },
    {
      id: 70,
      question: 'كيف ترد على ردود الفعل البناءة أو النقد؟',
      options: [
        'آخذ الأمر على محمل شخصي وأكن دفاعيًا',
        'اعترف بذلك ولكن لا أقوم بأي تغييرات',
        'أقدّرها واستخدمها لتحسين أدائي',
        'أتجاهلها باعتبارها غير ذات صلة أو غير صحيحة',
      ],
      answer: 'أقدّرها واستخدمها لتحسين أدائي',
    },
  ],
  'الذكاء العاطفي': [
    {
      id: 71,
      question: 'عندما تواجه مشاعر قوية، ما هو رد فعلك النموذجي؟',
      options: [
        'في كثير من الأحيان لا ألاحظها حتى تصبح مؤثرة',
        'أنا أعرفها وأفهم لماذا أشعر بهذه الطريقة',
        'أتجاهلها وأتمنى أن تغيب',
        'أنا أعبر عنها دون النظر في العواقب',
      ],
      answer: 'أنا أعرفها وأفهم لماذا أشعر بهذه الطريقة',
    },
    {
      id: 72,
      question: 'كيف تتعامل مع الإحباط عندما لا تسير الأمور كما تريد؟',
      options: [
        'أفقد أعصابي وأشعر بالانزعاج',
        'أظل هادئًا وأحاول أن أفهم الوضع',
        'أحتفظ بمشاعري لنفسي ولكني أشعر بالتوتر',
        'أتجنب الموقف وأمضي قدمًا دون معالجته',
      ],
      answer: 'أظل هادئًا وأحاول أن أفهم الوضع',
    },
    {
      id: 73,
      question: 'ما الذي يدفعك لتحقيق أهدافك؟',
      options: [
        'المكافآت الخارجية مثل المال أو المكافأة المعنوية',
        'شعور شخصي بالإنجاز والنمو المهني',
        'الحاجة إلى إثبات خطأ الآخرين',
        'تجنب الفشل والانتقادات',
      ],
      answer: 'شعور شخصي بالإنجاز والنمو المهني',
    },
    {
      id: 74,
      question: 'عندما ينشأ نزاع ما، ما هو النهج الذي تتبعه لحله؟',
      options: [
        'أتجنب الصراع وآمل أن يحل بنفسه',
        'أتناول هذه القضية مباشرة وأسعى إلى حل مفيد للطرفين',
        'أسمح للآخرين باتباع طرقهم لتجنب المواجهة',
        'أواجه وأصر على وجهة نظري',
      ],
      answer: 'أتناول هذه القضية مباشرة وأسعى إلى حل مفيد للطرفين',
    },
    {
      id: 75,
      question: 'ما مدى قدرتك على الاحساس مشاعر الآخرين؟',
      options: [
        'عادةً لا ألاحظ ذلك إلا إذا أخبروني مباشرة',
        'أستطيع في كثير من الأحيان أن أشعر بما يشعر به الآخرون من خلال لغة جسدهم ونبرة صوتهم',
        'ألاحظ أحيانًا ولكني غير متأكد مما يجب فعله حيال ذلك',
        'ألاحظ ولكنني أميل إلى إساءة تفسير مشاعرهم',
      ],
      answer:
        'أستطيع في كثير من الأحيان أن أشعر بما يشعر به الآخرون من خلال لغة جسدهم ونبرة صوتهم',
    },
  ],
  'القدرة على حل المشاكل': [
    {
      id: 76,
      question: 'ماذا تعتمد في حل مشكلة معقدة؟',
      options: [
        'أركز على جانب واحد وأتجاهل الباقي',
        'أقوم بتقسيمها إلى أجزاء أصغر لتحليل كل جزء منها',
        'أتعامل مع كل شيء مرة واحدة لأنتهي منه بسرعة',
        'أنتظر حتى يتولى شخص آخر زمام المبادرة',
      ],
      answer: 'أقوم بتقسيمها إلى أجزاء أصغر لتحليل كل جزء منها',
    },
    {
      id: 77,
      question: 'عند توليد حلول محتملة',
      options: [
        'ألتزم بما نجح في الماضي',
        'أقوم بالعصف الذهني لخيارات متعددة، مع الأخذ بعين الاعتبار الأفكار التقليدية والإبداعية',
        'أختار الحل الأول الذي يتبادر إلى ذهني',
        'أتجنب التفكير كثيرًا آملا أن تختفي المشكلة',
      ],
      answer:
        'أقوم بالعصف الذهني لخيارات متعددة، مع الأخذ بعين الاعتبار الأفكار التقليدية والإبداعية',
    },
    {
      id: 78,
      question: 'كيف تقيُم الحلول المحتملة للمشكلة؟',
      options: [
        'أختار الحل الأسهل للتنفيذ',
        'أوازن إيجابيات وسلبيات كل خيار بعناية',
        'أذهب مع شعوري الغريزي دون الكثير من التحليل',
        'أطلب من شخص آخر اتخاذ القرار',
      ],
      answer: 'أوازن إيجابيات وسلبيات كل خيار بعناية',
    },
    {
      id: 79,
      question: 'عند اتخاذ القرار، عادةً ما تقوم بما يلي',
      options: [
        'أقرر بسرعة لتجنب إضاعة الوقت',
        'آخذ وقتًا لجمع كل المعلومات الضرورية والنظر في الآثار المترتبة',
        'أدع الآخرين يقررون عني لتجنب المسؤولية',
        'أختار الخيار الذي يتطلب أقل جهد',
      ],
      answer: 'آخذ وقتًا لجمع كل المعلومات الضرورية والنظر في الآثار المترتبة',
    },
    {
      id: 80,
      question: 'كيف تتعامل مع حل المشكلات في إطار الفريق؟',
      options: [
        'السيطرة وإملاء الحل على الفريق',
        'أشجُع المداخلات من جميع أعضاء الفريق والتعاون للحصول على أفضل الحلول',
        'أدع الآخرين يتعاملون معها وأتجنب التدخٌل',
        'أوافق على ما تقرره الأغلبية دون سؤال',
      ],
      answer:
        'أشجُع المداخلات من جميع أعضاء الفريق والتعاون للحصول على أفضل الحلول',
    },
  ],
};

export default skillsArTestData;

export const skillsArThresholdScoring = {
  'الابداع والابتكار': {
    thresholds: {
      20: 'الإبداع هو القدرة على توليد أفكار أو حلول أو مناهج جديدة ومبتكرة. وهو يتضمن التفكير خارج الصندوق، والابتعاد عن الأنماط التقليدية، ورؤية المشاكل أو الفرص من منظور جديد.',
      30: 'الإبداع هو القدرة على توليد أفكار أو حلول أو مناهج جديدة ومبتكرة. وهو يتضمن التفكير خارج الصندوق، والابتعاد عن الأنماط التقليدية، ورؤية المشاكل أو الفرص من منظور جديد.',
      40: 'الإبداع هو القدرة على توليد أفكار أو حلول أو مناهج جديدة ومبتكرة. وهو يتضمن التفكير خارج الصندوق، والابتعاد عن الأنماط التقليدية، ورؤية المشاكل أو الفرص من منظور جديد.',
      50: 'الإبداع هو القدرة على توليد أفكار أو حلول أو مناهج جديدة ومبتكرة. وهو يتضمن التفكير خارج الصندوق، والابتعاد عن الأنماط التقليدية، ورؤية المشاكل أو الفرص من منظور جديد.',
      60: 'الإبداع هو القدرة على توليد أفكار أو حلول أو مناهج جديدة ومبتكرة. وهو يتضمن التفكير خارج الصندوق، والابتعاد عن الأنماط التقليدية، ورؤية المشاكل أو الفرص من منظور جديد.',
      70: 'الإبداع هو القدرة على توليد أفكار أو حلول أو مناهج جديدة ومبتكرة. وهو يتضمن التفكير خارج الصندوق، والابتعاد عن الأنماط التقليدية، ورؤية المشاكل أو الفرص من منظور جديد.',
      75: 'الإبداع هو القدرة على توليد أفكار أو حلول أو مناهج جديدة ومبتكرة. وهو يتضمن التفكير خارج الصندوق، والابتعاد عن الأنماط التقليدية، ورؤية المشاكل أو الفرص من منظور جديد.',
      80: 'الإبداع هو القدرة على توليد أفكار أو حلول أو مناهج جديدة ومبتكرة. وهو يتضمن التفكير خارج الصندوق، والابتعاد عن الأنماط التقليدية، ورؤية المشاكل أو الفرص من منظور جديد.',
    },
  },
  'فن التواصل': {
    thresholds: {
      20: 'مهارات التواصل هي القدرة على نقل المعلومات بوضوح وفعالية من خلال الأساليب اللفظية وغير اللفظية والمكتوبة. وهي تتضمن الاستماع  والتعاطف والوضوح والقدرة على التواصل مع جماهير مختلفة. تعد مهارات التواصل ضرورية لبناء العلاقات وحل النزاعات وتحقيق الأهداف في كل من البيئات الشخصية والمهنية.',
      30: 'مهارات التواصل هي القدرة على نقل المعلومات بوضوح وفعالية من خلال الأساليب اللفظية وغير اللفظية والمكتوبة. وهي تتضمن الاستماع  والتعاطف والوضوح والقدرة على التواصل مع جماهير مختلفة. تعد مهارات التواصل ضرورية لبناء العلاقات وحل النزاعات وتحقيق الأهداف في كل من البيئات الشخصية والمهنية.',
      40: 'مهارات التواصل هي القدرة على نقل المعلومات بوضوح وفعالية من خلال الأساليب اللفظية وغير اللفظية والمكتوبة. وهي تتضمن الاستماع  والتعاطف والوضوح والقدرة على التواصل مع جماهير مختلفة. تعد مهارات التواصل ضرورية لبناء العلاقات وحل النزاعات وتحقيق الأهداف في كل من البيئات الشخصية والمهنية.',
      50: 'مهارات التواصل هي القدرة على نقل المعلومات بوضوح وفعالية من خلال الأساليب اللفظية وغير اللفظية والمكتوبة. وهي تتضمن الاستماع  والتعاطف والوضوح والقدرة على التواصل مع جماهير مختلفة. تعد مهارات التواصل ضرورية لبناء العلاقات وحل النزاعات وتحقيق الأهداف في كل من البيئات الشخصية والمهنية.',
      60: 'مهارات التواصل هي القدرة على نقل المعلومات بوضوح وفعالية من خلال الأساليب اللفظية وغير اللفظية والمكتوبة. وهي تتضمن الاستماع  والتعاطف والوضوح والقدرة على التواصل مع جماهير مختلفة. تعد مهارات التواصل ضرورية لبناء العلاقات وحل النزاعات وتحقيق الأهداف في كل من البيئات الشخصية والمهنية.',
      70: 'مهارات التواصل هي القدرة على نقل المعلومات بوضوح وفعالية من خلال الأساليب اللفظية وغير اللفظية والمكتوبة. وهي تتضمن الاستماع  والتعاطف والوضوح والقدرة على التواصل مع جماهير مختلفة. تعد مهارات التواصل ضرورية لبناء العلاقات وحل النزاعات وتحقيق الأهداف في كل من البيئات الشخصية والمهنية.',
      75: 'مهارات التواصل هي القدرة على نقل المعلومات بوضوح وفعالية من خلال الأساليب اللفظية وغير اللفظية والمكتوبة. وهي تتضمن الاستماع  والتعاطف والوضوح والقدرة على التواصل مع جماهير مختلفة. تعد مهارات التواصل ضرورية لبناء العلاقات وحل النزاعات وتحقيق الأهداف في كل من البيئات الشخصية والمهنية.',
      80: 'مهارات التواصل هي القدرة على نقل المعلومات بوضوح وفعالية من خلال الأساليب اللفظية وغير اللفظية والمكتوبة. وهي تتضمن الاستماع  والتعاطف والوضوح والقدرة على التواصل مع جماهير مختلفة. تعد مهارات التواصل ضرورية لبناء العلاقات وحل النزاعات وتحقيق الأهداف في كل من البيئات الشخصية والمهنية.',
    },
  },
  'إدارة القلق': {
    thresholds: {
      20: 'تشير إدارة الإجهاد إلى التقنيات والاستراتيجيات المستخدمة للتعامل مع الإجهاد بطريقة صحية ومنتجة. وهي تتضمن التعرف على مصادر الإجهاد وتطوير آليات التكيف والحفاظ على موقف إيجابي.',
      30: 'تشير إدارة الإجهاد إلى التقنيات والاستراتيجيات المستخدمة للتعامل مع الإجهاد بطريقة صحية ومنتجة. وهي تتضمن التعرف على مصادر الإجهاد وتطوير آليات التكيف والحفاظ على موقف إيجابي.',
      40: 'تشير إدارة الإجهاد إلى التقنيات والاستراتيجيات المستخدمة للتعامل مع الإجهاد بطريقة صحية ومنتجة. وهي تتضمن التعرف على مصادر الإجهاد وتطوير آليات التكيف والحفاظ على موقف إيجابي.',
      50: 'تشير إدارة الإجهاد إلى التقنيات والاستراتيجيات المستخدمة للتعامل مع الإجهاد بطريقة صحية ومنتجة. وهي تتضمن التعرف على مصادر الإجهاد وتطوير آليات التكيف والحفاظ على موقف إيجابي.',
      60: 'تشير إدارة الإجهاد إلى التقنيات والاستراتيجيات المستخدمة للتعامل مع الإجهاد بطريقة صحية ومنتجة. وهي تتضمن التعرف على مصادر الإجهاد وتطوير آليات التكيف والحفاظ على موقف إيجابي.',
      70: 'تشير إدارة الإجهاد إلى التقنيات والاستراتيجيات المستخدمة للتعامل مع الإجهاد بطريقة صحية ومنتجة. وهي تتضمن التعرف على مصادر الإجهاد وتطوير آليات التكيف والحفاظ على موقف إيجابي.',
      75: 'تشير إدارة الإجهاد إلى التقنيات والاستراتيجيات المستخدمة للتعامل مع الإجهاد بطريقة صحية ومنتجة. وهي تتضمن التعرف على مصادر الإجهاد وتطوير آليات التكيف والحفاظ على موقف إيجابي.',
      80: 'تشير إدارة الإجهاد إلى التقنيات والاستراتيجيات المستخدمة للتعامل مع الإجهاد بطريقة صحية ومنتجة. وهي تتضمن التعرف على مصادر الإجهاد وتطوير آليات التكيف والحفاظ على موقف إيجابي.',
    },
  },
  'إدارة الوقت': {
    thresholds: {
      20: 'إدارة الوقت هي مهارة تنظيم وتخطيط كيفية تقسيم وقتك بشكل فعال بين الأنشطة المختلفة. وهي تتضمن تحديد أولويات المهام، وتحديد الأهداف، وجدولة العمل، وتخصيص الوقت بكفاءة لتحقيق أقصى قدر من الإنتاجية وتحقيق الأهداف.',
      30: 'إدارة الوقت هي مهارة تنظيم وتخطيط كيفية تقسيم وقتك بشكل فعال بين الأنشطة المختلفة. وهي تتضمن تحديد أولويات المهام، وتحديد الأهداف، وجدولة العمل، وتخصيص الوقت بكفاءة لتحقيق أقصى قدر من الإنتاجية وتحقيق الأهداف.',
      40: 'إدارة الوقت هي مهارة تنظيم وتخطيط كيفية تقسيم وقتك بشكل فعال بين الأنشطة المختلفة. وهي تتضمن تحديد أولويات المهام، وتحديد الأهداف، وجدولة العمل، وتخصيص الوقت بكفاءة لتحقيق أقصى قدر من الإنتاجية وتحقيق الأهداف.',
      50: 'إدارة الوقت هي مهارة تنظيم وتخطيط كيفية تقسيم وقتك بشكل فعال بين الأنشطة المختلفة. وهي تتضمن تحديد أولويات المهام، وتحديد الأهداف، وجدولة العمل، وتخصيص الوقت بكفاءة لتحقيق أقصى قدر من الإنتاجية وتحقيق الأهداف.',
      60: 'إدارة الوقت هي مهارة تنظيم وتخطيط كيفية تقسيم وقتك بشكل فعال بين الأنشطة المختلفة. وهي تتضمن تحديد أولويات المهام، وتحديد الأهداف، وجدولة العمل، وتخصيص الوقت بكفاءة لتحقيق أقصى قدر من الإنتاجية وتحقيق الأهداف.',
      70: 'إدارة الوقت هي مهارة تنظيم وتخطيط كيفية تقسيم وقتك بشكل فعال بين الأنشطة المختلفة. وهي تتضمن تحديد أولويات المهام، وتحديد الأهداف، وجدولة العمل، وتخصيص الوقت بكفاءة لتحقيق أقصى قدر من الإنتاجية وتحقيق الأهداف.',
      75: 'إدارة الوقت هي مهارة تنظيم وتخطيط كيفية تقسيم وقتك بشكل فعال بين الأنشطة المختلفة. وهي تتضمن تحديد أولويات المهام، وتحديد الأهداف، وجدولة العمل، وتخصيص الوقت بكفاءة لتحقيق أقصى قدر من الإنتاجية وتحقيق الأهداف.',
      80: 'إدارة الوقت هي مهارة تنظيم وتخطيط كيفية تقسيم وقتك بشكل فعال بين الأنشطة المختلفة. وهي تتضمن تحديد أولويات المهام، وتحديد الأهداف، وجدولة العمل، وتخصيص الوقت بكفاءة لتحقيق أقصى قدر من الإنتاجية وتحقيق الأهداف.',
    },
  },
  'القدرة على التأقلم': {
    thresholds: {
      20: 'القدرة على التكيف هي القدرة على الانسجام مع الظروف أو التغييرات أو التحديات الجديدة. وهي تتضمن الانفتاح على الأفكار الجديدة، والمرونة في مواجهة عدم اليقين، والقدرة على الصمود في مواجهة النكسات.',
      30: 'القدرة على التكيف هي القدرة على الانسجام مع الظروف أو التغييرات أو التحديات الجديدة. وهي تتضمن الانفتاح على الأفكار الجديدة، والمرونة في مواجهة عدم اليقين، والقدرة على الصمود في مواجهة النكسات.',
      40: 'القدرة على التكيف هي القدرة على الانسجام مع الظروف أو التغييرات أو التحديات الجديدة. وهي تتضمن الانفتاح على الأفكار الجديدة، والمرونة في مواجهة عدم اليقين، والقدرة على الصمود في مواجهة النكسات.',
      50: 'القدرة على التكيف هي القدرة على الانسجام مع الظروف أو التغييرات أو التحديات الجديدة. وهي تتضمن الانفتاح على الأفكار الجديدة، والمرونة في مواجهة عدم اليقين، والقدرة على الصمود في مواجهة النكسات.',
      60: 'القدرة على التكيف هي القدرة على الانسجام مع الظروف أو التغييرات أو التحديات الجديدة. وهي تتضمن الانفتاح على الأفكار الجديدة، والمرونة في مواجهة عدم اليقين، والقدرة على الصمود في مواجهة النكسات.',
      70: 'القدرة على التكيف هي القدرة على الانسجام مع الظروف أو التغييرات أو التحديات الجديدة. وهي تتضمن الانفتاح على الأفكار الجديدة، والمرونة في مواجهة عدم اليقين، والقدرة على الصمود في مواجهة النكسات.',
      75: 'القدرة على التكيف هي القدرة على الانسجام مع الظروف أو التغييرات أو التحديات الجديدة. وهي تتضمن الانفتاح على الأفكار الجديدة، والمرونة في مواجهة عدم اليقين، والقدرة على الصمود في مواجهة النكسات.',
      80: 'القدرة على التكيف هي القدرة على الانسجام مع الظروف أو التغييرات أو التحديات الجديدة. وهي تتضمن الانفتاح على الأفكار الجديدة، والمرونة في مواجهة عدم اليقين، والقدرة على الصمود في مواجهة النكسات.',
    },
  },
  'الذكاء العاطفي': {
    thresholds: {
      20: 'الذكاء العاطفي هو القدرة على التعرف على عواطفك وعواطف الآخرين وفهمها وإدارتها. ويشمل مهارات مثل التعاطف والوعي الذاتي وتنظيم الذات والدافع والمهارات الاجتماعية.',
      30: 'الذكاء العاطفي هو القدرة على التعرف على عواطفك وعواطف الآخرين وفهمها وإدارتها. ويشمل مهارات مثل التعاطف والوعي الذاتي وتنظيم الذات والدافع والمهارات الاجتماعية.',
      40: 'الذكاء العاطفي هو القدرة على التعرف على عواطفك وعواطف الآخرين وفهمها وإدارتها. ويشمل مهارات مثل التعاطف والوعي الذاتي وتنظيم الذات والدافع والمهارات الاجتماعية.',
      50: 'الذكاء العاطفي هو القدرة على التعرف على عواطفك وعواطف الآخرين وفهمها وإدارتها. ويشمل مهارات مثل التعاطف والوعي الذاتي وتنظيم الذات والدافع والمهارات الاجتماعية.',
      60: 'الذكاء العاطفي هو القدرة على التعرف على عواطفك وعواطف الآخرين وفهمها وإدارتها. ويشمل مهارات مثل التعاطف والوعي الذاتي وتنظيم الذات والدافع والمهارات الاجتماعية.',
      70: 'الذكاء العاطفي هو القدرة على التعرف على عواطفك وعواطف الآخرين وفهمها وإدارتها. ويشمل مهارات مثل التعاطف والوعي الذاتي وتنظيم الذات والدافع والمهارات الاجتماعية.',
      75: 'الذكاء العاطفي هو القدرة على التعرف على عواطفك وعواطف الآخرين وفهمها وإدارتها. ويشمل مهارات مثل التعاطف والوعي الذاتي وتنظيم الذات والدافع والمهارات الاجتماعية.',
      80: 'الذكاء العاطفي هو القدرة على التعرف على عواطفك وعواطف الآخرين وفهمها وإدارتها. ويشمل مهارات مثل التعاطف والوعي الذاتي وتنظيم الذات والدافع والمهارات الاجتماعية.',
    },
  },
  'القدرة على حل المشاكل': {
    thresholds: {
      20: 'حل المشكلات هو عملية تحديد المشكلات أو التحديات وتحليلها وإيجاد حل لها. ويتضمن ذلك فهم السبب الجذري للمشكلة، والتفكير في الحلول المحتملة، وتقييم الخيارات، وتنفيذ أفضل مسار للعمل.',
      30: 'حل المشكلات هو عملية تحديد المشكلات أو التحديات وتحليلها وإيجاد حل لها. ويتضمن ذلك فهم السبب الجذري للمشكلة، والتفكير في الحلول المحتملة، وتقييم الخيارات، وتنفيذ أفضل مسار للعمل.',
      40: 'حل المشكلات هو عملية تحديد المشكلات أو التحديات وتحليلها وإيجاد حل لها. ويتضمن ذلك فهم السبب الجذري للمشكلة، والتفكير في الحلول المحتملة، وتقييم الخيارات، وتنفيذ أفضل مسار للعمل.',
      50: 'حل المشكلات هو عملية تحديد المشكلات أو التحديات وتحليلها وإيجاد حل لها. ويتضمن ذلك فهم السبب الجذري للمشكلة، والتفكير في الحلول المحتملة، وتقييم الخيارات، وتنفيذ أفضل مسار للعمل.',
      60: 'حل المشكلات هو عملية تحديد المشكلات أو التحديات وتحليلها وإيجاد حل لها. ويتضمن ذلك فهم السبب الجذري للمشكلة، والتفكير في الحلول المحتملة، وتقييم الخيارات، وتنفيذ أفضل مسار للعمل.',
      70: 'حل المشكلات هو عملية تحديد المشكلات أو التحديات وتحليلها وإيجاد حل لها. ويتضمن ذلك فهم السبب الجذري للمشكلة، والتفكير في الحلول المحتملة، وتقييم الخيارات، وتنفيذ أفضل مسار للعمل.',
      75: 'حل المشكلات هو عملية تحديد المشكلات أو التحديات وتحليلها وإيجاد حل لها. ويتضمن ذلك فهم السبب الجذري للمشكلة، والتفكير في الحلول المحتملة، وتقييم الخيارات، وتنفيذ أفضل مسار للعمل.',
      80: 'حل المشكلات هو عملية تحديد المشكلات أو التحديات وتحليلها وإيجاد حل لها. ويتضمن ذلك فهم السبب الجذري للمشكلة، والتفكير في الحلول المحتملة، وتقييم الخيارات، وتنفيذ أفضل مسار للعمل.',
    },
  },
};
