//#region Imports
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MenuBar from '../../../Header/MenuBar';
import VerticalLinearStepper from '../../../SharedComponents/MuiStepper';
import {
  personalityEnTestData,
  personalityEnThresholdScoring,
} from '../../../../Data/personalityEnQuestions';
import personalityArTestData, {
  personalityArThresholdScoring,
} from '../../../../Data/personalityArQuestions';
import { useAuth } from '../../../../hooks/useAuth';
//#endregion

const PersonalityTestPage = () => {
  //#region States
  const { i18n, t } = useTranslation();
  const [testData, setTestData] = useState(personalityEnTestData);
  const { user } = useAuth();
  const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';
  //#endregion

  //#region To change the data between En and Ar on change of language
  const [testDescription, setTestDescription] = useState(
    personalityEnThresholdScoring
  );
  useEffect(() => {
    const currentLanguage =
      i18n.language || localStorage.getItem('i18nextLng') || 'en';
    if (currentLanguage === 'ar') {
      setTestData(personalityArTestData);
      setTestDescription(personalityArThresholdScoring);
    } else {
      setTestData(personalityEnTestData);
      setTestDescription(personalityEnThresholdScoring);
    }
  }, [i18n.language]);

  const combineData = (data) => {
    return Object.values(data).flat();
  };
  const combinedQuestions = combineData(testData);
  //#endregion

  return (
    <div dir={{ direction: 'ltr' }}>
      <MenuBar />
      <div className="TestPage">
        {/* <div className="contentTestMobile">
          <p className="content">{t('personalityPage.content')}</p>
        </div> */}
        <div className="contentTestDesktop before">
          {/* <div className="contentHolder">
          <p className="content">{t('personalityPage.content')}</p>

          </div> */}
        </div>
        <div className="stepperHolder">
          <div className="testExplain" dir={direction}>
            <p className="title"> {t('personalityPage.title')}</p>
            <p className="subtitle">{t('personalityPage.subtitle')}</p>
            <hr />
          </div>
          <VerticalLinearStepper
            combinedQuestions={combinedQuestions}
            questions={testData}
            description={testDescription}
            type="personality"
            user={user}
          />
        </div>
        <div className="contentTestDesktop after">
          <div className="contentHolder"></div>
        </div>
      </div>
    </div>
  );
};

export default PersonalityTestPage;
