import { useState } from 'react';
import { updateTestAccess } from '../api/HasAccess';

const useHasAccess = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleUpdateTestAccess = async (
    type,
    topSelectedMajors = [],
    topNonSelectedMajors = []
  ) => {
    try {
      setLoading(true);
      const email = localStorage.getItem('email');

      if (!email) {
        throw new Error('Email not found.');
      }

      // Wrap the email in an array as required
      const emailArray = [email];

      const data = await updateTestAccess(
        emailArray,
        type,
        topSelectedMajors,
        topNonSelectedMajors
      );
      return data;
    } catch (error) {
      console.error('Error in handleUpdateTestAccess:', error.message);
      setError(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, handleUpdateTestAccess };
};

export default useHasAccess;
