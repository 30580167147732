export const simulationEnAnalysis = {
  industries: [
    {
      id: 1,
      industryName: 'Healthcare',
      listmajors: [
        'Applied Mathematics',
        'Biochemistry',
        'Bioinformatics',
        'Biology',
        'Biomedical Engineering',
        'Biomedical Science',
        'Chemistry',
        'Clinical Laboratory Science',
        'Dentistry',
        'Earth and Life Science',
        'Ergotherapy/ Occupational Therapy',
        'Food and Science Technology/ Quality Assurance',
        'Fundamental Health Science',
        'Health Communication/ Promotion',
        'Laboratory Sciences',
        'Medical Imaging Sciences',
        'Medical Technology with Concentration in Radiologic Science',
        'Medicine',
        'Nursing',
        'Nutrition and Dietetics',
        'Optics and Optometry',
        'Pet Medicine',
        'Pharmacy',
        'Physical Therapy',
        'Physics',
        'Psychology',
        'Psychomotricity',
        'Public Health',
        'Social Work',
        'Speech Therapy',
      ],
      majors: [
        {
          name: 'Applied Mathematics',
          workplaces: [
            'Hospital',
            'Government',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math'],
          workType: ['Analysing data'],
          preferredCourses: ['Math'],
          description:
            'Applied Mathematics focuses on using mathematical methods and techniques to solve real-world problems across various fields, including engineering, science, economics, and technology.',
        },
        {
          name: 'Biochemistry',
          workplaces: [
            'Hospital',
            'Government',
            'NGO',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Chemistry'],
          workType: ['Analysing data'],
          preferredCourses: ['Biology', 'Chemistry'],
          description:
            'Biochemistry studies topics such as enzymology, metabolism, molecular genetics, and biochemical pathways, applying this knowledge to research in medicine, genetics, and biotechnology.',
        },
        {
          name: 'Bioinformatics',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Biology', 'Computer'],
          description:
            'Bioinformatics focuses on using computational tools and techniques to analyze and interpret biological data. It combines biology, computer science, and information technology principles to manage and analyze data.',
        },

        {
          name: 'Biology',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology'],
          workType: ['Analysing data'],
          preferredCourses: ['Biology'],
          description:
            'Biology studies topics about the structure, function, growth, and development of various life forms, preparing them for careers in research, healthcare, environmental science, and biotechnology.',
        },
        {
          name: 'Biomedical Engineering',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology', 'Physics', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Biology', 'Physics', 'Computer'],
          description:
            'Biomedical Engineering involves the development and improvement of medical devices, diagnostic equipment, and biotechnological solutions.',
        },
        {
          name: 'Biomedical Science',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology'],
          workType: ['Analysing data'],
          preferredCourses: ['Biology'],
          description:
            'Biomedical Science Studies topics about the diagnosis, treatment, and prevention of diseases, preparing them for careers in medical research, clinical laboratories, and healthcare settings.',
        },
        {
          name: 'Chemistry',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Chemistry'],
          workType: ['Analysing data'],
          preferredCourses: ['Chemistry'],
          description:
            'Chemistry studies topics about chemical reactions, molecular interactions, and laboratory techniques, preparing students for careers in research, pharmaceuticals, environmental science, and chemical manufacturing.',
        },
        {
          name: 'Clinical Laboratory Science',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'Manufacturing Enterprise',
          ],
          requiredSubjects: ['Biology', 'Chemistry'],
          workType: ['Analysing data'],
          preferredCourses: ['Biology', 'Chemistry'],
          description:
            'Clinical Laboratory Science is a major that focuses on analyzing bodily fluids and tissues to diagnose and monitor diseases.',
        },
        {
          name: 'Dentistry',
          workplaces: [
            'Hospital',
            'Government',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology'],
          workType: ['Helping people mentally or physically'],
          preferredCourses: ['Math', 'Biology'],
          description:
            'Dentistry is a field focused on the study, diagnosis, prevention, and treatment of diseases and conditions affecting the oral cavity, teeth, gums, and related structures.',
        },
        {
          name: 'Earth and Life Science',
          workplaces: [
            'Hospital',
            'Government',
            'NGO',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology'],
          workType: ['Analysing data'],
          preferredCourses: ['Biology'],
          description:
            "Earth and Life Science studies topics about Earth's structure, climate systems, and the diverse forms of life, including their interactions and adaptations.",
        },
        {
          name: 'Ergotherapy/ Occupational Therapy',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'NGO',
            'Schools/ Universities',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Sociology'],
          workType: ['Helping people mentally or physically'],
          preferredCourses: ['Biology', 'Sociology'],
          description:
            'Ergotherapy/ Occupational Therapy focuses on helping individuals develop, recover, or maintain the skills needed for daily living and work activities.',
        },

        {
          name: 'Food and Science Technology/ Quality Assurance',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'Retail Shop',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology'],
          workType: ['Analysing data'],
          preferredCourses: ['Biology'],
          description:
            'Food and Science Technology/ Quality Assurance is a field that focuses on the science and technology of food production, processing, and safety. Students learn about food preservation, quality control, and regulatory compliance.',
        },
        {
          name: 'Fundamental Health Science',
          workplaces: [
            'Hospital',
            'Government',
            'NGO',
            'Company',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology'],
          workType: ['Helping people mentally or physically'],
          preferredCourses: ['Biology'],
          description:
            'Fundamental Health Science studies topics about the mechanisms of health and illness, research methodologies, and foundational concepts that inform clinical practices and health interventions.',
        },
        {
          name: 'Health Communication/ Promotion',
          workplaces: [
            'Hospital',
            'Government',
            'NGO',
            'Company',
            'Schools/ Universities',
            'Retail Shop',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Sociology'],
          workType: ['Helping people mentally or physically'],
          preferredCourses: ['Biology', 'Sociology'],
          description:
            'Health Communication/ Promotion studies topics about health education, public health campaigns, media communication, and behavior change theories.',
        },
        {
          name: 'Laboratory Sciences',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Chemistry'],
          workType: ['Analysing data'],
          preferredCourses: ['Biology', 'Chemistry'],
          description:
            'Laboratory Sciences focuses on the study of scientific techniques and procedures used in laboratory settings to analyze and interpret biological, chemical, and physical samples.',
        },
        {
          name: 'Medical Imaging Sciences',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology', 'Physics', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Biology', 'Physics', 'Computer'],
          description:
            'Medical Imaging Sciences focuses on the study of techniques and technologies used to capture and interpret images of the human body for diagnostic and therapeutic purposes.',
        },
        {
          name: 'Medical Technology with Concentration in Radiologic Science',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology', 'Physics', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Biology', 'Physics', 'Computer'],
          description:
            'Medical Technology with Concentration in Radiologic Science focuses on the application of medical technology and radiologic techniques to diagnose and treat patients.',
        },
        {
          name: 'Medicine',
          workplaces: [
            'Hospital',
            'Government',
            'NGO',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology', 'Chemistry', 'Physics'],
          workType: ['Helping people mentally or physically'],
          preferredCourses: ['Math', 'Biology', 'Chemistry', 'Physics'],
          description:
            'Medicine focuses on the study of the diagnosis, treatment, and prevention of diseases and medical conditions.',
        },
        {
          name: 'Midwifery',
          workplaces: [
            'Hospital',
            'Government',
            'NGO',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology'],
          workType: ['Helping people mentally or physically'],
          preferredCourses: ['Biology'],
          description:
            'Midwifery focuses on the care of pregnant women, childbirth, and postnatal support for mothers and newborns.',
        },
        {
          name: 'Nursing',
          workplaces: ['Hospital', 'Government'],
          requiredSubjects: ['Biology', 'Sociology'],
          workType: ['Helping people mentally or physically'],
          preferredCourses: ['Biology', 'Sociology'],
          description:
            'Nursing focuses on the study of providing medical care and support to patients across various healthcare settings.',
        },
        {
          name: 'Nutrition and Dietetics',
          workplaces: [
            'Hospital',
            'Government',
            'NGO',
            'Company',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology'],
          workType: ['Helping people mentally or physically'],
          preferredCourses: ['Biology'],
          description:
            'Nutrition and Dietetics study topics about nutritional science, diet planning, food safety, and the role of diet in preventing and managing diseases.',
        },
        {
          name: 'Optics and Optometry',
          workplaces: [
            'Hospital',
            'Government',
            'NGO',
            'Company',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Physics'],
          workType: ['Helping people mentally or physically'],
          preferredCourses: ['Biology', 'Physics'],
          description:
            'Optics and Optometry study topics about visual systems, optics, eye diseases, vision correction, and optical instruments.',
        },
        {
          name: 'Pet Medicine',
          workplaces: [
            'Hospital',
            'NGO',
            'Company',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology'],
          workType: ['Working for the environment'],
          preferredCourses: ['Biology'],
          description:
            'Pet Medicine focuses on studying veterinary care specifically for companion animals such as dogs, cats, and other pets.',
        },
        {
          name: 'Pharmacy',
          workplaces: [
            'Hospital',
            'Government',
            'NGO',
            'Company',
            'Retail Shop',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Chemistry'],
          workType: ['Helping people mentally or physically'],
          preferredCourses: ['Biology', 'Chemistry'],
          description:
            'Pharmacy focuses on the study of medications, their effects, and their role in patient care.',
        },
        {
          name: 'Physical Therapy',
          workplaces: [
            'Hospital',
            'Government',
            'NGO',
            'Company',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Physics'],
          workType: ['Helping people mentally or physically'],
          preferredCourses: ['Biology', 'Physics'],
          description:
            'Physical Therapy involves the assessment, diagnosis, and treatment of physical impairments and disabilities.',
        },
        {
          name: 'Physics',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Physics'],
          workType: ['Analysing data'],
          preferredCourses: ['Physics'],
          description:
            'Physics focuses on studying the fundamental principles governing matter, energy, and the interactions between them.',
        },
        {
          name: 'Psychology',
          workplaces: [
            'Hospital',
            'Government',
            'NGO',
            'Company',
            'Schools/ Universities',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Sociology'],
          workType: ['Helping people mentally or physically'],
          preferredCourses: ['Biology', 'Sociology'],
          description:
            'Psychology studies topics about various psychological theories, research methods, and practical applications related to cognition, emotion, development, and social interactions.',
        },
        {
          name: 'Psychomotricity',
          workplaces: [
            'Hospital',
            'Government',
            'NGO',
            'Company',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Sociology'],
          workType: ['Helping people mentally or physically'],
          preferredCourses: ['Biology', 'Sociology'],
          description:
            'Psychomotricity studies topics about the coordination of mental and physical activities, including fine and gross motor skills, sensory integration, and movement control.',
        },
        {
          name: 'Public Health',
          workplaces: [
            'Hospital',
            'Government',
            'NGO',
            'Company',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Sociology'],
          workType: ['Helping people mentally or physically'],
          preferredCourses: ['Biology', 'Sociology'],
          description:
            'Public Health studies topics about disease prevention, health promotion, epidemiology, environmental health, and health policy.',
        },

        {
          name: 'Social Work',
          workplaces: [
            'Hospital',
            'Government',
            'NGO',
            'Company',
            'Schools/ Universities',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Sociology'],
          workType: ['Helping people mentally or physically'],
          preferredCourses: ['Biology', 'Sociology'],
          description:
            'Social Work focuses on the study of supporting individuals and communities to improve their well-being and address social issues.',
        },
        {
          name: 'Speech Therapy',
          workplaces: [
            'Hospital',
            'Government',
            'NGO',
            'Company',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Sociology'],
          workType: ['Helping people mentally or physically'],
          preferredCourses: ['Biology', 'Sociology'],
          description:
            'Speech Therapy focuses on the assessment, diagnosis, and treatment of speech, language, and communication disorders.',
        },
      ],
    },

    {
      id: 2,
      industryName: 'Construction',
      listmajors: [
        'Applied Mathematics',
        'Architecture',
        'Arts and Interior Planning',
        'Civil Engineering',
        'Civil Environmental Engineering',
        'Geology',
        'Landscape Engineering',
        'Surveying Engineering',
      ],

      majors: [
        {
          name: 'Applied Mathematics',
          workplaces: [
            'Hospital',
            'Government',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math'],
          workType: ['Analysing data'],
          preferredCourses: ['Math'],
          description:
            'Applied Mathematics focuses on using mathematical methods and techniques to solve real-world problems across various fields, including engineering, science, economics, and technology.',
        },
        {
          name: 'Architecture',
          workplaces: [
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Arts'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Math', 'Arts'],
          description:
            'Architecture studies topics about architectural design principles, building materials, structural engineering, and environmental considerations.',
        },
        {
          name: 'Arts and Interior Planning',
          workplaces: [
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Math', 'Arts'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Math', 'Arts'],
          description:
            'Arts and Interior Planning focuses on space planning, color theory, furniture design, and architectural elements, as well as client needs and trends in interior design.',
        },
        {
          name: 'Civil Engineering',
          workplaces: [
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Physics', 'Computer'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Math', 'Physics', 'Computer'],
          description:
            'Civil Engineering prepares students for careers in construction, urban planning, transportation, and environmental management, with roles such as civil engineer, project manager, and structural designer.',
        },
        {
          name: 'Civil Environmental Engineering',
          workplaces: [
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology', 'Physics', 'Computer'],
          workType: ['Working for the environment'],
          preferredCourses: ['Math', 'Biology', 'Physics', 'Computer'],
          description:
            'Civil Environmental Engineering combines civil engineering principles with environmental science and focuses on water resources management, waste treatment, pollution control, and sustainable construction practices.',
        },
        {
          name: 'Geology',
          workplaces: [
            'Government',
            'Company',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Physics', 'Geography'],
          workType: ['Analysing data'],
          preferredCourses: ['Biology', 'Physics', 'Geography'],
          description:
            "Geology involves the study of the Earth, which can be beneficial for understanding archaeological sites' geological contexts and using techniques like stratigraphy.",
        },
        {
          name: 'Landscape Engineering',
          workplaces: [
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Arts', 'Computer'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Math', 'Arts', 'Computer'],
          description:
            'Landscape Engineering focuses on the study of designing, planning, and managing outdoor spaces and environments with an emphasis on both aesthetic and functional aspects.',
        },
        {
          name: 'Surveying Engineering',
          workplaces: [
            'Government',
            'Company',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Biology', 'Computer'],
          description:
            'Surveying Engineering focuses on the measurement, analysis, and management of land and property boundaries.',
        },
      ],
    },
    {
      id: 3,
      industryName: 'Agriculture',
      listmajors: [
        'Agri-Business',
        'Agricultural Economics',
        'Agricultural Engineering',
        'Animal Science and Technology',
        'Applied Mathematics',
        'Biochemistry',
        'Biofood Engineering',
        'Bioinformatics',
        'Biology',
        'Chemical Engineering',
        'Chemistry',
        'Civil Environmental Engineering',
        'Earth and Life Science',
        'Engineering Sciences-Food Engineering',
        'Environmental / Forestry Engineering',
        'Environmental Health/ Sciences',
        'Fundamental Health Science',
        'Geology',
        'Geoscience and Natural Resources',
        'Health Communication/ Promotion',
        'Plant Production/ Protection',
        'Poultry and Cattle Medicine',
        'Quality Control of Foodstuffs of Animal Origin',
        'Surveying Engineering',
      ],

      majors: [
        {
          name: 'Agri-Business',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Retail Shop',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology', 'Chemistry'],
          workType: ['Working for the environment'],
          preferredCourses: ['Math', 'Biology', 'Chemistry'],
          description:
            'Agri-Business focuses on the business aspects of agriculture, including farm management, agricultural finance, marketing, and supply chain logistics.',
        },
        {
          name: 'Agricultural Economics',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology', 'Chemistry'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Biology', 'Chemistry'],
          description:
            'Agricultural Economics focuses on analyzing agricultural production, market trends, and policy impacts, with an emphasis on optimizing resource use, improving farm profitability, and addressing issues related to food supply and rural development.',
        },
        {
          name: 'Agricultural Engineering',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology', 'Chemistry', 'Computer'],
          workType: ['Working for the environment'],
          preferredCourses: ['Math', 'Biology', 'Chemistry', 'Computer'],
          description:
            'Agricultural Engineering focuses on designing and optimizing machinery, irrigation systems, and infrastructure to enhance productivity, efficiency, and sustainability in agriculture.',
        },
        {
          name: 'Animal Science and Technology',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology', 'Chemistry', 'Computer'],
          workType: ['Working for the environment'],
          preferredCourses: ['Math', 'Biology', 'Chemistry', 'Computer'],
          description:
            'Animal Science and Technology focuses on the study of animal biology, management, and technology to improve the health, productivity, and welfare of domestic animals.',
        },
        {
          name: 'Applied Mathematics',
          workplaces: [
            'Hospital',
            'Government',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math'],
          workType: ['Analysing data'],
          preferredCourses: ['Math'],
          description:
            'Applied Mathematics focuses on using mathematical methods and techniques to solve real-world problems across various fields, including engineering, science, economics, and technology.',
        },
        {
          name: 'Biochemistry',
          workplaces: [
            'Hospital',
            'Government',
            'NGO',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Chemistry'],
          workType: ['Analysing data'],
          preferredCourses: ['Biology', 'Chemistry'],
          description:
            'Biochemistry studies topics such as enzymology, metabolism, molecular genetics, and biochemical pathways, applying this knowledge to research in medicine, genetics, and biotechnology.',
        },
        {
          name: 'Biofood Engineering',
          workplaces: [
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Chemistry', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Biology', 'Chemistry', 'Computer'],
          description:
            'Biofood Engineering studies topics about food technology, bioprocessing, and the design of food production systems, preparing them for careers in the food industry, quality assurance, and food innovation.',
        },
        {
          name: 'Bioinformatics',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Biology', 'Computer'],
          description:
            'Bioinformatics focuses on using computational tools and techniques to analyze and interpret biological data. It combines biology, computer science, and information technology principles to manage and analyze data.',
        },
        {
          name: 'Biology',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology'],
          workType: ['Analysing data'],
          preferredCourses: ['Biology'],
          description:
            'Biology studies topics about the structure, function, growth, and development of various life forms, preparing them for careers in research, healthcare, environmental science, and biotechnology.',
        },
        {
          name: 'Chemical Engineering',
          workplaces: [
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Chemistry', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Chemistry', 'Computer'],
          description:
            'Chemical Engineering focuses on the design, optimization, and operation of processes that transform raw materials into valuable products.',
        },
        {
          name: 'Chemistry',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Chemistry'],
          workType: ['Analysing data'],
          preferredCourses: ['Chemistry'],
          description:
            'Chemistry studies topics about chemical reactions, molecular interactions, and laboratory techniques, preparing students for careers in research, pharmaceuticals, environmental science, and chemical manufacturing.',
        },
        {
          name: 'Civil Environmental Engineering',
          workplaces: [
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology', 'Physics', 'Computer'],
          workType: ['Working for the environment'],
          preferredCourses: ['Math', 'Biology', 'Physics', 'Computer'],
          description:
            'Civil Environmental Engineering combines civil engineering principles with environmental science and focuses on water resources management, waste treatment, pollution control, and sustainable construction practices.',
        },
        {
          name: 'Earth and Life Science',
          workplaces: [
            'Hospital',
            'Government',
            'NGO',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology'],
          workType: ['Analysing data'],
          preferredCourses: ['Biology'],
          description:
            "Earth and Life Science studies topics about Earth's structure, climate systems, and the diverse forms of life, including their interactions and adaptations.",
        },
        {
          name: 'Engineering Sciences-Food Engineering',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Chemistry', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Biology', 'Chemistry', 'Computer'],
          description:
            'Engineering Sciences-Food Engineering studies topics about food processing technologies, biochemical processes, and the engineering aspects of food production, preparing them for careers in the food industry, quality assurance, and food technology.',
        },
        {
          name: 'Environmental / Forestry Engineering',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology', 'Computer'],
          workType: ['Working for the environment'],
          preferredCourses: ['Math', 'Biology', 'Computer'],
          description:
            'Environmental / Forestry Engineering studies topics about ecosystem management, environmental impact assessment, and the engineering aspects of land and water management.',
        },
        {
          name: 'Environmental Health/ Sciences',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology'],
          workType: ['Working for the environment'],
          preferredCourses: ['Math', 'Biology'],
          description:
            'Environmental Health/ Sciences studies topics about environmental toxins, epidemiology, risk assessment, and environmental regulations.',
        },
        {
          name: 'Fundamental Health Science',
          workplaces: [
            'Hospital',
            'Government',
            'NGO',
            'Company',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology'],
          workType: ['Helping people mentally or physically'],
          preferredCourses: ['Biology'],
          description:
            'Fundamental Health Science studies topics about the mechanisms of health and illness, research methodologies, and foundational concepts that inform clinical practices and health interventions.',
        },
        {
          name: 'Geology',
          workplaces: [
            'Government',
            'Company',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Physics', 'Geography'],
          workType: ['Analysing data'],
          preferredCourses: ['Biology', 'Physics', 'Geography'],
          description:
            "Geology involves the study of the Earth, which can be beneficial for understanding archaeological sites' geological contexts and using techniques like stratigraphy.",
        },
        {
          name: 'Geoscience and Natural Resources',
          workplaces: [
            'Government',
            'Company',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Physics', 'Geography'],
          workType: ['Analysing data'],
          preferredCourses: ['Biology', 'Physics', 'Geography'],
          description:
            'Geoscience and Natural Resources is a major that focuses on studying the Earth’s physical structure, processes, and the management of natural resources.',
        },
        {
          name: 'Health Communication/ Promotion',
          workplaces: [
            'Hospital',
            'Government',
            'NGO',
            'Company',
            'Schools/ Universities',
            'Retail Shop',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Sociology'],
          workType: ['Helping people mentally or physically'],
          preferredCourses: ['Biology', 'Sociology'],
          description:
            'Health Communication/ Promotion studies topics about health education, public health campaigns, media communication, and behavior change theories.',
        },
        {
          name: 'Plant Production/ Protection',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Physics'],
          workType: ['Working for the environment'],
          preferredCourses: ['Biology', 'Physics'],
          description:
            'Plant Production/ Protection focuses on the study of cultivating crops and managing plant health to maximize yield and minimize losses.',
        },
        {
          name: 'Poultry and Cattle Medicine',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology'],
          workType: ['Working for the environment'],
          preferredCourses: ['Math', 'Biology'],
          description:
            'Poultry and Cattle Medicine focuses on the veterinary care of poultry and cattle, including their health management, disease prevention, and treatment.',
        },
        {
          name: 'Quality Control of Foodstuffs of Animal Origin',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology'],
          workType: ['Working for the environment'],
          preferredCourses: ['Biology'],
          description:
            'Quality Control of Foodstuffs of Animal Origin studies topics about food safety regulations, inspection techniques, quality assurance processes, and microbiological testing specific to meat, dairy, and other animal products.',
        },
        {
          name: 'Surveying Engineering',
          workplaces: [
            'Government',
            'Company',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Biology', 'Computer'],
          description:
            'Surveying Engineering focuses on the measurement, analysis, and management of land and property boundaries.',
        },
      ],
    },
    {
      id: 4,
      industryName: 'Media',
      listmajors: [
        'Advertising and Marketing Communication',
        'Audiovisual Production',
        'Cinema Production',
        'Communication and Visual Arts',
        'Computer Graphics and Interactive Media',
        'Design and Applied Arts',
        'Digital Advertising',
        'Digital Media',
        'Graphic Design',
        'Journalism and Electronic Media',
        'Media and Communication',
        'Music',
        'Photography',
        'Radio/TV and Film',
      ],

      majors: [
        {
          name: 'Advertising and Marketing Communication',
          workplaces: [
            'Bank',
            'Company',
            'Retail Shop',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Arts'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts'],
          description:
            'Advertising and Marketing Communication studies topics on how to develop compelling messages, create marketing campaigns, and utilize various media channels to reach target audiences effectively.',
        },

        {
          name: 'Audiovisual Production',
          workplaces: ['Company', 'Research or Consultancy Company', 'Media'],
          requiredSubjects: ['Arts', 'Computer'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts', 'Computer'],
          description:
            'Audiovisual Production studies topics about filming techniques, sound design, editing, and post-production processes, as well as storytelling and project management.',
        },
        {
          name: 'Cinema Production',
          workplaces: ['Company', 'Research or Consultancy Company', 'Media'],
          requiredSubjects: ['Arts', 'Computer'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts', 'Computer'],
          description:
            'Cinema Production studies topics about technical skills and creative techniques needed to produce films, from pre-production planning to post-production editing.',
        },
        {
          name: 'Communication and Visual Arts',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Retail Shop',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Arts', 'Computer'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts', 'Computer'],
          description:
            'Communication and Visual Arts focuses on how to Create and analyze visual content for various platforms, develop communication strategies, and understand the impact of visual media on society.',
        },
        {
          name: 'Computer Graphics and Interactive Media',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Retail Shop',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Arts', 'Computer'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts', 'Computer'],
          description:
            'Computer Graphics and Interactive Media focuses on the creation and manipulation of visual content using computer technology, along with the development of interactive digital experiences.',
        },
        {
          name: 'Design and Applied Arts',
          workplaces: [
            'NGO',
            'Company',
            'Retail Shop',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Arts', 'Computer'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts', 'Computer'],
          description:
            'Design and Applied Arts studies topics about design theory, color theory, materials, and digital tools, preparing them for careers in various creative industries where design plays a central role.',
        },
        {
          name: 'Digital Advertising',
          workplaces: [
            'Bank',
            'Company',
            'Retail Shop',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Arts', 'Computer'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts', 'Computer'],
          description:
            'Digital Advertising studies topics about digital marketing strategies, content creation, analytics, and consumer behavior, preparing them for careers in online marketing, digital media, and advertising agencies.',
        },
        {
          name: 'Digital Media',
          workplaces: [
            'Company',
            'Retail Shop',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Arts', 'Computer'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts', 'Computer'],
          description:
            'Digital Media studies topics about various forms of digital content, including text, images, audio, and video, and how to leverage digital tools and platforms to engage audiences.',
        },
        {
          name: 'Graphic Design',
          workplaces: [
            'Company',
            'Retail Shop',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Arts', 'Computer'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts', 'Computer'],
          description:
            'Graphic Design is a major that focuses on creating visual content to communicate messages and ideas. Students learn to use design software and develop creative problem-solving abilities to produce aesthetically pleasing and effective visual communications.',
        },
        {
          name: 'Journalism and Electronic Media',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Languages', 'Sociology'],
          workType: ['Serving people or interacting with them'],
          preferredCourses: ['Languages', 'Sociology'],
          description:
            'Journalism and Electronic Media studies topics about journalism ethics, multimedia storytelling, digital media platforms, broadcasting, and content creation.',
        },
        {
          name: 'Media and Communication',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: [''],
          workType: ['Creating new ideas'],
          preferredCourses: [''],
          description:
            'Media and Communication focuses on the study of how information and content are created, distributed, and consumed across various media platforms.',
        },
        {
          name: 'Music',
          workplaces: ['NGO', 'Company', 'Media'],
          requiredSubjects: ['Arts'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts'],
          description:
            'Music studies topics about various musical genres, techniques, and instruments, as well as music theory, ear training, and music history.',
        },
        {
          name: 'Photography',
          workplaces: [
            'NGO',
            'Company',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Arts'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts'],
          description:
            'Photography focuses on the study of visual storytelling and the technical aspects of capturing and editing images.',
        },
        {
          name: 'Radio/TV and Film',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Arts', 'Computer'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts', 'Computer'],
          description:
            'Radio/TV and Film focuses on the production, direction, and management of audio-visual content across various media platforms.',
        },
      ],
    },
    {
      id: 5,
      industryName: 'Retail',
      listmajors: [
        'Advertising and Marketing Communication',
        'Agri-Business',
        'Fashion and Pattern Drafting',
        'Product Design',
      ],
      majors: [
        {
          name: 'Advertising and Marketing Communication',
          workplaces: [
            'Bank',
            'Company',
            'Retail Shop',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Arts'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts'],
          description:
            'Advertising and Marketing Communication studies topics on how to develop compelling messages, create marketing campaigns, and utilize various media channels to reach target audiences effectively.',
        },
        {
          name: 'Agri-Business',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Retail Shop',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology', 'Chemistry'],
          workType: ['Working for the environment'],
          preferredCourses: ['Math', 'Biology', 'Chemistry'],
          description:
            'Agri-Business focuses on the business aspects of agriculture, including farm management, agricultural finance, marketing, and supply chain logistics.',
        },
        {
          name: 'Fashion and Pattern Drafting',
          workplaces: [
            'NGO',
            'Company',
            'Retail Shop',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Arts'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts'],
          description:
            'Fashion and Pattern Drafting focuses on designing and creating clothing and accessories. Students study how to create and modify patterns, select fabrics, and bring fashion concepts to life.',
        },
        {
          name: 'Product Design',
          workplaces: [
            'Company',
            'Retail Shop',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Arts'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts'],
          description:
            'Product Design studies topics about design principles, materials, manufacturing processes, user experience, and prototyping.',
        },
      ],
    },
    {
      id: 6,
      industryName: 'Financial Services',
      listmajors: [
        'Accounting',
        'Accounting and Auditing',
        'Accounting and Finance',
        'Agricultural Economics',
        'Applied Mathematics',
        'Banking and Finance',
        'Economics',
        'Statistics',
      ],
      majors: [
        {
          name: 'Accounting',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'NGO',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Retail Shop',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Math'],
          workType: ['Analysing data'],
          preferredCourses: ['Math'],
          description:
            'Accounting is the systematic process of recording, summarizing, analyzing, and reporting financial transactions of a business or organization.',
        },
        {
          name: 'Accounting and Auditing',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'NGO',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Retail Shop',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Math'],
          workType: ['Analysing data'],
          preferredCourses: ['Math'],
          description:
            'Accounting and Auditing is the combination of accounting that involves recording and reporting financial transactions and auditing that involves the independent examination and verification of financial statements and records.',
        },
        {
          name: 'Accounting and Finance',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'NGO',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Retail Shop',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Math'],
          workType: ['Analysing data'],
          preferredCourses: ['Math'],
          description:
            'Accounting and Finance is the combination of accounting that deals with the recording and reporting of financial transactions and finance that involves the management of assets, liabilities, and the planning of future financial strategies.',
        },
        {
          name: 'Agricultural Economics',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology', 'Chemistry'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Biology', 'Chemistry'],
          description:
            'Agricultural Economics focuses on analyzing agricultural production, market trends, and policy impacts, with an emphasis on optimizing resource use, improving farm profitability, and addressing issues related to food supply and rural development.',
        },
        {
          name: 'Applied Mathematics',
          workplaces: [
            'Hospital',
            'Government',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math'],
          workType: ['Analysing data'],
          preferredCourses: ['Math'],
          description:
            'Applied Mathematics focuses on using mathematical methods and techniques to solve real-world problems across various fields, including engineering, science, economics, and technology.',
        },
        {
          name: 'Banking and Finance',
          workplaces: ['Bank', 'Company', 'Research or Consultancy Company'],
          requiredSubjects: ['Math'],
          workType: ['Analysing data'],
          preferredCourses: ['Math'],
          description:
            'Banking and Finance studies topics about financial markets, corporate finance, and regulatory frameworks, preparing them for careers in banking, investment, financial planning, and related fields.',
        },
        {
          name: 'Economics',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'NGO',
            'Bank',
            'Company',
            'Retail Shop',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Math'],
          workType: ['Analysing data'],
          preferredCourses: ['Math'],
          description:
            'Economics studies topics about microeconomics, macroeconomics, econometrics, and economic forecasting.',
        },
        {
          name: 'Statistics',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'Bank',
            'Company',
            'Retail Shop',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Math'],
          workType: ['Analysing data'],
          preferredCourses: ['Math'],
          description:
            'Statistics focuses on the study of data collection, analysis, interpretation, and presentation.',
        },
      ],
    },
    {
      id: 7,
      industryName: 'Telecommunication',
      listmajors: [
        'Applied Mathematics',
        'Communication and Visual Arts',
        'Communication Engineering',
        'Computer and Communication Engineering',
        'Computer Engineering',
        'Computer Graphics and Interactive Media',
        'Computer Network and Data Communications',
        'Computer Science',
        'Telecommunication and Networks Engineering',
      ],
      majors: [
        {
          name: 'Applied Mathematics',
          workplaces: [
            'Hospital',
            'Government',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math'],
          workType: ['Analysing data'],
          preferredCourses: ['Math'],
          description:
            'Applied Mathematics focuses on using mathematical methods and techniques to solve real-world problems across various fields, including engineering, science, economics, and technology.',
        },
        {
          name: 'Communication and Visual Arts',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Retail Shop',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Arts', 'Computer'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts', 'Computer'],
          description:
            'Communication and Visual Arts focuses on how to Create & analyze visual content for various platforms, develop communication strategies, and understand the impact of visual media on society.',
        },
        {
          name: 'Communication Engineering',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'NGO',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Math', 'Physics', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Physics', 'Computer'],
          description:
            'Communication Engineering studies the principles of transmitting data and voice over various media, developing technologies for mobile communication, internet infrastructure, and broadcasting.',
        },
        {
          name: 'Computer and Communication Engineering',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'NGO',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Math', 'Physics', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Physics', 'Computer'],
          description:
            'Computer and Communication Engineering covers areas like network design, telecommunications, signal processing, and data transmission alongside core computer engineering topics.',
        },
        {
          name: 'Computer Engineering',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'NGO',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Math', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Computer'],
          description:
            'Computer Engineering focuses on designing, developing, and maintaining computer systems and hardware.',
        },
        {
          name: 'Computer Graphics and Interactive Media',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Retail Shop',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Arts', 'Computer'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts', 'Computer'],
          description:
            'Computer Graphics and Interactive Media focuses on the creation and manipulation of visual content using computer technology, along with the development of interactive digital experiences.',
        },
        {
          name: 'Computer Network and Data Communications',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'NGO',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Math', 'Physics', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Physics', 'Computer'],
          description:
            'Computer Network and Data Communications is a field that focuses on designing, implementing, and managing interconnected computer systems that exchange data.',
        },
        {
          name: 'Computer Science',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'NGO',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Math', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Computer'],
          description:
            'Computer Science Studies topics about programming, data structures, artificial intelligence, databases, and computer networks.',
        },
        {
          name: 'Telecommunication and Networks Engineering',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'NGO',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Math', 'Physics', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Physics', 'Computer'],
          description:
            'Telecommunication and Networks Engineering studies topics about telecommunications technologies, network architecture, data transmission, and wireless communications.',
        },
      ],
    },
    {
      id: 8,
      industryName: 'Education',
      listmajors: [
        'Applied Mathematics',
        'Biology',
        'Chemistry',
        'Comparative Linguistics Engineering',
        'Early Childhood Education',
        'Education',
        'Geography',
        'History',
        'Music',
        'Philosophy',
        'Physical Education',
        'Physics',
        'Religions Sciences',
        'Social Sciences',
        'Sociology-Anthropology',
        'Translation and Interpretation',
      ],
      majors: [
        {
          name: 'Applied Mathematics',
          workplaces: [
            'Hospital',
            'Government',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math'],
          workType: ['Analysing data'],
          preferredCourses: ['Math'],
          description:
            'Applied Mathematics focuses on using mathematical methods and techniques to solve real-world problems across various fields, including engineering, science, economics, and technology.',
        },
        {
          name: 'Biology',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology'],
          workType: ['Analysing data'],
          preferredCourses: ['Biology'],
          description:
            'Biology studies topics about the structure, function, growth, and development of various life forms, preparing them for careers in research, healthcare, environmental science, and biotechnology.',
        },
        {
          name: 'Chemistry',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Chemistry'],
          workType: ['Analysing data'],
          preferredCourses: ['Chemistry'],
          description:
            'Chemistry studies topics about chemical reactions, molecular interactions, and laboratory techniques, preparing students for careers in research, pharmaceuticals, environmental science, and chemical manufacturing.',
        },
        {
          name: 'Comparative Linguistics Engineering',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Schools/ Universities',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Languages'],
          workType: ['Transfer your knowledge to others'],
          preferredCourses: ['Languages'],
          description:
            'Comparative Linguistics Engineering focuses on the study and comparison of languages to understand their structures, histories, and relationships.',
        },
        {
          name: 'Early Childhood Education',
          workplaces: [
            'NGO',
            'Schools/ Universities',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Sociology'],
          workType: ['Serving people or interacting with them'],
          preferredCourses: ['Sociology'],
          description:
            'Early Childhood Education trains to design and implement curricula, assess developmental milestones, and foster positive early learning experiences.',
        },
        {
          name: 'Education',
          workplaces: ['Government', 'NGO', 'Schools/ Universities'],
          requiredSubjects: [''],
          workType: ['Transfer your knowledge to others'],
          preferredCourses: [''],
          description:
            'Education focuses on the study of teaching methods, learning processes, and educational systems.',
        },
        {
          name: 'Geography',
          workplaces: [
            'Government',
            'Company',
            'Schools/ Universities',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Geography'],
          workType: ['Transfer your knowledge to others'],
          preferredCourses: ['Geography'],
          description:
            "Geography focuses on studying the Earth's landscapes, environments, and the relationships between people and their environments.",
        },
        {
          name: 'History',
          workplaces: [
            'Government',
            ' NGO',
            'Company',
            'Schools/ Universities',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['History'],
          workType: ['Transfer your knowledge to others'],
          preferredCourses: ['History'],
          description:
            'History provides a broader context of the human past, focusing on different periods, regions, and cultures. Some programs offer a concentration in archaeological studies.',
        },
        {
          name: 'Music',
          workplaces: ['NGO', 'Company', 'Media'],
          requiredSubjects: ['Arts'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts'],
          description:
            'Music studies topics about various musical genres, techniques, and instruments, as well as music theory, ear training, and music history.',
        },
        {
          name: 'Philosophy',
          workplaces: [
            'Company',
            'Schools/ Universities',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Philosophy'],
          workType: ['Transfer your knowledge to others'],
          preferredCourses: ['Philosophy'],
          description:
            'Philosophy focuses on the study of fundamental questions about existence, knowledge, ethics, and reason.',
        },
        {
          name: 'Physical Education',
          workplaces: ['Company', 'Schools/ Universities'],
          requiredSubjects: ['Biology', 'Physics'],
          workType: ['Helping people mentally or physically'],
          preferredCourses: ['Biology', 'Physics'],
          description:
            'Physical Education focuses on teaching and promoting physical activity and health through structured programs in schools or recreational settings.',
        },
        {
          name: 'Physics',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Physics'],
          workType: ['Analysing data'],
          preferredCourses: ['Physics'],
          description:
            'Physics focuses on studying the fundamental principles governing matter, energy, and the interactions between them.',
        },
        {
          name: 'Religions Sciences',
          workplaces: [
            'Company',
            'Schools/ Universities',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['History'],
          workType: ['Transfer your knowledge to others'],
          preferredCourses: ['History'],
          description:
            'Religions Sciences focuses on the study of religious beliefs, practices, and institutions across various cultures and historical contexts.',
        },
        {
          name: 'Social Sciences',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Schools/ Universities',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Sociology'],
          workType: ['Analysing data'],
          preferredCourses: ['Sociology'],
          description:
            'Social Sciences focuses on the study of human society, behavior, and social relationships.',
        },
        {
          name: 'Sociology-Anthropology',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Schools/ Universities',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Sociology'],
          workType: ['Analysing data'],
          preferredCourses: ['Sociology'],
          description:
            'Sociology-Anthropology is the study of humans, and their ancestors, which includes cultural, social, biological, and linguistic aspects.',
        },
        {
          name: 'Translation and Interpretation',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Schools/ Universities',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['languages'],
          workType: ['Transfer your knowledge to others'],
          preferredCourses: ['languages'],
          description:
            'Translation and Interpretation studies topics about language proficiency, translation techniques, interpretation skills, and cultural nuances.',
        },
      ],
    },
    {
      id: 9,
      industryName: 'Aerospace',
      listmajors: [
        'Aircraft Maintenance',
        'Applied Mathematics',
        'Astronomy',
        'Mechanical Engineering',
        'Mechatronics Engineering',
        'Physics',
        'Telecommunication and Networks Engineering',
      ],
      majors: [
        {
          name: 'Aircraft Maintenance',
          workplaces: ['Airport', 'Government', 'Manufacturing Enterprise'],
          requiredSubjects: ['Math', 'Physics'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Physics'],
          description:
            'Aircraft Maintenance focuses on the technical skills and knowledge required to inspect, repair, and maintain aircraft to ensure their safety and functionality.',
        },
        {
          name: 'Applied Mathematics',
          workplaces: [
            'Hospital',
            'Government',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math'],
          workType: ['Analysing data'],
          preferredCourses: ['Math'],
          description:
            'Applied Mathematics focuses on using mathematical methods and techniques to solve real-world problems across various fields, including engineering, science, economics, and technology.',
        },
        {
          name: 'Astronomy',
          workplaces: [
            'Government',
            'Company',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology', 'Physics'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Biology', 'Physics'],
          description:
            'Astronomy focuses on the study of celestial objects and phenomena, including stars, planets, galaxies, and the universe as a whole.',
        },
        {
          name: 'Mechanical Engineering',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Physics', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Physics', 'Computer'],
          description:
            'Mechanical Engineering focuses on the study of the design, analysis, and manufacturing of mechanical systems and devices.',
        },
        {
          name: 'Mechatronics Engineering',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Physics', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Physics', 'computer'],
          description:
            'Mechatronics Engineering focuses on integrating mechanical, electronic, and computer systems to design and create advanced automated and robotic systems.',
        },
        {
          name: 'Physics',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Physics'],
          workType: ['Analysing data'],
          preferredCourses: ['Physics'],
          description:
            'Physics focuses on studying the fundamental principles governing matter, energy, and the interactions between them.',
        },
        {
          name: 'Telecommunication and Networks Engineering',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'NGO',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Math', 'Physics', 'Computer'],
          workType: ['Analyzing data'],
          preferredCourses: ['Math', 'Physics', 'Computer'],
          description:
            'Telecommunication and Networks Engineering studies topics about telecommunications technologies, network architecture, data transmission, and wireless communications.',
        },
      ],
    },
    {
      id: 10,
      industryName: 'Manufacturing',
      listmajors: [
        'Agricultural Engineering',
        'Aircraft Maintenance',
        'Applied Mathematics',
        'Architecture',
        'Arts and Interior Planning',
        'Biochemistry',
        'Biofood Engineering',
        'Bioinformatics',
        'Biology',
        'Biomedical Engineering',
        'Chemical Engineering',
        'Chemistry',
        'Civil Engineering',
        'Civil Environmental Engineering',
        'Clinical Laboratory Science',
        'Electrical and Electronics Engineering',
        'Electronic Engineering',
        'Electrical Engineering',
        'Industrial Engineering',
        'Landscape Engineering',
        'Mechanical Engineering',
        'Mechatronics Engineering',
        'Medical Imaging Sciences',
        'Medical Technology with Concentration in Radiologic Science',
        'Petrochemical Engineering',
        'Petroleum Engineering',
        'Pharmacy',
        'Physics',
        'Plant Production/ Protection',
        'Statistics',
        'Telecommunication and Networks Engineering',
      ],

      majors: [
        {
          name: 'Agricultural Engineering',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology', 'Chemistry', 'Computer'],
          workType: ['Working for the environment'],
          preferredCourses: ['Math', 'Biology', 'Chemistry', 'Computer'],
          description:
            'Agricultural Engineering focuses on designing and optimizing machinery, irrigation systems, and infrastructure to enhance productivity, efficiency, and sustainability in agriculture.',
        },
        {
          name: 'Aircraft Maintenance',
          workplaces: ['Airport', 'Government', 'Manufacturing Enterprise'],
          requiredSubjects: ['Math', 'Physics'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Physics'],
          description:
            'Aircraft Maintenance focuses on the technical skills and knowledge required to inspect, repair, and maintain aircraft to ensure their safety and functionality.',
        },
        {
          name: 'Applied Mathematics',
          workplaces: [
            'Hospital',
            'Government',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math'],
          workType: ['Analysing data'],
          preferredCourses: ['Math'],
          description:
            'Applied Mathematics focuses on using mathematical methods and techniques to solve real-world problems across various fields, including engineering, science, economics, and technology.',
        },
        {
          name: 'Architecture',
          workplaces: [
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Math'],
          description:
            'Architecture studies topics about architectural design principles, building materials, structural engineering, and environmental considerations.',
        },
        {
          name: 'Arts and Interior Planning',
          workplaces: [
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Math', 'Arts'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Math', 'Arts'],
          description:
            'Arts and Interior Planning focuses on space planning, color theory, furniture design, and architectural elements, as well as client needs and trends in interior design.',
        },
        {
          name: 'Biochemistry',
          workplaces: [
            'Hospital',
            'Government',
            'NGO',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Chemistry'],
          workType: ['Analysing data'],
          preferredCourses: ['Biology', 'Chemistry'],
          description:
            'Biochemistry studies topics such as enzymology, metabolism, molecular genetics, and biochemical pathways, applying this knowledge to research in medicine, genetics, and biotechnology.',
        },
        {
          name: 'Biofood Engineering',
          workplaces: [
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Chemistry', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Biology', 'Chemistry', 'Computer'],
          description:
            'Biofood Engineering studies topics about food technology, bioprocessing, and the design of food production systems, preparing them for careers in the food industry, quality assurance, and food innovation.',
        },
        {
          name: 'Bioinformatics',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Biology', 'Computer'],
          description:
            'Bioinformatics focuses on using computational tools and techniques to analyze and interpret biological data. It combines biology, computer science, and information technology principles to manage and analyze data.',
        },
        {
          name: 'Biology',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology'],
          workType: ['Analysing data'],
          preferredCourses: ['Biology'],
          description:
            'Biology studies topics about the structure, function, growth, and development of various life forms, preparing them for careers in research, healthcare, environmental science, and biotechnology.',
        },
        {
          name: 'Biomedical Engineering',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology', 'Physics', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Biology', 'Physics', 'Computer'],
          description:
            'Biomedical Engineering involves the development and improvement of medical devices, diagnostic equipment, and biotechnological solutions.',
        },
        {
          name: 'Chemical Engineering',
          workplaces: [
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Chemistry', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Chemistry', 'Computer'],
          description:
            'Chemical Engineering focuses on the design, optimization, and operation of processes that transform raw materials into valuable products.',
        },
        {
          name: 'Chemistry',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Chemistry'],
          workType: ['Analysing data'],
          preferredCourses: ['Chemistry'],
          description:
            'Chemistry studies topics about chemical reactions, molecular interactions, and laboratory techniques, preparing students for careers in research, pharmaceuticals, environmental science, and chemical manufacturing.',
        },
        {
          name: 'Civil Engineering',
          workplaces: [
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Physics', 'Computer'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Math', 'Physics', 'Computer'],
          description:
            'Civil Engineering prepares students for careers in construction, urban planning, transportation, and environmental management, with roles such as civil engineer, project manager, and structural designer.',
        },
        {
          name: 'Civil Environmental Engineering',
          workplaces: [
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology', 'Physics', 'Computer'],
          workType: ['Working for the environment'],
          preferredCourses: ['Math', 'Biology', 'Physics', 'Computer'],
          description:
            'Civil Environmental Engineering combines civil engineering principles with environmental science and focuses on water resources management, waste treatment, pollution control, and sustainable construction practices.',
        },
        {
          name: 'Clinical Laboratory Science',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'Manufacturing Enterprise',
          ],
          requiredSubjects: ['Biology', 'Chemistry'],
          workType: ['Analysing data'],
          preferredCourses: ['Biology', 'Chemistry'],
          description:
            'Clinical Laboratory Science is a major that focuses on analyzing bodily fluids and tissues to diagnose and monitor diseases.',
        },
        {
          name: 'Electrical and Electronics Engineering',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Physics', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Physics', 'Computer'],
          description:
            'Electrical and Electronics Engineering is a combination of electrical and electronic Engineering which mixes power systems and infrastructure with the design and application of electronic devices and circuits.',
        },
        {
          name: 'Electronic Engineering',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Physics', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Physics', 'Computer'],
          description:
            'Electronics Engineering focuses on the design and development of electronic circuits and devices. It deals with smaller-scale systems that use electronic components like transistors, diodes, and integrated circuits.',
        },
        {
          name: 'Electrical Engineering',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Physics', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Physics', 'Computer'],
          description:
            'Electrical Engineering studies topics about electrical theory, circuit analysis, signal processing, and the application of electrical engineering principles to solve problems in various industries.',
        },
        {
          name: 'Industrial Engineering',
          workplaces: [
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Physics', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Physics', 'Computer'],
          description:
            'Industrial Engineering focuses on the study of optimizing complex systems, processes, and organizations to improve efficiency and productivity.',
        },
        {
          name: 'Landscape Engineering',
          workplaces: [
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Arts', 'Computer'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Math', 'Arts', 'Computer'],
          description:
            'Landscape Engineering focuses on the study of designing, planning, and managing outdoor spaces and environments with an emphasis on both aesthetic and functional aspects.',
        },
        {
          name: 'Mechanical Engineering',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Physics', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Physics', 'Computer'],
          description:
            'Mechanical Engineering focuses on the study of the design, analysis, and manufacturing of mechanical systems and devices.',
        },
        {
          name: 'Mechatronics Engineering',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Physics', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Physics', 'computer'],
          description:
            'Mechatronics Engineering focuses on integrating mechanical, electronic, and computer systems to design and create advanced automated and robotic systems.',
        },
        {
          name: 'Medical Imaging Sciences',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology', 'Physics', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Biology', 'Physics', 'Computer'],
          description:
            'Medical Imaging Sciences focuses on the study of techniques and technologies used to capture and interpret images of the human body for diagnostic and therapeutic purposes.',
        },
        {
          name: 'Medical Technology with Concentration in Radiologic Science',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology', 'Physics', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Biology', 'Physics', 'Computer'],
          description:
            'Medical Technology with Concentration in Radiologic Science focuses on the application of medical technology and radiologic techniques to diagnose and treat patients.',
        },
        {
          name: 'Petrochemical Engineering',
          workplaces: [
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Chemistry', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Chemistry', 'Computer'],
          description:
            'Petrochemical Engineering is a specialized branch of chemical engineering that focuses on the production and processing of chemicals derived from petroleum and natural gas.',
        },
        {
          name: 'Petroleum Engineering',
          workplaces: [
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Chemistry', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Chemistry', 'Computer'],
          description:
            'Petroleum Engineering focuses on the study of the extraction and production of oil and natural gas.',
        },
        {
          name: 'Pharmacy',
          workplaces: [
            'Hospital',
            'Government',
            'NGO',
            'Company',
            'Retail Shop',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Chemistry'],
          workType: ['Helping people mentally or physically'],
          preferredCourses: ['Biology', 'Chemistry'],
          description:
            'Pharmacy focuses on the study of medications, their effects, and their role in patient care.',
        },
        {
          name: 'Physics',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Physics'],
          workType: ['Analysing data'],
          preferredCourses: ['Physics'],
          description:
            'Physics focuses on studying the fundamental principles governing matter, energy, and the interactions between them.',
        },
        {
          name: 'Plant Production/ Protection',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Physics'],
          workType: ['Working for the environment'],
          preferredCourses: ['Biology', 'Physics'],
          description:
            'Plant Production/ Protection focuses on the study of cultivating crops and managing plant health to maximize yield and minimize losses.',
        },
        {
          name: 'Statistics',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'Bank',
            'Company',
            'Retail Shop',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Math'],
          workType: ['Analysing data'],
          preferredCourses: ['Math'],
          description:
            'Statistics focuses on the study of data collection, analysis, interpretation, and presentation.',
        },
        {
          name: 'Telecommunication and Networks Engineering',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'NGO',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Math', 'Physics', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Physics', 'Computer'],
          description:
            'Telecommunication and Networks Engineering studies topics about telecommunications technologies, network architecture, data transmission, and wireless communications.',
        },
      ],
    },
    {
      id: 11,
      industryName: 'Information Technology',
      listmajors: [
        'Applied Mathematics',
        'Bioinformatics',
        'Business Information Systems',
        'Communication Engineering',
        'Computer and Communication Engineering',
        'Computer Engineering',
        'Computer Graphics and Interactive Media',
        'Computer Network and Data Communications',
        'Computer Science',
        'Data Science',
        'Mechatronics Engineering',
        'Telecommunication and Networks Engineering',
      ],

      majors: [
        {
          name: 'Applied Mathematics',
          workplaces: [
            'Hospital',
            'Government',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math'],
          workType: ['Analysing data'],
          preferredCourses: ['Math'],
          description:
            'Applied Mathematics focuses on using mathematical methods and techniques to solve real-world problems across various fields, including engineering, science, economics, and technology.',
        },
        {
          name: 'Bioinformatics',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Biology', 'Computer'],
          description:
            'Bioinformatics focuses on using computational tools and techniques to analyze and interpret biological data. It combines biology, computer science, and information technology principles to manage and analyze data.',
        },
        {
          name: 'Business Information Systems',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'NGO',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Retail Shop',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Computer'],
          description:
            'Business Information Systems focuses on the use of IT to solve business problems, improve processes, and support decision-making.',
        },
        {
          name: 'Communication Engineering',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'NGO',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Math', 'Physics', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Physics', 'Computer'],
          description:
            'Communication Engineering studies the principles of transmitting data and voice over various media, developing technologies for mobile communication, internet infrastructure, and broadcasting.',
        },
        {
          name: 'Computer and Communication Engineering',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'NGO',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Math', 'Physics', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Physics', 'Computer'],
          description:
            'Computer and Communication Engineering covers areas like network design, telecommunications, signal processing, and data transmission alongside core computer engineering topics.',
        },
        {
          name: 'Computer Engineering',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'NGO',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Math', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Computer'],
          description:
            'Computer Engineering focuses on designing, developing, and maintaining computer systems and hardware.',
        },
        {
          name: 'Computer Graphics and Interactive Media',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Retail Shop',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Arts', 'Computer'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts', 'Computer'],
          description:
            'Computer Graphics and Interactive Media focuses on the creation and manipulation of visual content using computer technology, along with the development of interactive digital experiences.',
        },
        {
          name: 'Computer Network and Data Communications',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'NGO',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Math', 'Physics', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Physics', 'Computer'],
          description:
            'Computer Network and Data Communications is a field that focuses on designing, implementing, and managing interconnected computer systems that exchange data.',
        },
        {
          name: 'Computer Science',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'NGO',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Math', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Computer'],
          description:
            'Computer Science Studies topics about programming, data structures, artificial intelligence, databases, and computer networks.',
        },
        {
          name: 'Data Science',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'Bank',
            'Company',
            'Retail Shop',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Math', 'Physics', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Physics', 'computer'],
          description:
            'Data Science is a field that focuses on extracting insights and knowledge from data using statistical analysis, machine learning, and computational techniques.',
        },
        {
          name: 'Mechatronics Engineering',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Physics', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Physics', 'computer'],
          description:
            'Mechatronics Engineering focuses on integrating mechanical, electronic, and computer systems to design and create advanced automated and robotic systems.',
        },
        {
          name: 'Telecommunication and Networks Engineering',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'NGO',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Math', 'Physics', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Physics', 'Computer'],
          description:
            'Telecommunication and Networks Engineering studies topics about telecommunications technologies, network architecture, data transmission, and wireless communications.',
        },
      ],
    },
    {
      id: 12,
      industryName: 'Hospitality and Tourism',
      listmajors: [
        'Archaeology',
        'Culinary Arts and Restaurant Management',
        'Hospitality',
        'Hotel Management and Tourism',
      ],

      majors: [
        {
          name: 'Archaeology',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Retail Shop',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['History'],
          workType: ['Analysing data'],
          preferredCourses: ['History'],
          description:
            'Archaeology focuses on the study of human history through excavation and analysis of artifacts, structures, and other physical remains.',
        },
        {
          name: 'Conservation, Restoration of Cultural Property and Sacred Art',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Retail Shop',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['History', 'Arts'],
          workType: ['Analysing data'],
          preferredCourses: ['History', 'Arts'],
          description:
            'Conservation, Restoration of Cultural Property and Sacred Art is a field dedicated to the preservation, protection, and restoration of historical artifacts, artworks, and sacred objects.',
        },
        {
          name: 'Culinary Arts and Restaurant Management',
          workplaces: ['Company', 'Retail Shop'],
          requiredSubjects: ['Arts'],
          workType: ['Serving people or interacting with them'],
          preferredCourses: ['Arts'],
          description:
            'Culinary Arts and Restaurant Management is a field that combines the study of cooking techniques and food preparation with the management of restaurant operations.',
        },
        {
          name: 'Hospitality',
          workplaces: ['Company', 'Retail Shop'],
          requiredSubjects: ['Sociology'],
          workType: ['Serving people or interacting with them'],
          preferredCourses: ['Sociology'],
          description:
            'Hospitality focuses on studying the hospitality industry, including hotel and restaurant management, tourism, event planning, and customer service.',
        },
        {
          name: 'Hotel Management and Tourism',
          workplaces: ['Company', 'Retail Shop'],
          requiredSubjects: ['Sociology'],
          workType: ['Serving people or interacting with them'],
          preferredCourses: ['Sociology'],
          description:
            'Hotel Management and Tourism studies topics about hospitality management, tourism planning, guest services, marketing, and financial management within the industry.',
        },
      ],
    },
    {
      id: 13,
      industryName: 'Logistics',
      listmajors: ['Distribution and Logistics Management'],

      majors: [
        {
          name: 'Distribution and Logistics Management',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'NGO',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Retail Shop',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: [''],
          workType: ['Analysing data'],
          preferredCourses: [''],
          description:
            'Distribution and Logistics Management studies topics about optimizing supply chain operations, managing logistics networks, and ensuring timely delivery.',
        },
      ],
    },
    {
      id: 14,
      industryName: 'Real estate',
      listmajors: [
        'Architecture',
        'Arts and Interior Planning',
        'Civil Engineering',
        'Civil Environmental Engineering',
        'Landscape Engineering',
      ],

      majors: [
        {
          name: 'Architecture',
          workplaces: [
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Arts'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Math', 'Arts'],
          description:
            'Architecture studies topics about architectural design principles, building materials, structural engineering, and environmental considerations.',
        },
        {
          name: 'Arts and Interior Planning',
          workplaces: [
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Math', 'Arts'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Math', 'Arts'],
          description:
            'Arts and Interior Planning focuses on space planning, color theory, furniture design, and architectural elements, as well as client needs and trends in interior design.',
        },
        {
          name: 'Civil Engineering',
          workplaces: [
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Physics', 'Computer'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Math', 'Physics', 'Computer'],
          description:
            'Civil Engineering prepares students for careers in construction, urban planning, transportation, and environmental management, with roles such as civil engineer, project manager, and structural designer.',
        },
        {
          name: 'Civil Environmental Engineering',
          workplaces: [
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology', 'Physics', 'Computer'],
          workType: ['Working for the environment'],
          preferredCourses: ['Math', 'Biology', 'Physics', 'Computer'],
          description:
            'Civil Environmental Engineering combines civil engineering principles with environmental science and focuses on water resources management, waste treatment, pollution control, and sustainable construction practices.',
        },
        {
          name: 'Landscape Engineering',
          workplaces: [
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Arts', 'Computer'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Math', 'Arts', 'Computer'],
          description:
            'Landscape Engineering focuses on the study of designing, planning, and managing outdoor spaces and environments with an emphasis on both aesthetic and functional aspects.',
        },
      ],
    },
    {
      id: 15,
      industryName: 'Creative Industries',
      listmajors: [
        'Advertising and Marketing Communication',
        'Architecture',
        'Arts and Interior Planning',
        'Audiovisual Production',
        'Cinema Production',
        'Civil Engineering',
        'Civil Environmental Engineering',
        'Communication and Visual Arts',
        'Computer Graphics and Interactive Media',
        'Design and Applied Arts',
        'Digital Advertising',
        'Digital Media',
        'Fashion and Pattern Drafting',
        'Graphic Design',
        'Journalism and Electronic Media',
        'Landscape Engineering',
        'Music',
        'Photography',
        'Product Design',
        'Radio/TV and Film',
      ],

      majors: [
        {
          name: 'Advertising and Marketing Communication',
          workplaces: [
            'Bank',
            'Company',
            'Retail Shop',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Arts'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts'],
          description:
            'Advertising and Marketing Communication studies topics on how to develop compelling messages, create marketing campaigns, and utilize various media channels to reach target audiences effectively.',
        },
        {
          name: 'Architecture',
          workplaces: [
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Arts'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Math', 'Arts'],
          description:
            'Architecture studies topics about architectural design principles, building materials, structural engineering, and environmental considerations.',
        },
        {
          name: 'Arts and Interior Planning',
          workplaces: [
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Math', 'Arts'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Math', 'Arts'],
          description:
            'Arts and Interior Planning focuses on space planning, color theory, furniture design, and architectural elements, as well as client needs and trends in interior design.',
        },
        {
          name: 'Audiovisual Production',
          workplaces: ['Company', 'Research or Consultancy Company', 'Media'],
          requiredSubjects: ['Arts', 'Computer'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts', 'Computer'],
          description:
            'Audiovisual Production studies topics about filming techniques, sound design, editing, and post-production processes, as well as storytelling and project management.',
        },
        {
          name: 'Cinema Production',
          workplaces: ['Company', 'Research or Consultancy Company', 'Media'],
          requiredSubjects: ['Arts', 'Computer'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts', 'Computer'],
          description:
            'Cinema Production studies topics about technical skills and creative techniques needed to produce films, from pre-production planning to post-production editing.',
        },
        {
          name: 'Civil Engineering',
          workplaces: [
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Physics', 'Computer'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Math', 'Physics', 'Computer'],
          description:
            'Civil Engineering prepares students for careers in construction, urban planning, transportation, and environmental management, with roles such as civil engineer, project manager, and structural designer.',
        },
        {
          name: 'Civil Environmental Engineering',
          workplaces: [
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology', 'Physics', 'Computer'],
          workType: ['Working for the environment'],
          preferredCourses: ['Math', 'Biology', 'Physics', 'Computer'],
          description:
            'Civil Environmental Engineering combines civil engineering principles with environmental science and focuses on water resources management, waste treatment, pollution control, and sustainable construction practices.',
        },
        {
          name: 'Communication and Visual Arts',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Retail Shop',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Arts', 'Computer'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts', 'Computer'],
          description:
            'Communication and Visual Arts focuses on how to Create & analyze visual content for various platforms, develop communication strategies, and understand the impact of visual media on society.',
        },
        {
          name: 'Computer Graphics and Interactive Media',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Retail Shop',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Arts', 'Computer'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts', 'Computer'],
          description:
            'Computer Graphics and Interactive Media focuses on the creation and manipulation of visual content using computer technology, along with the development of interactive digital experiences.',
        },
        {
          name: 'Design and Applied Arts',
          workplaces: [
            'NGO',
            'Company',
            'Retail Shop',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Arts', 'Computer'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts', 'Computer'],
          description:
            'Design and Applied Arts study topics about design theory, color theory, materials, and digital tools, preparing them for careers in various creative industries where design plays a central role.',
        },
        {
          name: 'Digital Advertising',
          workplaces: [
            'Bank',
            'Company',
            'Retail Shop',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Arts', 'Computer'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts', 'Computer'],
          description:
            'Digital Advertising studies topics about digital marketing strategies, content creation, analytics, and consumer behavior, preparing them for careers in online marketing, digital media, and advertising agencies.',
        },
        {
          name: 'Digital Media',
          workplaces: [
            'Company',
            'Retail Shop',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Arts', 'Computer'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts', 'Computer'],
          description:
            'Digital Media studies topics about various forms of digital content, including text, images, audio, and video, and how to leverage digital tools and platforms to engage audiences.',
        },
        {
          name: 'Fashion and Pattern Drafting',
          workplaces: [
            'NGO',
            'Company',
            'Retail Shop',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Arts'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts'],
          description:
            'Fashion and Pattern Drafting focuses on designing and creating clothing and accessories. Students study how to create and modify patterns, select fabrics, and bring fashion concepts to life.',
        },
        {
          name: 'Graphic Design',
          workplaces: [
            'Company',
            'Retail Shop',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Arts', 'Computer'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts', 'Computer'],
          description:
            'Graphic Design is a major that focuses on creating visual content to communicate messages and ideas. Students learn to use design software and develop creative problem-solving abilities to produce aesthetically pleasing and effective visual communications.',
        },
        {
          name: 'Journalism and Electronic Media',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Languages', 'Sociology'],
          workType: ['Serving people or interacting with them'],
          preferredCourses: ['Languages', 'Sociology'],
          description:
            'Journalism and Electronic Media studies topics about journalism ethics, multimedia storytelling, digital media platforms, broadcasting, and content creation.',
        },
        {
          name: 'Landscape Engineering',
          workplaces: [
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Arts', 'Computer'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Math', 'Arts', 'Computer'],
          description:
            'Landscape Engineering focuses on the study of designing, planning, and managing outdoor spaces and environments with an emphasis on both aesthetic and functional aspects.',
        },
        {
          name: 'Music',
          workplaces: ['NGO', 'Company', 'Media'],
          requiredSubjects: ['Arts'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts'],
          description:
            'Music studies topics about various musical genres, techniques, and instruments, as well as music theory, ear training, and music history.',
        },
        {
          name: 'Photography',
          workplaces: [
            'NGO',
            'Company',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Arts'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts'],
          description:
            'Photography focuses on the study of visual storytelling and the technical aspects of capturing and editing images.',
        },
        {
          name: 'Product Design',
          workplaces: [
            'Company',
            'Retail Shop',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Arts'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts'],
          description:
            'Product Design studies topics about design principles, materials, manufacturing processes, user experience, and prototyping.',
        },
        {
          name: 'Radio/TV and Film',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Arts', 'Computer'],
          workType: ['Creating new ideas'],
          preferredCourses: ['Arts', 'Computer'],
          description:
            'Radio/TV and Film focuses on the production, direction, and management of audio-visual content across various media platforms.',
        },
      ],
    },
    {
      id: 16,
      industryName:
        'Green Industries (Renewable Energy and Sustainable Agriculture)',
      listmajors: [
        'Agri-Business',
        'Agricultural Economics',
        'Agricultural Engineering',
        'Animal Science and Technology',
        'Applied Mathematics',
        'Biology',
        'Chemical Engineering',
        'Chemistry',
        'Civil Environmental Engineering',
        'Engineering Sciences-Food Engineering',
        'Environmental / Forestry Engineering',
        'Environmental Health/ Sciences',
        'Fundamental Health Science',
        'Mechanical Engineering',
        'Mechatronics Engineering',
        'Petrochemical Engineering',
        'Petroleum Engineering',
        'Physics',
        'Plant Production/ Protection',
        'Poultry and Cattle Medicine',
      ],

      majors: [
        {
          name: 'Agri-Business',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Retail Shop',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology', 'Chemistry'],
          workType: ['Working for the environment'],
          preferredCourses: ['Math', 'Biology', 'Chemistry'],
          description:
            'Agri-Business focuses on the business aspects of agriculture, including farm management, agricultural finance, marketing, and supply chain logistics.',
        },
        {
          name: 'Agricultural Economics',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology', 'Chemistry'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Biology', 'Chemistry'],
          description:
            'Agricultural Economics focuses on analyzing agricultural production, market trends, and policy impacts, with an emphasis on optimizing resource use, improving farm profitability, and addressing issues related to food supply and rural development.',
        },
        {
          name: 'Agricultural Engineering',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology', 'Chemistry', 'Computer'],
          workType: ['Working for the environment'],
          preferredCourses: ['Math', 'Biology', 'Chemistry', 'Computer'],
          description:
            'Agricultural Engineering focuses on designing and optimizing machinery, irrigation systems, and infrastructure to enhance productivity, efficiency, and sustainability in agriculture.',
        },
        {
          name: 'Animal Science and Technology',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology', 'Chemistry', 'Computer'],
          workType: ['Working for the environment'],
          preferredCourses: ['Math', 'Biology', 'Chemistry', 'Computer'],
          description:
            'Animal Science and Technology focuses on the study of animal biology, management, and technology to improve the health, productivity, and welfare of domestic animals.',
        },
        {
          name: 'Applied Mathematics',
          workplaces: [
            'Hospital',
            'Government',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math'],
          workType: ['Analysing data'],
          preferredCourses: ['Math'],
          description:
            'Applied Mathematics focuses on using mathematical methods and techniques to solve real-world problems across various fields, including engineering, science, economics, and technology.',
        },
        {
          name: 'Biology',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology'],
          workType: ['Analysing data'],
          preferredCourses: ['Biology'],
          description:
            'Biology studies topics about the structure, function, growth, and development of various life forms, preparing them for careers in research, healthcare, environmental science, and biotechnology.',
        },
        {
          name: 'Chemical Engineering',
          workplaces: [
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Chemistry', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Chemistry', 'Computer'],
          description:
            'Chemical Engineering focuses on the design, optimization, and operation of processes that transform raw materials into valuable products.',
        },
        {
          name: 'Chemistry',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Chemistry'],
          workType: ['Analysing data'],
          preferredCourses: ['Chemistry'],
          description:
            'Chemistry studies topics about chemical reactions, molecular interactions, and laboratory techniques, preparing students for careers in research, pharmaceuticals, environmental science, and chemical manufacturing.',
        },
        {
          name: 'Civil Environmental Engineering',
          workplaces: [
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology', 'Physics', 'Computer'],
          workType: ['Working for the environment'],
          preferredCourses: ['Math', 'Biology', 'Physics', 'Computer'],
          description:
            'Civil Environmental Engineering combines civil engineering principles with environmental science and focuses on water resources management, waste treatment, pollution control, and sustainable construction practices.',
        },
        {
          name: 'Engineering Sciences-Food Engineering',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Chemistry', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Biology', 'Chemistry', 'Computer'],
          description:
            'Engineering Sciences-Food Engineering studies topics about food processing technologies, biochemical processes, and the engineering aspects of food production, preparing them for careers in the food industry, quality assurance, and food technology.',
        },
        {
          name: 'Environmental / Forestry Engineering',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology', 'Computer'],
          workType: ['Working for the environment'],
          preferredCourses: ['Math', 'Biology', 'Computer'],
          description:
            'Environmental / Forestry Engineering studies topics about ecosystem management, environmental impact assessment, and the engineering aspects of land and water management.',
        },
        {
          name: 'Environmental Health/ Sciences',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology'],
          workType: ['Working for the environment'],
          preferredCourses: ['Math', 'Biology'],
          description:
            'Environmental Health/ Sciences studies topics about environmental toxins, epidemiology, risk assessment, and environmental regulations.',
        },
        {
          name: 'Fundamental Health Science',
          workplaces: [
            'Hospital',
            'Government',
            'NGO',
            'Company',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology'],
          workType: ['Helping people mentally or physically'],
          preferredCourses: ['Biology'],
          description:
            'Fundamental Health Science studies topics about the mechanisms of health and illness, research methodologies, and foundational concepts that inform clinical practices and health interventions.',
        },
        {
          name: 'Mechanical Engineering',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Physics', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Physics', 'Computer'],
          description:
            'Mechanical Engineering focuses on the study of the design, analysis, and manufacturing of mechanical systems and devices.',
        },
        {
          name: 'Mechatronics Engineering',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Physics', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Physics', 'computer'],
          description:
            'Mechatronics Engineering focuses on integrating mechanical, electronic, and computer systems to design and create advanced automated and robotic systems.',
        },
        {
          name: 'Petrochemical Engineering',
          workplaces: [
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Chemistry', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Chemistry', 'Computer'],
          description:
            'Petrochemical Engineering is a specialized branch of chemical engineering that focuses on the production and processing of chemicals derived from petroleum and natural gas.',
        },
        {
          name: 'Petroleum Engineering',
          workplaces: [
            'Government',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Chemistry', 'Computer'],
          workType: ['Analysing data'],
          preferredCourses: ['Math', 'Chemistry', 'Computer'],
          description:
            'Petroleum Engineering focuses on the study of the extraction and production of oil and natural gas.',
        },
        {
          name: 'Physics',
          workplaces: [
            'Hospital',
            'Government',
            'Company',
            'Schools/ Universities',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Physics'],
          workType: ['Analysing data'],
          preferredCourses: ['Physics'],
          description:
            'Physics focuses on studying the fundamental principles governing matter, energy, and the interactions between them.',
        },
        {
          name: 'Plant Production/ Protection',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Physics'],
          workType: ['Working for the environment'],
          preferredCourses: ['Biology', 'Physics'],
          description:
            'Plant Production/ Protection focuses on the study of cultivating crops and managing plant health to maximize yield and minimize losses.',
        },
        {
          name: 'Poultry and Cattle Medicine',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Math', 'Biology'],
          workType: ['Working for the environment'],
          preferredCourses: ['Math', 'Biology'],
          description:
            'Poultry & Cattle Medicine focuses on the veterinary care of poultry and cattle, including their health management, disease prevention, and treatment.',
        },
      ],
    },
    {
      id: 17,
      industryName: 'Religious Industry',
      listmajors: [
        'Christian Studies',
        'Islamic Studies',
        'Religions Sciences',
        'Theology',
      ],

      majors: [
        {
          name: 'Christian Studies',
          workplaces: [
            'Company',
            'Schools/ Universities',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['History'],
          workType: ['Transfer your knowledge to others'],
          preferredCourses: ['History'],
          description:
            'Christian Studies focuses on interfaith dialogue, comparative theology, and the social and political impacts of this religion.',
        },
        {
          name: 'Islamic Studies',
          workplaces: [
            'Company',
            'Schools/ Universities',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['History'],
          workType: ['Transfer your knowledge to others'],
          preferredCourses: ['History'],
          description:
            'Islamic Studies focuses on the study of Islamic religion, culture, history, and civilization.',
        },
        {
          name: 'Religions Sciences',
          workplaces: [
            'Company',
            'Schools/ Universities',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['History'],
          workType: ['Transfer your knowledge to others'],
          preferredCourses: ['History'],
          description:
            'Religions Sciences focuses on the study of religious beliefs, practices, and institutions across various cultures and historical contexts.',
        },
        {
          name: 'Theology',
          workplaces: [
            'Company',
            'Schools/ Universities',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['History'],
          workType: ['Transfer your knowledge to others'],
          preferredCourses: ['History'],
          description:
            'Theology explores theological concepts, sacred texts, religious history, and the philosophy of religion.',
        },
      ],
    },

    {
      id: 18,
      industryName: 'Business and Management Industries',
      listmajors: ['Business Administration', 'Human Resources Management'],

      majors: [
        {
          name: 'Business Administration',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'NGO',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Retail Shop',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: [''],
          workType: ['Serving people or interacting with them'],
          preferredCourses: [''],
          description:
            'Business Administration focuses on the overall management and operation of businesses, covering a broad range of topics such as finance, marketing, human resources, and operations.',
        },
        {
          name: 'Human Resources Management',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'NGO',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Retail Shop',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: [''],
          workType: ['Serving people or interacting with them'],
          preferredCourses: [''],
          description:
            "Human Resources Management focuses on the study of managing an organization's workforce, including recruitment, training, employee relations, and performance management.",
        },
      ],
    },
    {
      id: 19,
      industryName: 'Legal Industries',
      listmajors: ['Forensic Science', 'Law'],

      majors: [
        {
          name: 'Forensic Science',
          workplaces: [
            'Government',
            'Company',
            'Research or Consultancy Company',
          ],
          requiredSubjects: ['Biology', 'Chemistry', 'Physics'],
          workType: ['Analysing data'],
          preferredCourses: ['Biology', 'Chemistry', 'Physics'],
          description:
            'Forensic Science is a field that applies scientific principles and techniques to solve crimes and legal issues. Students learn about forensic biology, chemistry, toxicology, and crime scene investigation.',
        },
        {
          name: 'Law',
          workplaces: [
            'Hospital',
            'Airport',
            'Government',
            'NGO',
            'Bank',
            'Company',
            'Schools/ Universities',
            'Retail Shop',
            'Manufacturing Enterprise',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['Languages', 'Philosophy'],
          workType: ['Analysing data'],
          preferredCourses: ['Languages', 'Philosophy'],
          description:
            'Law studies topics including constitutional, criminal, civil, and international law, as well as legal research, ethics, and court procedures.',
        },
      ],
    },
    {
      id: 20,
      industryName: 'Administrative and Politics Industries',
      listmajors: [
        'Administrative and Politics Sciences',
        'International and Diplomatic Relations',
        'International Business',
      ],

      majors: [
        {
          name: 'Administrative and Politics Sciences',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Research or Consultancy Company',
            'Media',
          ],
          requiredSubjects: ['History', 'Sociology'],
          workType: ['Serving people or interacting with them'],
          preferredCourses: ['History', 'Sociology'],
          description:
            'Administrative and Politics Sciences studies topics about the creation and implementation of public policies, the roles of various governmental and non-governmental organizations, and the principles of political analysis and leadership.',
        },
        {
          name: 'International and Diplomatic Relations',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Research or Consultancy Company',
          ],
          requiredSubjects: [''],
          workType: ['Serving people or interacting with them'],
          preferredCourses: [''],
          description:
            'International and Diplomatic Relations focuses on the study of global politics, diplomacy, international law, and relations between countries.',
        },
        {
          name: 'International Business',
          workplaces: [
            'Government',
            'NGO',
            'Company',
            'Research or Consultancy Company',
          ],
          requiredSubjects: [''],
          workType: ['Serving people or interacting with them'],
          preferredCourses: [''],
          description:
            'International Business focuses on the study of global commerce, including the strategies, operations, and challenges of conducting business across international borders.',
        },
      ],
    },
  ],
};
